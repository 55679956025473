<div class="container">
  <h1 class="text-center"><img src="assets/img/logo-Publiexpress.png"></h1>
  <h2 class="text-center">Cotización #{{producto.id}}</h2>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6"><label>Nombre</label> {{producto.nombre | titlecase}}</div>
        <div class="col-md-6"><label>Comercio </label> {{producto.comercio}}</div>
        <div class="col-md-6"><label>Contacto</label> {{producto.contacto}}</div>
        <div class="col-md-6"><label>Fecha </label> {{producto.publiexpress_ordenes_at}}</div>
        <div class="col-md-6"><label>Email </label> {{producto.email}}</div>
        <div class="col-md-6"><label>Asesor </label> {{producto.usuario_nombre}}</div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <h5>Productos cotizados</h5>
      <table class="table table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Producto</th>
            <th scope="col" class="text-center">Cantidad</th>
            <th scope="col" class="text-right">Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of producto.productos; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>
              {{item.nombre | titlecase}}
              <div>
                <h6 *ngIf="item.cantidad == 0">Medida {{item.ancho}} x {{item.largo}}</h6>
              </div>
            </td>
            <td class="text-center"><span *ngIf="item.cantidad >=1">{{item.cantidad}} {{item.unidad | titlecase}}</span>
              <span *ngIf="item.cantidad ==0">1 Und</span></td>
            <td class="text-right text-bold">${{item.valor | decimal}}</td>
          </tr>
        </tbody>
      </table>
      <div class="text-right mt-2">
        <h4>${{producto.valor_total | decimal}}</h4>
      </div>
    </div>
  </div>
</div>