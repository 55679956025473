import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-reporte-puntos-conciliacion',
  templateUrl: './reporte-puntos-conciliacion.component.html',
  styleUrls: ['./reporte-puntos-conciliacion.component.css']
})
export class ReportePuntosConciliacionComponent implements OnInit {

  id:any;
  perfil_id:any;
  cargando:boolean = false;

  puntoSelected:any;
  saldo_total:any;
  conciliacion_total:any;
  isPuntoSelected: boolean = false;

  puntos:any = [];
  offset:any = 3;
  pagesNumber:any;
  page:any;

  conciliaciones:any = [];
  offset2:any = 3;
  pagesNumber2:any;
  page2:any;


  constructor(private servicios: ServicesService) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoPuntos();
  }

  listadoPuntos(page = 1){
    this.cargando = true;
    this.isPuntoSelected = false;
    this.servicios.getPuntos(page).subscribe( (data) => {
      this.puntos = data['comercios'];
      this.saldo_total = data['saldo_total'];
      this.cargando = false;
      this.ForpagesNumber();
    });
  }

  ForpagesNumber() {
    let from = this.puntos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.puntos.total_page){
      to = this.puntos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  changePage(page) {
    this.page = page;
    this.puntos.current = page;
    this.listadoPuntos(page); 
    // this.filtro();      
  }

  onSelectPunto(item){
    this.puntoSelected = item;
    this.listadoConciliaciones();
    this.isPuntoSelected = true;
  }

  listadoConciliaciones(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getComerciosConciliacion(this.puntoSelected.id+'-'+this.puntoSelected.perfil_id+''+page).subscribe( (data) => {
        this.conciliaciones = data['conciliacion'];
        this.conciliacion_total = data['total'];
        this.cargando = false;
        this.ForpagesNumber2()
      });
    }else{
      var url = this.servicios.getComerciosConciliacion(this.puntoSelected.id+'-'+this.puntoSelected.perfil_id+'-'+page).subscribe( (data) => {
        this.conciliaciones = data['conciliacion'];
        this.conciliacion_total = data['total'];
        this.cargando = false;
        this.ForpagesNumber2()

      });
    }
  }
  ForpagesNumber2() {
    let from = this.conciliaciones.current - this.offset2;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset2 * 2); 
    if(to >= this.conciliaciones.total_page){
      to = this.conciliaciones.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber2 = pagesArray;
    // return pagesArray;
  }

  changePage2(page) {
    this.page2 = page;
    this.conciliaciones.current = page;
    this.listadoConciliaciones(page); 
    // this.filtro();      
  }

}
