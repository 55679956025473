import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  pass:any;
  repass:any;
  token:any;
  fieldTextType: boolean;
  comercio_id:any;

  constructor(public router: Router,private servicios: ServicesService) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('comercio_id');
    // console.log(this.comercio_id);
  }

  changePass(){
    if(this.pass === this.repass){

      let params = {
        change: true,
        password: this.pass,
        cid: this.comercio_id,
      };
      this.servicios.postComercio(params)
      .subscribe( (data) => {
        if (data["logger"] == true){
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });        
  
          this.router.navigate(['/operaciones']);     
        } else {
          // this.codeSms = data['cd'];
        }
      });

    }
 
  }

  toggleFieldTextTypePass() {
    this.fieldTextType = !this.fieldTextType;
  } 

}