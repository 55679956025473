import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-conciliacion',
  templateUrl: './ver-conciliacion.component.html',
  styleUrls: ['./ver-conciliacion.component.css']
})
export class VerConciliacionComponent implements OnInit {

  cargando: boolean =false;
  verComercio: any = {};
  id: any;
  nombre: any;
  razon_social:any;
  saldo:any;
  ganancia:any;
  historial:any = {};

  img: any;
  valor: any;
  valorConfirmacion: any;
  nota: any;
  checkNumberOnly:any;
  checkString:any;
  dotCount:number=0
  disabledButton = false;

  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;

  constructor(public router: Router,
    public servicios: ServicesService) { 
    if(this.router.getCurrentNavigation().extras.state){
      this.verComercio = this.router.getCurrentNavigation().extras.state.itemComercio;
      this.id = this.verComercio['id']; 
      this.nombre = this.verComercio['nombre'];                     
      this.razon_social = this.verComercio['razon_social'];                     
      this.saldo = this.verComercio['saldo'];                     
      this.ganancia = this.verComercio['ganancia'];                     
      this.historial = this.verComercio['historial']; 
                          
    } 
  }

  ngOnInit(): void {
    // if(!this.id){
    //   this.router.navigate(['../comercios/pasacupo'])
    // }
  }

  
  setPasacupo(){
    this.disabledButton = true;
    // console.log(this.valor);
    
    let params = {
      nuevo: true,
      id: this.id,
      valor: this.valor,
      nota:this.nota
    }
    // console.log(params);
    this.servicios.postComerciosPasacupo(params)
    .subscribe( (data) => {
      console.log(data);

      if (data["logger"] == true){
        this.disabledButton = false;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
        this.router.navigate(['../comercios/pasacupo'])
      } else {
        this.disabledButton = false;
      }
    });

  }

  checarSiSonIguales(){
    if(this.valor ==this.valorConfirmacion){
      console.log('son iguales');
    }
    
  }
  cancelar(){
    this.router.navigate(['../comercios/pasacupo'])
  }

  numberOnly() {
    // console.log('dentro');
    // console.log(event.target.value);
    // console.log(this.valor);
    
    let num = this.valor; 
    // if(!isNaN(num)){
    // num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    // num = num.split('').reverse().join('').replace(/^[\.]/,'');
    // return this.valor = num;
    // }else{
    //   return null;
    // }       
  }

  preview(files) {
    // this.cargando = true;
    console.log('entrando');
    console.log(files);
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // if (mimeType.match(/application\/pdf/) || mimeType.match(/image\/png/) || mimeType.match(/image\/jpeg/) ||  mimeType.match(/image\/jpg/)  == null) {
    //   this.message = "No hay Archivo.";
    //   return;
    // }
    var reader = new FileReader();
    this.imagePath = files;
    this.fileName = files[0].name
    console.log(this.fileName);
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      this.saveAdjunto();
    }
  }

  saveAdjunto(){
    if (!this.fileURL){
        console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL
      };
      this.cargando = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        console.log(data);
        
        if(data['logger'] == true){
          this.img = data['filename'];
          let ruta = data['fileurl'];
          this.listadjuntos.push(params);
          this.cargando = false;
        } else {
          // this.alerta(data['msg'], 'error');
          this.cargando = false;
        }
      });
    }
  }
}
