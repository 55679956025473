import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-pasar-ganancia',
  templateUrl: './pasar-ganancia.component.html',
  styleUrls: ['./pasar-ganancia.component.css']
})
export class PasarGananciaComponent implements OnInit {

  disabledButton:boolean = false;
  itemVer: any = [];
  cargando: boolean =false;
  spinner: boolean =false;
  // id: any;
  perfil_id: any;
  comercio_id: any;
  valor: any;

  pasar_ganancia: any = [];
  pagesNumber: any;
  offset= 3;
  buscar: any;
  page: any;

  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;

  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.comercio_id);
    this.listadoSolicitudesPasarGanancia();
  }

  listadoSolicitudesPasarGanancia(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getPasarGanancia(this.comercio_id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        this.pasar_ganancia = data['pasar_ganancia'];
        // console.log('resp = ' + this.pasar_ganancia);
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getPasarGanancia(this.comercio_id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.pasar_ganancia = data['pasar_ganancia'];
        this.cargando = false;
        this.ForpagesNumber()

      });
    }     
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.pasar_ganancia.current = page;
    // this.filtro();
        // this.listadopasar_ganancia(page); 
    this.listadoSolicitudesPasarGanancia(page);       
  }

  ForpagesNumber() {
    let from = this.pasar_ganancia.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.pasar_ganancia.total_page){
      to = this.pasar_ganancia.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  filtro(){
    // console.log('page '+this.page);
    // console.log('buscar '+this.buscar);
      let params = {
        filtro: true,
        buscar: this.buscar,
        page: this.page
      }
      this.servicios.postPasarGanancia(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.pasar_ganancia = data['pasar_ganancia'];
            this.ForpagesNumber();
          } else {
            this.pasar_ganancia.size = 0;
            this.pasar_ganancia.items = [];
          }      
      });    
  }  

  guardar(){
    if(this.valor <= 0 || this.valor == null){
      this.spinner = false;
      this.limpiarModal();
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "El valor proporcionado no es válido!",
        showConfirmButton: false,
        timer: 1500,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      });
      return ;
    }
    let params = {
      nuevo: true,
      id: this.comercio_id,
      valor: this.valor
    }
    // console.log(params);
    this.spinner = true;
    this.servicios.postPasarGanancia(params)
    .subscribe( (data) => {
      // console.log(data);
      if (data["logger"] == true){
        this.spinner = false;
        this.limpiarModal();
        this.listadoSolicitudesPasarGanancia();
        this.cerrarModal('modalSolicitudPasarGanancia');
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
      } else {
        this.spinner = false;
        this.limpiarModal();
        this.listadoSolicitudesPasarGanancia();
        this.cerrarModal('modalSolicitudPasarGanancia');
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
      }
    });
  }

  ver(item){
    // console.log(item);
    this.itemVer = item;
  }

  cerrarModal(info){
    // console.log(info);
    this.limpiarModal();
    // document.getElementById(info).style.display = "none";
    if(info == 'modalVer'){
      $('#modalVer').modal('hide');
    }
    if(info == 'modalSolicitudPasarGanancia'){
      $('#modalSolicitudPasarGanancia').modal('hide');
    }
  }

  limpiarModal(){
    this.valor = '';
  }

  procesar(item:any, estado){
    let text = estado == 2 ? 'Desea aprobar la solicitud' : 'Desea rechazar la solicitud'; 
    let accion = estado == 2 ? 'Sí, Aprobar!' : 'Sí, Rechazar!'; 
    Swal.fire({
      title: '¿Está seguro?',
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: accion,
    }).then((result) => {
      if (result.value) {
        let params = {
          editarEstado : true,
          estado : estado,
          id: item.id
        }
        console.log(params);
        this.spinner = true;
        this.servicios.postPasarGanancia(params).subscribe( data => {
          if (data["logger"] == true){
            this.spinner = false;
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            this.spinner = false;
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
          this.cerrarModal('modalVer');
          this.listadoSolicitudesPasarGanancia();
        } );
      }
    });
  }

}
