<div class="login-page login-img">
    <div class="login-box" style="margin-top: -5%;">

      <div class="row mb-3" style="z-index: 1000;position: relative">
        <div class="col-md-12">
          <span id="logo-plataforma" class="logo-lg">
            <img src="assets/img/logo-mensajero.png" style="display:block;margin:auto">
          </span>
        </div>
      </div>

      <!-- /.login-logo -->
      <div class="card" style="z-index: 1000;position: relative">
        <div style="border-radius:8px">
          <p class="login-box-msg" style="font-weight: 600;">INGRESA TUS DATOS</p>
    
          <form>
            <div class="input-group mb-3">
              <input [(ngModel)]="usuario" name="usuario" type="email" class="form-control" placeholder="Nombre de Usuario">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input [(ngModel)]="password" name="password"  type="password" class="form-control" placeholder="Contraseña">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button (click)="Ingresar()" class="btn btn-primary btn-block">Ingresar</button>
              </div>
              <!-- /.col -->
            </div>
          </form>
    
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
    
  </div>
