
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Pasa Cupo</h1>
              </div>
              <div class="col-sm-12 mt-3">             
              </div>
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="mb-2"><h5>{{nombre }} / {{razon_social}} <div>Saldo <span style="color: red;"><b>${{saldo | decimal}}</b></span>  Ganancia <span style="color: green;"><b>${{ganancia | decimal}}</b></span></div></h5></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Valor</label>
                                <input [(ngModel)]="valor" name="valor" class="form-control" style="font-size: 2em;" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" min="900">
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Observación</label>
                                <textarea class="form-control" rows="3" [(ngModel)]="nota"></textarea>
                            </div>                            
                        </div>
                        <div class="col-md-12">
                        <div class="form-group">
                            <label for="img" class="form-label">Comprobante PNG/JPEG/JPG*</label>
                            <input [(ngModel)]="img" type="file" class="form-control" name="img" #file accept='image/png, image/jpeg, image/jpg' (change)="preview(file.files)">
                          </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <button *ngIf="valor >=900 && nota"  class="btn btn-primary mr-1" [disabled]="disabledButton" (click)="setPasacupo()">
                              <span *ngIf="!disabledButton">Enviar</span>
                              <span *ngIf="disabledButton">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Procesando...
                              </span>
                            </button>                             
                            <!-- <button class="btn btn-primary mr-1" *ngIf="valor >=900 && nota" (click)="setPasacupo()">Enviar</button> -->
                            <button class="btn btn-danger" (click)="cancelar()">Cancelar</button>
                        </div>                        
                    </div>

                </div>
  
               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
            <div class="col-8">
              <div class="card">
                <div class="card-header">
                  <div class="card-title">Historial pasacupo</div>
                </div>
                <div class="card-body">
                  <div class="row" *ngFor="let item of historial">
                    <div class="col-8">{{item.nota}}</div>
                    <div class="col-2"><b>${{item.valor | decimal}}</b></div>
                    <div class="col-2">{{item.comercio_pasacupo_at}}</div>
                  </div>

                  <div *ngIf="!historial.length">
                    <h5 class="text-center">No hay historial disponible</h5>
                  </div>

                </div>
              </div>

            </div>
          </div>   
        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  