import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-listado-conciliacion',
  templateUrl: './listado-conciliacion.component.html',
  styleUrls: ['./listado-conciliacion.component.css']
})
export class ListadoConciliacionComponent implements OnInit {

  itemVer:any = [];

  public imagePath;
  imgURL: any;
  imgurl: any;
  public message: string;
  img: any;
  
  listadjuntos: any = [];
  fileURL: any;
  fileName: any;
  imgAdj:any;

  conciliaciones:any = [];

  findConvenio = false;
  cargando = false;
  cargandoImg = false;
  loaderImgComprobante: boolean = false;
  mensajero: any = [];
  pagesNumber: any;
  offset= 3;
  id: any;
  idItem: any;
  perfil_id: any;
  comercio_id: any;

  id_orden: any;
  ordenItem: any = {};

  listConvenios: any = [];
  
  recaudo_clientes_item_id:any;
  codigo_cuenta:any;
  referencia:any;
  nombre:any;
  fecha_ven:any;
  valor:any;
  nota:any;

  convenio_comercio_id:any;
  razon_social:any;
  tipo_documento:any;
  numero_documento:any;

  disabledButton: boolean = false;
  spinner: boolean  = false;
  reporteExport:any;

  fecha_desde:any;
  fecha_hasta: any;

  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoConciliaciones();
    this.getListConvenios();
  }

  saveConciliacion(){
    // console.log(this.comercio_id);
    let params = {
      nuevo: true,
      comercio_id: this.comercio_id,
      comercio_recaudo_id: this.convenio_comercio_id,
      valor: this.valor,
      img: this.imgAdj,
      nota: this.nota,
      estado: 2
    }
    // console.log(params);
    this.disabledButton = true;
    this.servicios.postComerciosConciliacion(params)
    .subscribe( (data) => {
      // console.log(data);
      if (data["logger"] == true){
        this.disabledButton = false;
        this.listadoConciliaciones();
        this.cerrarModal('nuevaConciliacion');
        this.backConvenios();
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
      }
    });
  }

  changePage(page) {
    this.conciliaciones.current = page;
    this.listadoConciliaciones(page);
    // if(!this.buscadorPersonas){
    //   this.listadoconciliaciones(page);
    // }else{
    //   this.listadoconciliaciones();
    // }          
  }  

  listadoConciliaciones(page = null){
    // console.log(this.comercio_id);
    // console.log(this.perfil_id);
    this.cargando = true;
    if(!page){
      this.servicios.getComerciosConciliacion(this.comercio_id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        // console.log(data);
        this.conciliaciones = data['conciliacion'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getComerciosConciliacion(this.comercio_id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.conciliaciones = data['conciliacion'];
        this.cargando = false;
        this.ForpagesNumber()

      });
    }     
  }

  procesar(item, estado): void{
    let msg = "";
    let params = {
      editar: false,
      solitudstatus : false,
      estado : 1,
      id: item.id
    }
    if (estado == 2){
      msg = 'Procesar';
      params.editar = true;
      params.estado = estado;
    }
    if (estado == 0){
      msg = 'Rechazar';
      params.editar = true;
      params.estado = estado;
    } 
    if (estado == 3){
      msg = 'Aprobar';
      params.solitudstatus = true;
      params.estado = estado;
    } 
    if (estado == -1){
      msg = 'Rechazar';
      params.solitudstatus = true;
      params.estado = estado;
    } 
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea '+ msg +' la conciliación?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, '+ msg +'!',
    }).then((result) => {
      if (result.value) {  
        // console.log(params)   
        this.spinner = true;
        this.servicios.postComerciosConciliacion(params).subscribe( data => {
          if (data["logger"] == true){
            this.spinner = false;
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
          // console.log(this.conciliaciones);
          this.listadoConciliaciones();
          this.cerrarModal('modalVer');
        } );
      }
    });
  }

  ver(item){
    // console.log(item);
    this.itemVer = item;
  }

  ForpagesNumber() {
    let from = this.conciliaciones.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.conciliaciones.total_page){
      to = this.conciliaciones.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  backConvenios(){
    document.getElementById('convenios').style.display = "block";  
    document.getElementById('newConciliacion').style.display = "none";
    this.img = null;
    this.nota= null;
    this.valor= null;
    this.convenio_comercio_id= null;
  }

  getListConvenios(){
    this.cargando = true;
    // id sera el perfil_id
    let params = {
      statusperfilid: true,
      id: 3
    };
    // console.log(params)
    this.servicios.postComercio(params).subscribe( (data) => {
      this.listConvenios = data['comercio_convenios'];
      this.cargando = false;
      // console.log('dentro');      
      // console.log(data['comercio_convenios']);
    });
  }

  selectConvenio(item){
    this.img = item.imgUrl;
    this.convenio_comercio_id = item.id;
    this.razon_social = item.razon_social
    this.tipo_documento= item.tipo_documento;
    this.numero_documento= item.numero_documento;
    
    document.getElementById('convenios').style.display = "none";  
    document.getElementById('newConciliacion').style.display = "block";
    // console.log(item);
  }


  exportExcel(){
    let params = {
      export: true,
      id:this.comercio_id,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
    }
    // console.log(params);
    this.servicios.postComerciosConciliacion(params)
    .subscribe( (data) => {
      this.reporteExport = data['concilicacion'];
      let fecha = data['fecha']
      console.log(data);
      // console.log(this.reporteExport);
      
      const fileName = this.fecha_desde+'-'+this.fecha_hasta+'-reporte_concilicacion.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

      // ANCHO COLUMNAS
      ws['!cols'] = [ {width:2},{width:8},{width:35}, {width:35},{width:20},{width:20},{width:20},{width:20},{width:20},{width:20}];
      // ELIMINA COLUMNA1
      ws['!cols'][0] = { hidden: true };
      // ws['!cols'][1] = { hidden: true };
      // ws['!cols'][13] = { hidden: true };
      // ws['!cols'][14] = { hidden: true };
      // ws['!cols'][18] = { hidden: true };
      // // ws['G2'] = { Target:"#E2" };
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // CAMBIA CABECERA
      XLSX.utils.sheet_add_aoa(ws, [["log","ID","COMERCIO","RECAUDADOR","VALOR","FECHA","ESTADO","TOTAL"]]);
      XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  

      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);
    });
    
    
  }   

  preview(files) {
    this.cargando = true;
    // console.log('entrando');
    // console.log(files);
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // if (mimeType.match(/application\/pdf/) || mimeType.match(/image\/png/) || mimeType.match(/image\/jpeg/) ||  mimeType.match(/image\/jpg/)  == null) {
    //   this.message = "No hay Archivo.";
    //   return;
    // }
    var reader = new FileReader();
    this.imagePath = files;
    this.fileName = files[0].name
    // console.log(this.fileName);
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      this.saveAdjunto();
    }
  }

  saveAdjunto(){
    if (!this.fileURL){
        // console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL
      };
      this.cargando = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        // console.log(data);
        
        if(data['logger'] == true){
          this.imgAdj = data['filename'];
          let ruta = data['fileurl'];
          this.listadjuntos.push(params);
          this.cargando = false;
        } else {
          // this.alerta(data['msg'], 'error');
          this.cargando = false;
        }
      });
    }
  }

  cerrarModal(info){
    // console.log(info);
    this.backConvenios();
    // document.getElementById(info).style.display = "none";
    if(info == 'modalVer'){
      $('#modalVer').modal('hide');
    }
    if(info == 'nuevaConciliacion'){
      $('#nuevaConciliacion').modal('hide');
    }
  }
}
