<app-header-catalogo></app-header-catalogo>

<div class="card">
	<div class="row">
		<aside class="col-sm-5 border-right">
			<article class="gallery-wrap"> 
				<div class="img-big-wrap">				
						<div *ngIf="producto.imgUrl" class="bd-placeholder-img card-img-top" [ngStyle]="{'background-image':' url(' + producto.imgUrl + ')','height':'550px','width':'100%','background-size':'cover','background-position':'center'}"></div>
				</div> <!-- slider-product.// -->
				<div class="img-small-wrap">
					<!-- <div class="item-gallery"> <img src="https://s9.postimg.org/tupxkvfj3/image.jpg"> </div>
					<div class="item-gallery"> <img src="https://s9.postimg.org/tupxkvfj3/image.jpg"> </div>
					<div class="item-gallery"> <img src="https://s9.postimg.org/tupxkvfj3/image.jpg"> </div>
					<div class="item-gallery"> <img src="https://s9.postimg.org/tupxkvfj3/image.jpg"> </div> -->
				</div> <!-- slider-nav.// -->
			</article> <!-- gallery-wrap .end// -->
		</aside>
		<aside class="col-sm-7">
<article class="card-body p-5">
	<h3 class="title mb-3">{{producto.titulo}}</h3>

<p class="price-detail-wrap"> 
	<span class="price h3 text-warning"> 
		<span class="currency">COP $</span><span class="num">{{producto.valor_servicio | decimal}}</span>
	</span> 
	<!-- <span>/per kg</span>  -->
</p> <!-- price-detail-wrap .// -->
<dl class="item-property">
  <dt>Descripción</dt>
  <dd><p>{{producto.descripcion}}</p></dd>
</dl>
<dl class="param param-feature">
  <dt><h2>COD# {{producto.id}}</h2></dt>
  <dd></dd>
</dl>


<hr>
	<div class="row">
		<div class="col-sm-5">
			<dl class="param param-inline">
			  <dt>Cantidad: </dt>
			  <dd>
			  	<select class="form-control form-control-sm" style="width:70px;">
			  		<option value="1"> 1</option>
			  		<option value="2"> 2</option>
			  		<option value="3"> 3</option>
			  		<option value="4"> 4</option>
			  		<option value="5"> 5</option>
			  	</select>
			  </dd>
			</dl>  <!-- item-property .// -->
		</div> <!-- col.// -->
		<!-- <div class="col-sm-7">
			<dl class="param param-inline">
				  <dt>Size: </dt>
				  <dd>
				  	<label class="form-check form-check-inline">
					  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
					  <span class="form-check-label">SM</span>
					</label>
					<label class="form-check form-check-inline">
					  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
					  <span class="form-check-label">MD</span>
					</label>
					<label class="form-check form-check-inline">
					  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
					  <span class="form-check-label">XXL</span>
					</label>
				  </dd>
			</dl>  
		</div> -->
     <!-- col.// -->
	</div> <!-- row.// -->
	<hr>
	<h4>Si desesa obtener este producto contacta a uno de nuestros puntos Pagoexpress. Mas Cercanos</h4>
	<!-- <a href="#" class="btn btn-lg btn-primary text-uppercase"> Comprar </a>
	<a href="#" class="btn btn-lg btn-outline-primary text-uppercase"> <i class="fas fa-shopping-cart"></i> Agregar al Carrito </a> -->
</article> <!-- card-body.// -->
		</aside> <!-- col.// -->
	</div> <!-- row.// -->
</div> <!-- card.// -->


<app-footer-catalogo></app-footer-catalogo>