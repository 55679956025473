import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var $: any;
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-solicitud-saldo',
  templateUrl: './solicitud-saldo.component.html',
  styleUrls: ['./solicitud-saldo.component.css']
})
export class SolicitudSaldoComponent implements OnInit {
  disabledButton:boolean = false;
  itemVer: any = [];
  cargando: boolean =false;
  spinner: boolean =false;
  // id: any;
  perfil_id: any;
  comercio_id: any;
  tipo_solicitud: any;
  valor: any;
  medio_pago: any;
  img: any;
  nota: any;

  solicitudes_saldo: any = [];
  pagesNumber: any;
  offset= 3;
  buscar: any;
  page: any;

  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;

  //info convenios
  
  img_convenio:any;
  comercio_id_convenio:any;
  listConvenios:any = [];
  razon_social_convenio:any;
  tipo_documento_convenio:any;
  numero_documento_convenio:any;
  banco_nombre_convenio:any;
  banco_cuenta_convenio:any;
  banco_tipo_convenio:any;
  
  reporteExport:any;
  reporteMes:any;
  fecha_desde:any;
  fecha_hasta: any;


  staticContado:any = {
    imgUrl: 'assets/img/icon-dinero.png',
    id: '290',
    razon_social: 'Pago Express',
    tipo_documento: '',
    numero_documneto: '',
    banco_convenio: 'Bancolombia',
    banco_nombre: 'Bancolombia',
    banco_tipo: 'Corriente',
    banco_cuenta: '459-376304-52',
  }
  staticCredito:any = {
    imgUrl: 'assets/img/icon-factura.png',
    id: null,
    razon_social: 'Pago Express',
    tipo_documento: '',
    numero_documneto: '',
    banco_convenio: '',
    banco_tipo: '',
    banco_cuenta: '',
  }
  staticPasarASaldo:any = {
    imgUrl: 'assets/img/icon-cerdito.png',
    id: null,
    razon_social: 'Pago Express',
    tipo_documento: 'ganancia',
    numero_documneto: '',
    banco_convenio: '',
    banco_tipo: '',
    banco_cuenta: '',
  }

  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.comercio_id);
    this.listadoSolicitudesSaldo();
    this.getListConvenios();
  }

  preview(files) {
    this.cargando = true;
    // this.cargando = true;
    // console.log('entrando');
    // console.log(files);
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // if (mimeType.match(/application\/pdf/) || mimeType.match(/image\/png/) || mimeType.match(/image\/jpeg/) ||  mimeType.match(/image\/jpg/)  == null) {
    //   this.message = "No hay Archivo.";
    //   return;
    // }
    var reader = new FileReader();
    this.imagePath = files;
    this.fileName = files[0].name
    // console.log(this.fileName);
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      this.saveAdjunto();
    }
  }

  saveAdjunto(){
    if (!this.fileURL){
        // console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL
      };
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        // console.log(data);
        
        if(data['logger'] == true){
          this.img = data['filename'];
          let ruta = data['fileurl'];
          this.listadjuntos.push(params);
          this.cargando = false;
        } else {
          // this.alerta(data['msg'], 'error');
          this.cargando = false;
        }
      });
    }
  }

  listadoSolicitudesSaldo(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getSolicitudesSaldo(this.comercio_id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        this.solicitudes_saldo = data['solicitud_saldo'];
        // console.log('resp = ' + this.solicitudes_saldo);
        console.log(this.solicitudes_saldo);        

        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
      
      var url = this.servicios.getSolicitudesSaldo(this.comercio_id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.solicitudes_saldo = data['solicitud_saldo'];
        console.log(this.solicitudes_saldo);        

        this.cargando = false;
        this.ForpagesNumber()

      });
    }
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.solicitudes_saldo.current = page;
    this.filtro();
        // this.listadosolicitudes_saldo(page); 
        // this.listadoSolicitudesSaldo(page);       
  }

  ForpagesNumber() {
    let from = this.solicitudes_saldo.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.solicitudes_saldo.total_page){
      to = this.solicitudes_saldo.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  filtro(){
    // console.log('page '+this.page);
    // console.log('buscar '+this.buscar);
      let params = {
        filtro: true,
        buscar: this.buscar,
        page: this.page
      }
      this.servicios.setSolicitudSaldo(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.solicitudes_saldo = data['solicitud_saldo'];
            console.log(this.solicitudes_saldo);
            
            this.ForpagesNumber();
          } else {
            this.solicitudes_saldo.size = 0;
            this.solicitudes_saldo.items = [];
          }      
      });    
  }  

  guardar(){
    let params;
    if(this.comercio_id_convenio >= 0){
      params = {
        nuevo: true,
        comercio_id: this.comercio_id,
        tipo_solicitud: 'contado',
        valor: this.valor,
        medio_pago: this.medio_pago,
        entidad_pago: this.banco_nombre_convenio,
        img: this.img,
        nota: this.nota,
        comercio_recaudo_id: this.comercio_id_convenio,
        banco_tipo: this.banco_tipo_convenio,
        banco_cuenta: this.banco_cuenta_convenio
      }  
    }
    if(this.comercio_id_convenio == null){
      params = {
        nuevo: true,
        comercio_id: this.comercio_id,
        tipo_solicitud: 'crédito',
        valor: this.valor,
        nota: this.nota,
      }  
    }
    if(this.tipo_documento_convenio == 'ganancia'){
      params = {
        nuevo: true,
        comercio_id: this.comercio_id,
        tipo_solicitud: 'pasar ganancia',
        valor: this.valor
      }  
    }
    // console.log(params);
    this.spinner = true;
    this.servicios.setSolicitudSaldo(params)
    .subscribe( (data) => {
      // console.log(data);
      if (data["logger"] == true){
        this.spinner = false;
        this.limpiarModal();
        this.listadoSolicitudesSaldo();
        this.cerrarModal('modalSolicitudSaldo');
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
      }
    });
  }

  ver(item){
    // console.log(item);
    this.itemVer = item;
    // console.log(this.itemVer);
    
  }

  cerrarModal(info){
    // console.log(info);
    this.limpiarModal();
    // document.getElementById(info).style.display = "none";
    if(info == 'modalVer'){
      $('#modalVer').modal('hide');
    }
    if(info == 'modalSolicitudSaldo'){
      $('#modalSolicitudSaldo').modal('hide');
    }
    this.backConvenios();
  }

  limpiarModal(){
    this.medio_pago = '';
    this.valor = '';
    this.tipo_solicitud = '';
    this.img = '';
    this.nota = '';
  }

  aprobar(item:any){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea aprobar la solcitud de saldo?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Aprobar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          solitudstatus : true,
          comercio_id : item.comercio_id,
          estado : 2,
          id: item.id
        }     
        this.spinner = true;
        this.servicios.setSolicitudSaldo(params).subscribe( data => {
          if (data["logger"] == true){
            this.spinner = false;
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            this.spinner = false;
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
          this.cerrarModal('modalVer');
          this.listadoSolicitudesSaldo();
        } );
      }
    });
  }

  rechazar(item:any){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Rechazar la solcitud de saldo?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Rechazar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          solitudstatus : true,
          comercio_id : item.comercio_id,
          estado : -1,
          id: item.id
        }     
        this.spinner = true;
        this.servicios.setSolicitudSaldo(params).subscribe( data => {
          if (data["logger"] == true){
            this.spinner = false;
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            this.spinner = false;
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
          this.cerrarModal('modalVer');
          this.listadoSolicitudesSaldo();
        } );
      }
    });
  }

  backConvenios(){
    document.getElementById('convenios').style.display = "block";  
    document.getElementById('newSolicitudSaldo').style.display = "none";
    this.img_convenio = null;
    this.medio_pago = null;
    this.comercio_id_convenio = null;
    this.razon_social_convenio = null;
    this.tipo_documento_convenio = null;
    this.numero_documento_convenio = null;
    this.banco_nombre_convenio = null;
    this.banco_cuenta_convenio = null;
    this.banco_tipo_convenio = null;
  }
  
  getListConvenios(){
    this.cargando = true;
    // id sera el perfil_id
    let params = {
      statusperfilid: true,
      id: 3
    };
    // console.log(params)
    this.servicios.postComercio(params).subscribe( (data) => {
      this.listConvenios = data['comercio_convenios'];
      this.cargando = false;
      // console.log('dentro');      
      // console.log(data['comercio_convenios']);
    });
  }


  selectConvenio(item){
    this.img_convenio = item.imgUrl;
    this.comercio_id_convenio = item.id;
    this.razon_social_convenio = item.razon_social;
    this.tipo_documento_convenio = item.tipo_documento;
    this.numero_documento_convenio = item.numero_documento;
    this.banco_nombre_convenio = item.banco_nombre;
    this.banco_cuenta_convenio = item.banco_cuenta;
    this.banco_tipo_convenio = item.banco_tipo;
    if(!this.comercio_id_convenio || this.comercio_id_convenio == 0){
      document.getElementById('datosConvenio').style.display = "none";
    }else{
      document.getElementById('datosConvenio').style.display = "block";
    }
    document.getElementById('convenios').style.display = "none";  
    document.getElementById('newSolicitudSaldo').style.display = "block";
    // console.log(item);
  }

  // exportExcel(){
  //   let params = {
  //     export: true,
  //     id: this.comercio_id,
  //     perfil_id: this.perfil_id
  //   }
  //   this.cargando = true;
  //   this.servicios.setSolicitudSaldo(params)
  //   .subscribe( (data) => {
  //     let exportxlsx = data['solicitud_saldo'];
  //     const fileName = 'reporte_solicitud_saldo.xlsx';
  //     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportxlsx);
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'reporte');
  //     XLSX.writeFile(wb, fileName);
  //     this.cargando = false;
  //   });
  // }

  exportExcel(){
    this.cargando = true;
    let params = {
      export: true,
      id:this.comercio_id,
      perfil_id: this.perfil_id,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
      mes:this.reporteMes,
    }
    console.log(params);
    this.servicios.setSolicitudSaldo(params)
    .subscribe( (data) => {


      let exportxlsx = data['solicitud_saldo'];
      const fileName = 'reporte_solicitud_saldo.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportxlsx);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);
      this.cargando = false;

      // this.reporteExport = data['recaudo'];
      // let fecha = data['fecha']
      // // console.log(this.reporteExport);
      
      // const fileName = this.fecha_desde+'-'+this.fecha_hasta+'-reporte_recaudos_punto.xlsx';
      // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

      // // ANCHO COLUMNAS
      // ws['!cols'] = [ {width:2},{width:8},{width:15}, {width:10},{width:20},{width:20},{width:40},{width:50},{width:20},{width:20}];
      // // ELIMINA COLUMNA1
      // ws['!cols'][0] = { hidden: true };
      // const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // // CAMBIA CABECERA
      // XLSX.utils.sheet_add_aoa(ws, [["log","ID","FECHA","HORA","CUENTA","REFERENCIA","NOMBRE","PUNTO","RECAUDO","VALOR","ESTADO"]]);
      // XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  

      // XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      // XLSX.writeFile(wb, fileName);

      // this.cargando = false;
    });
    
  } 


}
