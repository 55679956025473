import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-compras-ecommerce',
  templateUrl: './compras-ecommerce.component.html',
  styleUrls: ['./compras-ecommerce.component.css']
})
export class ComprasEcommerceComponent implements OnInit {
  comercio_id:any;
  perfil_id:any;
  cargando:boolean = false;
  inputBuscar:any;
  productos: any = [];
  inputCantidad = 1;
  producto: any = [];
  listproducto: any = [];

  constructor(private servicios: ServicesService, private router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoVentas();
  }

  listadoVentas(){
  }

  buscarProducto(){
    let params = {
      findProducto: true,
      buscar: this.inputBuscar
    }
    this.servicios.setEcommerceProducto(params)
    .subscribe( (data) => {
      console.log(data);

      if (data["logger"] == true){
        this.productos = data['producto'];
        // this.router.navigate(['productos/listado'])
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
      }
    });

  }  

  addProducto(item){
    $('#modalProductoCant').modal('show');
    this.producto = item;
    console.log(this.producto);
  }
  
  addNewProducto(item){
    this.listproducto.push(item);
  }
  
  addcant(cant,stock){    
    if(cant == '+1'){
      this.inputCantidad = this.inputCantidad + cant;
      if(this.inputCantidad >= stock){
        this.inputCantidad = parseFloat(stock);
      }
    }

    if(cant == '-1'){      
      this.inputCantidad = this.inputCantidad + cant;
      if(this.inputCantidad == 0){
        this.inputCantidad = 1;
      }
    }    
  }
  

  cerrarmodal(){

  }

}
