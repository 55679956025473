import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reporte-conciliacion',
  templateUrl: './reporte-conciliacion.component.html',
  styleUrls: ['./reporte-conciliacion.component.css']
})
export class ReporteConciliacionComponent implements OnInit {

  cargando: boolean = false;
  convenioSelected: boolean = false;
  isSelectedRecaudos: boolean = false;
  isSelectedConciliaciones: boolean = false;
  listConvenios:any = [];
  infoConvenio:any = [];

  
  perfil_id: any;
  id: any;

  mensajero: any = [];
  offsetM = 3;
  pagesNumberM: any;
  pageM: any;

  conciliaciones: any = [];
  offsetC = 3;
  pagesNumberC: any;
  pageC: any;

  reporteExportRecaudo: any = [];
  ReporteExportConciliaciones: any = [];


  constructor(private servicios: ServicesService) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.getListConvenios();
  }

  getListConvenios(){
    this.cargando = true;
    // id sera el perfil_id
    let params = {
      statusperfilid: true,
      id: 3
    };
    this.servicios.postComercio(params).subscribe( (data) => {
      this.listConvenios = data['comercio_convenios'];
      this.cargando = false;
      this.convenioSelected = false;
      this.isSelectedRecaudos = false;
      this.isSelectedConciliaciones = false;
      // console.log('dentro');      
      // console.log(data['comercio_convenios']);
    });
  }

  selectConvenio(convenio){
    this.cargando = true;
    let params = {
      reporte: true,
      id: convenio.id,
      fecha: new Date().getFullYear()+"-"+(new Date().getMonth()+1)
    }
    // console.log(params);
    this.servicios.postComercio(params).subscribe(
      data => {
        this.infoConvenio = data['comercio'];
        this.cargando = false;
        this.convenioSelected = true;
        this.isSelectedRecaudos = false;
        this.isSelectedConciliaciones = false;
      }
    );
  }

  onRecaudos(){
    this.isSelectedRecaudos = true;
    this.isSelectedConciliaciones = false;
    this.listadoMensajero();
  }

  listadoMensajero(page = null){
    this.cargando = true;

    if(!page){
      this.servicios.getRecaudo(this.infoConvenio.id+'-'+this.infoConvenio.perfil_id+''+page).subscribe( (data) => {
        this.mensajero = data['recaudo'];
        this.cargando = false;
        // console.log(this.mensajero);
        this.ForpagesNumberM();
      });
    }else{
      this.servicios.getRecaudo(this.infoConvenio.id+'-'+this.infoConvenio.perfil_id+'-'+page).subscribe( (data) => {
        this.mensajero = data['recaudo'];
        this.cargando = false;
        this.ForpagesNumberM();
      });
    }     
  }

  onConciliaciones(){
    this.isSelectedConciliaciones = true;
    this.isSelectedRecaudos = false;
    this.listadoConciliaciones();
  }

  listadoConciliaciones(page = null){
    // console.log(this.comercio_id);
    // console.log(this.perfil_id);
    this.cargando = true;
    if(!page){
      this.servicios.getComerciosConciliacion(this.infoConvenio.id+'-'+this.infoConvenio.perfil_id+''+page).subscribe( (data) => {
        // console.log(data);
        this.conciliaciones = data['conciliacion'];
        this.cargando = false;
        this.ForpagesNumberC();
      });
    }else{
      var url = this.servicios.getComerciosConciliacion(this.infoConvenio.id+'-'+this.infoConvenio.perfil_id+'-'+page).subscribe( (data) => {
        this.conciliaciones = data['conciliacion'];
        this.cargando = false;
        this.ForpagesNumberC();

      });
    }     
  }

  ForpagesNumberM() {
    let from = this.mensajero.current - this.offsetM;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offsetM * 2); 
    if(to >= this.mensajero.total_page){
      to = this.mensajero.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumberM = pagesArray;
    // return pagesArray;
  }

  changePageM(page) {
    this.pageM = page;
    // console.log(page);
    this.mensajero.current = page;
    this.listadoMensajero(page); 
    // this.filtro();      
  }
  
  ForpagesNumberC() {
    let from = this.conciliaciones.current - this.offsetC;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offsetC * 2); 
    if(to >= this.conciliaciones.total_page){
      to = this.conciliaciones.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumberC = pagesArray;
    // return pagesArray;
  }

  changePageC(page) {
    this.pageC = page;
    // console.log(page);
    this.conciliaciones.current = page;
    this.listadoConciliaciones(page); 
    // this.filtro();      
  }

  exportExcel(){
    let params = {
      export: true,
      id:this.infoConvenio.id,
      todo: true
    }
    this.cargando = true;
    this.servicios.postRecaudo(params)
    .subscribe( (data) => {
      let exportxlsx = data['recaudo'];
      const fileName = 'reporte.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportxlsx);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);
      this.cargando = false;
    });
  }

  exportExcelRecaudos(){
    let params = {
      export: true,
      id:this.infoConvenio.id,
    }
    // console.log(params);
    this.cargando = true;
    this.servicios.postRecaudo(params)
    .subscribe( (data) => {
      this.reporteExportRecaudo = data['recaudo'];
      // console.log(this.reporteExport);
      
      const fileName = 'reporte_recaudos.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExportRecaudo);

      // ANCHO COLUMNAS
      ws['!cols'] = [ {width:2},{width:8},{width:15}, {width:10},{width:20},{width:20},{width:40},{width:50},{width:20},{width:20}];
      // ELIMINA COLUMNA1
      ws['!cols'][0] = { hidden: true };
      // ws['!cols'][1] = { hidden: true };
      // ws['!cols'][13] = { hidden: true };
      // ws['!cols'][14] = { hidden: true };
      // ws['!cols'][18] = { hidden: true };
      // // ws['G2'] = { Target:"#E2" };
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // CAMBIA CABECERA
      XLSX.utils.sheet_add_aoa(ws, [["log","ID","FECHA","HORA","CUENTA","REFERENCIA","NOMBRE","PUNTO","VALOR","ESTADO"]]);
      XLSX.utils.sheet_add_json(ws, this.reporteExportRecaudo, { origin: 'A2', skipHeader: true });  

      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);
      this.cargando = false;
    });
    
    
  } 
  
  
  exportExcelConciliaciones(){
    let params = {
      export: true,
      id:this.infoConvenio.id,
    }
    console.log(params);
    this.servicios.postComerciosConciliacion(params)
    .subscribe( (data) => {
      this.ReporteExportConciliaciones = data['conciliaciones'];
      
      const fileName = 'reporte.xlsx';  
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.ReporteExportConciliaciones, 
        // {
        //   header: ['id','fecha','hora', 'nombre', 'email', 'valor','estado']
        // }              
      )
      XLSX.utils.sheet_add_aoa(ws, [["Your Mesage Goes Here"]], { origin: 0 });
  
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(this.ReporteExportConciliaciones, [["Your Mesage Goes Here"]], { origin: 0 });
      let merges = wb['!merges'] = [{ s: 'A1', e: 'G1' }];

    
      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);

    }); 
    
  }

}
