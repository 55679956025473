import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-pasacupo-comercio',
  templateUrl: './pasacupo-comercio.component.html',
  styleUrls: ['./pasacupo-comercio.component.css']
})
export class PasacupoComercioComponent implements OnInit {

  cargando: boolean =false;
  comercios: any = [];
  pagesNumber: any;
  offset= 1;

  id:any;
  perfil_id:any;

  
  buscar:any;
  page:any;

  key:string = 'saldo';
  reverse:boolean = true;
  
  constructor(private servicios: ServicesService,
              public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoComercios();
  }

  changePage(page) {
    this.page = page;
    this.comercios.current = page;
    this.filtro();
    // this.listadoComercios(page);
    // if(!this.buscadorPersonas){
    //   this.listadoComercios(page);
    // }else{
    //   this.listadoComercios();
    // }          
  }

  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  verPasacupo(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemComercio: item
      }
    }
    this.router.navigate(['/comercios/pasacupo/ver'],navigationExtras);
  }

  EliminarComercio(item){
     let params = {
          id: item.id,
          eliminar: true,
          estado: 0,
        }
        console.log(params);
        this.servicios.postComercio(params)
        .subscribe( (data) => {
          console.log(data);
          if (data["logger"] == true){
            this.listadoComercios();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });

          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }

        });
  }  

  listadoComercios(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getComerciosPasacupo(1).subscribe( (data) => {
        this.comercios = data['comercios'];
        this.cargando = false;
        console.log(data['comercios']);
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getComerciosPasacupo(page).subscribe( (data) => {
        this.comercios = data['comercios'];
        this.cargando = false;
        console.log(data['comercios']);
        this.ForpagesNumber()

      });
    } 
    this.buscar = '';    
  }


  ForpagesNumber() {
    let from = this.comercios.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.comercios.total_page){
      to = this.comercios.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  filtro(){
    // console.log('page '+this.page);
    // console.log('buscar '+this.buscar);
      let params = {
        filtro: true,
        buscar: this.buscar,
        pageFiltro: this.page
      }
      console.log(params)
      this.servicios.postComerciosPasacupo(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.comercios = data['comercios'];
            // console.log(this.mensajero)
            this.ForpagesNumber();
          } else {
            this.comercios.size = 0;
            this.comercios.items = [];
          }      
      });    
  }
}
