
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Modulo Recaudo</h1>
                </div>
              </div>
            </div>
        </div>
  
          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">Editar Recaudo</h3>
                    </div>
                    <form [formGroup]="form" (ngSubmit)="EnviarRecaudo()">
                      <div class="card-body">
                          <div class="row">
                            <div class="form-group col-md-6">
                                <label>Fecha</label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }"
                                  [(ngModel)]="fecha"
                                  formControlName="fecha" 
                                  class="form-control"
                                  name="fecha" 
                                  type="date" 
                                  value="2011-08-19">
                                  <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback">
                                    <div *ngIf="f.fecha.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
  
                              <div class="form-group col-md-6">
                                <label>Hora</label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.hora.errors }"
                                  [(ngModel)]="hora"
                                  formControlName="hora" 
                                  name="hora"
                                  class="form-control" 
                                  type="time" 
                                  value="13:45:00">
                                  <div *ngIf="submitted && f.hora.errors" class="invalid-feedback">
                                    <div *ngIf="f.hora.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
  
                              <div class="col-md-6">
                                <div style="margin-top:0%" class="form-group">
                                    <label>Nombre <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                                    [(ngModel)]="nombre"
                                    formControlName="nombre" 
                                    type="text" 
                                    class="form-control" 
                                    name="nombre" 
                                    required>
                                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                      <div *ngIf="f.nombre.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div> 
  
                              <div class="col-md-6">
                                <div class="form-group">
                                    <label>Celular <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.celular.errors }"
                                    [(ngModel)]="celular"
                                    name="celular" 
                                    type="number" 
                                    class="form-control" 
                                    formControlName="celular">
                                    <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                                      <div *ngIf="f.celular.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>
  
                              <div class="col-md-6">
                                <div class="form-group">
                                    <label>Correo <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                    [(ngModel)]="email"
                                    name="email" 
                                    type="email" 
                                    class="form-control" 
                                    formControlName="email">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                      <div *ngIf="f.email.required">El Email es obligatorio</div>
                                    </div>
                                </div>
                              </div> 
  
                              <div class="col-md-6">
                                <div class="form-group">
                                    <label>Recaudo <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.recaudo_referencia.errors }" 
                                    [(ngModel)]="recaudo_referencia"
                                    type="text" 
                                    name="recaudo_referencia" 
                                    class="form-control" 
                                    formControlName="recaudo_referencia">
                                    <div *ngIf="submitted && f.recaudo_referencia.errors" class="invalid-feedback">
                                      <div *ngIf="f.recaudo_referencia.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>
  
                              <div class="col-md-6">
                                <div class="form-group">
                                    <label>Referencia <span style="color: red;">*</span></label>
                                    <!-- [ngClass]="{ 'is-invalid': submitted && f.referencia_recaudo.errors }"  -->
                                    <input 
                                    type="text" 
                                    name="referencia_recaudo" 
                                    class="form-control" 
                                    >
                                    <!-- <div *ngIf="submitted && f.referencia_recaudo.errors" class="invalid-feedback">
                                      <div *ngIf="f.referencia_recaudo.errors.required">Campo obligatorio</div>
                                    </div> -->
                                </div>
                              </div>
  
                              <div class="col-md-6">
                                <div class="form-group">
                                    <label>valor <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.valor.errors }" 
                                    [(ngModel)]="valor"
                                    type="text" 
                                    name="valor" 
                                    class="form-control" 
                                    formControlName="valor">
                                    <div *ngIf="submitted && f.valor.errors" class="invalid-feedback">
                                      <div *ngIf="f.valor.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <button type="submit" class="btn btn-primary float-right">Guardar</button>
                      </div>
                    </form>
                  </div>
    
                </div>
              </div>
            </div>
        </div>
      </div> 
      <app-footer-main></app-footer-main>  
  </div>
