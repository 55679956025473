<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>

    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1><button class="btn btn-primary" routerLink="/mensajeria/listado" type="button"><i
                                    class="fa fa-chevron-left"></i></button> Mensajeria</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-default">
                            <div class="card-header">
                                <h3 class="card-title">Crear Mensajeria</h3>
                            </div>
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="col-md-12">
                                    <div class="card card-default">
                                        <div class="card-header" style="background: rgb(238, 237, 237);">
                                            <h3 class="card-title" style="font-size: 15px;font-weight: bold;">
                                                Agencia</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="numero_guia" style="font-size: 14px;">Numero de
                                                            guia</label>
                                                        <input [readonly]="!ver" formControlName="numero_guia"
                                                            name="numero_guia" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 14px;">Departamento origen:</label>
                                                        <div class="input-group">
                                                            <select formControlName="departamento_origen"
                                                                name="departamento_origen"
                                                                class="form-control float-right"
                                                                (change)="onSelectDepartamento()">
                                                                <option value=''><b>Elegir</b></option>
                                                                <option [value]="item.id"
                                                                    *ngFor="let item of departamentos">{{item.nombre |
                                                                    uppercase}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 14px;">Ciudad origen:</label>
                                                        <div class="input-group">
                                                            <select formControlName="ciudad_origen" name="ciudad_origen"
                                                                class="form-control float-right">
                                                                <option value=''><b>Elegir</b></option>
                                                                <option [value]="item.id"
                                                                    *ngFor="let item of municipios">{{item.municipio |
                                                                    uppercase}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="ciudad_origen"
                                                            style="font-size: 14px;">Ciudad origen</label>
                                                        <input [readonly] = "ver" formControlName="ciudad_origen" name="ciudad_origen" type="text" class="form-control">
                                                    </div>
                                                </div> -->
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="fecha_envio" style="font-size: 14px;">Fecha</label>
                                                        <input readonly formControlName="fecha_envio" name="fecha_envio"
                                                            type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="pais_destino" style="font-size: 14px;">País
                                                            destino</label>
                                                        <input readonly type="text" class="form-control"
                                                            value="Colombia">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 14px;">Departamento destino:</label>
                                                        <div class="input-group">
                                                            <select formControlName="departamento_destino"
                                                                name="departamento_destino"
                                                                class="form-control float-right"
                                                                (change)="onSelectDepartamento_destino()">
                                                                <option value=''><b>Elegir</b></option>
                                                                <option [value]="item.id"
                                                                    *ngFor="let item of departamentos">{{item.nombre |
                                                                    uppercase}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 14px;">Ciudad destino:</label>
                                                        <div class="input-group">
                                                            <select formControlName="ciudad_destino"
                                                                name="ciudad_destino" class="form-control float-right">
                                                                <option value=''><b>Elegir</b></option>
                                                                <option [value]="item.id"
                                                                    *ngFor="let item of municipios_destino">
                                                                    {{item.municipio | uppercase}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="ciudad_destino"
                                                            style="font-size: 14px;">Ciudad destino</label>
                                                        <input [readonly] = "ver" formControlName="ciudad_destino" name="ciudad_destino" type="text" class="form-control">
                                                    </div>
                                                </div> -->
                                                <!--
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="ciudad_destino"
                                                            style="font-size: 14px;">Tiempo estimado entrega</label>
                                                        <input formControlName="ciudad_destino" name="ciudad_destino" type="number" class="form-control"> Días
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <input class="form-check-input" type="checkbox" formControlName="aplica_convenio_checkbox"  id="aplica_convenio_checkbox" value="aplica_convenio_checkbox">
                                                        <label style="font-size: 14px;" for="aplica_convenio">Aplica convenio </label>
                                                        <select formControlName="aplica_convenio" class="custom-select"
                                                            name="aplica_convenio">
                                                            <option value="bancolombia">Bancolombia</option>
                                                            <option value="davivienda">Davivienda</option>
                                                            <option value="nequi">Nequi</option>
                                                            <option value="daviplata">Daviplata</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="sucursal_convenio" style="font-size: 14px;">Sucursal convenio</label>
                                                        <select formControlName="sucursal_convenio" class="custom-select"
                                                            name="sucursal_convenio">
                                                            <option value="ahorros">Ahorros</option>
                                                            <option value="corriente">Corriente</option>
                                                            <option value="billetera movil">Billetera Movil</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="exampleInputBancoCuenta1"
                                                            style="font-size: 14px;">Numero Cuenta</label>
                                                        <input formControlName="banco_cuenta" name="banco_cuenta"
                                                            type="number" class="form-control">
                                                    </div>
                                                </div>
                                                -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card card-default">
                                        <div class="card-header" style="background: rgb(238, 237, 237);">
                                            <h3 class="card-title" style="font-size: 15px;font-weight: bold;">
                                                Datos del envio</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <!--
                                                <div class="col-md-4 row">
                                                    <div class="form-group mr-1">
                                                        <label for="piezas"><small><b>Piezas</b></small></label>
                                                        <input style="width: 80px;" formControlName="piezas" name="piezas" type="number" class="form-control">
                                                    </div>
                                                        <div class="form-group mr-1">
                                                        <label for="total_piezas"><small><b>Total piezas</b></small></label>
                                                        <input style="width: 80px;" formControlName="total_piezas" name="total_piezas" type="number" class="form-control">
                                                        </div>
                                                        
                                                    <div class="form-group mr-1">
                                                        <label for="peso_bruto"><small><b>Peso bruto</b></small></label>
                                                        <input style="width: 80px;" formControlName="peso_bruto" name="peso_bruto" type="number" class="form-control">
                                                        </div>
                                                    
                                                </div>
                                                
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="total_piezas"
                                                            style="font-size: 14px;">Total piezas</label>
                                                        <input formControlName="total_piezas" name="total_piezas" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="peso_bruto"
                                                            style="font-size: 14px;">Peso bruto</label>
                                                        <input formControlName="peso_bruto" name="peso_bruto" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-check col-md-2">
                                                    <input class="form-check-input" type="checkbox" formControlName="activar_peso_volumen"  id="activar_peso_volumen" value="activar_peso_volumen">
                                                    <label class="form-check-label" for="activar_peso_volumen">
                                                      Activar peso por volumen
                                                    </label>
                                                </div>
                                                <div class="col-md-4 row">
                                                        <div class="form-group mr-1">
                                                            <label for="alto"><small><b>Alto cms</b></small></label>
                                                            <input style="width: 80px;" formControlName="alto" name="alto" type="number" class="form-control">
                                                        </div>
                                                        <div class="form-group mr-1">
                                                            <label for="largo"><small><b>Largo cms</b></small></label>
                                                            <input style="width: 80px;" formControlName="largo" name="largo" type="number" class="form-control">
                                                        </div>
                                                        <div class="form-group mr-1">
                                                            <label for="ancho"><small><b>Ancho cms</b></small></label>
                                                            <input style="width: 80px;" formControlName="ancho" name="ancho" type="number" class="form-control">
                                                        </div>
                                                    
                                                </div>
                                                
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="alto"
                                                            style="font-size: 14px;">Alto cms</label>
                                                        <input formControlName="alto" name="alto" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="largo"
                                                            style="font-size: 14px;">Largo cms</label>
                                                        <input formControlName="largo" name="largo" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="ancho"
                                                            style="font-size: 14px;">Ancho cms</label>
                                                        <input formControlName="ancho" name="ancho" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="peso_volumen"
                                                            style="font-size: 14px;">Peso por volumen</label>
                                                        <input formControlName="peso_volumen" name="peso_volumen" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-check col-md-2">
                                                    <input class="form-check-input" type="checkbox" formControlName="contra_pago"  id="contra_pago" value="contra_pago">
                                                    <label class="form-check-label" for="contra_pago">
                                                      Contra pago
                                                    </label>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="valor_comercial"
                                                            style="font-size: 14px;">Valor comercial</label>
                                                        <input formControlName="valor_comercial" name="valor_comercial" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                -->
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="peso" style="font-size: 14px;">Peso
                                                            <small>(Kg)</small></label>
                                                        <input [readonly]="ver" formControlName="peso" name="peso"
                                                            type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="forma_pago" style="font-size: 14px;">Forma de
                                                            pago</label>
                                                        <select formControlName="forma_pago" class="custom-select"
                                                            name="forma_pago">
                                                            <option value="al cobro">Al cobro</option>
                                                            <option value="contado">Contado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="tipo_servicio"
                                                            style="font-size: 14px;">Tipo de servicio</label>
                                                        <input formControlName="tipo_servicio" name="tipo_servicio" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="tipo_envio" style="font-size: 14px;">Forma de pago</label>
                                                        <select formControlName="tipo_envio" class="custom-select"
                                                            name="tipo_envio">
                                                            <option value="bancolombia">Bancolombia</option>
                                                            <option value="davivienda">Davivienda</option>
                                                            <option value="nequi">Nequi</option>
                                                            <option value="daviplata">Daviplata</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                -->
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label for="dice_contener" style="font-size: 14px;">Dice
                                                            contener</label>
                                                        <input [readonly]="ver" formControlName="dice_contener"
                                                            name="dice_contener" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label for="nota" style="font-size: 14px;">Observaciones</label>
                                                        <input [readonly]="ver" formControlName="nota" name="nota"
                                                            type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card card-default">
                                        <div class="card-header" style="background: rgb(238, 237, 237);">
                                            <h3 class="card-title" style="font-size: 15px;font-weight: bold;">
                                                Liquidación</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="valor_flete" style="font-size: 14px;">Valor
                                                            flete</label>
                                                        <input [readonly]="ver" formControlName="valor_flete"
                                                            name="valor_flete" class="form-control" currencyMask
                                                            [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="valor_descuento"
                                                            style="font-size: 14px;">Valor descuento</label>
                                                        <input [readonly] = "ver" formControlName="valor_descuento" name="valor_descuento" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="valor_sobre_flete"
                                                            style="font-size: 14px;">Valor sobre flete</label>
                                                        <input [readonly] = "ver" formControlName="valor_sobre_flete" name="valor_sobre_flete" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="valor_impuestos"
                                                            style="font-size: 14px;">Valor impuestos</label>
                                                        <input [readonly] = "ver" formControlName="valor_impuestos" name="valor_impuestos" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                                    </div>
                                                </div>
                                                -->
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="valor_servicio" style="font-size: 14px;">Valor
                                                            servicio</label>
                                                        <input type="hidden" formControlName="valor_servicio" name="valor_servicio" [value]="valor_servicio()" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                                        <h5>$ {{valor_servicio() | decimal}}</h5>
                                                        <small class="text-danger" *ngIf="f.forma_pago.value == 'al cobro'">Si la forma de pago es al cobro, el valor del servicio debe ser cancelado en el punto!.</small>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-6 text-center">
                                                    <div class="form-group">
                                                        <label for="valor_total" style="font-size: 14px;">Valor total
                                                            servicios</label>
                                                        <!-- <input type="hidden" formControlName="valor_total" name="valor_total"  [value]="valor_total()" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"> -->
                                                        <h4>$ {{valor_total() | decimal}}</h4>
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 14px;">Cuenta Banco </label>
                                                        <select formControlName="banco_nombre" class="custom-select"
                                                            name="banco_nombre">
                                                            <option value="bancolombia">Bancolombia</option>
                                                            <option value="davivienda">Davivienda</option>
                                                            <option value="nequi">Nequi</option>
                                                            <option value="daviplata">Daviplata</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label style="font-size: 14px;">Tipo de cuenta</label>
                                                        <select formControlName="banco_tipo" class="custom-select"
                                                            name="banco_tipo">
                                                            <option value="ahorros">Ahorros</option>
                                                            <option value="corriente">Corriente</option>
                                                            <option value="billetera movil">Billetera Movil</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="exampleInputBancoCuenta1"
                                                            style="font-size: 14px;">Numero Cuenta</label>
                                                        <input [readonly] = "ver" formControlName="banco_cuenta" name="banco_cuenta"
                                                            type="number" class="form-control">
                                                    </div>
                                                </div>
                                                -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card card-default">
                                        <div class="card-header" style="background: rgb(238, 237, 237);">
                                            <h3 class="card-title" style="font-size: 15px;font-weight: bold;">
                                                Remitente</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_tipo_identificacion"
                                                            style="font-size: 14px;">Tipo de identificación</label>
                                                        <select formControlName="remite_tipo_identificacion"
                                                            class="custom-select" name="remite_tipo_identificacion">
                                                            <option value="cedula ciudadania">Cédula Ciudadania</option>
                                                            <option value="cedula extranjeria">Cédula extranjeria
                                                            </option>
                                                            <option value="nit">NIT</option>
                                                            <option value="tarjeta identidad">Tarjeta de identidad
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_identificacion"
                                                            style="font-size: 14px;">Identificación</label>
                                                        <input [readonly]="ver" formControlName="remite_identificacion"
                                                            name="remite_identificacion" type="number"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_celular" style="font-size: 14px;">Número
                                                            celular</label>
                                                        <input [readonly]="ver" formControlName="remite_celular"
                                                            name="remite_celular" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_nombre_razon_social"
                                                            style="font-size: 14px;">Nombres / Razón social</label>
                                                        <input [readonly]="ver"
                                                            formControlName="remite_nombre_razon_social"
                                                            name="remite_nombre_razon_social" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_primer_apellido"
                                                            style="font-size: 14px;">Primer apellido</label>
                                                        <input [readonly]="ver" formControlName="remite_primer_apellido"
                                                            name="remite_primer_apellido" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_segundo_apellido"
                                                            style="font-size: 14px;">Segundo apellido</label>
                                                        <input [readonly]="ver"
                                                            formControlName="remite_segundo_apellido"
                                                            name="remite_segundo_apellido" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_direccion"
                                                            style="font-size: 14px;">Dirección</label>
                                                        <input [readonly]="ver" formControlName="remite_direccion"
                                                            name="remite_direccion" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="remite_email" style="font-size: 14px;">Email</label>
                                                        <input [readonly]="ver" formControlName="remite_email"
                                                            name="remite_email" type="email" class="form-control">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card card-default">
                                        <div class="card-header" style="background: rgb(238, 237, 237);">
                                            <h3 class="card-title" style="font-size: 15px;font-weight: bold;">
                                                Destinatario</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_tipo_identificacion"
                                                            style="font-size: 14px;">Tipo de identificación</label>
                                                        <select formControlName="destino_tipo_identificacion"
                                                            class="custom-select" name="destino_tipo_identificacion">
                                                            <option value="cedula ciudadania">Cédula Ciudadania</option>
                                                            <option value="cedula extranjeria">Cédula extranjeria
                                                            </option>
                                                            <option value="nit">NIT</option>
                                                            <option value="tarjeta identidad">Tarjeta de identidad
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_identificacion"
                                                            style="font-size: 14px;">Identificación</label>
                                                        <input [readonly]="ver" formControlName="destino_identificacion"
                                                            name="destino_identificacion" type="number"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_celular" style="font-size: 14px;">Número
                                                            celular</label>
                                                        <input [readonly]="ver" formControlName="destino_celular"
                                                            name="destino_celular" type="number" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_nombre_razon_social"
                                                            style="font-size: 14px;">Nombres / Razón social</label>
                                                        <input [readonly]="ver"
                                                            formControlName="destino_nombre_razon_social"
                                                            name="destino_nombre_razon_social" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_primer_apellido"
                                                            style="font-size: 14px;">Primer apellido</label>
                                                        <input [readonly]="ver"
                                                            formControlName="destino_primer_apellido"
                                                            name="destino_primer_apellido" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_segundo_apellido"
                                                            style="font-size: 14px;">Segundo apellido</label>
                                                        <input [readonly]="ver"
                                                            formControlName="destino_segundo_apellido"
                                                            name="destino_segundo_apellido" type="text"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_direccion"
                                                            style="font-size: 14px;">Direccion</label>
                                                        <input [readonly]="ver" formControlName="destino_direccion"
                                                            name="destino_direccion" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="tipo_entrega" style="font-size: 14px;">Tipo
                                                            entrega</label>
                                                        <select formControlName="tipo_entrega" class="custom-select"
                                                            name="tipo_entrega">
                                                            <option value="entrega_en_direccion">Entrega en dirección
                                                            </option>
                                                            <option value="reclame_en_oficina">Reclame en oficina
                                                            </option>
                                                            <option value="veredas">Veredas</option>
                                                            <option value="kilometros">Kilometros</option>
                                                            <option value="centros_penitenciarios">Centros
                                                                penitenciarios</option>
                                                            <option value="guarnicion_militar">Guarnicion militar
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="destino_email"
                                                            style="font-size: 14px;">Email</label>
                                                        <input [readonly]="ver" formControlName="destino_email"
                                                            name="destino_email" type="email" class="form-control">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label *ngIf="imgUrl" for="img" style="font-size: 14px;">Imagen</label>
                                        <img *ngIf="imgUrl" style="margin-top:1%"
                                            src="https://api1.pagoexpress.co/public/img/upload/{{imgUrl}}" height="50"
                                            width="100%" class="img-fluid" alt="Responsive image">
                                        <input *ngIf="perfil_id == 1 && !imgUrl" #file type="file" accept='image/*'
                                            (change)="preview(file.files)" />
                                    </div>
                                </div>
                                <div class="col-md-12" style="padding-top: 2%;padding-bottom: 2%;">
                                    <button *ngIf="isNumero_guia && perfil_id == 1"
                                        class="btn btn-block btn-lg btn-success " (click)="onEditar()"
                                        type="button">Actualizar</button>
                                    <button *ngIf="!ver" class="btn btn-block btn-lg btn-success"
                                        type="submit">Enviar</button>
                                    <a routerLink="/mensajeria/listado"
                                        class="btn btn-block btn-lg btn-danger">Regresar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer-main></app-footer-main>
</div>