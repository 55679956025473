

<!-- <div>
  <section>
    <router-outlet></router-outlet>
  </section>
</div>
<app-footer-main></app-footer-main> -->


<div class="wrapper">

<!--   
  <div class="content-wrapper">
  
  </div> -->
  <section class="content">
    <router-outlet></router-outlet>
  </section>
  <!-- <app-footer-main></app-footer-main>  -->
</div>
