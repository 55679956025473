<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Modulo Multiproducto</h1>
                </div>
              </div>
            </div>
        </div>

          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="text-center">{{nombre }} - {{razon_social}} ID {{id}}</h3>
                  <div class="card card-default">
                    <!-- <div class="card-header">
                      <h3 class="card-title text-center">{{razon_social}}</h3>
                    </div> -->
                    <div class="card-body">
                      <div class="row" *ngFor="let item of listProductos">
                        <div class="col-md-1" >

                          <div class="form-group">
                            <div class="custom-control custom-switch">
                              <input type="checkbox" [checked]="item.estado ==1" [(ngModel)]="item.estado" class="custom-control-input" [id]="item.id">
                              <label class="custom-control-label" [for]="item.id"></label>
                            </div>
                          </div> 

                        </div> 
                        <div class="col-md-3">
                          <h3>{{item.nombre | titlecase}}</h3>
                        </div>
                        <div class="col-md-3" >
                          <!-- <div>% <b>${{item.porcentaje}}</b></div>                         -->
                          <div><b>${{item.tarifa_fija}}</b></div>                        
                        </div>   
                        <div class="col-md-3" >
                          <input *ngIf="item.estado" [(ngModel)]="item.valor" name="tarifa" type="number" class="form-control" placeholder="Valor">
                        </div>                                                                                        
                      </div>

                    </div>
                    <button type="button" class="btn btn-lg btn-primary" (click)="setComercioProductos()">Asignar</button>

                    <!-- fin body -->
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div> 
      <app-footer-main></app-footer-main>  
  </div>
