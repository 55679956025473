import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavbarMainComponent }             from './components/adminlte/navbar/navbar-main/navbar-main.component';
import { NavbarLeftLinksComponent }        from './components/adminlte/navbar/navbar-left-links/navbar-left-links.component';
import { NavbarSearchFormComponent }       from './components/adminlte/navbar/navbar-search-form/navbar-search-form.component';
import { NavbarRightLinksComponent }       from './components/adminlte/navbar/navbar-right-links/navbar-right-links.component';
import { SidebarMainComponent }            from './components/adminlte/sidebar/sidebar-main/sidebar-main.component';
import { SidebarBrandLogoComponent }       from './components/adminlte/sidebar/sidebar-brand-logo/sidebar-brand-logo.component';
import { SidebarMenuComponent }            from './components/adminlte/sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarmenuMenuItemComponent }    from './components/adminlte/sidebar/sidebarmenu-menu-item/sidebarmenu-menu-item.component';
import { SidebarmenuSubmenuItemComponent } from './components/adminlte/sidebar/sidebarmenu-submenu-item/sidebarmenu-submenu-item.component';
import { SidebarmenuUserinfoComponent }    from './components/adminlte/sidebar/sidebarmenu-userinfo/sidebarmenu-userinfo.component';
import { FooterMainComponent }             from './components/adminlte/footer/footer-main/footer-main.component';
import { Pagina1Component } from './components/paginas/pagina1/pagina1.component';
import { Pagina2Component } from './components/paginas/pagina2/pagina2.component';
import { IndexComponent } from './components/paginas/index/index.component';
import { ListadoComerciosComponent } from './components/comercios/listado-comercios/listado-comercios.component';
import { ListadoMensajeroComponent } from './components/mensajero/listado-mensajero/listado-mensajero.component';
import { LoginComponent } from './account/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NuevoComercioComponent } from './components/comercios/nuevo-comercio/nuevo-comercio.component';

import { ReactiveFormsModule } from '@angular/forms';
import { EditarComercioComponent } from './components/comercios/editar-comercio/editar-comercio.component';
import { ListadoRecaudoComponent } from './components/recaudo/listado-recaudo/listado-recaudo.component';
import { NuevoMensajeroComponent } from './components/mensajero/nuevo-mensajero/nuevo-mensajero.component';
import { EditarMensajeroComponent } from './components/mensajero/editar-mensajero/editar-mensajero.component';
import { NuevoRecaudoComponent } from './components/recaudo/nuevo-recaudo/nuevo-recaudo.component';
import { EditarRecaudoComponent } from './components/recaudo/editar-recaudo/editar-recaudo.component';
import { ImprimirComponent } from './components/mensajero/imprimir/imprimir.component';
import { ReporteComponent } from './components/mensajero/reporte/reporte.component';
import { DecimalPipe } from './pipes/decimal.pipe';
import { OperacionesComponent } from './components/operaciones/operaciones.component';
import { ProductoComponent } from './components/mensajero/producto/producto.component';
import { PasacupoComercioComponent } from './components/comercios/pasacupo-comercio/pasacupo-comercio.component';
import { MultiproductoComercioComponent } from './components/comercios/multiproducto-comercio/multiproducto-comercio.component';
import { VerPasacupoComercioComponent } from './components/comercios/ver-pasacupo-comercio/ver-pasacupo-comercio.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NuevaCompensacionComponent } from './components/compensaciones/nueva-compensacion/nueva-compensacion.component';
import { ListadoCompensacionComponent } from './components/compensaciones/listado-compensacion/listado-compensacion.component';
import { ListadoUsuariosComponent } from './components/usuarios/listado-usuarios/listado-usuarios.component';
import { NuevoUsuarioComponent } from './components/usuarios/nuevo-usuario/nuevo-usuario.component';
import { EditarUsuarioComponent } from './components/usuarios/editar-usuario/editar-usuario.component';
import { ListadoProspectosComponent } from './components/prospectos/listado-prospectos/listado-prospectos.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ListadoAfiliacionesComponent } from './components/afiliaciones/listado-afiliaciones/listado-afiliaciones.component';
import { NuevaAfiliacionComponent } from './components/afiliaciones/nueva-afiliacion/nueva-afiliacion.component';
import { HomeComponent } from './components/recaudo/home/home.component';
import { HomeReporteComponent } from './components/reportes/home-reporte/home-reporte.component';
import { ImportCsvComponent } from './components/recaudo/import-csv/import-csv.component';
import { RecaudoListadoComponent } from './components/operaciones/recaudos/recaudo-listado/recaudo-listado.component';
import { RecaudoImprimirComponent } from './components/operaciones/recaudos/recaudo-imprimir/recaudo-imprimir.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { ChangePasswordComponent } from './components/usuarios/change-password/change-password.component';
import { SolicitudSaldoComponent } from './components/comercios/solicitud-saldo/solicitud-saldo.component';

//calendario
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ReporteProspectosComponent } from './components/prospectos/reporte-prospectos/reporte-prospectos.component';

//sort table
import { Ng2OrderModule } from 'ng2-order-pipe';

//locale 
import { registerLocaleData } from '@angular/common';
import localeEsCo from '@angular/common/locales/es-CO';
registerLocaleData(localeEsCo);

import { ListadoConciliacionComponent } from './components/comercios/listado-conciliacion/listado-conciliacion.component';
import { VerConciliacionComponent } from './components/comercios/ver-conciliacion/ver-conciliacion.component';
import { ListadoMensajeriaComponent } from './components/mensajeria/listado-mensajeria/listado-mensajeria.component';
import { FormularioMensajeriaComponent } from './components/mensajeria/formulario-mensajeria/formulario-mensajeria.component';
import { PrintMensajeriaComponent } from './components/mensajeria/print-mensajeria/print-mensajeria.component';
import { ReporteMensajeriaComponent } from './components/mensajeria/reporte-mensajeria/reporte-mensajeria.component';
import { ReporteConciliacionComponent } from './components/comercios/reporte-conciliacion/reporte-conciliacion.component';
import { ReportePuntosConciliacionComponent } from './components/comercios/reporte-puntos-conciliacion/reporte-puntos-conciliacion.component';
import { SorteoProspectosComponent } from './components/prospectos/sorteo-prospectos/sorteo-prospectos.component';
import { PasarGananciaComponent } from './components/comercios/pasar-ganancia/pasar-ganancia.component';
import { AnchetaComponent } from './components/pagoexpress/ancheta/ancheta.component';
import { ListadoProductosComponent } from './components/productos/listado-productos/listado-productos.component';
import { FormularioProductoComponent } from './components/productos/formulario-producto/formulario-producto.component';
import { OperacionesEcommerceComponent } from './components/ecommerce/operaciones-ecommerce/operaciones-ecommerce.component';
import { CatalogoComponent } from './components/ecommerce/catalogo/catalogo.component';
import { VerProductoComponent } from './components/ecommerce/catalogo/ver-producto/ver-producto.component';
import { CategoriasComponent } from './components/ecommerce/catalogo/categorias/categorias.component';
import { HeaderCatalogoComponent } from './components/ecommerce/catalogo/header-catalogo/header-catalogo.component';
import { FooterCatalogoComponent } from './components/ecommerce/catalogo/footer-catalogo/footer-catalogo.component';
import { VentasEcommerceComponent } from './components/ecommerce/ventas-ecommerce/ventas-ecommerce.component';
import { ComprasEcommerceComponent } from './components/ecommerce/compras-ecommerce/compras-ecommerce.component';
import { ProductosPubliexpressComponent } from './components/publiexpress/productos-publiexpress/productos-publiexpress.component';
import { OperacionesPubliexpressComponent } from './components/publiexpress/operaciones-publiexpress/operaciones-publiexpress.component';
import { VerProductosPubliexpressComponent } from './components/publiexpress/ver-productos-publiexpress/ver-productos-publiexpress.component';
import { CotizadorPubliexpressComponent } from './components/publiexpress/cotizador-publiexpress/cotizador-publiexpress.component';
import { OrdenesPubliexpressComponent } from './components/publiexpress/ordenes-publiexpress/ordenes-publiexpress.component';
import { CotizacionComponent } from './components/publiexpress/cotizacion/cotizacion.component';
import { ListadoEmpresasComponent } from './components/empresa/listado-empresas/listado-empresas.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarMainComponent,
    NavbarLeftLinksComponent,
    NavbarSearchFormComponent,
    NavbarRightLinksComponent,
    SidebarMainComponent,
    SidebarBrandLogoComponent,
    SidebarMenuComponent,
    SidebarmenuUserinfoComponent,
    SidebarmenuMenuItemComponent,
    SidebarmenuSubmenuItemComponent,
    FooterMainComponent,
    Pagina1Component,
    Pagina2Component,
    IndexComponent,
    ListadoComerciosComponent,
    ListadoMensajeroComponent,
    LoginComponent,
    NuevoComercioComponent,
    EditarComercioComponent,
    ListadoRecaudoComponent,
    NuevoMensajeroComponent,
    EditarMensajeroComponent,
    NuevoRecaudoComponent,
    EditarRecaudoComponent,
    ImprimirComponent,
    ReporteComponent,
    DecimalPipe,
    OperacionesComponent,
    ProductoComponent,
    PasacupoComercioComponent,
    MultiproductoComercioComponent,
    VerPasacupoComercioComponent,
    NuevaCompensacionComponent,
    ListadoCompensacionComponent,
    ListadoUsuariosComponent,
    NuevoUsuarioComponent,
    EditarUsuarioComponent,
    ListadoProspectosComponent,
    ListadoAfiliacionesComponent,
    NuevaAfiliacionComponent,
    HomeComponent,
    HomeReporteComponent,
    ImportCsvComponent,
    RecaudoListadoComponent,
    RecaudoImprimirComponent,
    ChangePasswordComponent,
    SolicitudSaldoComponent,
    ReporteProspectosComponent,
    ListadoConciliacionComponent,
    VerConciliacionComponent,
    ListadoMensajeriaComponent,
    FormularioMensajeriaComponent,
    PrintMensajeriaComponent,
    ReporteMensajeriaComponent,
    ReporteConciliacionComponent,
    ReportePuntosConciliacionComponent,
    SorteoProspectosComponent,
    PasarGananciaComponent,
    AnchetaComponent,
    ListadoProductosComponent,
    FormularioProductoComponent,
    OperacionesEcommerceComponent,
    CatalogoComponent,
    VerProductoComponent,
    CategoriasComponent,
    HeaderCatalogoComponent,
    FooterCatalogoComponent,
    VentasEcommerceComponent,
    ComprasEcommerceComponent,
    ProductosPubliexpressComponent,
    OperacionesPubliexpressComponent,
    VerProductosPubliexpressComponent,
    CotizadorPubliexpressComponent,
    OrdenesPubliexpressComponent,
    CotizacionComponent,
    ListadoEmpresasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgxBarcodeModule,
    NgxCsvParserModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    CommonModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    BrowserAnimationsModule,
    Ng2OrderModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
