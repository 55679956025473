
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>Modulo Empresas</h1>
            </div>
            <div class="col-sm-12 mt-3">             
              <button type="button" routerLink="/usuarios/nuevo" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
              <button type="button" (click)="listadoUsuarios()" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Perfil</th>
                      <th>Nombre</th>
                      <th>Usuario</th>
                      <th>Contacto</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of usuarios.items">
                      <td><span (click)="Editar(item)" style="cursor: pointer;">{{item.id}}</span></td>
                      <td  (click)="Editar(item)" style="cursor: pointer;"><span *ngIf="item.perfil_id==1">Administrador</span> <span *ngIf="item.perfil_id ==2">Vendedor</span> <span *ngIf="item.perfil_id ==3">Empresario</span> <span *ngIf="item.perfil_id ==4">Punto de Venta</span> <span *ngIf="item.perfil_id ==5">Supervisor</span> <span *ngIf="item.perfil_id ==6">Vendedor</span> <span *ngIf="item.perfil_id ==7">Externo Afiliaciones</span> <span *ngIf="item.perfil_id ==8">Publicista</span></td>
                      <td>{{item.nombre | titlecase}}</td>
                      <td>{{item.usuario_nickname}}</td>
                      <td>{{item.celular}}</td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button (click)="Editar(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button>
                            <div class="dropdown-divider"></div>
                            <button (click)="Eliminar(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button>
                          </div>
                        </div>                         
                      </td>
                    </tr>

                  <!--Modal Ver Mensajero -->
                  <div id="modalVerMensajero" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                    <div role="document" class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header ">
                          <h5 class="modal-title">
                            Solicitud
                          </h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>                            
                        </div>
                        <div class="modal-body">
                         
                        </div>
                        <div class="modal-footer">
                          <button #CerrarModal (click)="cerrarmodal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  </tbody>
                </table>
              </div>


              <!-- paginador -->
              <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="usuarios.current > 1">
                    <button type="button" class="page-link" (click)="changePage(usuarios.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>                  

                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': usuarios.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>                  

                  <li class="page-item" *ngIf="usuarios.current < usuarios.total_page">
                    <button type="button" class="page-link" (click)="changePage(usuarios.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>                  
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{usuarios.size}}</b></p>
                </div>
              </div>              
              <!-- Fin paginador -->               
            
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>   
      </section>   
    </div> 
    <app-footer-main></app-footer-main>  
</div>  