import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-header-catalogo',
  templateUrl: './header-catalogo.component.html',
  styleUrls: ['./header-catalogo.component.css']
})
export class HeaderCatalogoComponent implements OnInit {
  listCategorias: any[];

  constructor(private servicios: ServicesService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.listadoCategoria();     
  }

  listadoCategoria(){
    this.servicios.getEcommerceCategoria().subscribe( (data) => {
        this.listCategorias = data['categorias'];                 
    });
  }

}
