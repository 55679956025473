<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        
                          <!-- modal asesoria -->
                          <!-- Button trigger modal -->

                          <!-- Modal -->
                          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">Información asesoria</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div class="modal-body row">
                                  <div>Correo electrónico: <b>apuntosexternos@gmail.com</b></div>
                                  <div class="align-items-center">Celular (Llamada - Whatsapp): <b>313 282 34 05</b> <a class="ml-3" href="https://api.whatsapp.com/send?phone=573132823405" target="_blank"><i class="fab fa-whatsapp fa-2x"></i></a></div>
                                  <div class="align-items-center">Celular (Llamada - Whatsapp): <b>315 385 65 98</b> <a class="ml-3" href="https://api.whatsapp.com/send?phone=573153856598" target="_blank"><i class="fab fa-whatsapp fa-2x"></i></a></div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- fin modal asesoria -->
        <!-- <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Modulo Afiliaciones</h1>
                </div>
              </div>
            </div>
        </div> -->

          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 mt-4">
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">
                        <button class="btn btn-light" routerLink="/afiliaciones/listado"><i class="fa fa-chevron-left"></i></button>   
                        Nueva Solicitud 
                      </h3>
                    </div>
                      <div class="card-body">
                          <div class="row">
                              <div class="col-12" *ngIf="!servicio_id">
                                <div class="text-center"><h4 class="">Seleccione Servicio</h4>
                                                                    
                                  <div class="btn-group" role="group">
                                    <button style="width: 160px;height: 160px;border-radius: 50%;" type="button" class="btn btn-warning mr-2" data-toggle="modal" data-target="#exampleModal">
                                      <h5>Asesoria</h5>
                                    </button>
                                    <button  *ngFor="let item of listServicios" class="btn btn-primary mr-2" type="button" (click)="selectService(item)" style="width: 160px;height: 160px;border-radius: 50%;"><h5>{{item.nombre}}</h5></button>
                                  </div>                                  

                                </div>
                              </div>

                              <div class="col-12 mt-4" *ngIf="servicio_id">
                                <div><h3 (click)="resetServicio()" style="cursor: pointer;"><i class="fa fa-chevron-left" (click)="resetServicio()" style="cursor: pointer;"></i> Servicio <b>{{servicio_nombre}}</b></h3></div>
                              </div>                              
                          </div>
                          <div class="row" id="basicaInfo" *ngIf="servicio_id">
                            <div class="col-12 mt-4 pt-2 text-left" style="border-top: 1px solid #ccc;">
                              <h4>Información Básica Personal</h4>
                            </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input [(ngModel)]="nombre" name="nombre" type="text" class="form-control" placeholder="">
                                </div>                                  
                              </div>

                              <div class="col-6">
                                <div class="form-group">
                                    <label>Cedula</label>
                                    <input [(ngModel)]="cedula"  type="number" class="form-control" placeholder="">
                                </div>                                  
                              </div> 
                              
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Ciudad</label>
                                    <input [(ngModel)]="ciudad"  type="text" class="form-control" placeholder="">
                                </div>                                  
                              </div>   
                              
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Dirección</label>
                                    <input [(ngModel)]="direccion"  type="text" class="form-control" placeholder="">
                                </div>                                  
                              </div>  
                              
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Celular</label>
                                    <input [(ngModel)]="celular"  type="number" class="form-control" placeholder="">
                                </div>                                  
                              </div> 
                              
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Correo</label>
                                    <input [(ngModel)]="correo"  type="text" class="form-control" placeholder="">
                                </div>                                  
                              </div>                                                             
                          </div>


                          <div class="row" >
                            <div class="col-12 mt-4 pt-4 text-left"  *ngIf="servicio_id" style="border-top: 1px solid #ccc;">
                              <h4>Información Adicional y Adjuntos</h4>
                            </div> 

                            <!-- SERVICIO 1 ARL-->
                            <div class="col-6" id="servicio1" *ngIf="servicio_id ==1">

                              <div class="form-group">
                                <label>Cargo o Función</label>
                                <input [(ngModel)]="cargo_funcion"  type="text" class="form-control" placeholder="">
                              </div> 


                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="certificado_medico_laboral"  id="op2" value="option2">
                                <label class="form-check-label" for="op2">
                                  Certificado Médico Ocupacional <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="contrato_laboral" id="op3" value="option3">
                                <label class="form-check-label" for="op3">
                                  Contrato laborar (opcional) <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="cedula_150" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div> 
                            </div> 


                            <!-- SERVICIO 2 EPS -->
                            <div class="col-6" id="servicio2" *ngIf="servicio_id ==2">
                              <div class="form-group">
                                <label>EPS</label>
                                <input [(ngModel)]="eps"  type="text" class="form-control" placeholder="">
                              </div> 

                              <div class="form-group">
                                <label>Beneficiario</label>
                                <input [(ngModel)]="beneficiario"  type="text" class="form-control" placeholder="">
                              </div>   

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="cedula_150" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div> 

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hm7_registro" id="op1" value="option1">
                                <label class="form-check-label" for="op1">
                                  Hijos menores 7 años registro civil <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hm7_18_registro" id="op2" value="option2">
                                <label class="form-check-label" for="op2">
                                  Hijos mayores a 7 años menores 18 tarjeta y registro <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hm18_25_cedula" id="op3" value="option3">
                                <label class="form-check-label" for="op3">
                                  De 18 a 25 Cedula <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <!-- <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="cedula_150" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div>                                -->

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="casado_registro_cedula" id="op3" value="option3">
                                <label class="form-check-label" for="op3">
                                  Casados Registro Civil Matriminio y Copia cedula cónyuge <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="union_libre_declaracion_jurada" id="op3" value="option3">
                                <label class="form-check-label" for="op3"> 
                                  Unión libre declaración juramentada <b>Adjuntar</b>.
                                </label>
                              </div>
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="padres_registro_civil_como_hijo" id="op3" value="option3">
                                <label class="form-check-label" for="op3"> 
                                  Padres Registro Civil como hijo <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hermanos_discapacitados" id="op3" value="option3">
                                <label class="form-check-label" for="op3"> 
                                  Hermanos discapacitados declaración <b>Adjuntar</b>.
                                </label>
                              </div>                               

                            </div>    
                            

                            <!-- SERVICIO 3 PENSION-->
                            <div class="col-6" id="servicio2" *ngIf="servicio_id ==3">
                              <div class="form-group">
                                <label>Tipo Afiliación</label>
                                <select [(ngModel)]="primera_vez_o_traslado" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="PRIMERA VEZ">PRIMERA VEZ</option>
                                  <option value="TRASLADO">TRASLADO</option>
                                </select>
                              </div> 

                              <!-- <div class="form-group">
                                <label>Regimen</label>
                                <select [(ngModel)]="primera_vez_o_traslado" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="PRIMERA VEZ">CONTRIBUTIVO</option>
                                  <option value="TRASLADO">SUBSIDIADO</option>
                                </select>
                              </div>                                -->

                              <!-- <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="formulario_fondo" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Formulario según Fondo <b>Adjuntar</b>.
                                </label>
                              </div> -->
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="cedula_150" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div>                              
                             
                            </div> 
                            
                            <!-- SERVICIO 4 PILA -->
                            <div class="col-6" id="servicio2" *ngIf="servicio_id ==4">

                              <div class="form-group">
                                <label>EPS</label>
                                <input [(ngModel)]="eps"  type="text" class="form-control" placeholder="">
                              </div>  

                              <div class="form-group">
                                <label>Fondo de Pensión</label>
                                <input [(ngModel)]="fondo_pension"  type="text" class="form-control" placeholder="">
                              </div>   

                              <div class="form-group">
                                <label>Caja de Compensación</label>
                                <input [(ngModel)]="caja_compensacion"  type="text" class="form-control" placeholder="">
                              </div>    
                              
                              <!-- <div class="form-group">
                                <label>Fondo de Pensión</label>
                                <input [(ngModel)]="fondo_pension"  type="text" class="form-control" placeholder="">
                              </div>                                  -->


                              <div class="form-group">
                                <label>Mes a Cancelar</label>
                                <input [(ngModel)]="mes_a_cancelar"  type="month" class="form-control" placeholder="">
                              </div>   

                              <div class="form-group">
                                <label>Novedad</label>
                                <select [(ngModel)]="novedades_ingreso_retiro" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="Ingreso">Ingreso</option>
                                  <option value="Retiro">Retiro</option>
                                </select>
                              </div>                               

                              <div class="form-group">
                                <label>Nivel Riesgo</label>
                                <select [(ngModel)]="nivel_riesgo" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div> 

                              <div class="form-group">
                                <label>Base de Cotización</label>
                                <input [(ngModel)]="base_cotizacion"  type="text" class="form-control" placeholder="">
                              </div>                               
                              

                              
                              <div class="form-check">
                                <input class="form-check-input" [(ngModel)]="cedula_150"  type="checkbox" name="exampleRadios" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div>                              
                             
                            </div>  
                            
                            
                            <!-- SERVICIO 5 CAJA COMPENSACION-->
                            <div class="col-6" id="servicio1" *ngIf="servicio_id ==5">

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="cedula_150" id="op4" value="option4">
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div> 

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hm7_registro" id="op1" value="option1">
                                <label class="form-check-label" for="op1">
                                  Hijos menores 7 años registro civil <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hm7_18_registro" id="op2" value="option2">
                                <label class="form-check-label" for="op2">
                                  Hijos mayores a 7 años menores 18 tarjeta y registro <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="hm18_25_cedula" id="op3" value="option3">
                                <label class="form-check-label" for="op3">
                                  De 18 a 25 Cedula <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="certificados_estudios_hijos_7_25" id="op3" value="option3">
                                <label class="form-check-label" for="op3">
                                  Certificado de estudios para hijos mayores de 7 a 25 <b>Adjuntar</b>.
                                </label>
                              </div>                               
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="casado_registro_cedula" id="op3" value="option3">
                                <label class="form-check-label" for="op3">
                                  Casados Registro Civil Matriminio y Copia cedula cónyuge <b>Adjuntar</b>.
                                </label>
                              </div>                                

                            
                            </div>                             
                            

                            <div class="col-6"  *ngIf="servicio_id">                                 
                              <div>
                          
                                <!-- CARGAR ADJUNTOS -->
                                <div class="form-group text-center">
                                    <span style="color:red;" *ngIf="message">{{message}}</span>
                                    <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files)" style="display:none;"/>
                                    <h5 class="linkFile" (click)="file.click()" style="border: 2px dashed #ccc;padding-top: 15px;padding-bottom: 15px;margin-top: 10px;cursor: pointer;">Cargar Adjuntos</h5>
                                </div>
                                <!-- FIN CARGAR ADJUNTOS-->
                                
                                <div class="list-group" *ngFor="let itemAdjunto of listadjuntos let i = index">
                                  <div class="row">
                                    <div class="col-md-11">
                                      <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a>
                                    </div>
                                    <div class="col-md-1 ">
                                      <button class="btn btn-sm btn-secondary" type="button" (click)="deleteAdjunto(i,itemAdjunto)"><i class="fa fa-trash-alt"></i></button> 
                                    </div>
                                  </div>
                                </div>  
                                <div *ngIf="cargando" class="alert text-center mt-3 animated fadeIn faster">
                                  <h4 class="alert-heading">Cargando...</h4>
                                  <p>
                                    <i class="fa fa-sync-alt fa-spin fa-3x"></i>
                                  </p>
                                  <p class="mb-0">
                                    Espere por favor
                                  </p>
                                </div>                                  
                                                        
                              </div>
                              

                              <br>                              
                            </div>
                            
                            <div class="col-12 mt-4"  *ngIf="servicio_id">
                              <h2 class="mt-4 text-center">Valor ${{servicio_costo | decimal }}</h2>
                              <button *ngIf="servicio_id ==1" class="btn btn-lg btn-block " 
                              [disabled]="!cargo_funcion || !certificado_medico_laboral || !contrato_laboral || !cedula_150"  
                              [ngClass]="{'bg-primary': cargo_funcion || certificado_medico_laboral || contrato_laboral || cedula_150, 
                                          'bg-danger':!cargo_funcion || !certificado_medico_laboral || !contrato_laboral || !cedula_150 }"  (click)="setSolicitud()">Crear solicitud {{servicio_nombre}}</button>

                              <button *ngIf="servicio_id ==2" class="btn btn-lg btn-block " 
                              [disabled]="!beneficiario || !cedula_150 || !hm7_registro || !hm7_18_registro || !hm18_25_cedula || !casado_registro_cedula || !union_libre_declaracion_jurada || !padres_registro_civil_como_hijo || !hermanos_discapacitados"  
                              [ngClass]="{'bg-primary': beneficiario || cedula_150 || hm7_registro || hm7_18_registro || hm18_25_cedula || casado_registro_cedula || union_libre_declaracion_jurada || padres_registro_civil_como_hijo || hermanos_discapacitados, 
                                          'bg-danger': !beneficiario || !cedula_150 || !hm7_registro || !hm7_18_registro || !hm18_25_cedula || !casado_registro_cedula || !union_libre_declaracion_jurada || !padres_registro_civil_como_hijo || !hermanos_discapacitados }"  (click)="setSolicitud()">Crear solicitud {{servicio_nombre}}</button>


                              <button *ngIf="servicio_id ==3" class="btn btn-lg btn-block " 
                              [disabled]="!primera_vez_o_traslado || !cedula_150"  
                              [ngClass]="{'bg-primary': primera_vez_o_traslado || cedula_150, 
                                          'bg-danger': !primera_vez_o_traslado || !cedula_150 }"  (click)="setSolicitud()">Crear solicitud {{servicio_nombre}}</button>


                              <button *ngIf="servicio_id ==4" class="btn btn-lg btn-block " 
                              [disabled]="!mes_a_cancelar || !novedades_ingreso_retiro || !nivel_riesgo || !base_cotizacion || !cedula_150"  
                              [ngClass]="{'bg-primary': mes_a_cancelar || novedades_ingreso_retiro || nivel_riesgo || base_cotizacion || cedula_150 , 
                                          'bg-danger': !mes_a_cancelar || !novedades_ingreso_retiro || !nivel_riesgo || !base_cotizacion || !cedula_150 }"  (click)="setSolicitud()">Crear solicitud {{servicio_nombre}}</button>


                              <button *ngIf="servicio_id ==5" class="btn btn-lg btn-block " 
                              [disabled]="!cedula_150 || !hm7_registro || !hm7_18_registro || !hm18_25_cedula || !casado_registro_cedula"  
                              [ngClass]="{'bg-primary': cedula_150 || hm7_registro || hm7_18_registro || hm18_25_cedula || casado_registro_cedula , 
                                          'bg-danger': !cedula_150 || !hm7_registro || !hm7_18_registro || !hm18_25_cedula || !casado_registro_cedula }"  (click)="setSolicitud()">Crear solicitud {{servicio_nombre}}</button>
                                          
                            </div>
                            
                            

                          </div> <!-- FIN SERVICIO  -->
                      </div>
                  </div>
    
                </div>
              </div>
            </div>
        </div>
      </div> 
      <app-footer-main></app-footer-main>  
  </div>
