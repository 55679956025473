import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-imprimir',
  templateUrl: './imprimir.component.html',
  styleUrls: ['./imprimir.component.css']
})
export class ImprimirComponent implements OnInit {

  ItemImprimirOrden: any = {};
  nombre: any;

  constructor(public router: Router) { 
    if(this.router.getCurrentNavigation().extras.state){
      this.ItemImprimirOrden = this.router.getCurrentNavigation().extras.state.itemMensajeroImprimirOrden;
      this.nombre = this.ItemImprimirOrden['nombre']; 
      console.log(this.ItemImprimirOrden);
    }
  }

  ngOnInit(): void {
    if(!this.nombre){
      this.router.navigate(['../mensajero/listado']);
    }
  }

  NuevaOperacion(){
    this.router.navigate(['../mensajero/nuevo']);
  }

  print() {
    window.print();
  }

}
