import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebarmenu-submenu-item',
  templateUrl: './sidebarmenu-submenu-item.component.html',
  styleUrls: ['./sidebarmenu-submenu-item.component.css']
})
export class SidebarmenuSubmenuItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
