import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listado-mensajero',
  templateUrl: './listado-mensajero.component.html',
  styleUrls: ['./listado-mensajero.component.css']
})
export class ListadoMensajeroComponent implements OnInit {

  @ViewChild('CerrarModal') modalClose;

  public imagePath;
  imgURL: any;
  imgurl: any;
  public message: string;
  img: any;

  cargando = false;
  cargandoImg = false;
  loaderImgComprobante: boolean = false;
  mensajero: any = [];
  pagesNumber: any;
  offset= 3;
  id: any;
  idItem: any;
  perfil_id;

  id_orden: any;
  ordenItem: any = {};

  constructor(public servicios: ServicesService, public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.perfil_id);
    this.listadoMensajero();
  }

  preview(files, id) {
    this.idItem = id;
    this.loaderImgComprobante = true;

    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "No hay fotos.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      // console.log("esta es la imagen"+this.imgURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen(){
    if(this.imgURL) {
      let params = {
        archivo: this.imgURL
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          this.img = data['filename'];
          this.imgurl = data['fileUrl'];
          // this.cargandoImg = false;
          // this.loaderImgComprobante = false;
          this.SetEditarImg();
        }
      });
    } else {
      Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Porfavor seleccione una imagen",
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
    }
  }

  SetEditarImg(){
    let params = {
      editarImg: true,
      id: this.idItem,
      mensajero: {
        operador_comercio_id: this.id,
        img: this.img
      }
    }
    // console.log(params);
    this.servicios.postEditarEstado(params)
    .subscribe( (data) => {
      if (data["logger"] == true){
        this.loaderImgComprobante= false;
        this.img= '';
      } else {
      }
    });  
  }

  VerOrdenMensajero(item){
    this.ordenItem = item;
    // console.log(item);
  }

  EnviarEstadoOrden(id,estado,nota){
      let params = {
        editarEstado: true,
        id: id,
        mensajero: {
          estado: estado,
          operador_comercio_id: this.id,
          nota: nota,
          // img: this.img
        }
      }
      // console.log(params);
      this.servicios.postEditarEstado(params)
      .subscribe( (data) => {
        if (data["logger"] == true){
          this.listadoMensajero();
          this.imgURL= '';
          this.ordenItem = '';
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }
      });
  }

  EditarMensajero(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajero: item
      }
    }
    this.router.navigate(['/mensajero/editar'],navigationExtras);
  }

  changePage(page) {
    // console.log(page);
    this.mensajero.current = page;
    this.listadoMensajero(page);       
  }

  listadoMensajero(page = null){
    this.cargando = true;

    if(!page){
      this.servicios.getMensajero(this.id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        this.mensajero = data['mensajero'];
        this.cargando = false;
        // console.log(data['mensajero']);
        this.ForpagesNumber()
      });
    }else{
      this.servicios.getMensajero(this.id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.mensajero = data['mensajero'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }     
  }

  ForpagesNumber() {
    let from = this.mensajero.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.mensajero.total_page){
      to = this.mensajero.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  cerrarmodal(){
    this.imgURL = '';
    this.ordenItem = '';
  } 

  Imprimir(item){
    this.EnviarItemImprimir(item);
  }

  EnviarItemImprimir(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajeroImprimirOrden: item
      }
    }
    this.router.navigate(['../mensajero/imprimir'],navigationExtras); 
  }
    
}
