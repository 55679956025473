import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-cotizador-publiexpress',
  templateUrl: './cotizador-publiexpress.component.html',
  styleUrls: ['./cotizador-publiexpress.component.css']
})
export class CotizadorPubliexpressComponent implements OnInit {
  solicitud_comercio_id:any;
  obj: any = [];
  productoItem: any = [];
  productoOrdenItems: any = [];
  productoOrdenItemsValorTotal: number = 0;
  cargando: boolean = false;

  cantidad: number = 0;
  ancho: number = 0;
  largo: number = 0;
  min_m2: number = 0;
  max_m2: number = 0;
  calpmt: number = 0;
  valorTotal: any;
  estado: any;
  nombre: any;
  contacto: any;
  email: any;
  nota: any;

  constructor(public router: Router,private servicios: ServicesService) { }

  ngOnInit(): void {
    this.solicitud_comercio_id = localStorage.getItem('id');

    this.listadoProductos()
  }

  listadoProductos() {
    let params = {
      getproducto: true,
    }
    this.servicios.getPubliexpressProducto(params).subscribe((data) => {
      this.obj = data['obj'];
      this.cargando = false;
    });
  }

  selectProducto(item) {
    this.productoItem = item;
    $('#productoListado').hide();
  }

  deleteProducto() {
    $('#productoListado').show();
    this.productoItem = [];
    this.cantidad = 0;
    this.ancho = 0;
    this.largo = 0;
    this.min_m2 = 0;
    this.max_m2 = 0;
    this.valorTotal = '';
    this.estado = '';
    this.calpmt = 0;
  }

  calvalor(item) {
    if (this.cantidad && !item.pmt2) {
      this.valorTotal = item.valor * this.cantidad;
    }
    if (this.ancho && this.largo && item.pmt2) {
      this.calpmt = this.ancho * this.largo;
      console.log(this.calpmt);
      console.log(item.max_m2);

      if (item.max_m2) {
        if (this.calpmt <= item.max_m2) {
          this.valorTotal = this.ancho * this.largo * item.pmt2;
          this.estado = '';
        } else {
          this.valorTotal = 0;
          this.estado = 'No disponible';
        }

      } else {
        this.valorTotal = this.ancho * this.largo * item.pmt2;
      }

    }

  }

  addProducto(item) {
    console.log(item);
    
    let params = {
      id: item.id,
      imgUrl: item.imgUrl,
      max_m2: item.max_m2,
      min_m2: item.min_m2,
      nombre: item.nombre,
      pmt2: item.pmt2,
      calpmt: this.calpmt,
      cantidad: this.cantidad,
      unidad: item.unidad,
      ancho: this.ancho,
      largo: this.largo,
      valor: this.valorTotal,
    }
    this.productoOrdenItems.push(params);
    this.productoOrdenItemsValorTotal = this.productoOrdenItemsValorTotal + params.valor;
    this.deleteProducto();
  }

  newOrden(){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea crear la orden de publicidad `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
    }).then((result) => {
      if (result.value) {
        console.log('dentro');
        let params = {
          comercio_id: this.solicitud_comercio_id,
          nombre: this.nombre,
          contacto: this.contacto,
          email: this.email,
          productos: this.productoOrdenItems,
          valor_total: this.productoOrdenItemsValorTotal,
          estado: 1,
          nuevo: true,
        }
        // console.log(this.Banco);        
        this.servicios.setPubliexpressOrdenes(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: data['msg'],
                  showConfirmButton: false,
                  timer: 1500,
                  showClass: {
                    popup: 'animated fadeInDown faster'
                  },
                  hideClass: {
                    popup: 'animated fadeOutUp faster'
                  }
                });
                this.router.navigate(['../publiexpress/operaciones'])

            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
            }
            
        });        
        
      }
    });     
  }

}
