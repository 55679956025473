<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>

  <div class="content-wrapper">
    <!-- Modales -->

    <!-- MODAL NUEVO RECAUDO -->
    <div class="modal fade" id="modalSolicitudSaldo" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom:0px;">
            <div class="mb-2" style="border-radius: 1%/30%;
            background-color: #00c4ea;
            color: #fff;
            width: 100%;
            line-height: 2.3;
            padding-top: 5px;
            padding-bottom: 5px;">
              <h3 class="text-center m-0" style="font-weight: 600;">Solicitud de Saldo</h3>
            </div>
          </div>
          <div class="modal-body pt-0">
            <div id="convenios">
              <!-- <nav class="navbar navbar-dark bg-dark p-0 text-center" style="border-radius: 5px;">
                <div class="mt-1" style="text-align: center;width: 100%;"><h4>Convenios de Recaudos</h4></div>
              </nav>
              <div class="row align-items-center">
                <div class="col-2" *ngFor="let item of listConvenios; let i = index">
                  <div id="divConvenio" class="mt-2" style="cursor: pointer;" (click)="staticContado(item)">
                    <img [src]="item.imgUrl" class="card-img-top rounded-circle" alt="...">
                  </div>
                </div>
              </div> -->
              <nav class="navbar navbar-dark bg-dark p-0 text-center mt-4" style="border-radius: 5px;">
                <div class="mt-1" style="text-align: center;width: 100%;">
                  <h4>Pago Express</h4>
                </div>
              </nav>
              <div class="row justify-content-around">
                <div class="col-2 text-center">
                  <div id="divConvenio" class="mt-2" style="cursor: pointer;" (click)="selectConvenio(staticContado)">
                    <!-- <div id="divConvenio" class="mt-2" style="cursor: pointer;" > -->
                    <img [src]="staticContado.imgUrl" class="card-img-top rounded-circle" alt="...">
                  </div>
                  <h5 class="mt-1">Contado</h5>
                </div>
                <div class="col-2 text-center">
                  <div id="divConvenio" class="mt-2" style="cursor: pointer;" (click)="selectConvenio(staticCredito)">
                    <img [src]="staticCredito.imgUrl" class="card-img-top rounded-circle" alt="...">
                  </div>
                  <h5 class="mt-1">Crédito</h5>
                </div>
                <!-- <div class="col-2 text-center">
                  <div id="divConvenio" class="mt-2" style="cursor: pointer;" (click)="selectConvenio(staticPasarASaldo)">
                    <img [src]="staticPasarASaldo.imgUrl" class="card-img-top rounded-circle" alt="...">
                  </div>
                  <h5 class="mt-1">Ganancia</h5>
                </div> -->
              </div>

            </div>
            <div id="newSolicitudSaldo" style="display: none;">

              <div class="row">
                <div class="col-3 text-center">
                  <img [src]="img_convenio" class="card-img-top" style="width: 150px;">
                  <div id="datosConvenio" class="pt-0">
                    <h3 class="card-text mb-0"><b>{{razon_social_convenio}}</b></h3>
                    <p class="card-text mt-0">{{tipo_documento_convenio}} {{numero_documento_convenio}}</p>
                    <p class="card-text"><b>Información Bancacaria</b></p>
                    <p class="card-text">Banco: <b>{{banco_nombre_convenio | titlecase }}</b> <br />
                      Tipo de cuenta: <b>{{banco_tipo_convenio}}</b> <br />
                      Número de cuenta: <b>{{banco_cuenta_convenio}}</b></p>
                  </div>
                </div>
                <div class="col-9">
                  <div class="row">
                    <button class="btn btn-block btn-default mb-3" (click)="backConvenios()"><i
                        class="fa fa-chevron-left"></i></button>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Valor</label>
                          <input [(ngModel)]="valor" name="valor" class="form-control" style="font-size: 2em;"
                            currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"
                            min="900">
                        </div>
                      </div>

                      <div class="form-group" *ngIf="comercio_id_convenio == 0" class="col-md-12">
                        <label>Entidad de Pago*</label>
                        <div class="input-group">
                          <select [(ngModel)]="banco_nombre_convenio" name="banco_nombre_convenio" class="form-control">
                            <option [value]="'Bancolombia'">Bancolombia</option>
                            <option [value]="'Nequi'">Nequi</option>
                            <option [value]="'Daviplata'">Daviplata</option>
                            <option [value]="'Pagoexpress'">Pagoexpress</option>
                            <option [value]="'Davivienda'">Davivienda</option>
                            <option [value]="'Compensación'">Compensación</option>
                          </select>
                        </div>
                      </div>

                      <div *ngIf="comercio_id_convenio" class="col-md-12">
                        <div class="form-group">
                          <label>Medio de Pago*</label>
                          <select [(ngModel)]="medio_pago" name="medio_pago" class="form-control">
                            <option [value]="'Transferencia'">Transferencia</option>
                            <option [value]="'Consignación'">Consignación</option>
                            <option [value]="'Efectivo'">Efectivo</option>
                          </select>
                        </div>
                      </div>

                      <div *ngIf="tipo_documento_convenio != 'ganancia'" class="col-md-12">
                        <div class="form-group">
                          <label>Observación</label>
                          <textarea class="form-control" rows="3" [(ngModel)]="nota"></textarea>
                        </div>
                      </div>
                      <div *ngIf="comercio_id_convenio" class="col-md-12">
                        <div class="form-group">
                          <div>
                            <label for="img" class="form-label">Comprobante PNG/JPEG/JPG*</label>
                            <div *ngIf="cargando" class="spinner-border" role="status"></div>
                          </div>
                          <input type="file" class="form-control" #file accept='image/png, image/jpeg, image/jpg'
                            (change)="preview(file.files)">
                        </div>
                      </div>
                      <div class="col-md-12 text-center">
                        <button class="btn btn-primary btn-block" [disabled]="!valor || spinner" (click)="guardar()">
                          <span *ngIf="!disabledButton">Enviar</span>
                          <span *ngIf="disabledButton">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Procesando...
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">

                  <!-- <div class="row">
                    <div class="col-2">
                      <img [src]="img" class="card-img-top">
                    </div>
                    <div class="col-10">
                      <div class="pt-0">
                        <h3 class="card-text mb-0"><b>{{razon_social}}</b></h3>
                        <p class="card-text mt-0">{{tipo_documento}} {{numero_documento}}</p>
                      </div>                          
                    </div>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"
              (click)="cerrarModal('modalSolicitudSaldo')">Cerrar</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>


    <!--Modal Ver  -->
    <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div role="document" class="modal-dialog modal-xl">
        <div class="modal-content">
          <div
            [ngClass]="{'bg-warning': itemVer.estado == 1, 'bg-primary':itemVer.estado == 0, 'bg-success':itemVer.estado == 2, 'bg-danger':itemVer.estado == -1 }"
            class="modal-header ">
            <h5 class="modal-title">
              Detalle Solicitud de Saldo
              <div *ngIf="spinner" class="spinner-border mt-2" role="status">
                <span class="visually-hidden"></span>
              </div>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-5 text-bold h2">ID:</div>
                      <div class="col-md-7 h2">{{itemVer.id}}</div>

                      <div class="col-md-5 text-bold h5">Comercio:</div>
                      <div class="col-md-7 h5">{{itemVer.razon_social | titlecase}}</div>

                      <!-- <div class="col-md-5 text-bold h5">Recaudador:</div>
                      <div class="col-md-7 h5">{{itemVer.razon_social_recaudo | titlecase}}</div> -->


                      <div class="col-md-5 text-bold h5">Comercio Destino:</div>
                      <div class="col-md-7 h5">{{itemVer.comercio_recaudo_nombre | titlecase}}</div>


                      <div class="col-md-5 text-bold h5">Valor:</div>
                      <div class="col-md-7 h5">${{itemVer.valor | decimal}}</div>

                      <div class="col-md-5 text-bold h5">Tipo de solicitud:</div>
                      <div class="col-md-7 h5">{{itemVer.tipo_solicitud | titlecase}}</div>

                      <div class="col-md-5 text-bold h5">Medio de pago:</div>
                      <div class="col-md-7 h5">{{itemVer.medio_pago | titlecase}}</div>

                      <div class="col-md-5 text-bold h5">Entidad de pago:</div>
                      <div class="col-md-7 h5">{{itemVer.entidad_pago | titlecase}}</div>

                      <div class="col-md-5 text-bold h5">Descripción:</div>
                      <div class="col-md-7 h5">{{itemVer.nota | titlecase}}</div>

                      <div class="col-md-5 text-bold h5">Fecha:</div>
                      <div class="col-md-7 h5">{{itemVer.solicitud_saldo_at}}</div>

                      <div class="col-md-5 text-bold h5">Estado:</div>
                      <div class="col-md-7">
                        <h4><span class="badge bg-danger btn-block" *ngIf="itemVer.estado == -1">Rechazado</span><span
                            class="badge bg-warning btn-block" *ngIf="itemVer.estado == 1">Pendiente</span><span
                            class="badge bg-success btn-block" *ngIf="itemVer.estado == 2">Aprobado</span></h4>
                      </div>


                      <button [disabled]="spinner" *ngIf="itemVer.estado == 1" class="btn btn-block btn-success mt-4"
                        (click)="aprobar(itemVer)">Aprobar</button>
                      <button [disabled]="spinner" *ngIf="itemVer.estado == 1" class="btn btn-block btn-danger mt-1"
                        (click)="rechazar(itemVer)">Rechazar</button>
                    </div>
                  </div>
                </div>

                <div class="card" *ngIf="itemVer.conciliacion_id">
                  <div class="card-body row">
                    <div class="col-md-7 text-bold h3">
                      CONCILIACIÓN:
                    </div>
                    <div class="col-md-5 h3">
                      {{itemVer.conciliacion_id}}
                    </div>

                    <div class="col-md-12 text-bold h5">
                      Nota:
                    </div>
                    <div class="col-md-12 h5 mb-3">
                      {{itemVer.conciliacion_nota}}
                    </div>

                    <div class="col-md-5 text-bold h5">Estado:</div>
                    <div class="col-md-7">
                      <h4>
                        <span class="badge bg-danger btn-block" *ngIf="itemVer.conciliacion_estado == -1">Anulada</span>
                        <span class="badge bg-danger btn-block"
                          *ngIf="itemVer.conciliacion_estado == 0">Rechazado</span>
                        <span class="badge bg-warning btn-block"
                          *ngIf="itemVer.conciliacion_estado == 1">Pendiente</span>
                        <span class="badge bg-success btn-block"
                          *ngIf="itemVer.conciliacion_estado == 3">Recibido</span>
                      </h4>
                    </div>
                  </div>
                </div>

              </div>
              <!-- fin col-md-6 -->
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <img *ngIf="itemVer.img" [src]="itemVer.img" class="d-block w-100" alt="Imagen no encontrada">
                  </div>
                  <div class="col-md-12">
                    <!-- {{ itemVer | json}} -->

                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button #CerrarModal (click)="cerrarModal('modalVer')" type="button" class="btn btn-secondary"
              data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin modal ver -->
    <!-- Fin modales -->


    <!-- INICIO LISTADO -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1>Modulo Solicitud Saldo</h1>
          </div>
          <div class="col-sm-12 col-md-4 mt-3">

            <button type="button" *ngIf="cargando" class="btn btn-primary"><span
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
            <button type="button" (click)="listadoSolicitudesSaldo()" *ngIf="!cargando" class="btn btn-primary"><i
                class="fa fa-sync"></i> </button>

            <button class="btn btn-success ml-1" type="button" id="button-addon2"  data-toggle="modal" data-target="#exportModal"><i class="fa fa-file-excel"></i></button>
            <!-- <button (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button> -->
            <!-- Boton Modal Solicitud -->
            <button *ngIf="perfil_id == 4" type="button" class="btn btn-success m-2" data-toggle="modal"
              data-target="#modalSolicitudSaldo"><i class="fa fa-plus"></i> Solicitar saldo</button>

          </div>
          <div class="col-sm-12 col-md-8 mt-3" *ngIf="perfil_id == 1">
            <input [(ngModel)]="buscar" name="buscar" type="text" (change)="filtro()" placeholder="Buscar por comercio"
              class="form-control float-right text-uppercase">
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Comercio</th>
                    <!-- <th>Recaudador</th> -->
                    <th>Destino</th>
                    <th>Valor</th>
                    <th>Tipo</th>
                    <th>Medio</th>
                    <th>Entidad</th>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th *ngIf="perfil_id == 1">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of solicitudes_saldo.items">
                    <td>{{item.id}}</td>
                    <td>{{item.razon_social | titlecase}}</td>
                    <!-- <td>{{item.razon_social_recaudo | titlecase}}</td> -->
                    <td>{{item.comercio_recaudo_nombre | titlecase}}</td>
                    <td>${{item.valor | decimal}}</td>
                    <td>{{item.tipo_solicitud | titlecase}}</td>
                    <td>{{item.medio_pago | titlecase}}</td>
                    <td>{{item.entidad_pago | titlecase}}</td>
                    <td>{{item.solicitud_saldo_at}}</td>
                    <td>
                      <h4><span class="badge bg-danger" *ngIf="item.estado == -1">Rechazado</span><span
                          class="badge bg-warning" *ngIf="item.estado == 1">Pendiente</span><span
                          class="badge bg-success" *ngIf="item.estado == 2">Aprobado</span></h4>
                    </td>
                    <td *ngIf="perfil_id == 1">
                      <div class="dropdown">
                        <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <button (click)="ver(item)" data-toggle="modal" data-target="#modalVer" type="button"
                            class="dropdown-item"><i class="fas fa-search"></i> Ver Detalles</button>

                          <!-- <button (click)="EditarComercio(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button>
                          <button (click)="MultiproductoComercio(item)" type="button" class="dropdown-item"><i class="fas fa-shopping-basket"></i> Multiproductos</button>
                          <div class="dropdown-divider"></div>
                          <button (click)="EliminarComercio(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button> -->
                        </div>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>


            <!-- paginador -->
            <div class="card-footer clearfix">
              <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item" *ngIf="solicitudes_saldo.current > 1">
                  <button type="button" class="page-link" (click)="changePage(solicitudes_saldo.current - 1)">
                    <span>Atras</span>
                  </button>
                </li>

                <li class="page-item" *ngFor="let page of pagesNumber"
                  [ngClass]="{'active': solicitudes_saldo.current == page}">
                  <button type="button" class="page-link" (click)="changePage(page)">
                    {{ page }}
                  </button>
                </li>

                <li class="page-item" *ngIf="solicitudes_saldo.current < solicitudes_saldo.total_page">
                  <button type="button" class="page-link" (click)="changePage(solicitudes_saldo.next)">
                    <span>Siguiente</span>
                  </button>
                </li>
              </ul>
              <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                <p>Total registros encontrados: <b>{{solicitudes_saldo.size}}</b></p>
              </div>
            </div>
            <!-- Fin paginador -->

            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
    </section>
    <!-- FIN LISTADO -->

  </div>
  <app-footer-main></app-footer-main>
</div>


<!-- Modal Filtro Reporte -->
<div class="modal fade" id="exportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Exportar Reporte</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-md-5">
            <div class="form-group">
              <label>Fecha Desde:</label>
              <div class="input-group">
                <input [(ngModel)]="fecha_desde" name="fecha_desde" type="date" class="form-control float-right">
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>Fecha Hasta:</label>
              <div class="input-group">
                <input [(ngModel)]="fecha_hasta" name="fecha_hasta" type="date" class="form-control float-right">
              </div>
            </div>
          </div>
          <div class="col-md-2" style="margin-top:31px;">
            <button type="button" *ngIf="cargando" class="btn btn-primary"><span
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
            <button [disabled]="!fecha_hasta || !fecha_desde" *ngIf="!cargando" (click)="exportExcel()" type="button"
              class="btn btn-primary"><i class="fa fa-search"></i> </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>