import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})
export class NuevoUsuarioComponent implements OnInit {
  
  form: FormGroup;
  submitted = false;
  Usuario: any = {};
  fieldTextType: boolean;

  constructor(public router: Router,
    public servicios: ServicesService,
    private formBuilder: FormBuilder) { }

    ngOnInit() {
      this.form = this.formBuilder.group({      
        nombre: ['', [Validators.required]],
        email: ['', [Validators.required]],
        usuario_nickname: ['', [Validators.required]],
        password: ['', [Validators.required]],
        celular: ['', [Validators.required]],
        perfil_id: ['', [Validators.required]],
        img: ['', [Validators.required]],
      });
    }
  
    get f() { return this.form.controls; }
  
    EnviarUsuario(){          
      this.submitted = true;
      // if(this.form.invalid){
      //   return;
      // }
      this.Usuario = this.form.value;
       let params = {
            nuevo: true,
            usuario: this.Usuario
          }
          // console.log(params);
          this.servicios.postUsuario(params)
          .subscribe( (data) => {
            // console.log(data);
            if (data["logger"] == true){
              this.router.navigate(['../usuarios/listado'])
              Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: data['msg'],
                  showConfirmButton: false,
                  timer: 1500,
                  showClass: {
                    popup: 'animated fadeInDown faster'
                  },
                  hideClass: {
                    popup: 'animated fadeOutUp faster'
                  }
                });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
            }
          });          
    }
  
    toggleFieldTextTypePass() {
      this.fieldTextType = !this.fieldTextType;
    } 
  

}
