<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

    <div class="content-wrapper">
      
      <!--Modal Ver recaudo -->
      <div id="modalVerRecaudo" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div [ngClass]="{'bg-warning': verItem.estado == 1, 'bg-primary':verItem.estado == 2, 'bg-success':verItem.estado == 3, 'bg-danger':verItem.estado == 4 }" class="modal-header ">
              <h5 class="modal-title">
                Solicitud {{verItem.id}}
                <span class="bg-warning" style="padding: 5px;" *ngIf="verItem.estado == 1"><b>- Pendiente</b></span> 
                <span class="bg-primary" style="padding: 5px;" *ngIf="verItem.estado == 2"><b>- Procesando</b></span> 
                <span class="bg-success" style="padding: 5px;" *ngIf="verItem.estado == 3"><b>- Procesado</b></span> 
                <span class="bg-danger" style="padding: 5px;" *ngIf="verItem.estado == 4"><b>- Cancelada</b></span>
              </h5>
              <button type="button" class="close" (click)="cerrarModal('modalVerRecaudo')" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>                            
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">

                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-6">
                          <input readonly [(ngModel)]="verItem.fecha" class="form-control" name="fecha" type="date" 
                          value="2011-08-19"style="outline: none;background-color: rgb(229, 233, 231);
                          border: 0;text-align: center;">
                        </div>
                        <div class="col-md-6">
                          <input readonly [(ngModel)]="verItem.hora" class="form-control" name="hora" type="time" 
                          value="13:45:00" style="outline: none;background-color: rgb(229, 233, 231);
                          border: 0;text-align: center;">
                        </div>
                      </div>
                    </div>

                    <div class="form-group" style="text-align: center;">
                      <label style="text-align: center;">{{verItem.nota}}</label>
                    </div>
                    <hr>

                      
                    <div style="margin-top:-1%" class="form-group">
                      <label>Punto Recaudo:</label> <span> {{verItem.recaudo_comercio_razon_social}} {{verItem.recaudo_comercio_municipio}}</span>
                    </div>

                    <div style="margin-top:-3%" class="form-group">
                      <label>Numero Cuenta:</label> <span> {{verItem.numero_cuenta}}</span>
                    </div>   

                    <div style="margin-top:-3%" class="form-group">
                      <label>Referencia:</label> <span> {{verItem.recaudo_referencia}}</span>
                    </div>   

                    <div style="margin-top:-3%" class="form-group">
                      <label>Nombres:</label> <span> {{verItem.nombre}}</span>
                    </div>    
                    <div style="margin-top:-3%" class="form-group">
                      <label>Numero de Celular:</label> <span> {{verItem.celular}}</span>
                    </div>  
                    <div style="margin-top:-3%" class="form-group">
                      <label>Correo Electronico:</label> <span> {{verItem.email}}</span>
                    </div>                                     


                    <div style="margin-top:-3%" class="form-group">
                      <label>Valor:</label> <span> ${{verItem.valor | decimal}}</span>
                    </div>             

                    <div style="margin-top:3%" class="form-group">
                      <button *ngIf="verItem.estado == 1" class="btn btn-sm btn-success w-100" type="button">
                          <i class="fa fa-smile-wink"></i>
                          Activo
                      </button>
                      <button *ngIf="verItem.estado == 2" class="btn btn-sm btn-danger w-100" type="button">
                          <i class="fa fa-dizzy"></i>
                          Inactivo
                      </button>
                    </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button #CerrarModal (click)="cerrarModal('modalVerRecaudo')" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              <!-- <button type="button" class="btn btn-secondary" (click)="cerrarModal('modalVerRecaudo'+item.id)">Cerrar</button> -->
            </div>
          </div>
        </div>
      </div>


    <!-- Modal Filtro Reporte -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Exportar Reporte</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
            
              <div class="col-md-5">
                <div class="form-group">
                    <label>Fecha Desde:</label>
                    <div class="input-group">
                      <input [(ngModel)]="fecha_desde" name="fecha_desde" type="date" class="form-control float-right">
                    </div>
                  </div>
              </div>
              <div class="col-md-5">
                  <div class="form-group">
                      <label>Fecha Hasta:</label>
                      <div class="input-group">
                        <input [(ngModel)]="fecha_hasta"name="fecha_hasta" type="date" class="form-control float-right">
                      </div>
                    </div>                                
              </div>
              <div class="col-md-2" style="margin-top:31px;">
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button [disabled]="!fecha_hasta || !fecha_desde"  *ngIf="!cargando" (click)="exportExcel()" type="button" class="btn btn-primary"><i class="fa fa-search"></i> </button>
              </div>         
            </div>
          </div>

        </div>
      </div>
    </div>



      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>
                <div style="float: left;">Modulo Recaudo </div>
                <!-- <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoRecaudo()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button> -->
                <!-- <button (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button> -->
                

                <div class="input-group  mb-3 col-3">
                  <div class="input-group-prepend" id="button-addon3">
                    <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                    <button type="button" (click)="listadoRecaudo()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                    <!-- <button [disabled]="!reporteMes" class="btn btn-success" type="button" id="button-addon2"><i class="fa fa-file-excel"></i></button> -->
                    <button class="btn btn-success" type="button" id="button-addon2"  data-toggle="modal" data-target="#exampleModal"><i class="fa fa-file-excel"></i></button>
                  </div>



                  <!-- <select class="form-control" id="inputGroupSelect04" [(ngModel)]="reporteMes" aria-label="Example select with button addon">
                    <option selected>Seleccione Mes</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                  <div class="input-group-prepend" id="button-addon4">
                    <button [disabled]="!reporteMes" (click)="exportExcel()" class="btn btn-success" type="button" id="button-addon2"><i class="fa fa-download"></i></button>
                  </div> -->

                </div>


              </h1>
            </div>
            <!-- <div class="col-sm-12 mt-3">
              <button type="button" routerLink="/recaudo/nuevo" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
            </div> -->
          </div>
        </div>
      </section>

      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Fecha</th>
                      <th>Cuenta</th>
                      <th>Referencia</th>
                      <th>Nombre</th>
                      <th>Punto</th>
                      <th>Valor</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of recaudo.items">
                      <td  data-toggle="modal" data-target="#modalVerRecaudo" style="cursor: pointer;">{{item.id}}</td>
                      <td>{{item.fecha}} {{item.hora}}</td>
                      <td>{{item.numero_cuenta}}</td>
                      <td>{{item.recaudo_referencia}}</td>
                      <td>{{item.nombre}}</td>
                      <td>{{item.recaudo_comercio_razon_social}}</td>
                      <td><b>${{item.valor | decimal}}</b></td>
                      <td>
                        <span class="bg-warning" style="padding: 5px;" *ngIf="item.estado == 1"><b>Pendiente</b></span> 
                        <span class="bg-primary" style="padding: 5px;" *ngIf="item.estado == 2"><b>Procesando</b></span> 
                        <span class="bg-success" style="padding: 5px;" *ngIf="item.estado == 3"><b>Procesado</b></span> 
                        <span class="bg-danger" style="padding: 5px;" *ngIf="item.estado == 4"><b>Cancelada</b></span>
                      </td>                      
                      <td>
                        <button (click)="verDetalle(item)" data-toggle="modal" data-target="#modalVerRecaudo" type="button"
                            class="btn btn-success btn-sm"><i class="fas fa-search"></i></button>
                        <!-- <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalVerRecaudo"><i class="fas fa-search" (click)="verDetalle(item)"></i></button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <!-- paginador -->
              <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="recaudo.current > 1">
                    <button type="button" class="page-link" (click)="changePage(recaudo.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>                  

                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': recaudo.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>                  

                  <li class="page-item" *ngIf="recaudo.current < recaudo.total_page">
                    <button type="button" class="page-link" (click)="changePage(recaudo.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>                  
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{recaudo.size}}</b></p>
                </div>
              </div>              
              <!-- Fin paginador -->               
            
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>   
      </section>   
    </div> 
    <app-footer-main></app-footer-main>  
</div>  
