import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  id: any;
  slug:any;
  titulo:any;
  listProductos: any = [];

  constructor(private route: ActivatedRoute,public servicios: ServicesService, public router: Router) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
        this.slug = this.route.snapshot.paramMap.get('slug');
        this.titulo = this.slug.replace('-', ' ');

        this.id = this.route.snapshot.paramMap.get('id');

        if(this.slug && this.id){
          this.getProductos();      
        }else{
          this.router.navigate(['/catalogo/']);
        } 

      });
    console.log('dentro');
  }


  getProductos(){
    let params = {
      getcategoriaproductos: true,
      slug: this.slug,
      id: this.id,
    }
    this.servicios.setEcommerceProducto(params).subscribe( (data) => {
        this.listProductos = data['productos']; 
        // console.log(this.listProductos);                        
    });
  }

  // ngOnDestroy () {
  //   this.listProductos?.unsubscribe();
  // }

}

