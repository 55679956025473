import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {

  cargando: boolean =false;
  productos: any = [];
  form: FormGroup;
  submitted = false;

  constructor(private servicios: ServicesService,
    public router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.listadoProductos();

    this.form = this.formBuilder.group({      
      nombre: ['', [Validators.required]],
      producto_categoria_id: ['', [Validators.required]],
      porcentaje: ['', [Validators.nullValidator]],
      tarifa_fija: ['', [Validators.nullValidator]],      
    }); 
  }

  get f() { return this.form.controls; } 

  listadoProductos(){
    this.cargando = true;
      this.servicios.getProductos('3CAPAGO').subscribe( (data) => {
        this.productos = data['categorias'];
        this.cargando = false;
        // console.log(this.productos);
      });    
  }

  enviarNuevoProducto(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }

    // console.log(this.form.value);
    let params = {
      nuevo: true,
      producto: this.form.value
    }
    this.servicios.setProducto(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.listadoProductos();
          this.form.reset();
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }
    });   

  }
}
