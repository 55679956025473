
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

  <div class="content-wrapper">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>PubliExpress</h1>
              </div>
            </div>
          </div>
      </div>

      <div class="content row">          
          <div class="col-lg-12 col-xs-12">
              <div class="info-box"  title="PRODUCTOS" routerLink="/publiexpress/productos" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                  <i class="fa fa-boxes"></i>
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">MIS PRODUCTOS</span>
                </div>
              </div>
          </div>

          <div class="col-lg-12 col-xs-12">
            <div class="info-box"  title="VENTAS" routerLink="/publiexpress/cotizador" style="cursor: pointer">
              <span class="info-box-icon bg-warning">
                <i class="fa fa-tags"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text">COTIZADOR</span>
              </div>
            </div>            
          </div> 
          
          <div class="col-lg-12 col-xs-12">
            <div class="info-box"  title="COMPRAS" routerLink="/publiexpress/ordenes" style="cursor: pointer">
              <span class="info-box-icon bg-warning">
                <i class="fa fa-shopping-bag"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text">MIS ORDENES </span>
              </div>
            </div>            
          </div>                

      </div>       

  </div>
    
    <app-footer-main></app-footer-main>  
</div>  

