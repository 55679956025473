import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-ventas-ecommerce',
  templateUrl: './ventas-ecommerce.component.html',
  styleUrls: ['./ventas-ecommerce.component.css']
})
export class VentasEcommerceComponent implements OnInit {
  comercio_id:any;
  perfil_id:any;
  cargando:boolean = false;

  constructor(private servicios: ServicesService, private router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoVentas();
  }

  listadoVentas(){

  }

}
