
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Productos</h1>
              </div>
              <div class="col-sm-12 mt-3">
                <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/ecommerce/operaciones" class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a>
                <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/productos/form" class="btn btn-success mr-1">Nuevo</a>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoProductos()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
              </div>
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover" id="">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th></th>
                        <th>SKU</th>
                        <th>TITULO</th>
                        <th>VALOR</th>
                        <th>STOCK</th>
                        <th>FECHA</th>
                        <th>ESTADO</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of productos.items; let i = index">
                        <td>{{i+1}}</td>
                        <td (click)="onVer(item)" style="cursor: pointer;"><img *ngIf="item.imgUrl" style="margin-top:1%" src="{{item.imgUrl}}" height="50px" width="" class=""></td>
                        <td (click)="onVer(item)" style="cursor: pointer;">00{{item.id}}</td>
                        <td (click)="onVer(item)" style="cursor: pointer;">{{item.titulo | titlecase}}</td>
                        <td>${{item.valor | decimal}}</td>
                        <td>${{item.stock}}</td>
                        <td>{{item.ecommerce_productoco_at}}</td>
                        <td><h4><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span><span class="badge bg-danger" *ngIf="item.estado ==-1">Rechazado</span></h4></td>
                        <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="onVer(item)" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver detalle</button>
                              <button *ngIf="perfil_id == 1 && item.estado == 1" (click)="onEditarEstado(item, 0, 'Eliminación')" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button>
                            </div>
                          </div>                         
                        </td>
                      </tr>
  
                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="productos.current > 1">
                      <button type="button" class="page-link" (click)="changePage(productos.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': productos.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="productos.current < productos.total_page">
                      <button type="button" class="page-link" (click)="changePage(productos.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{productos.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>   
        </section>
      </div>
      <app-footer-main></app-footer-main>  
  </div>  