
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Mensajeria</h1>
              </div>
              <div class="col-sm-12 mt-3">
                <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/mensajeria/form" class="btn btn-success mr-1">Nuevo</a>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoMensajeria()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
              </div>
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover" id="">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>NÚMERO GUIA</th>
                        <th>COMERCIO</th>
                        <th>VALOR</th>
                        <th>FECHA</th>
                        <th>ESTADO</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of mensajeria.items">
                        <td (click)="onVer(item)" style="cursor: pointer;">{{item.id}}</td>
                        <td (click)="onVer(item)" style="cursor: pointer;">{{item.numero_guia}}</td>
                        <td>{{item.razon_social | titlecase}}</td>
                        <td>${{item.valor_total | decimal}}</td>
                        <td>{{item.mensajeria_at}}</td>
                        <td><h4><span class="badge bg-success" *ngIf="item.estado ==3">Despachado</span><span class="badge bg-danger" *ngIf="item.estado ==0">Rechazado</span><span class="badge bg-warning" *ngIf="item.estado ==1">Pendiente</span><span class="badge bg-info" *ngIf="item.estado ==2">En ruta</span></h4></td>
                        <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="onVer(item)" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver detalle</button>
                              <button *ngIf="perfil_id == 1 && item.estado == 1" (click)="onEditarEstado(item, 2, 'En ruta')" type="button" class="dropdown-item"><i class="fas fa-road"></i> En ruta</button>
                              <button *ngIf="perfil_id == 1 && item.estado == 2" (click)="onEditarEstado(item, 3, 'Despachado')" type="button" class="dropdown-item"><i class="fas fa-truck"></i> Despachado</button>
                              <button (click)="onSelect(item)" type="button" class="dropdown-item" style="color: red;"><i class="far fa-file-pdf"></i> PDF</button>
                              <button *ngIf="perfil_id == 1 && item.estado == 1" (click)="onEditarEstado(item, 0, 'Eliminación')" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button>
                            </div>
                          </div>                         
                        </td>
                      </tr>
  
                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="mensajeria.current > 1">
                      <button type="button" class="page-link" (click)="changePage(mensajeria.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': mensajeria.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="mensajeria.current < mensajeria.total_page">
                      <button type="button" class="page-link" (click)="changePage(mensajeria.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{mensajeria.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>   
        </section>
      </div>
      <app-footer-main></app-footer-main>  
  </div>  