import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formulario-mensajeria',
  templateUrl: './formulario-mensajeria.component.html',
  styleUrls: ['./formulario-mensajeria.component.css']
})
export class FormularioMensajeriaComponent implements OnInit {

  comercio_id: any;
  perfil_id: any;
  ver: boolean = false;
  departamentos: any = [];
  municipios: any = [];
  municipios_destino: any = [];
  id: any;
  isNumero_guia: boolean = false;

  imgUrl: any;

  form = new FormGroup({
    numero_guia: new FormControl(''),
    img: new FormControl('', Validators.nullValidator),
    ciudad_origen: new FormControl('', Validators.required),
    ciudad_destino: new FormControl('', Validators.required),
    forma_pago: new FormControl('', Validators.required),
    dice_contener: new FormControl('', Validators.required),
    nota: new FormControl('', Validators.required),
    valor_flete: new FormControl('', Validators.required),
    valor_servicio: new FormControl('', Validators.required),
    valor_total: new FormControl('', Validators.required),
    remite_tipo_identificacion: new FormControl('', Validators.required),
    remite_identificacion: new FormControl('', Validators.required),
    remite_nombre_razon_social: new FormControl('', Validators.required),
    remite_primer_apellido: new FormControl('', Validators.required),
    remite_segundo_apellido: new FormControl('', Validators.required),
    remite_direccion: new FormControl('', Validators.required),
    remite_email: new FormControl('', Validators.nullValidator),
    remite_celular: new FormControl('', Validators.required),
    destino_tipo_identificacion: new FormControl('', Validators.required),
    destino_identificacion: new FormControl('', Validators.required),
    destino_nombre_razon_social: new FormControl('', Validators.required),
    destino_primer_apellido: new FormControl('', Validators.required),
    destino_segundo_apellido: new FormControl('', Validators.required),
    destino_direccion: new FormControl('', Validators.required),
    destino_email: new FormControl('', Validators.nullValidator),
    destino_celular: new FormControl('', Validators.required),
    estado: new FormControl(''),
    fecha_envio: new FormControl(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(), Validators.required),
    tipo_entrega: new FormControl('', Validators.required),
    peso: new FormControl('', Validators.required),
    departamento_origen: new FormControl('', Validators.required),
    departamento_destino: new FormControl('', Validators.required),
  })

  constructor(private servicios: ServicesService,
    private router: Router,
    private route: ActivatedRoute) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.ver = true;
      let verMensajeria = this.router.getCurrentNavigation().extras.state.itemMensajeria;
      this.id = verMensajeria.id;
      // console.log(verMensajeria);
      // console.log(this.ver);
      // this.form.get('numero_guia').disable();
      this.form.get('numero_guia').setValue(verMensajeria.numero_guia);
      this.form.get('ciudad_origen').setValue(verMensajeria.ciudad_origen);
      this.form.get('ciudad_destino').setValue(verMensajeria.ciudad_destino);
      this.form.get('forma_pago').setValue(verMensajeria.forma_pago);
      this.form.get('dice_contener').setValue(verMensajeria.dice_contener);
      this.form.get('nota').setValue(verMensajeria.nota);
      this.form.get('valor_flete').setValue(verMensajeria.valor_flete);
      this.form.get('valor_servicio').setValue(verMensajeria.valor_servicio);
      this.form.get('valor_total').setValue(verMensajeria.valor_total);
      this.form.get('remite_tipo_identificacion').setValue(verMensajeria.remite_tipo_identificacion);
      this.form.get('remite_identificacion').setValue(verMensajeria.remite_identificacion);
      this.form.get('remite_nombre_razon_social').setValue(verMensajeria.remite_nombre_razon_social);
      this.form.get('remite_primer_apellido').setValue(verMensajeria.remite_primer_apellido);
      this.form.get('remite_segundo_apellido').setValue(verMensajeria.remite_segundo_apellido);
      this.form.get('remite_direccion').setValue(verMensajeria.remite_direccion);
      this.form.get('remite_email').setValue(verMensajeria.remite_email);
      this.form.get('remite_celular').setValue(verMensajeria.remite_celular);
      this.form.get('destino_tipo_identificacion').setValue(verMensajeria.destino_tipo_identificacion);
      this.form.get('destino_identificacion').setValue(verMensajeria.destino_identificacion);
      this.form.get('destino_nombre_razon_social').setValue(verMensajeria.destino_nombre_razon_social);
      this.form.get('destino_primer_apellido').setValue(verMensajeria.destino_primer_apellido);
      this.form.get('destino_segundo_apellido').setValue(verMensajeria.destino_segundo_apellido);
      this.form.get('destino_direccion').setValue(verMensajeria.destino_direccion);
      this.form.get('destino_email').setValue(verMensajeria.destino_email);
      this.form.get('destino_celular').setValue(verMensajeria.destino_celular);
      this.form.get('estado').setValue(verMensajeria.estado);
      this.form.get('fecha_envio').setValue(verMensajeria.fecha_envio);
      this.form.get('tipo_entrega').setValue(verMensajeria.tipo_entrega);
      this.form.get('peso').setValue(verMensajeria.peso);
      this.servicios.getDepartamentoMunicipios(verMensajeria.departamento_origen + "-" + verMensajeria.ciudad_origen).subscribe(
        data => {
          this.form.get('departamento_origen').setValue(data['departamento'][0].id);
          this.servicios.getDepartamentoMunicipios(verMensajeria.departamento_origen).subscribe(data => this.municipios = data["municipios"])
          this.form.get('ciudad_origen').setValue(data['municipio'][0].id);
        }
      )
      this.servicios.getDepartamentoMunicipios(verMensajeria.departamento_destino + "-" + verMensajeria.ciudad_destino).subscribe(
        data => {
          this.form.get('departamento_destino').setValue(data['departamento'][0].id);
          this.servicios.getDepartamentoMunicipios(verMensajeria.departamento_destino).subscribe(data => this.municipios_destino = data["municipios"])
          this.form.get('ciudad_destino').setValue(data['municipio'][0].id);
        }
      )
      this.form.get('forma_pago').disable();
      this.form.get('remite_tipo_identificacion').disable();
      this.form.get('destino_tipo_identificacion').disable();
      this.form.get('tipo_entrega').disable();
      this.form.get('departamento_origen').disable();
      this.form.get('departamento_destino').disable();
      this.form.get('ciudad_origen').disable();
      this.form.get('ciudad_destino').disable();
      this.imgUrl = verMensajeria['img'];
      if (!verMensajeria.numero_guia) {
        this.isNumero_guia = true;
      } else {
        this.form.get('numero_guia').disable();
      }
    }

  }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.servicios.getDepartamentos().subscribe(data => this.departamentos = data['departamentos']);
    if (this.perfil_id != 1) {
      this.form.get('numero_guia').disable();
    }
  }

  get f() { return this.form.controls; }
  // valor_servicio() { return this.form.value.valor_flete * 0.10 }
  valor_servicio() { return this.form.value.valor_flete }
  valor_total() {
    // if(this.form.get('forma_pago').value == 'al cobro') {
    //   return +this.form.value.valor_flete;
    // }else{
    // return +this.form.value.valor_flete + this.valor_servicio();
    return +this.form.value.valor_flete;
    // } 
  }

  onSubmit() {
    Swal.fire({
      title: '¿Está seguro?',
      html: "Desea crear la solicitud de mensajeria ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Continuar!',
    }).then((result) => {
      if (result.value) {
        console.log(this.form.value.valor_servicio);
        this.form.get('valor_servicio').setValue(this.valor_servicio());
        this.form.get('valor_total').setValue(this.valor_total());
        console.log(this.form.value);
        if (this.form.valid) {
          let params = {
            nuevo: true,
            comercio_id: this.comercio_id,
            mensajeria: this.form.value
          }
          console.log(params);
          this.servicios.setMensajeria(params)
            .subscribe((data) => {
              console.log(data);

              if (data["logger"] == true) {
                this.router.navigate(['mensajeria/listado'])
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: data['msg'],
                  showConfirmButton: false,
                  timer: 1500,
                  showClass: {
                    popup: 'animated fadeInDown faster'
                  },
                  hideClass: {
                    popup: 'animated fadeOutUp faster'
                  }
                });
              } else {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: data['msg'],
                  showConfirmButton: false,
                  timer: 1500,
                  showClass: {
                    popup: 'animated fadeInDown faster'
                  },
                  hideClass: {
                    popup: 'animated fadeOutUp faster'
                  }
                });
              }
            });
        }else{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "Error Faltan campos por llenar",
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }

      }
    });




  }

  onEditar() {

    console.log(this.form.value.numero_guia);
    this.form.get('img').setValue(this.imgUrl);
    if (this.form.value.numero_guia != '' && this.form.value.img != '') {
      let params = {
        editar: true,
        id: this.id,
        mensajeria: this.form.value
      }
      console.log(params);
      this.servicios.setMensajeria(params)
        .subscribe((data) => {
          console.log(data);

          if (data["logger"] == true) {
            this.router.navigate(['mensajeria/listado'])
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Cargar número de guía e imagen Soporte",
        showConfirmButton: false,
        timer: 2500,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      });
    }
  }

  onSelectDepartamento() {
    // console.log(this.form.value.departamento_origen);
    this.servicios.getDepartamentoMunicipios(this.form.value.departamento_origen).subscribe(data => {
      console.log(data);
      this.municipios = data["municipios"];
    });
  }

  onSelectDepartamento_destino() {
    // console.log(this.form.value.departamento_origen);
    this.servicios.getDepartamentoMunicipios(this.form.value.departamento_destino).subscribe(data => {
      console.log(data);
      this.municipios_destino = data["municipios"];
    });
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgUrl = reader.result;
      // console.log("esta es la imagen"+this.imgURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen() {
    if (!this.imgUrl) {
      console.log('error');
      return;
    } else {
      let params = {
        archivo: this.imgUrl
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgOrden(params)
        .subscribe((data) => {
          if (data['logger'] == true) {
            this.imgUrl = data['filename'];
            console.log("img" + this.imgUrl);
          }
        });
    }
  }
}
