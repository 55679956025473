
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content">
           <div class="row">
            <div class="col-md-12" style="margin-top: 2%;">
                <div class="card card-primary">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Fecha Desde:</label>
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">
                                          <i class="far fa-calendar-alt"></i>
                                        </span>
                                      </div>
                                      <input [(ngModel)]="fecha_desde" name="fecha_desde" type="date" class="form-control float-right">
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Fecha Hasta:</label>
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">
                                          <i class="far fa-calendar-alt"></i>
                                        </span>
                                      </div>
                                      <input [(ngModel)]="fecha_hasta"name="fecha_hasta" type="date" class="form-control float-right">
                                    </div>
                                  </div>                                
                            </div>

                            <div class="col-md-3" *ngIf="perfil_id <= 2">
                              <div class="form-group">
                                  <label>Punto:</label>
                                  <div class="input-group">
                                    <select [(ngModel)]="punto" name="punto" class="form-control float-right">
                                      <option value=''><b>TODOS</b></option>
                                      <option [value]="item.id" *ngFor="let item of puntos">{{item.razon_social | uppercase}}</option>
                                    </select>
                                  </div>
                                </div>                                
                            </div>                            
                            <div class="col-md-3" style="margin-top:31px;">
                              <button (click)="ListadoReportes()" type="button" class="btn btn-primary"><i class="fa fa-search"></i> </button>
                              <button *ngIf="Reporte.items" (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>
                            </div>
                        </div>
                      <!-- Date range -->
                      

                      <section class="content">
                        <div class="row">
                          <div class="col-12" *ngIf="Reporte.items">
                            <div class="card card-primary" style="padding-top: 5px;padding-bottom: 5px;">
                              <div class="card-body" style="padding-top: 0%;padding-bottom: 0%;">
                                <h4><span class="badge bg-warning">Pendiente {{Reporte.estado_count_pendiente}}</span> <span class="badge bg-success">Procesado {{Reporte.estado_count_procesado}}</span>  <span class="badge bg-danger">Cancelado {{Reporte.estado_count_cancelado}}</span> 
                                  <!-- <button (click)="exportExcel()" type="button" class="btn btn-sm btn-success"><i class="fa fa-file-excel"></i> </button> -->
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="card">
                              <div class="card-body table-responsive p-0">
                                <table class="table table-hover">
                                  <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Fecha</th>
                                      <th>Operación</th>
                                      <th>Nombre</th>
                                      <th>Email</th>
                                      <th>Valor</th>
                                      <th>Estado</th>
                                      <th>Acciones</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of Reporte.items; let i = index">
                                      <td>{{item.id}}</td>
                                      <td>{{item.fecha}} {{item.hora}}</td>
                                      <td *ngIf="item.banco"><b>Banco </b><div>{{item.banco | uppercase}}</div></td>
                                      <td *ngIf="item.recaudo_id_comercio || item.recaudo_empresa_nombre  || item.recaudo_referencia"><b>Recaudo </b><div>{{item.recaudo_referencia | uppercase}}</div></td>
                                      <td>{{item.nombre}}</td>
                                      <td>{{item.email}}</td>
                                      <td class="text-right">$ {{item.valor | decimal}}</td>
                                      <td><span class="bg-warning" style="padding: 5px;" *ngIf="item.estado == 1"><b>Pendiente</b></span> 
                                          <span class="bg-primary" style="padding: 5px;" *ngIf="item.estado == 2"><b>Procesando</b></span> 
                                          <span class="bg-success" style="padding: 5px;" *ngIf="item.estado == 3"><b>Procesado</b></span> 
                                          <span class="bg-danger" style="padding: 5px;" *ngIf="item.estado == 4"><b>Cancelada</b></span></td>
                                      <td>
                                        <button (click)="VerReporte(item)" data-toggle="modal" data-target="#modalVerMensajero" type="button" class="btn btn-success btn-sm"><i class="fas fa-search"></i></button>
                                      </td>
                                    </tr>
                                    
                                    <div id="modalVerMensajero" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false"> 
                                      <div role="document" class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                              <div class="col-md-12">
                                                <div class="row">
                                                  <div class="col-md-8">
                                                    <span class="bg-warning" style="padding: 5px;" *ngIf="ordenReporte.estado == 1"><b>Pendiente</b></span> 
                                                    <span class="bg-primary" style="padding: 5px;" *ngIf="ordenReporte.estado == 2"><b>Procesando</b></span> 
                                                    <span class="bg-success" style="padding: 5px;" *ngIf="ordenReporte.estado == 3"><b>Procesado</b></span> 
                                                    <span class="bg-danger" style="padding: 5px;" *ngIf="ordenReporte.estado == 4"><b>Cancelada</b></span>
                                                  </div>
                                                </div>
                
                                                <div class="col-md-12">
                                                  <div class="row">
                                                    <div class="col-md-7 mt-2">
                                                      <img style="display:block;margin:auto;" *ngIf="ordenReporte.banco == 'bancolombia'" width="250" src="assets/img/logo-bancolombia.png">
                                                      <img style="display:block;margin:auto;" *ngIf="ordenReporte.banco == 'colpatria'" width="250" src="assets/img/logo-colpatria.png">
                                                      <img style="display:block;margin:auto;" *ngIf="ordenReporte.banco == 'davivienda'" width="250" src="assets/img/logo-davivienda.png">
                  
                                                      <div style="margin-top:-1%;font-size: 2em;" class="form-group">
                                                        <span> {{ordenReporte.tipo | titlecase}}</span>
                                                      </div>  
                                                      <div style="margin-top:-5%;font-size: 1.3em;" class="form-group" *ngIf="ordenReporte.numero_cuenta">
                                                        <label>Numero de Cuenta:</label> <span> {{ordenReporte.numero_cuenta}}</span>
                                                      </div> 
                                                      <div style="margin-top:-5%;font-size: 2em;" class="form-group">
                                                        <label>Valor:</label> <span> {{ordenReporte.valor | decimal}}</span>
                                                      </div>
                                                      
                                                      <div class="row">
                                                        <div class="col-md-12">
                                                          <div class="row">
                                                            <div class="col-md-6">
                                                              <input readonly [(ngModel)]="ordenReporte.fecha" class="form-control" name="fecha" type="date" 
                                                                    value="2011-08-19" style="outline: none;background-color: rgb(229, 233, 231);
                                                                    border: 0;text-align: center;">
                                                            </div>
                                                            <div class="col-md-6">
                                                              <input readonly [(ngModel)]="ordenReporte.hora" class="form-control" name="hora" type="time" 
                                                                    value="13:45:00" style="outline: none;background-color: rgb(229, 233, 231);
                                                                    border: 0;text-align: center;">
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-12 mt-4"> 
                                                            <div style="margin-top:-1%" class="form-group">
                                                              <label>Nombre:</label> <span> {{ordenReporte.nombre}}</span>
                                                            </div>    
                                                            <div style="margin-top:-3%" class="form-group">
                                                              <label>Celular:</label> <span> {{ordenReporte.celular}}</span>
                                                            </div>  
                                                            <div style="margin-top:-3%" class="form-group">
                                                              <label>Email:</label> <span> {{ordenReporte.email}}</span>
                                                            </div>   
                                                            <div style="margin-top:-3%" class="form-group">
                                                              <label>Punto:</label> <span> {{ordenReporte.punto}}</span>
                                                            </div> 
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-5">
                                                      <div class="col-md-12" *ngIf="!ordenReporte.recaudo_id_comercio">
                                                        <div class="row">
                                                          <div class="col-md-12" style="margin-top: 45%;">
                                                            <div class="form-group text-center mt-2">
                                                              <img *ngIf="ordenReporte.imgUrl" style="margin-top:1%" [src]="ordenReporte.imgUrl" height="200" width="100%" class="img-fluid" alt="Responsive image">
                                                              <img *ngIf="!ordenReporte.imgUrl" style="margin-top:1%" src="assets/img/default-image.png" height="200" width="100%" class="img-fluid" alt="Responsive image">
                                                            </div>
                                                            <div class="form-group">
                                                              <div class="form-group mt-2">
                                                                <label>Nota:</label> <span> {{ordenReporte.nota}}</span>
                                                              </div> 
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          <div class="modal-footer">
                                            <button #CerrarModal type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div> 
                                  </tbody>
                                </table>

                                <!-- valor total -->
                                <div style="text-align: right;margin-right: 15%;" *ngIf="valorTotal"><h1>$ {{valorTotal  | decimal}}</h1></div>
                              </div>
                
                              <div class="card-footer clearfix">
                                <ul class="pagination pagination-sm m-0 float-right">
                                  <li class="page-item" *ngIf="Reporte.current > 1">
                                    <button type="button" class="page-link" (click)="changePage(Reporte.current - 1)">
                                      <span>Atras</span>
                                    </button>
                                  </li>                  
                
                                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': Reporte.current == page}">
                                    <button type="button" class="page-link" (click)="changePage(page)">
                                      {{ page }}
                                    </button>
                                  </li>                  
                
                                  <li class="page-item" *ngIf="Reporte.current < Reporte.total_page">
                                    <button type="button" class="page-link" (click)="changePage(Reporte.next)">
                                      <span>Siguiente</span>
                                    </button>
                                  </li>                  
                                </ul>
                                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                                  <p>Total registros encontrados: <b>{{Reporte.size}}</b></p>
                                </div>
                              </div>              
                            </div>
                         
                          </div>
                        </div>   
                      </section>  

                    </div>
                    <!-- /.card-body -->
                  </div> 
            </div>
           </div>
        </div>
        <!-- fin content -->
      </div>
      
      <app-footer-main></app-footer-main>  
  </div>  
  
