<app-header-catalogo></app-header-catalogo>

<div class="album py-5 bg-light">
  <div class="container">

    <div class="row">
      <div class="col-md-12 text-center">
        <h3>{{titulo | titlecase}}</h3>
      </div>
      <div class="col-md-4" *ngFor="let item of listProductos.items">
        <div class="card mb-4 shadow-sm" routerLink="/catalogo/producto/{{item.producto.id}}" style="cursor: pointer;">
          <div class="bd-placeholder-img card-img-top" [ngStyle]="{'background-image':' url(' + item.producto.imgUrl + ')','height':'250px','width':'100%','background-size':'cover','background-position':'center'}"></div>
          <div class="card-body">
            <p class="card-text mb-0 text-bold">COD {{item.producto.id}}</p>
            <p class="card-text">{{item.producto.titulo | titlecase}}</p>
            <h3>$ {{item.producto.valor_servicio | decimal}}</h3>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-footer-catalogo></app-footer-catalogo>