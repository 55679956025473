
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Importar Archivo Plano</h1>
                </div>
              </div>
            </div>
        </div>

        <div class="container">
            <div class="card">
                <div class="card-body">
                    <h5 class="mb-2">Seleccione mes</h5>
                    <div class="row">
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('01','Enero')">Enero</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('02','Febrero')">Febrero</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('03','Marzo')">Marzo</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('04','Abril')">Abril</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('05','Mayo')">Mayo</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('06','Junio')">Junio</button></div>
        
                    </div>        
                    <div class="row mt-1">
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('07','Julio')">Julio</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('08','Agosto')">Agosto</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('09','Septiembre')">Septiembre</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('10','Octubre')">Octubre</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('11','Noviembre')">Noviembre</button></div>
                        <div class="col-2"><button type="button" class="btn-block btn btn-default" (click)="selectMes('12','Diciembre')">Diciembre</button></div>
                    </div>
                    <div class="mt-4">Descarga plantilla <a href="assets/files/plantilla_formato_recaudo.csv" target="_blank">guía recaudo</a></div>
                </div>

            </div>


            <div class="mt-1" *ngIf="mes">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" style="font-weight: 300;font-size: 1.4em;">Mes {{mes}}</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4">
                                    <h4>Registros importados <b>{{recaudo_clientes.total}}</b></h4>  
                                    <h4>Registros procesados <b>{{recaudo_clientes_procesado}}</b></h4>  
                                           
                                </div>

                                <div class="row mb-5">
                                    <div class="col-10">
                                        <input type="file" #fileImportInput class="form-control" [(ngModel)]="file" name="File Upload" id="csvFileUpload" (change)="fileChangeListener($event)" accept=".csv" />
                                        <h4 class="mt-3" *ngIf="cargandoImportar">
                                            <span class="spinner-border spinner-border-md" role="status" aria-hidden="true"></span> Importando Registros
                                        </h4>
                                    </div>
                                    <div class="col-2">
                                        <button type="button" [disabled]="cargandoImportar" (click)="importar()" *ngIf="btnImport " class="btn btn-primary">Importar <b>{{countImportar}}</b></button>                        
                                    </div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>


        </div>



    
    </div>
      
      <app-footer-main></app-footer-main>  
  </div>  
  
