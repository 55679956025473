import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DAYS_OF_WEEK,
} from 'angular-calendar';

import { CustomDateFormatter } from './custom-date-formatter.provider';
import { ServicesService } from 'src/app/ApiServices/services.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-reporte-prospectos',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reporte-prospectos.component.html',
  styleUrls: ['./reporte-prospectos.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class ReporteProspectosComponent {

  locale: string = 'es-CO';

  weekStartsOn: number = DAYS_OF_WEEK.SUNDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  datosDia:any[] = [];
  totalDia:any = 0;

  modalData: {
    action: string;
    event: any[];
  };

  // actions: CalendarEventAction[] = [
  //   {
  //     label: '<i class="fas fa-fw fa-pencil-alt"></i>',
  //     a11yLabel: 'Edit',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.handleEvent('Edited', event);
  //     },
  //   },
  //   {
  //     label: '<i class="fas fa-fw fa-trash-alt"></i>',
  //     a11yLabel: 'Delete',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.events = this.events.filter((iEvent) => iEvent !== event);
  //       this.handleEvent('Deleted', event);
  //     },
  //   },
  // ];

  refresh: Subject<any> = new Subject();

  events: any[] = []
  // events: CalendarEvent[] = [
  //   {
  //     start: subDays(startOfDay(new Date()), 1),
  //     end: addDays(new Date(), 1),
  //     title: 'A 3 day event',
  //     color: colors.red,
  //     actions: this.actions,
  //     allDay: true,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  //   {
  //     start: startOfDay(new Date()),
  //     title: 'An event with no end date',
  //     color: colors.yellow,
  //     actions: this.actions,
  //   },
  //   {
  //     start: subDays(endOfMonth(new Date()), 3),
  //     end: addDays(endOfMonth(new Date()), 3),
  //     title: 'A long event that spans 2 months',
  //     color: colors.blue,
  //     allDay: true,
  //   },
  //   {
  //     start: addHours(startOfDay(new Date()), 2),
  //     end: addHours(new Date(), 2),
  //     title: 'A draggable and resizable event',
  //     color: colors.yellow,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  // ];

  activeDayIsOpen: boolean = false;

  constructor(private modal: NgbModal,
              private services : ServicesService) {
                // this.events = datos.getData();
                // console.log(this.viewDate.getMonth()+1, this.viewDate.getFullYear());
                this.cargar();
              }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
      // let actual = new Date();
      // let year = actual.getFullYear();
      // let mes = actual.getMonth();
      // let dia = actual.getDate()-1;
      // let dayToday = new Date(year,mes,dia);
      // // console.log(date, dayToday);
      // if (date > dayToday){
      //   alert('preciono una fecha anterior al dia actual');
      // }
        if (events.length > 0){
        // this.viewDate = date;
        // console.log(events);
        // this.datosDia = events;
        // this.handleEvent("click", events);
        let fechaActual = date.getFullYear()+"-"+(date.getMonth()+1) + "-" + date.getDate();
        // console.log(fechaActual);
        let params = {
          calendario : true,
          calendario_fecha :fechaActual
        }
        this.services.postProspectosAdmin(params).subscribe( data => {
          this.datosDia = data['prospectos'];
          this.totalDia = data['totalDia']
          this.refresh.next();
        });
      }
  }

  cargar(): void {
    let fechaActual = this.viewDate.getFullYear()+"-"+(this.viewDate.getMonth()+1);
    // console.log(fechaActual);
    let params = {
      calendario : true,
      calendario_fecha :fechaActual
    }
    this.services.postProspectosAdmin(params).subscribe( data => {
      // console.log(data);
      let prospectosd = data['prospectos'];
      let temparray = [];
      for(let item of prospectosd){
        let start = new Date(item['start'].replace(/-/g, "/"));
        temparray.push({
          start : start,
          end: start,
          usuario_name:item['usuario_name'], 
          allDay: true,          
        });
      }
      // console.log(temparray)
      this.events = temparray;
      this.refresh.next();
    });
  }


  handleEvent(action: string, event: any[]): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'md' });
  }

  
  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    console.log(this.viewDate);
    this.cargar();
    this.activeDayIsOpen = false;
  }
}
