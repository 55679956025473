<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1>Compras</h1>
          </div>
          <div class="col-sm-12 mt-3">
            <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/ecommerce/operaciones" class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a>
            <button *ngIf="perfil_id == 1 || perfil_id == 4" class="btn btn-success mr-1" data-toggle="modal" data-target="#modalBuscarProducto">Comprar Producto</button>
            <!-- <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
            <button type="button" (click)="listadoProductos()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button> -->
          </div>
        </div>
      </div>
    </section>


<!-- Modal Comprar producto -->
<div class="modal fade" id="modalBuscarProducto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Comprar Producto</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="container mt-2 p-3 rounded cart">
          <div class="row no-gutters">
              <div class="col-md-8">
                <div class="input-group input-group-lg pr-3">
                  <input type="text" [(ngModel)]="inputBuscar" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" placeholder="Buscar Producto">
                  <div class="input-group-prepend">            
                    <button [disabled]="!inputBuscar" class="btn btn-primary" (click)="buscarProducto()">Buscar</button>  
                  </div>
                </div>

                  <div *ngIf="productos" class="product-details mr-2 mt-2">
                      <div class="d-flex flex-row align-items-center"><i class="fa fa-long-arrow-left"></i><span class="ml-2">Seleccione el producto</span></div>
                      <hr>

                      <div *ngFor="let item of productos; let i = index" class="d-flex justify-content-between align-items-center mt-3 p-2 items rounded">
                          <div class="d-flex flex-row"><img  *ngIf="item.imgUrl" class="rounded" src="{{item.imgUrl}}" width="40">
                              <div class="ml-2"><span class="font-weight-bold d-block">{{item.titulo}}</span><span class="spec">{{item.descripcion | slice:0:80}}</span></div>
                          </div>
                          <div class="d-flex flex-row align-items-center">
                            <div>
                            <!-- <select #val class="form-control" [(ngModel)]="inputCantidad">
                              <option value="1" selected> 1</option>
                              <option value="2"> 2</option>
                              <option value="3"> 3</option>
                              <option value="4"> 4</option>
                              <option value="5"> 5</option>                
                              <option [value]="item.id" *ngFor="let item of asesores">{{item.nombre | uppercase}}</option>
                            </select>                             -->
                            </div>
                            <div><small>{{item.stock}} disponibles</small></div>
                            <!-- <span class="d-block">2</span><span class="d-block ml-5 font-weight-bold">${{item.valor_servicio | decimal}}</span><i class="fa fa-trash-o ml-3 text-black-50"></i> -->
                          </div>
                          <div class="d-flex flex-row align-items-center"><button class="btn btn-primary" (click)="addProducto(item)"><i class="fa fa-plus"></i></button></div>
                      </div>

                  </div>
              </div>
              <div class="col-md-4">
                  <div class="bg-light rounded p-2">
                      <div class="d-flex justify-content-between align-items-center"><h4>Tu Pedido</h4></div>
                      <div></div>
                      <hr class="line">
                      <div class="d-flex justify-content-between information"><span>Subtotal</span><span>$0</span></div>
                      <div class="d-flex justify-content-between information"><span>Envio</span><span>$0</span></div>
                      <div class="d-flex justify-content-between information"><span>Total</span><span>$0</span></div><button class="btn btn-success btn-block d-flex justify-content-between mt-3" type="button"><span>Pedir Ahora </span><span>$312.000 <i class="fa fa-long-arrow-right ml-1"></i></span></button>
                  </div>
              </div>
          </div>
      </div>        

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>   

<!-- Modal modalProductoCant -->
<div class="modal fade" id="modalProductoCant" tabindex="-1" aria-labelledby="modalProductoCantLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalProductoCantLabel">Seleccione Cantidad</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>{{producto.titulo}}</h3>
        

        <div class="input-group mb-0 col-4 mx-auto">
          <div class="input-group-prepend">
            <button class="btn btn-light" type="button" id="button-addon1" (click)="addcant(-1,producto.stock)"><i class="fa fa-minus"></i></button>
          </div>
          <input class="form-control text-center" type="number" [(ngModel)]="inputCantidad">
          <div class="input-group-prepend">
            <button class="btn btn-light" type="button" id="button-addon2" (click)="addcant(+1,producto.stock)"><i class="fa fa-plus"></i></button>
          </div>          
        </div>
        <div class="text-center"><small>{{producto.stock}} disponibles</small></div>
        <div><button class="btn btn-block btn-success" (click)="addNewProducto(producto)">Agregar</button></div>
      </div>
    </div>
  </div>
</div>
     

  </div>
    
    <app-footer-main></app-footer-main>  
</div>  

