<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>

  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h3 *ngIf="!editar"><a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/publiexpress/productos"
                class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a> Añadir nuevo producto</h3>
            <h3 *ngIf="editar"><a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/publiexpress/productos"
                class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a> Editar producto</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="">

              <form [formGroup]="nuevoProductoForm" (ngSubmit)="onSubmit()">
                <div class="col-md-12">
                  <div class="card card-default">

                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="row">
                            <div class="col-md-10">
                              <div class="form-group">
                                <label for="nombre" style="font-size: 14px;">Nombre</label>
                                <input [readonly]="ver" formControlName="nombre" name="nombre" type="text"
                                  class="form-control">
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="unidad" style="font-size: 14px;">Unidad</label>
                                <select formControlName="unidad" class="custom-select" name="unidad">
                                  <option value="cm">CM</option>
                                  <option value="metros">METROS</option>
                                  <option value="millar">MILLAR</option>
                                </select>                                
                              </div>
                            </div>

                            <div class="col-md-12 card" *ngIf="nuevoProductoForm.value.unidad == 'metros' || nuevoProductoForm.value.unidad == 'cm'">
                              <div class="card-body row">
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <label for="ancho" style="font-size: 14px;">Ancho</label>
                                    <input [readonly]="ver" formControlName="ancho" name="ancho" type="text"
                                      class="form-control">
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <label for="largo" style="font-size: 14px;">Largo</label>
                                    <input [readonly]="ver" formControlName="largo" name="largo" type="text"
                                      class="form-control">
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <label for="min_m2" style="font-size: 14px;">Min mts</label>
                                    <input formControlName="min_m2" name="min_m2" type="text"
                                      class="form-control">
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <label for="max_m2" style="font-size: 14px;">Max mts</label>
                                    <input formControlName="max_m2" name="max_m2" type="text"
                                      class="form-control">
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <label for="pmt2" style="font-size: 14px;">Valor pmt2</label>
                                    <input [readonly]="ver" formControlName="pmt2" name="pmt2" class="form-control"
                                      currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div class="col-md-12 card" *ngIf="nuevoProductoForm.value.unidad == 'millar'">
                              <div class="card-body row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="valor" style="font-size: 14px;">Valor</label>
                                    <input formControlName="valor" name="valor" class="form-control"
                                      currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                  </div>
                                </div>                                
                              </div>
                            </div>                            



 
                            <div class="col-md-4">
                              <div class="form-group">
                                <label for="estado" style="font-size: 14px;">Estado</label>
                                <select formControlName="estado" class="custom-select" name="estado">
                                  <option value="1">Activo</option>
                                  <option value="2">Inactivo</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="card">
                            <div class="card-body">
                              <div class="btn-group float-right" role="group" aria-label="Basic example">
                                <button *ngIf="!editar" class="btn btn-primary float-right " type="submit">Publicar
                                  Producto</button>
                                <button *ngIf="editar" class="btn btn-warning float-right " type="submit">Actualizar
                                  Producto</button>
                                <a routerLink="/publiexpress/productos" class="btn btn-secondary float-right ">Regresar</a>
                              </div>
                            </div>
                          </div>


                          <div class="card">
                            <div class="card-body">
                              <!-- CARGAR ADJUNTOS DESTACADA -->
                              <div class="form-group text-center">
                                <input #img type="file" accept='application/pdf,image/png, image/jpeg, image/jpg'
                                  (change)="preview(img.files, '2')" style="display:none;" />
                                <img *ngIf="nuevoProductoForm.value.img" (click)="img.click()"
                                  style="margin-top:1%;cursor: pointer;"
                                  src="https://api1.pagoexpress.co/public/img/upload/{{nuevoProductoForm.value.img}}"
                                  width="100%" class="img-fluid rounded">
                                <span *ngIf="!nuevoProductoForm.value.img">
                                  <h5 class="linkFile" (click)="img.click()"
                                    style="border: 2px dashed #ccc;padding-top: 15px;padding-bottom: 15px;margin-top: 10px;cursor: pointer;">
                                    Cargar imagen producto</h5>
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-main></app-footer-main>
</div>