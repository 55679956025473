import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-listado-comercios',
  templateUrl: './listado-comercios.component.html',
  styleUrls: ['./listado-comercios.component.css']
})
export class ListadoComerciosComponent implements OnInit {

  cargando: boolean = false;
  comercios: any = [];
  pagesNumber: any;
  offset = 3;
  buscar: any;
  page: any;


  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.listadoComercios();
  }

  filtro() {
    let params = {
      filtro: true,
      buscar: this.buscar,
      page: this.page
    }
    this.servicios.postComercio(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.comercios = data['comercios'];
          this.ForpagesNumber();
        } else {
        }
      });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.comercios.current = page;
    this.filtro();
    // this.listadoComercios(page);        
  }

  EditarComercio(item) {
    let navigationExtras: NavigationExtras = {
      state: {
        itemComercio: item
      }
    }
    this.router.navigate(['/comercios/editar'], navigationExtras);
  }

  MultiproductoComercio(item) {
    let navigationExtras: NavigationExtras = {
      state: {
        itemComercio: item
      }
    }
    this.router.navigate(['/comercios/multiproducto'], navigationExtras);
  }

  EliminarComercio(item) {
    let params = {
      id: item.id,
      eliminar: true,
      estado: 0,
    }
    console.log(params);
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este comercio?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        this.servicios.postComercio(params)
        .subscribe((data) => {
          console.log(data);
          if (data["logger"] == true) {
            this.listadoComercios();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
  
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
  
        });


      }
    });



  }

  listadoComercios(page = null) {
    this.cargando = true;
    if (!page) {
      this.servicios.getComercios(1).subscribe((data) => {
        this.comercios = data['comercios'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    } else {
      var url = this.servicios.getComercios(page).subscribe((data) => {
        this.comercios = data['comercios'];
        this.cargando = false;
        this.ForpagesNumber()

      });
    }
  }


  ForpagesNumber() {
    let from = this.comercios.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.comercios.total_page) {
      to = this.comercios.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

}
