import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nueva-afiliacion',
  templateUrl: './nueva-afiliacion.component.html',
  styleUrls: ['./nueva-afiliacion.component.css']
})
export class NuevaAfiliacionComponent implements OnInit {
  cargando: boolean =false;
  id: any;
  perfil_id: any;
  public message: string;
  listServicios: any = [];
  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;


  servicio_id:any;
  servicio_nombre:any;
  servicio_costo:any;  
  servicio_comision:any;
  nombre:any;
  cedula:any;
  direccion:any;
  ciudad:any;
  celular:any;
  correo:any;
  cargo_funcion:any;
  certificado_medico_laboral:any;
  contrato_laboral:any;
  cedula_150:any;
  beneficiario:any;

  hm7_registro:any;
  hm7_18_registro:any;
  hm18_25_cedula:any;
  primera_vez_o_traslado:any;
  casado_registro_cedula:any;
  union_libre_declaracion_jurada:any;
  padres_registro_civil_como_hijo:any;
  hermanos_discapacitados:any;
  formulario_fondo:any;
  regimen: any;
  mes_a_cancelar:any;
  novedades_ingreso_retiro:any;
  entidad_pagar:any;
  nivel_riesgo:any;
  base_cotizacion:any;
  certificados_estudios_hijos_7_25:any;

  eps:any;
  fondo_pension:any;
  arl:any;
  caja_compensacion:any;

  comercio_id:any;
  usuario_id:any;

  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.comercio_id = localStorage.getItem('comercio_id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoServicios();
  }

  listadoServicios(){
    this.cargando = true;
        this.servicios.getAfiliacionesServicios().subscribe( (data) => {
        this.listServicios = data['servicios'];
        this.cargando = false;
        console.log(data);
      });
  }
  
  selectService(item){
    this.servicio_id = item.id;
    this.servicio_nombre = item.nombre;
    this.servicio_costo = item.costo;
    this.servicio_comision = item.comision;
  }

  resetServicio(){
    this.servicio_id = '';
    this.servicio_nombre = '';
  }

  preview(files) {
    // this.cargando = true;
    console.log('entrando');
    console.log(files);
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // if (mimeType.match(/application\/pdf/) || mimeType.match(/image\/png/) || mimeType.match(/image\/jpeg/) ||  mimeType.match(/image\/jpg/)  == null) {
    //   this.message = "No hay Archivo.";
    //   return;
    // }
    var reader = new FileReader();
    this.imagePath = files;
    this.fileName = files[0].name
    console.log(this.fileName);
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      this.saveAdjunto();
    }
  }

  saveAdjunto(){
    if (!this.fileURL){
        console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL
      };
      this.cargando = true;
      this.servicios.setFile(params)
      .subscribe( (data) => {
        console.log(data);
        
        if(data['logger'] == true){
          let params = {
            nombre: this.fileName,
            archivo: data['filename'],
            folder: data['folder'],
            tipo: data['tipo'],
            ruta: data['ruta'],
          };
          this.listadjuntos.push(params);
          this.cargando = false;
        } else {
          // this.alerta(data['msg'], 'error');
          this.cargando = false;
        }
      });
    }
  }

  deleteAdjunto(i: number,itemAdjunto){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea borrar el archivo `+itemAdjunto.nombre,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo!',
    }).then((result) => {
      if (result.value) {
        this.listadjuntos.splice(i,1);       
      }
    });       
}

  setSolicitud(){
    let params = {
      nuevo: true,
      adjuntos: this.listadjuntos,
      nombre: this.nombre,
      cedula: this.cedula,
      direccion: this.direccion,
      ciudad: this.ciudad,
      celular: this.celular,
      correo: this.correo,
      cargo_funcion: this.cargo_funcion,
      certificado_medico_laboral:this.certificado_medico_laboral,
      contrato_laboral: this.contrato_laboral,
      cedula_150: this.cedula_150,
      beneficiario:this.beneficiario,
      hm7_registro: this.hm7_registro,
      hm7_18_registro: this.hm7_18_registro,
      hm18_25_cedula: this.hm18_25_cedula,
      casado_registro_cedula:this.casado_registro_cedula,
      union_libre_declaracion_jurada: this.union_libre_declaracion_jurada,
      padres_registro_civil_como_hijo:this.padres_registro_civil_como_hijo,
      hermanos_discapacitados: this.hermanos_discapacitados,
      formulario_fondo: this.formulario_fondo,

      primera_vez_o_traslado: this.primera_vez_o_traslado,
      regimen:this.regimen,
      mes_a_cancelar: this.mes_a_cancelar,
      novedades_ingreso_retiro: this.novedades_ingreso_retiro,
      entidad_pagar:this.entidad_pagar,
      nivel_riesgo: this.nivel_riesgo,
      base_cotizacion: this.base_cotizacion,
      certificados_estudios_hijos_7_25: this.certificados_estudios_hijos_7_25,

      eps: this.eps,
      fondo_pension:this.fondo_pension,
      arl: this.arl,
      caja_compensacion:this.caja_compensacion,
      servicio_id: this.servicio_id,
      servicio_nombre: this.servicio_nombre,
      servicio_costo: this.servicio_costo,
      servicio_comision: this.servicio_comision,
      comercio_id: this.comercio_id,
      usuario_id: this.id,
    }

    console.log(params);

    this.servicios.setAfiliacion(params)
    .subscribe( (data) => {
      // console.log(data);
      if (data["logger"] == true){
        this.router.navigate(['../afiliaciones/listado'])
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
      }
    });
  }

}
