import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listado-prospectos',
  templateUrl: './listado-prospectos.component.html',
  styleUrls: ['./listado-prospectos.component.css']
})
export class ListadoProspectosComponent implements OnInit {

  cargando: boolean =false;
  prospectos: any = [];
  pagesNumber: any;
  itemVer: any = [];
  asesores: any;
  offset= 1;

  fecha_desde:any;
  fecha_hasta:any;
  asesor:any;
  zona:any;
  departamento: any;
  municipio: any;
  page:any;
  reporteExport:any;


  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.filtro();
    // this.listadoProspectos();
    this.listadoAsesores();
  }


  listadoProspectos(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getProspectosAdmin(1).subscribe( (data) => {
        this.prospectos = data['prospectos'];
        this.cargando = false;
        // console.log(data['prospectos']);
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getProspectosAdmin(page).subscribe( (data) => {
        this.prospectos = data['prospectos'];
        this.cargando = false;
        // console.log(data['prospectos']);
        this.ForpagesNumber()

      });
    }     
  }

  ForpagesNumber() {
    let from = this.prospectos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.prospectos.total_page){
      to = this.prospectos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  changePage(page) {
    // console.log(page);
    this.page = page; //para el filtro
    this.prospectos.current = page;

    this.filtro();
    // this.listadoProspectos(page);       
  }  

  listadoAsesores(){
    // 3CAPAGO
    let params = {
      asesores: true,
    }    
    this.servicios.getAsesores(params).subscribe( (data) => {
      this.asesores = data['usuarios'];
      // console.log(this.asesores);
    });    
  }

  ver(item){
    this.itemVer = item;
    // console.log(item);
  }

  cerrarmodal(){
    this.itemVer = '';
  } 

  filtro(){
    let params = {
      filtro: true,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
      departamento: this.departamento,
      municipio: this.municipio,
      zona: this.zona,
      asesor: this.asesor,
      page: this.page
    }
    
    this.servicios.postProspectosAdmin(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.prospectos = data['prospectos'];
          this.ForpagesNumber();
        } else {
        }
        
    });

  }

  exportExcel(){
    let params = {
      export: true,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,      
      departamento: this.departamento,
      municipio: this.municipio,
      zona: this.zona,
      asesor: this.asesor,
      page: this.page
    }
    // console.log(params);
    this.servicios.postProspectosAdmin(params)
    .subscribe( (data) => {
      this.reporteExport = data['prospectos'];
      console.log(this.reporteExport);
      
      
      const fileName = 'reporte_prospectos.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);


      // ANCHO COLUMNAS
      ws['!cols'] = [ {width:2},{width:2},{width:30}, {width:20},{width:20},{width:50},{width:20},{width:30},{width:20},{width:40},{width:20},{width:20},{width:20},{width:20},{width:20},{width:40},{width:40},{width:40},{width:40},{width:30},{width:30},{width:20}];
      // ELIMINA COLUMNA1
      ws['!cols'][0] = { hidden: true };
      ws['!cols'][1] = { hidden: true };
      ws['!cols'][13] = { hidden: true };
      ws['!cols'][14] = { hidden: true };
      ws['!cols'][18] = { hidden: true };
      // ws['G2'] = { Target:"#E2" };
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      // CAMBIA CABECERA
      // XLSX.utils.sheet_add_aoa(ws, [["log","Id","NOMBRE","DEPARTAMENTO","CIUDAD","DIRECCION","ZONA","CORREO","CELULAR","TIPO NEGOCIO","ANTIGUEDAD","FLUJO CLIENTES","CALIFICACIÓN","IMAGEN ","IMAGEN 2","IMAGEN 3"," ","FECHA","ASESOR"]]);
      // XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  
    
      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);

    }); 
  }

  editarEstado(estado,id){
    let params = {
      editarEstado: true,
      estado: estado,
      id: id
    }   
    console.log(params);

    Swal.fire({
      title: 'Esta seguro?',
      text: "Esta operación no se puede deshacer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )

        this.servicios.postProspectosAdmin(params).subscribe( (data) => {
          console.log(data);
    
          if (data["logger"] == true){
            document.getElementById('modalVer').style.display="none";
            // this.router.navigate(['../compensaciones/nuevo'])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
              this.listadoProspectos();
          }else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });         


      }
    })

       

       
  }  

}
