import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebarmenu-menu-item',
  templateUrl: './sidebarmenu-menu-item.component.html',
  styleUrls: ['./sidebarmenu-menu-item.component.css']
})
export class SidebarmenuMenuItemComponent implements OnInit {

  constructor() { 
    
  }

  ngOnInit(): void {
  }

}
