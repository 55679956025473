import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-editar-comercio',
  templateUrl: './editar-comercio.component.html',
  styleUrls: ['./editar-comercio.component.css']
})
export class EditarComercioComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  Comercio: any = {};
  item: any;
  verComercio: any = {};

  id: any;
  tipo_persona: any;
  tipo_documento: any;
  numero_documento: any;  
  razon_social: any;   
  perfil_id: any;  
  sexo: any;
  nombre: any; 
  apellido: any;  
  direccion: any; 
  celular: any;
  usuario_nickname: any;   
  usuario_email: any;     
  password: any;  
  departamento: any;
  municipio: any;        
  tipo_negocio: any;     
  zona: any;
  telefono: any;
  banco_nombre: any
  banco_cuenta: any
  banco_tipo: any
  codigo_punto: any;
  cupo_bancolombia: any;
  cupo_prepagado: any;
  estado: any;
  listProductos: any = [];
  fieldTextType: boolean;
  imgUrl:any;
  alianza:any;
  convenio_pago: any;

  constructor(public router: Router,
              public servicios: ServicesService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
                if(this.router.getCurrentNavigation().extras.state){
                  this.verComercio = this.router.getCurrentNavigation().extras.state.itemComercio;
                  console.log(this.verComercio);                  
                  this.id = this.verComercio['id']; 
                  this.tipo_persona = this.verComercio['tipo_persona']; 
                  this.tipo_documento = this.verComercio['tipo_documento'];
                  this.numero_documento = this.verComercio['numero_documento'];   
                  this.razon_social = this.verComercio['razon_social'];   
                  this.perfil_id = this.verComercio['perfil_id'];   
                  this.sexo = this.verComercio['sexo'];   
                  this.nombre = this.verComercio['nombre'];   
                  this.apellido = this.verComercio['apellido'];   
                  this.direccion = this.verComercio['direccion'];   
                  this.celular = this.verComercio['celular'];
                  this.usuario_nickname = this.verComercio['usuario_nickname'];      
                  this.usuario_email = this.verComercio['usuario_email'];      
                  this.password = this.verComercio['password'];      
                  this.municipio = this.verComercio['municipio'];      
                  this.departamento = this.verComercio['departamento'];      
                  this.tipo_negocio = this.verComercio['tipo_negocio'];      
                  this.zona = this.verComercio['zona'];  
                  this.telefono = this.verComercio['telefono']; 
                  this.banco_nombre = this.verComercio['banco_nombre']; 
                  this.banco_cuenta = this.verComercio['banco_cuenta']; 
                  this.banco_tipo = this.verComercio['banco_tipo'];                                        
                  this.codigo_punto = this.verComercio['codigo_punto'];                      
                  this.cupo_bancolombia = this.verComercio['cupo_bancolombia'];
                  this.cupo_prepagado = this.verComercio['cupo_prepagado'];
                  this.imgUrl = this.verComercio['img'];                      
                  this.alianza = this.verComercio['alianza'];   
                  this.convenio_pago = this.verComercio['convenio_pago'];                   
                }
               }

  ngOnInit(): void {
    if(!this.id){
      this.router.navigate(['../comercios/listado'])
    }
    this.form = this.formBuilder.group({      
      tipo_persona: ['', [Validators.required]],
      tipo_documento: ['', [Validators.required]],
      numero_documento: ['', [Validators.required]],
      razon_social: ['', [Validators.required]],
      perfil_id: ['', [Validators.required]],
      codigo_punto: [''],
      sexo: ['', [Validators.nullValidator]],
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      usuario_nickname: ['', [Validators.required]],
      usuario_email: [[{ value: '', disabled: true }], Validators.required],
      password: ['', [Validators.required]],
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      tipo_negocio: ['', [Validators.required]],
      cupo_bancolombia: ['', [Validators.nullValidator]],
      cupo_prepagado: ['', [Validators.nullValidator]],
      zona: ['', [Validators.nullValidator]],
      telefono: ['', [Validators.nullValidator]],
      banco_nombre: ['', [Validators.nullValidator]],
      banco_tipo: ['', [Validators.nullValidator]],
      banco_cuenta: ['', [Validators.nullValidator]],      
      estado: ['', [Validators.nullValidator]],
      alianza: [''],
      convenio_pago: ['', [Validators.nullValidator]],
    });
  }

  get f() { return this.form.controls; }
  cupo_total() { return (+this.form.value.cupo_prepagado) + (+this.form.value.cupo_bancolombia) }

  EditarComercio(){
    this.submitted = true;
    if(this.form.invalid){
      // alert("invalid")
      return;
    }
    this.form.value.img = this.imgUrl;
    this.Comercio = this.form.value;
    console.log(this.Comercio);
     let params = {
          id: this.id,
          editar: true,
          comercio: this.Comercio
        }
        // console.log(params);
        this.servicios.postComercio(params)
        .subscribe( (data) => {
          console.log(data);
          if (data["logger"] == true){
            this.router.navigate(['../comercios/listado'])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
  }

 
  toggleFieldTextTypePass() {
    this.fieldTextType = !this.fieldTextType;
  } 

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgUrl = reader.result; 
      // console.log("esta es la imagen"+this.imgURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen(){
    if(!this.imgUrl) {
      console.log('error');
      return;
    } else {
      let params = {
        archivo: this.imgUrl
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          this.imgUrl = data['filename'];
          console.log("img"+this.imgUrl);
        }
      });
    }
  }

}
