import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevo-comercio',
  templateUrl: './nuevo-comercio.component.html',
  styleUrls: ['./nuevo-comercio.component.css']
})
export class NuevoComercioComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  Comercio: any = {};
  fieldTextType: boolean;
  imgUrl:any;
  constructor(public router: Router,
              public servicios: ServicesService,
              private formBuilder: FormBuilder) {
              }
  ngOnInit() {
    this.form = this.formBuilder.group({      
      tipo_persona: ['', [Validators.required]],
      tipo_documento: ['', [Validators.required]],
      numero_documento: ['', [Validators.required]],
      razon_social: ['', [Validators.required]],
      perfil_id: ['', [Validators.required]],
      codigo_punto: [''],
      sexo: ['', [Validators.nullValidator]],
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      usuario_nickname: ['', [Validators.required]],
      usuario_email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      departamento: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      tipo_negocio: ['', [Validators.required]],      
      cupo_bancolombia: ['', [Validators.nullValidator]],
      cupo_prepagado: ['', [Validators.nullValidator]],
      zona: ['', [Validators.nullValidator]],
      telefono: ['', [Validators.nullValidator]],
      banco_nombre: ['', [Validators.nullValidator]],
      banco_tipo: ['', [Validators.nullValidator]],
      banco_cuenta: ['', [Validators.nullValidator]],
      img: [''],
      alianza: [''],
    });
  }

  get f() { return this.form.controls; }
  cupo_total() { return this.form.value.cupo_prepagado + this.form.value.cupo_bancolombia }

  EnviarComercio(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.form.value.img = this.imgUrl;
    this.Comercio = this.form.value;
     let params = {
          nuevo: true,
          comercio: this.Comercio
        }
        console.log(params);
        this.servicios.postComercio(params)
        .subscribe( (data) => {
          console.log(data);

          if (data["logger"] == true){
            this.router.navigate(['../comercios/listado'])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
  }

  toggleFieldTextTypePass() {
    this.fieldTextType = !this.fieldTextType;
  } 

    preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgUrl = reader.result; 
      // console.log("esta es la imagen"+this.imgURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen(){
    if(!this.imgUrl) {
      console.log('error');
      return;
    } else {
      let params = {
        archivo: this.imgUrl
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          this.imgUrl = data['filename'];
          console.log("img"+this.imgUrl);
        }
      });
    }
  }
}
