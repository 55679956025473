import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogoComponent implements OnInit {
  listLoNuevo: any[];

  constructor(private servicios: ServicesService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.listadoLoNuevo();     
  }


  listadoLoNuevo(){
    this.servicios.getEcommerceProductoCategoria().subscribe( (data) => {
        this.listLoNuevo = data['lonuevo'];
        // console.log(this.listLoNuevo);     
    });
  }



}
