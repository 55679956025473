<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Modulo Comercio</h1>
                </div>
              </div>
            </div>
        </div>

          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">Crear Comercio</h3>
                    </div>
                    <form [formGroup]="form" (ngSubmit)="EnviarComercio()">
                      <div class="card-body">
                          <div class="row">

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1" style="font-size: 14px;">Perfil <span style="color: red;">*</span></label>
                                    <select [ngClass]="{ 'is-invalid': submitted && f.perfil_id.errors }"
                                    formControlName="perfil_id" 
                                    class="custom-select" 
                                    name="perfil_id">
                                      <option value="5">Proveedor</option>
                                      <option value="4">Punto de Venta</option>
                                      <option value="3">Empresario</option>
                                      <option value="2">Vendedor</option>
                                      <option value="1">Administrador</option>
                                    </select>   
                                    <div *ngIf="submitted && f.perfil_id.errors" class="invalid-feedback">
                                      <div *ngIf="f.perfil_id.errors.required">Campo obligatorio</div>
                                    </div>                                 
                                  </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Codigo Punto<span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.codigo_punto.errors }" 
                                    type="number" 
                                    name="codigo_punto" 
                                    class="form-control" 
                                    formControlName="codigo_punto">
                                    <div *ngIf="submitted && f.codigo_punto.errors" class="invalid-feedback">
                                      <div *ngIf="f.codigo_punto.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>                               

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Tipo de persona <span style="color: red;">*</span></label>
                                    <select [ngClass]="{ 'is-invalid': submitted && f.tipo_persona.errors }"
                                    formControlName="tipo_persona" 
                                    class="custom-select" 
                                    name="tipo_persona">
                                      <option value="Natural">Natural</option>
                                      <option value="Juridica">Juridica</option>
                                    </select>
                                    <div *ngIf="submitted && f.tipo_persona.errors" class="invalid-feedback">
                                      <div *ngIf="f.tipo_persona.errors.required">Campo obligatorio</div>
                                    </div>
                                  </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Tipo de Documento <span style="color: red;">*</span></label>
                                    <select [ngClass]="{ 'is-invalid': submitted && f.tipo_documento.errors }" 
                                    formControlName="tipo_documento" 
                                    class="custom-select" 
                                    name="tipo_documento">
                                      <option value="Cedula">Cedula</option>
                                      <option value="Nit">Nit</option>
                                    </select>
                                    <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback">
                                      <div *ngIf="f.tipo_documento.errors.required">Campo obligatorio</div>
                                    </div>
                                  </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Numero de Documento <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.numero_documento.errors }" 
                                    type="number" 
                                    name="numero_documento" 
                                    class="form-control" 
                                    formControlName="numero_documento">
                                    <div *ngIf="submitted && f.numero_documento.errors" class="invalid-feedback">
                                      <div *ngIf="f.numero_documento.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>  

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Genero</label>
                                    <select formControlName="sexo" class="custom-select" name="sexo">
                                      <option>Masculino</option>
                                      <option>Femenino</option>
                                    </select>
                                  </div>                                  
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Razon social <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.razon_social.errors }"
                                    formControlName="razon_social" 
                                    name="razon_social" 
                                    type="text" 
                                    class="form-control">    
                                    <div *ngIf="submitted && f.razon_social.errors" class="invalid-feedback">
                                      <div *ngIf="f.razon_social.errors.required">Campo obligatorio</div>
                                    </div>                  
                                  </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Nombre <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" 
                                    formControlName="nombre" 
                                    type="text" 
                                    name="nombre" 
                                    class="form-control">
                                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                      <div *ngIf="f.nombre.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label for="apellido" style="font-size: 14px;"> Apellido <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }" 
                                  type="text" 
                                  class="form-control" 
                                  name="apellido" 
                                  formControlName="apellido">
                                  <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                                    <div *ngIf="f.apellido.errors.required">Campo obligatorio</div>
                                  </div>
                                </div>
                              </div> 


                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Dirección <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.direccion.errors }"
                                    name="direccion" 
                                    type="text" 
                                    class="form-control" 
                                    formControlName="direccion">
                                    <div *ngIf="submitted && f.direccion.errors" class="invalid-feedback">
                                      <div *ngIf="f.direccion.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Departamento <span style="color: red;">*</span></label>
                                    <input formControlName="departamento" name="departamento" type="text" class="form-control">                      
                                  </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Municipio <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.municipio.errors }"
                                    formControlName="municipio" 
                                    name="municipio" 
                                    type="text" 
                                    class="form-control">
                                    <div *ngIf="submitted && f.municipio.errors" class="invalid-feedback">
                                      <div *ngIf="f.municipio.errors.required">Campo obligatorio</div>
                                    </div> 
                                  </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Tipo de negocio <span style="color: red;">*</span></label>
                                    <select [ngClass]="{ 'is-invalid': submitted && f.tipo_negocio.errors }"
                                    formControlName="tipo_negocio" 
                                    class="custom-select" 
                                    name="tipo_negocio">
                                      <option value="internet">internet</option>
                                      <option value="oficina">oficina</option>
                                      <option value="drogueria">drogueria</option>
                                      <option value="independiente">independiente</option>
                                      <option value="peluqueria">peluqueria</option>
                                      <option value="tienda">tienda</option>
                                      <option value="otros">otros</option>
                                    </select> 
                                    <div *ngIf="submitted && f.tipo_negocio.errors" class="invalid-feedback">
                                      <div *ngIf="f.tipo_negocio.errors.required">Campo obligatorio</div>
                                    </div>                                   
                                  </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Zona</label>
                                    <input formControlName="zona" name="zona" type="text" class="form-control">
                                  </div>
                              </div> 

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Celular <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.celular.errors }"
                                    name="celular" 
                                    type="number" 
                                    class="form-control" 
                                    formControlName="celular">
                                    <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                                      <div *ngIf="f.celular.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1" style="font-size: 14px;">Telefono</label>
                                    <input formControlName="telefono" name="telefono" type="number" class="form-control">
                                  </div>
                              </div> 

                              <div *ngIf="f.perfil_id.value == 3" class="col-md-3">
                                <div class="form-group">
                                  <label for="img" style="font-size: 14px;">Imagen</label>
                                  <input #file type="file" accept='image/*' (change)="preview(file.files)"/>                          
                                </div>
                              </div>
                            

                              <!-- cupos -->
                              <div class="col-md-12">
                                <div class="card card-default">
                                  <div class="card-header" style="background: rgb(238, 237, 237);">
                                    <h3 class="card-title" style="font-size: 15px;font-weight: bold;">Información Cupos Bancos</h3>
                                  </div>
                                    <div class="card-body">

                                      <div class="row">

                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" style="font-size: 14px;">Cupo Bancolombia</label>
                                              <input formControlName="cupo_bancolombia" name="cupo_bancolombia" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                            </div>
                                        </div>                                                                                                                 
                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" style="font-size: 14px;">Cupo Prepagado</label>
                                              <input formControlName="cupo_prepagado" name="cupo_prepagado" class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                            </div>
                                        </div>                                                                                                                 
                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" style="font-size: 14px;">Cupo Total</label>
                                              <h3>$ {{cupo_total() | decimal }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" style="font-size: 14px;">Alianza</label>
                                              <select formControlName="alianza" name="alianza" class="form-control">
                                                <option value=''><b>Seleccione una opciòn</b></option>
                                                <option [value]="'PTM'">PTM</option>
                                                <option [value]="'Districol'">Districol</option>
                                              </select>
                                            </div>
                                        </div>                                                                                                                 
                                      </div>
                                    </div>                                    
                                </div>
                              </div>                               



                              <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label style="font-size: 14px;">Comisión %<span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.comision.errors }"
                                    name="comision" 
                                    type="text" 
                                    class="form-control" 
                                    formControlName="comision">
                                    <div *ngIf="submitted && f.comision.errors" class="invalid-feedback">
                                      <div *ngIf="f.comision.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div> -->
                              <div class="col-md-12">
                                <div class="card card-default">
                                  <div class="card-header" style="background: rgb(238, 237, 237);">
                                    <h3 class="card-title" style="font-size: 15px;font-weight: bold;">Información Cuenta Bancaria</h3>
                                  </div>
                                    <div class="card-body">

                                      <div class="row">

                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label style="font-size: 14px;">Cuenta Banco </label>
                                              <select                                              
                                              formControlName="banco_nombre" 
                                              class="custom-select" 
                                              name="banco_nombre">
                                                <option value="bancolombia">Bancolombia</option>
                                                <option value="davivienda">Davivienda</option>
                                                <option value="nequi">Nequi</option>
                                                <option value="daviplata">Daviplata</option>
                                              </select>                                  
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label style="font-size: 14px;">Tipo de cuenta</label>
                                              <select
                                              formControlName="banco_tipo" 
                                              class="custom-select" 
                                              name="banco_tipo">
                                                <option value="ahorros">Ahorros</option>
                                                <option value="corriente">Corriente</option>
                                                <option value="billetera movil">Billetera Movil</option>
                                              </select>                                  
                                            </div>
                                        </div>                              
        
                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label for="exampleInputBancoCuenta1" style="font-size: 14px;">Numero Cuenta</label>
                                              <input formControlName="banco_cuenta" name="banco_cuenta" type="number" class="form-control">
                                            </div>
                                        </div>                               
        
                                      </div>
                                    </div>                                    
                              </div>

                              </div>                                
                                                            

                              <div class="container-fluid">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="card card-default">
                                      <div class="card-header" style="background: rgb(238, 237, 237);">
                                        <h3 class="card-title" style="font-size: 15px;font-weight: bold;">Información Usuario Principal</h3>
                                      </div>
                                        <div class="card-body">
                                            <div class="row">
                                              
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                      <label style="font-size: 14px;">Nickname del Usuario <span style="color: red;">*</span></label>
                                                      <input [ngClass]="{ 'is-invalid': submitted && f.usuario_nickname.errors }" 
                                                      name="usuario_nickname" 
                                                      type="text" 
                                                      class="form-control" 
                                                      formControlName="usuario_nickname">
                                                      <div *ngIf="submitted && f.usuario_nickname.errors" class="invalid-feedback">
                                                        <div *ngIf="f.usuario_nickname.errors.required">Campo obligatorio</div>
                                                      </div>
                                                  </div>
                                                </div>
                  
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                      <label style="font-size: 14px;">Email del Usuario <span style="color: red;">*</span></label>
                                                      <input [ngClass]="{ 'is-invalid': submitted && f.usuario_email.errors }"
                                                      name="usuario_email" 
                                                      type="email" 
                                                      class="form-control" 
                                                      formControlName="usuario_email">
                                                      <div *ngIf="submitted && f.usuario_email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.usuario_email.required">El Email es obligatorio</div>
                                                        <div *ngIf="f.usuario_email.email">Por favor, ingresa tu Email</div>
                                                      </div>
                                                  </div>
                                                </div> 
                  
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                      <label style="font-size: 14px;">Password <span style="color: red;">*</span></label>
                                                      <div class="input-group">
                                                        <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                        name="password" 
                                                        [type]="fieldTextType ? 'text' : 'password'"
                                                        class="form-control" 
                                                        formControlName="password">
                                                        <div class="input-group-append">
                                                          <span class="input-group-text">
                                                            <i
                                                              class="fa"
                                                              [ngClass]="{
                                                                'fa-eye-slash': !fieldTextType,
                                                                'fa-eye': fieldTextType
                                                              }"
                                                              (click)="toggleFieldTextTypePass()"
                                                            ></i>
                                                          </span>
                                                        </div>                                                      
                                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                          <div *ngIf="f.password.errors.required">Campo obligatorio</div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="card-footer"> -->
                                      <button type="submit" class="btn btn-primary float-right">Guardar</button>
                                      <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </form>
                  </div>
    
                </div>
              </div>
            </div>
        </div>
      </div> 
      <app-footer-main></app-footer-main>  
  </div>
