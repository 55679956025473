import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css']
})
export class EditarUsuarioComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  Usuario: any = {};

  verUsuario: any = {};
  id: any;
  nombre: any;
  email: any;
  celular: any;
  usuario_nickname: any;
  password: any;
  perfil_id: any;

  fieldTextType: boolean;

  constructor(public router: Router,
    public servicios: ServicesService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) { 
      if(this.router.getCurrentNavigation().extras.state){
        this.verUsuario = this.router.getCurrentNavigation().extras.state.itemUsuario;
        // console.log(this.verUsuario);                  
        this.id = this.verUsuario['id']; 
        this.nombre = this.verUsuario['nombre']; 
        this.email = this.verUsuario['email']; 
        this.celular = this.verUsuario['celular']; 
        this.usuario_nickname = this.verUsuario['usuario_nickname']; 
        this.password = this.verUsuario['password']; 
        this.perfil_id = this.verUsuario['perfil_id'];                     
      }      
    }

  ngOnInit(): void {
    if(!this.id){
      this.router.navigate(['../usuario/listado'])
    }
    this.form = this.formBuilder.group({      
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required]],
      usuario_nickname: ['', [Validators.required]],
      password: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      perfil_id: ['', [Validators.required]],
      img: ['', [Validators.required]],
    });    
  }

  get f() { return this.form.controls; }

  EditarUsuario(){
    this.submitted = true;
    // if(this.form.invalid){
    //   return;
    // }
    this.Usuario = this.form.value;
    
     let params = {
          id: this.id,
          editar: true,
          usuario: this.Usuario
        }
        // console.log(params);
        this.servicios.postUsuario(params)
        .subscribe( (data) => {
          // console.log(data);
          if (data["logger"] == true){
            this.router.navigate(['../usuarios/listado'])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
  }

 
  toggleFieldTextTypePass() {
    this.fieldTextType = !this.fieldTextType;
  }   

}
