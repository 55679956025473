import { NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevo-mensajero',
  templateUrl: './nuevo-mensajero.component.html',
  styleUrls: ['./nuevo-mensajero.component.css']
})
export class NuevoMensajeroComponent implements OnInit {

  form: FormGroup;
  formulario: FormGroup;
  submitted = false;
  disabledButton = false;
  Banco: any = {};
  
  Recaudo: any = {};
  solicitud_comercio_id: any;
  cargando = false;
  empresaListado: any = [];
  racaudoEmpresa: any;

  razonsocial: any;
  numeroDocumento: any;
  recaudo_id_comercio: any;
  RecaudoItem: any = {};
  listProductosComercioCategoria: any = [];
  listProductos: any = [];
  productoId: any;

  constructor(public router: Router,
              private formBuilder: FormBuilder,
              public servicios: ServicesService) { 
  }

                     
  ngOnInit() {
    this.solicitud_comercio_id = localStorage.getItem('id');
     this.form = this.formBuilder.group({      
      nombre: ['', [Validators.required]],
      banco: ['', [Validators.nullValidator]],
      producto_id: ['', [Validators.nullValidator]],
      tipo: ['', [Validators.required]],
      convenio: ['', [Validators.nullValidator]],
      numero_convenio: ['', [Validators.nullValidator]],
      numero_cuenta: ['', [Validators.required]],
      valor: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      estado: ['1', [Validators.nullValidator]],
    }); 
    this.formulario = this.formBuilder.group({  
      recaudo_empresa_nombre: ['', [Validators.required]], 
      recaudo_id_comercio: ['', [Validators.nullValidator]],        
      producto_id: ['', [Validators.nullValidator]],
      nombre: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      recaudo_referencia: ['', [Validators.required]],
      valor: ['', [Validators.required]],
      estado: ['', [Validators.nullValidator]],
    });
    this.getProductosComercioCategoria();
  }

  buscarEmpresa(){
    this.cargando = true;
    this.servicios.getMensajeroEmpresa(this.racaudoEmpresa)
    .subscribe( (data) => {
      this.empresaListado = data['empresa'];
      this.cargando = false;
    });
  }

  get f() { return this.form.controls; } 
  get ff() { return this.formulario.controls; } 

  EnviarMensajeroBanco(){    
    this.submitted = true;
    this.disabledButton = true;

    if(this.form.invalid){
      this.disabledButton = false
      return;
    }
  


    this.Banco = this.form.value;
    let params = {
      solicitud_comercio_id: this.solicitud_comercio_id,
      nuevo: true,
      mensajero: this.Banco
    }
    // console.log(this.Banco);
    
    this.servicios.setMensajero(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.RecaudoItem = data['item'];
          this.EnviarItemImprimirBanco();
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
            this.disabledButton = false;
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
          this.disabledButton = false;
        }
        
    });


  }

  EnviarItemImprimirBanco(){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajeroImprimirOrden: this.RecaudoItem
      }
    }
    this.router.navigate(['../mensajero/imprimir'],navigationExtras); 
  }

  EnviarMensajeroRecaudo(){
    this.submitted = true;
    this.disabledButton = true;

    if(this.formulario.invalid){
      this.disabledButton = false
      return;
    }
    this.Recaudo = this.formulario.value;
    let params = {
      recaudo_id_comercio: this.recaudo_id_comercio,
      solicitud_comercio_id: this.solicitud_comercio_id,
      nuevo: true,
      mensajero: this.Recaudo,
    }

    // console.log(this.Recaudo);

    this.servicios.setMensajero(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.RecaudoItem = data['item'];
          this.EnviarItemImprimirRecaudo();
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
            this.disabledButton = false;
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
          this.disabledButton = false;
        }
    });
  }

  EnviarItemImprimirRecaudo(){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajeroImprimirOrden: this.RecaudoItem
      }
    }
    this.router.navigate(['../mensajero/imprimir'],navigationExtras); 
  }


  opcionProducto(item,itemProductos){
    // console.log(item);
    // console.log(itemProductos);
    this.listProductos = itemProductos;
    // console.log(this.listProductos);
    // console.log(this.form.value);  
    // console.log(this.formulario.value);  

    if(item =='bancos'){
      // console.log('dnetro banco');
      document.getElementById('banco').style.display = "block";
      document.getElementById('recaudo').style.display = "none";  
    }

    if(item =='recaudos'){
      // console.log('dnetro recaudos');      
      document.getElementById('recaudo').style.display = "block";
      document.getElementById('banco').style.display = "none";
    }
    document.getElementById('resumen').style.display = "none";

  }

  getProductosComercioCategoria(){
    this.servicios.getComercioCategoriaProductos(this.solicitud_comercio_id).subscribe( (data) => {
      this.listProductosComercioCategoria = data['categorias'];
      // console.log(data['categorias']);
    });
  }

  selectTipoBancoProductoId(item){
    // console.log(item);
    this.form.controls.banco.setValue(item.nombre);
    this.form.controls.producto_id.setValue(item.producto_id);
    // console.log(this.form);  
  }

  selectTipoRecaudoProductoId(item){
    // console.log(item);
    this.productoId = item.producto_id;
    // si es producto es recaudo propio va directo
    if(item.producto_id == 3){
      this.formulario.controls.estado.setValue(3);
    }else{
      this.formulario.controls.estado.setValue(1);
    }

    this.formulario.controls.producto_id.setValue(item.producto_id);
    this.formulario.controls.recaudo_empresa_nombre.setValue('');
  }  

  razonSocial(razon_social,numero_documento,id){
    this.razonsocial = razon_social;
    this.numeroDocumento = numero_documento;
    this.recaudo_id_comercio = id;
    this.formulario.controls.recaudo_empresa_nombre.setValue(razon_social);
    this.formulario.controls.recaudo_id_comercio.setValue(id);

  }

}
