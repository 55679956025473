import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-brand-logo',
  templateUrl: './sidebar-brand-logo.component.html',
  styleUrls: ['./sidebar-brand-logo.component.css']
})
export class SidebarBrandLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
