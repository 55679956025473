import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { ListadoAfiliacionesComponent } from './components/afiliaciones/listado-afiliaciones/listado-afiliaciones.component';
import { NuevaAfiliacionComponent } from './components/afiliaciones/nueva-afiliacion/nueva-afiliacion.component';
import { EditarComercioComponent } from './components/comercios/editar-comercio/editar-comercio.component';
import { ListadoComerciosComponent } from './components/comercios/listado-comercios/listado-comercios.component';
import { ListadoConciliacionComponent } from './components/comercios/listado-conciliacion/listado-conciliacion.component';
import { MultiproductoComercioComponent } from './components/comercios/multiproducto-comercio/multiproducto-comercio.component';
import { NuevoComercioComponent } from './components/comercios/nuevo-comercio/nuevo-comercio.component';
import { PasacupoComercioComponent } from './components/comercios/pasacupo-comercio/pasacupo-comercio.component';
import { ReporteConciliacionComponent } from './components/comercios/reporte-conciliacion/reporte-conciliacion.component';
import { ReportePuntosConciliacionComponent } from './components/comercios/reporte-puntos-conciliacion/reporte-puntos-conciliacion.component';
import { SolicitudSaldoComponent } from './components/comercios/solicitud-saldo/solicitud-saldo.component';
import { VerConciliacionComponent } from './components/comercios/ver-conciliacion/ver-conciliacion.component';
import { VerPasacupoComercioComponent } from './components/comercios/ver-pasacupo-comercio/ver-pasacupo-comercio.component';
import { ListadoCompensacionComponent } from './components/compensaciones/listado-compensacion/listado-compensacion.component';
import { NuevaCompensacionComponent } from './components/compensaciones/nueva-compensacion/nueva-compensacion.component';
import { FormularioMensajeriaComponent } from './components/mensajeria/formulario-mensajeria/formulario-mensajeria.component';
import { ListadoMensajeriaComponent } from './components/mensajeria/listado-mensajeria/listado-mensajeria.component';
import { PrintMensajeriaComponent } from './components/mensajeria/print-mensajeria/print-mensajeria.component';
import { ReporteMensajeriaComponent } from './components/mensajeria/reporte-mensajeria/reporte-mensajeria.component';
import { ImprimirComponent } from './components/mensajero/imprimir/imprimir.component';
import { ListadoMensajeroComponent } from './components/mensajero/listado-mensajero/listado-mensajero.component';
import { NuevoMensajeroComponent } from './components/mensajero/nuevo-mensajero/nuevo-mensajero.component';
import { ProductoComponent } from './components/mensajero/producto/producto.component';
import { ReporteComponent } from './components/mensajero/reporte/reporte.component';
import { OperacionesComponent } from './components/operaciones/operaciones.component';
import { RecaudoImprimirComponent } from './components/operaciones/recaudos/recaudo-imprimir/recaudo-imprimir.component';
import { RecaudoListadoComponent } from './components/operaciones/recaudos/recaudo-listado/recaudo-listado.component';
import { PasarGananciaComponent } from './components/comercios/pasar-ganancia/pasar-ganancia.component';

// import { IndexComponent } from './components/paginas/index/index.component';
import { Pagina1Component } from './components/paginas/pagina1/pagina1.component';
import { Pagina2Component } from './components/paginas/pagina2/pagina2.component';
import { ListadoProspectosComponent } from './components/prospectos/listado-prospectos/listado-prospectos.component';
import { ReporteProspectosComponent } from './components/prospectos/reporte-prospectos/reporte-prospectos.component';
import { SorteoProspectosComponent } from './components/prospectos/sorteo-prospectos/sorteo-prospectos.component';
import { EditarRecaudoComponent } from './components/recaudo/editar-recaudo/editar-recaudo.component';
import { ImportCsvComponent } from './components/recaudo/import-csv/import-csv.component';
import { ListadoRecaudoComponent } from './components/recaudo/listado-recaudo/listado-recaudo.component';
import { NuevoRecaudoComponent } from './components/recaudo/nuevo-recaudo/nuevo-recaudo.component';
import { HomeReporteComponent } from './components/reportes/home-reporte/home-reporte.component';
import { ChangePasswordComponent } from './components/usuarios/change-password/change-password.component';
import { EditarUsuarioComponent } from './components/usuarios/editar-usuario/editar-usuario.component';
import { ListadoUsuariosComponent } from './components/usuarios/listado-usuarios/listado-usuarios.component';
import { NuevoUsuarioComponent } from './components/usuarios/nuevo-usuario/nuevo-usuario.component';
import { AuthGuard } from './guards/auth.guard';
import { AnchetaComponent } from './components/pagoexpress/ancheta/ancheta.component';
import { ListadoProductosComponent } from './components/productos/listado-productos/listado-productos.component';
import { FormularioProductoComponent } from './components/productos/formulario-producto/formulario-producto.component';
import { OperacionesEcommerceComponent } from './components/ecommerce/operaciones-ecommerce/operaciones-ecommerce.component';
import { CatalogoComponent } from './components/ecommerce/catalogo/catalogo.component';
import { VerProductoComponent } from './components/ecommerce/catalogo/ver-producto/ver-producto.component';
import { CategoriasComponent } from './components/ecommerce/catalogo/categorias/categorias.component';
import { VentasEcommerceComponent } from './components/ecommerce/ventas-ecommerce/ventas-ecommerce.component';
import { ComprasEcommerceComponent } from './components/ecommerce/compras-ecommerce/compras-ecommerce.component';
import { OperacionesPubliexpressComponent } from './components/publiexpress/operaciones-publiexpress/operaciones-publiexpress.component';
import { ProductosPubliexpressComponent } from './components/publiexpress/productos-publiexpress/productos-publiexpress.component';
import { VerProductosPubliexpressComponent } from './components/publiexpress/ver-productos-publiexpress/ver-productos-publiexpress.component';
import { CotizadorPubliexpressComponent } from './components/publiexpress/cotizador-publiexpress/cotizador-publiexpress.component';
import { OrdenesPubliexpressComponent } from './components/publiexpress/ordenes-publiexpress/ordenes-publiexpress.component';
import { CotizacionComponent } from './components/publiexpress/cotizacion/cotizacion.component';
import { ListadoEmpresasComponent } from './components/empresa/listado-empresas/listado-empresas.component';

const routes: Routes = [
  { path: 'account/login', component: LoginComponent},
  { path: 'comercios/listado', component: ListadoComerciosComponent, canActivate:[AuthGuard]},
  { path: 'comercios/nuevo', component: NuevoComercioComponent, canActivate:[AuthGuard]},
  { path: 'comercios/editar', component: EditarComercioComponent, canActivate:[AuthGuard]},
  { path: 'comercios/pasacupo', component: PasacupoComercioComponent, canActivate:[AuthGuard]},
  { path: 'comercios/pasacupo/ver', component: VerPasacupoComercioComponent, canActivate:[AuthGuard]},
  { path: 'comercios/solicitud-saldo', component: SolicitudSaldoComponent, canActivate:[AuthGuard]},
  { path: 'comercios/conciliacion', component: ListadoConciliacionComponent, canActivate:[AuthGuard]},
  { path: 'comercios/conciliacion/ver', component: VerConciliacionComponent, canActivate:[AuthGuard]},
  { path: 'comercios/conciliacion/reporte', component: ReporteConciliacionComponent, canActivate:[AuthGuard]},
  { path: 'comercios/conciliacion/reporte-puntos', component: ReportePuntosConciliacionComponent, canActivate:[AuthGuard]},
  { path: 'comercios/pasar-ganancia', component: PasarGananciaComponent, canActivate:[AuthGuard]},
  
  { path: 'comercios/multiproducto', component: MultiproductoComercioComponent, canActivate:[AuthGuard]},

  { path: 'mensajero/listado', component: ListadoMensajeroComponent, canActivate:[AuthGuard]},
  { path: 'mensajero/imprimir', component: ImprimirComponent, canActivate:[AuthGuard]},
  { path: 'mensajero/reporte', component: ReporteComponent,canActivate:[AuthGuard]},
  { path: 'mensajero/producto', component: ProductoComponent,canActivate:[AuthGuard]},
  { path: 'mensajero/nuevo', component: NuevoMensajeroComponent, canActivate:[AuthGuard]},

  { path: 'recaudo/listado', component: ListadoRecaudoComponent, canActivate:[AuthGuard]},
  { path: 'recaudo/nuevo', component: NuevoRecaudoComponent, canActivate:[AuthGuard]},
  { path: 'recaudo/editar', component: EditarRecaudoComponent, canActivate:[AuthGuard]},
  { path: 'recaudo/importcsv', component: ImportCsvComponent, canActivate:[AuthGuard]},

  { path: 'compensaciones/listado', component: ListadoCompensacionComponent, canActivate:[AuthGuard]},
  { path: 'compensaciones/nuevo', component: NuevaCompensacionComponent, canActivate:[AuthGuard]},
  
  { path: 'empresas/listado-empresas', component: ListadoEmpresasComponent, canActivate:[AuthGuard]},

  { path: 'usuarios/listado', component: ListadoUsuariosComponent, canActivate:[AuthGuard]},
  { path: 'usuarios/nuevo', component: NuevoUsuarioComponent, canActivate:[AuthGuard]},
  { path: 'usuarios/editar', component: EditarUsuarioComponent, canActivate:[AuthGuard]},
  { path: 'comercios/password', component: ChangePasswordComponent, canActivate:[AuthGuard]},

  { path: 'prospectos/listado', component: ListadoProspectosComponent, canActivate:[AuthGuard]},
  { path: 'prospectos/reporte', component: ReporteProspectosComponent, canActivate:[AuthGuard]},


  { path: 'operaciones', component: OperacionesComponent, canActivate:[AuthGuard]},

  { path: 'operaciones/recaudos/listado', component: RecaudoListadoComponent, canActivate:[AuthGuard]},
  { path: 'operaciones/recaudos/imprimir', component: RecaudoImprimirComponent, canActivate:[AuthGuard]},


  { path: 'afiliaciones/listado', component: ListadoAfiliacionesComponent, canActivate:[AuthGuard]},
  { path: 'afiliaciones/nueva', component: NuevaAfiliacionComponent, canActivate:[AuthGuard]},

  //Mensajeria
  { path: 'mensajeria/listado', component: ListadoMensajeriaComponent, canActivate:[AuthGuard]},
  { path: 'mensajeria/form', component: FormularioMensajeriaComponent, canActivate:[AuthGuard]},
  { path: 'mensajeria/descargar', component: PrintMensajeriaComponent, canActivate:[AuthGuard]},
  { path: 'mensajeria/reporte', component: ReporteMensajeriaComponent, canActivate:[AuthGuard]},
  

  // ecommerce
  { path: 'ecommerce/operaciones', component: OperacionesEcommerceComponent, canActivate:[AuthGuard]},
  { path: 'ecommerce/ventas', component: VentasEcommerceComponent, canActivate:[AuthGuard]},
  { path: 'ecommerce/compras', component: ComprasEcommerceComponent, canActivate:[AuthGuard]},

  //Productos
  { path: 'productos/listado', component: ListadoProductosComponent, canActivate:[AuthGuard]},
  { path: 'productos/form', component: FormularioProductoComponent, canActivate:[AuthGuard]},

  // PubliExpress
  { path: 'publiexpress/operaciones', component: OperacionesPubliexpressComponent, canActivate:[AuthGuard]},
  { path: 'publiexpress/productos', component: ProductosPubliexpressComponent, canActivate:[AuthGuard]},
  { path: 'publiexpress/ver', component: VerProductosPubliexpressComponent, canActivate:[AuthGuard]},
  { path: 'publiexpress/cotizador', component: CotizadorPubliexpressComponent, canActivate:[AuthGuard]},
  { path: 'publiexpress/cotizacion/:id', component: CotizacionComponent},
  { path: 'publiexpress/ordenes', component: OrdenesPubliexpressComponent, canActivate:[AuthGuard]},
  
  { path: 'reportes', component: HomeReporteComponent, canActivate:[AuthGuard]},
  
  // { path: '', component: IndexComponent},
  { path: 'catalogo', component: CatalogoComponent},
  { path: 'catalogo/producto/:slug', component: VerProductoComponent},
  { path: 'catalogo/c/:slug/:id', component: CategoriasComponent},

  { path: 'pagina1', component: Pagina1Component},
  { path: 'pagina2', component: Pagina2Component},
  { path: 'pagina2', component: Pagina2Component},
  { path: 'inscripcion', component: SorteoProspectosComponent},
  { path: 'sorteo', component: AnchetaComponent},
  { path: '**', pathMatch: 'full', redirectTo: 'account/login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // ,{useHash: true}
  exports: [RouterModule]
})
export class AppRoutingModule { }
 