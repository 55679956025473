import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-ver-producto',
  templateUrl: './ver-producto.component.html',
  styleUrls: ['./ver-producto.component.css']
})
export class VerProductoComponent implements OnInit {
  id: any;
  slug:any;
  producto: any = [];

  constructor(private route: ActivatedRoute,public servicios: ServicesService, public router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('slug');
    if(this.id){
      this.getProducto();      
    }else{
      this.router.navigate(['/catalogo/']);
    }
  }

  getProducto(){
    let params = {
      getproducto: true,
      id: this.id,
    }
    this.servicios.setEcommerceProducto(params).subscribe( (data) => {
        this.producto = data['producto']; 
        // console.log(this.producto);                        
    });
  }

}
