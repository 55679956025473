<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Modulo Editar Usuario</h1>
                </div>
              </div>
            </div>
        </div>

          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-default">
                    <div class="card-header">
                      <h3 class="card-title">Crear Usuario</h3>
                    </div>
                    <form [formGroup]="form" (ngSubmit)="EditarUsuario()">
                      <div class="card-body">
                          <div class="row">

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label for="exampleInputEmail1" style="font-size: 14px;">Perfil ID</label>
                                  <select formControlName="perfil_id" class="custom-select" [(ngModel)]="perfil_id">
                                    <option value="8">Publicista</option>
                                    <option value="7">Externo Afiliaciones</option>
                                    <option value="6">Vendedor</option>
                                    <option value="5">Supervisor</option>
                                    <option value="1">Administrador</option>
                                  </select>                                    
                                </div>
                              </div> 
                              
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label style="font-size: 14px;">Nombre <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" 
                                  [(ngModel)]="nombre"
                                  formControlName="nombre" 
                                  type="text" 
                                  name="nombre" 
                                  class="form-control">
                                  <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                    <div *ngIf="f.nombre.errors.required">Campo obligatorio</div>
                                  </div>
                                </div>
                              </div> 


                              <div class="col-md-3">
                                <div class="form-group">
                                  <label style="font-size: 14px;">Celular <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.celular.errors }"
                                  [(ngModel)]="celular"
                                  name="celular" 
                                  type="number" 
                                  class="form-control" 
                                  formControlName="celular">
                                  <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                                    <div *ngIf="f.celular.errors.required">Campo obligatorio</div>
                                  </div>
                                </div>
                              </div>
                                                                                           

                              <div class="container-fluid">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="card card-default">
                                      <div class="card-header" style="background: rgb(238, 237, 237);">
                                        <h3 class="card-title" style="font-size: 15px;font-weight: bold;">Información Usuario Principal</h3>
                                      </div>
                                        <div class="card-body">
                                            <div class="row">
                                              
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                    <label style="font-size: 14px;">Nickname del Usuario <span style="color: red;">*</span></label>
                                                    <input [ngClass]="{ 'is-invalid': submitted && f.usuario_nickname.errors }" 
                                                    [(ngModel)]="usuario_nickname"
                                                    name="usuario_nickname" 
                                                    type="text" 
                                                    class="form-control" 
                                                    formControlName="usuario_nickname" [attr.disabled]="true">
                                                    <div *ngIf="submitted && f.usuario_nickname.errors" class="invalid-feedback">
                                                      <div *ngIf="f.usuario_nickname.errors.required">Campo obligatorio</div>
                                                    </div>
                                                  </div>
                                                </div>
                  
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                    <label style="font-size: 14px;">Email del Usuario <span style="color: red;">*</span></label>
                                                    <input
                                                    [(ngModel)]="email"
                                                    name="email" 
                                                    type="email" 
                                                    class="form-control" 
                                                    formControlName="email" [attr.disabled]="true">
                                                  </div>
                                                </div> 
                  
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                      <label style="font-size: 14px;">Password <span style="color: red;">*</span></label>
                                                      <div class="input-group">
                                                        <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                        [(ngModel)]="password"
                                                        name="password" 
                                                        [type]="fieldTextType ? 'text' : 'password'"
                                                        class="form-control" 
                                                        formControlName="password">
                                                        <div class="input-group-append">
                                                          <span class="input-group-text">
                                                            <i
                                                              class="fa"
                                                              [ngClass]="{
                                                                'fa-eye-slash': !fieldTextType,
                                                                'fa-eye': fieldTextType
                                                              }"
                                                              (click)="toggleFieldTextTypePass()"
                                                            ></i>
                                                          </span>
                                                        </div>    
                                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                          <div *ngIf="f.password.errors.required">Campo obligatorio</div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="card-footer"> -->
                                      <button type="submit" class="btn btn-primary float-right">Editar</button>
                                      <button type="button" class="btn btn-secondary float-right" routerLink="/usuarios/listado"><i class="fa fa-chevron-left"></i></button>
                                      <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </form>
                  </div>
    
                </div>
              </div>
            </div>
        </div>
      </div> 
      <app-footer-main></app-footer-main>  
  </div>
