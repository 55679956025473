import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent implements OnInit {
  id: any;
  producto: any = [];

  constructor(private route: ActivatedRoute, public servicios: ServicesService, public router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.getProducto();
      // console.log('dentro');          
    } else {
      // this.router.navigate(['/catalogo/']);
    }
  }

  getProducto() {
    let params = {
      getOrden: true,
      id: this.id,
    }
    this.servicios.setPubliexpressOrdenes(params).subscribe((data) => {
      this.producto = data['obj'];
      // console.log(this.producto);                        
    });
  }

}
