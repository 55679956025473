import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-listado-mensajeria',
  templateUrl: './listado-mensajeria.component.html',
  styleUrls: ['./listado-mensajeria.component.css']
})
export class ListadoMensajeriaComponent implements OnInit {

  comercio_id:any;
  perfil_id:any;
  cargando:boolean = false;
  mensajeria: any = [];
  itemImp: any = [];
  pagesNumber: any;
  offset= 3;
  
  constructor(private servicios: ServicesService,
              private router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoMensajeria();
  }

  listadoMensajeria(page = null){
    // console.log(this.comercio_id);
    // console.log(this.perfil_id);
    this.cargando = true;
    if(!page){
      this.servicios.getMensajeria(this.comercio_id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        // console.log(data);
        this.mensajeria = data['mensajeria'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getMensajeria(this.comercio_id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.mensajeria = data['mensajeria'];
        this.cargando = false;
        this.ForpagesNumber()

      });
    }     
  }

  ForpagesNumber() {
    let from = this.mensajeria.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.mensajeria.total_page){
      to = this.mensajeria.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  onSelect(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajeria: item
      }
    }
    this.router.navigate(['/mensajeria/descargar'],navigationExtras);
  }

  onVer(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajeria: item
      }
    }
    this.router.navigate(['/mensajeria/form'],navigationExtras);
  }

  onEditarEstado(item, newEstado, tipoEstado) {
    Swal.fire({
      title: '¿Está seguro?',
      html: "Desea cambiar el estado a " + tipoEstado + " para el envio de mensajeria <b>" + item.numero_guia + "</b>",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Continuar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          id: item.id,
          editarEstado: true,
          estado: newEstado,
        }
        console.log(params);
        this.servicios.setMensajeria(params)
          .subscribe((data) => {
            console.log(data);
            if (data["logger"] == true) {
              this.listadoMensajeria();
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });

            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
            }
          });
      }
    });
  }
  onEliminar(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar el envio de mensajeria con número de guia ${item.numero_guia}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          id: item.id,
          eliminar: true,
          estado: 0,
        }
        console.log(params);
        this.servicios.setMensajeria(params)
          .subscribe((data) => {
            console.log(data);
            if (data["logger"] == true) {
              this.listadoMensajeria();
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });

            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
            }
          });
      }
    });
  }
}
