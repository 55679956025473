
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>Modulo Mensajero</h1>
            </div>
            <div class="col-sm-12 mt-3">
              <button type="button" routerLink="/mensajero/nuevo" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
              <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
              <button type="button" (click)="listadoMensajero()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Fecha</th>
                      <th>Operación</th>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Contacto</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of mensajero.items; let i = index">
                      <td><span (click)="VerOrdenMensajero(item)" data-toggle="modal" data-target="#modalVerMensajero" style="cursor: pointer;">{{item.id}}</span></td>
                      <td>{{item.fecha}} {{item.hora}}</td>
                      <td *ngIf="item.banco"><b>Banco </b><div>{{item.banco | uppercase}}</div></td>
                      <td *ngIf="item.recaudo_id_comercio || item.recaudo_empresa_nombre || item.recaudo_referencia"><b>Recaudo </b><div>{{item.recaudo_referencia | uppercase}}</div></td>
                      <td>{{item.nombre}}</td>
                      <td>{{item.email}}</td>
                      <td>{{item.celular}}</td>
                      <td><span class="bg-warning" style="padding: 5px;" *ngIf="item.estado == 1"><b>Pendiente</b></span> 
                          <span class="bg-primary" style="padding: 5px;" *ngIf="item.estado == 2"><b>Procesando</b></span> 
                          <span class="bg-success" style="padding: 5px;" *ngIf="item.estado == 3"><b>Procesado</b></span> 
                          <span class="bg-danger" style="padding: 5px;" *ngIf="item.estado == 4"><b>Cancelada</b></span></td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button (click)="VerOrdenMensajero(item)" data-toggle="modal" data-target="#modalVerMensajero" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver Solicitud</button>
                            <button (click)="Imprimir(item)" type="button" class="dropdown-item"><i class="fas fa-print"></i> Imprimir</button>
                          </div>
                        </div> 

                        <!-- <button (click)="VerMensajero(item)" type="button" class="btn btn-success btn-sm"><i class="fas fa-search"></i></button> -->
                        <!-- <button (click)="EditarMensajero(item)" type="button" class="btn btn-primary btn-sm"><i class="fas fa-pen"></i> Editar</button> -->
                      </td>
                    </tr>
                    <!--Modal Ver Mensajero -->
                    <div id="modalVerMensajero" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                      <div role="document" [ngClass]="!ordenItem.recaudo_id_comercio ? 'modal-dialog modal-lg' : 'modal-dialog modal-md'">
                        <div class="modal-content">
                          <div [ngClass]="{'bg-warning': ordenItem.estado == 1, 'bg-primary':ordenItem.estado == 2, 'bg-success':ordenItem.estado == 3, 'bg-danger':ordenItem.estado == 4 }" class="modal-header ">
                            <h5 class="modal-title">
                              Solicitud {{ordenItem.id}}
                              <span class="bg-warning" style="padding: 5px;" *ngIf="ordenItem.estado == 1"><b>- Pendiente</b></span> 
                              <span class="bg-primary" style="padding: 5px;" *ngIf="ordenItem.estado == 2"><b>- Procesando</b></span> 
                              <span class="bg-success" style="padding: 5px;" *ngIf="ordenItem.estado == 3"><b>- Procesado</b></span> 
                              <span class="bg-danger" style="padding: 5px;" *ngIf="ordenItem.estado == 4"><b>- Cancelada</b></span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>                            
                          </div>
                          <div class="modal-body">
                            <div class="col-md-12">

                              <div [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-12' : 'col-md-12'">
                                <div class="row">
                                  <div class="mt-2" [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-7' : 'col-md-12'">
                                    <img style="display:block;margin:auto;" *ngIf="ordenItem.banco == 'Bancolombia'" width="250" src="assets/img/logo-bancolombia.png">
                                    <img style="display:block;margin:auto;" *ngIf="ordenItem.banco == 'Colpatria'" width="250" src="assets/img/logo-colpatria.png">
                                    <img style="display:block;margin:auto;" *ngIf="ordenItem.banco == 'Davivienda'" width="250" src="assets/img/logo-davivienda.png">

                                    <div style="margin-top:-1%;font-size: 2em;" class="form-group">
                                      <div *ngIf="ordenItem.recaudo_empresa">{{ordenItem.recaudo_empresa | titlecase}} - {{ordenItem.recaudo_empresa_nit}}</div>
                                      <div class="text-muted">{{ordenItem.recaudo_referencia}}</div>

                                      <span> {{ordenItem.tipo | titlecase}}</span>
                                    </div>  
                                    <div style="margin-top:-5%;font-size: 1.3em;" class="form-group" *ngIf="ordenItem.numero_cuenta">
                                      <label>Numero de Cuenta:</label> <span> {{ordenItem.numero_cuenta}}</span>
                                    </div> 
                                    <div style="margin-top:-5%;font-size: 1.2em;" class="form-group" *ngIf="ordenItem.numero_convenio">
                                      <label>Convenio:</label> <span> {{ordenItem.numero_convenio}}</span>
                                    </div>                                       
                                    <div style="margin-top:-5%;font-size: 2em;" class="form-group">
                                      <label>Valor:</label> <span> ${{ordenItem.valor | decimal}}</span>
                                    </div>
                                    
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <input readonly [(ngModel)]="ordenItem.fecha" class="form-control" name="fecha" type="date" 
                                                  value="2011-08-19" style="outline: none;background-color: rgb(229, 233, 231);
                                                  border: 0;text-align: center;">
                                          </div>
                                          <div class="col-md-6">
                                            <input readonly [(ngModel)]="ordenItem.hora" class="form-control" name="hora" type="time" 
                                                  value="13:45:00" style="outline: none;background-color: rgb(229, 233, 231);
                                                  border: 0;text-align: center;">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="mt-4" [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-12' : 'col-md-12'"> 
                                          <div style="margin-top:-1%" class="form-group">
                                            <label>Nombre:</label> <span> {{ordenItem.nombre}}</span>
                                          </div>    
                                          <div style="margin-top:-3%" class="form-group">
                                            <label>Celular:</label> <span> {{ordenItem.celular}} </span><span *ngIf="ordenItem.imgUrl"><a [href]="'https://api.whatsapp.com/send?phone=57'+ordenItem.celular+'&text=Operación Exitosa Mensajero Virtual '+ordenItem.imgUrl" target="_blank">link</a></span>
                                          </div>  
                                          <div style="margin-top:-3%" class="form-group">
                                            <label>Email:</label> <span> {{ordenItem.email}}</span>
                                          </div>   
                                          <div style="margin-top:-3%" class="form-group">
                                            <label>Punto:</label> <span> {{ordenItem.punto}}</span>
                                          </div> 
                                      </div>
                                    </div>
                                  </div>
                                  <div [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-5' : 'col-md-1'">
                                    <div class="col-md-12" *ngIf="!ordenItem.recaudo_id_comercio">
                                      <div class="row">
                                        <div class="col-md-12" *ngIf="perfil_id <= 3">
                                          <div   class="form-group" *ngIf="ordenItem.estado == 1 || ordenItem.estado == 3 || ordenItem.estado == 4">
                                            <label>Estado</label>
                                            <select [(ngModel)]="ordenItem.estado" name="estado" class="form-control form-control-sm">
                                              <option value="1">Pendiente</option>
                                              <!-- <option value="2">Procesando</option> -->
                                              <option value="3">Procesado</option>
                                              <option value="4">Rechazada</option>
                                            </select>                                 
                                          </div>
                                        </div>
                                        <div class="col-md-12">
                                          <div class="form-group text-center mt-2">
                                     
                                            <span style="color:red;" *ngIf="message">{{message}}</span>
                                            <input #file type="file" accept='image/*' (change)="preview(file.files, ordenItem.id)" style="display:none;"/>
                                            <div class="d-flex justify-content-center mb-3" *ngIf="cargandoImg">
                                              <div class="spinner-border" role="status" style="width: 1.5rem; height: 1.5rem;">
                                                <span class="sr-only">Cargando...</span>
                                              </div>
                                            </div>

                                            <img *ngIf="imgURL" style="margin-top:1%" [src]="imgURL" height="200" width="100%" class="img-fluid" alt="Responsive image"> 
                                            <img *ngIf="!imgURL && ordenItem.imgUrl" style="margin-top:1%" [src]="ordenItem.imgUrl" height="200" width="100%" class="img-fluid" alt="Responsive image">
                                            <img *ngIf="!ordenItem.imgUrl && !imgURL" style="margin-top:1%" src="assets/img/default-image.png" height="200" width="100%" class="img-fluid" alt="Responsive image">
                                            <div *ngIf="!loaderImgComprobante"><h5 class="linkFile mt-2" (click)="file.click()" *ngIf="perfil_id <= 3">Cargar Imagen Comprobante</h5></div>
                                            <div *ngIf="loaderImgComprobante" class="text-center">
                                              <div class="spinner-border" role="status"></div>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <textarea [(ngModel)]="ordenItem.nota" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Nota"></textarea>
                                          </div>
                                          <button *ngIf="perfil_id <= 3 && !ordenItem.ganancia" data-dismiss="modal" aria-label="Close" (click)="EnviarEstadoOrden(ordenItem.id,ordenItem.estado,ordenItem.nota)" type="button" class="btn btn-success btn-lg btn-block btn-sm">Guardar <i style="margin-left: 2%;" class="fas fa-share-square"></i></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>                            
                          </div>
                          <div class="modal-footer">
                            <button #CerrarModal (click)="cerrarmodal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>
              </div>

              <!-- paginador -->
              <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="mensajero.current > 1">
                    <button type="button" class="page-link" (click)="changePage(mensajero.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>                  

                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': mensajero.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>                  

                  <li class="page-item" *ngIf="mensajero.current < mensajero.total_page">
                    <button type="button" class="page-link" (click)="changePage(mensajero.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>                  
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{mensajero.size}}</b></p>
                </div>
              </div>              
              <!-- Fin paginador -->               
            
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>   
      </section>   
    </div> 
    <app-footer-main></app-footer-main>  
</div>  



