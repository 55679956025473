import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-main',
  templateUrl: './navbar-main.component.html',
  styleUrls: ['./navbar-main.component.css']
})
export class NavbarMainComponent implements OnInit {
  mode:any;

  constructor() { }

  ngOnInit(): void {
    this.mode = localStorage.getItem('dev');
  }

}
