<div class="d-flex flex-column flex-md-row align-items-center p-0 px-md-4 mb-0 bg-white border-bottom shadow-sm">
  <h5 class="my-0 mr-md-auto font-weight-normal" routerLink="/catalogo/" style="cursor:pointer"><img src="assets/img/logo-mensajero.png" style="display:block;margin:auto"></h5>
  <form class="form-inline my-2 my-lg-0">
    <input class="form-control mr-sm-2" type="search" placeholder="Busca productos" aria-label="Search">
    <button class="btn btn-primary my-2 my-sm-0" type="submit">Buscar</button>
  </form>
</div>
<nav class="site-header py-1" style="background-color: #4f226c;">
  <div class="container d-flex flex-column flex-md-row justify-content-between" >
    <a *ngFor="let item of listCategorias; let i=index" style="color: #fff;text-decoration: none;background-color: #0000;font-size: 1.5em;" class="py-2 d-none d-md-inline-block" routerLink="/catalogo/c/{{item.slug}}/{{item.id}}">{{item.nombre | titlecase}}</a>
  </div>
</nav>