import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formulario-producto',
  templateUrl: './formulario-producto.component.html',
  styleUrls: ['./formulario-producto.component.css']
})
export class FormularioProductoComponent implements OnInit {
  
  public nuevoProductoForm: FormGroup;


  comercio_id:any;
  perfil_id:any;
  ver:boolean = false;
  editar:boolean = false;
  id: any;

  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;
  cargando: boolean = false;
  
  listCategorias: any[];
  selectCategorias = [];
  selectCategoriasEdit = [];
  categorias = [];


  constructor(private servicios: ServicesService,private router: Router,private route: ActivatedRoute) {
    this.nuevoProductoForm = new FormGroup({
      titulo: new FormControl(null, Validators.required),
      descripcion: new FormControl(null, Validators.required),
      estado: new FormControl(null, Validators.required),
      comercio_id: new FormControl(null, Validators.required),       
      valor: new FormControl(null, Validators.required),
      stock: new FormControl(null, Validators.required),
      img_destacada: new FormControl(null, Validators.nullValidator),        
  }); 

    if(this.router.getCurrentNavigation().extras.state){
    //   if(this.router.getCurrentNavigation().extras.state.ver){
    //     this.ver = true;  
    //   }
      if(this.router.getCurrentNavigation().extras.state.editar){
        this.editar = true;  
          let itemProducto = this.router.getCurrentNavigation().extras.state.item;
          this.id = itemProducto.id;
          // console.log(itemProducto);
          // console.log(this.ver);
          // this.form.get('numero_guia').disable();
          this.nuevoProductoForm.get('titulo').setValue(itemProducto.titulo);
          this.nuevoProductoForm.get('descripcion').setValue(itemProducto.descripcion);
          this.nuevoProductoForm.get('estado').setValue(itemProducto.estado);
          this.nuevoProductoForm.get('comercio_id').setValue(itemProducto.comercio_id);
          this.nuevoProductoForm.get('valor').setValue(itemProducto.valor);
          this.nuevoProductoForm.get('stock').setValue(itemProducto.stock);
          this.nuevoProductoForm.get('img_destacada').setValue(itemProducto.img_destacada);
          this.selectCategoriasEdit = itemProducto.categoria;          
      }
    }  
    
  }
  
  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoCategoria();     
  }

  listadoCategoria(){
    this.servicios.getEcommerceCategoria().subscribe( (data) => {
        this.listCategorias = data['categorias']; 
        
        for (var i = 0; i < this.listCategorias.length; i++) {
          var ismatch = false; // we haven't found it yet
          for (var j = 0; j < this.selectCategoriasEdit.length; j++) {
            if (this.listCategorias[i].id == this.selectCategoriasEdit[j].categoria_id) {
              // we have found this.officeLIST[i]] in this.office, so we can stop searching
              ismatch = true;
              this.listCategorias[i].checked = true;//  checkbox status true
              this.categorias.push(this.listCategorias[i]);
              break;
            }
          }
          if (!ismatch) {
            this.listCategorias[i].checked = false;//  checkbox status false
            this.categorias.push(this.listCategorias[i]);
          } //End if
        }
          // console.log(this.categorias);       
    });

  }



  async onSubmit(){
    this.nuevoProductoForm.get('comercio_id').setValue(this.comercio_id);
    
    // console.log(this.nuevoProductoForm.value);
    if(this.nuevoProductoForm.valid){
      var params = {};
      if(!this.editar){
        params = {
          nuevo: true,
          titulo: this.nuevoProductoForm.value.titulo,
          descripcion: this.nuevoProductoForm.value.descripcion,
          valor: this.nuevoProductoForm.value.valor,
          stock: this.nuevoProductoForm.value.stock,
          categoria: this.selectCategorias,
          estado: this.nuevoProductoForm.value.estado,
          img_destacada: this.nuevoProductoForm.value.img_destacada,
          comercio_id: this.nuevoProductoForm.value.comercio_id,
        }
      }else{
        params = {
          editar: true,
          id: this.id,
          titulo: this.nuevoProductoForm.value.titulo,
          descripcion: this.nuevoProductoForm.value.descripcion,
          valor: this.nuevoProductoForm.value.valor,
          stock: this.nuevoProductoForm.value.stock,
          categoria: this.selectCategorias,
          estado: this.nuevoProductoForm.value.estado,
          img_destacada: this.nuevoProductoForm.value.img_destacada,
          comercio_id: this.nuevoProductoForm.value.comercio_id,
        }
      }

        console.log(params);
        this.servicios.setEcommerceProducto(params)
        .subscribe( (data) => {
          console.log(data);

          if (data["logger"] == true){
            this.router.navigate(['productos/listado'])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
    } else {
          Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'El formulario no es válido!',
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });      
    }
  }



  preview(files, estado) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // this.field = field;
    this.fileName = files[0].name
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      // console.log("esta es la imagen"+this.fileURL);
      this.GuardarImagen(estado);
    }

  }

  GuardarImagen(estado){
    if(!this.fileURL) {
      console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL,
        estado: estado
      };
      this.cargando = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          let params = {
            nombre: this.fileName,
            archivo: data['filename'],
            ruta: data['ruta'],
            estado: estado
          };
          this.fileURL = data['filename'];
          if(estado ==2){
            //img destacada
            this.nuevoProductoForm.get('img_destacada').setValue(data['filename']);
          }
          if(estado ==1){
            //img galeria
            this.listadjuntos.push(params);
            console.log(this.listadjuntos);  
          }
          this.cargando = false;
          // if (this.field == 'ccorut') this.form.get('ccorut').setValue(this.fileURL);
          // if (this.field == 'cc') this.form.get('cc').setValue(this.fileURL);
          // if (this.field == 'img1') this.form.get('img1').setValue(this.fileURL);
          // if (this.field == 'img2') this.form.get('img2').setValue(this.fileURL);
          // if (this.field == 'img3') this.form.get('img3').setValue(this.fileURL);
          // console.log("img:::"+this.fileURL);
          // console.log("field:::"+this.field);
          
        }
      });
    }
  }

  deleteAdjunto(i: number,itemAdjunto){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea borrar el archivo `+itemAdjunto,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo!',
    }).then((result) => {
      if (result.value) {
        this.listadjuntos.splice(i,1);       
      }
    });       
}


  onSelectCategoria(e,item) {  
    if (e.target.checked) {
      // this.selectCategorias.push(e.target.value) 
      // this.selectCategorias.push(item) 
      item.checked = true;
      this.selectCategorias.push(item) 
    } else {
      item.checked = false;
      let i: number = 0;
      this.selectCategorias.forEach((item) =>{
          // console.log(item);          
        if (item == e.target.value) {
            this.selectCategorias.splice(i,1);       
            return;
          }
          i++;
      })
    }
  }  

}
