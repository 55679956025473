import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recaudo-imprimir',
  templateUrl: './recaudo-imprimir.component.html',
  styleUrls: ['./recaudo-imprimir.component.css']
})
export class RecaudoImprimirComponent implements OnInit {

  ItemImprimirOrden: any = {};
  nombre: any;
  direccion:any;
  constructor(public router: Router) { 
    if(this.router.getCurrentNavigation().extras.state){
      this.ItemImprimirOrden = this.router.getCurrentNavigation().extras.state.itemMensajeroImprimirOrden;
      this.nombre = this.ItemImprimirOrden['nombre']; 
      console.log(this.ItemImprimirOrden);
    }
  }

  ngOnInit(): void {
    if(!this.nombre){
      this.router.navigate(['../operaciones/recaudos/listado']);
    }
  }

  NuevaOperacion(){
    this.router.navigate(['../operaciones/recaudos/listado']);
  }

  print() {
    window.print();
  }

}
