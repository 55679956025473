
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Operaciones</h1>
                </div>
              </div>
            </div>
        </div>

        <div class="content row"  *ngIf="perfil_id == 4">
            <div class="col-lg-3 col-xs-6">
                <div class="info-box"  title="hoy">
                <span class="info-box-icon bg-primary">
                    {{statusMensajeroHoy}}
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">SOLICITUDES HOY</span>
                </div>
                </div>
            </div> 

            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <div class="info-box"  title="hoy">
                <span class="info-box-icon bg-primary">
                    {{countPuntos}}
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">PUNTOS</span>
                </div>
                </div>
            </div>                                     
        </div>

        <div class="content row">
            
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <div class="info-box"  title="COMERCIOS" routerLink="/comercios/listado" style="cursor: pointer">
                  <span class="info-box-icon bg-warning">
                    <i class="fa fa-table"></i>
                  </span>
                  <div class="info-box-content">
                    <span class="info-box-text">COMERCIOS</span>
                  </div>
                </div>
            </div>

            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1 || perfil_id == 4">
                <div class="info-box"  title="ENVIAR SOLICITUD DE SALDO" routerLink="/comercios/solicitud-saldo" style="cursor: pointer">
                  <span class="info-box-icon bg-primary">
                    <i class="fa fa-share-square"></i>
                  </span>
                  <div class="info-box-content">
                    <span class="info-box-text">SOLICITUD DE SALDO</span>
                  </div>
                </div>
            </div>            
            

            <!-- <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 4 || perfil_id == 1">
                <div class="info-box"  title="RECAUDOS" routerLink="/mensajero/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-money-bill"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">RECAUDOS</span>
                </div>
                </div>
            </div> -->

            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 4 || perfil_id == 1">
                <div class="info-box"  title="RECAUDOS" routerLink="/operaciones/recaudos/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-money-bill"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">RECAUDOS</span>
                </div>
                </div>
            </div>


            <!-- <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 4 || perfil_id == 1">
                <div class="info-box"  title="MENSAJERO" routerLink="/mensajero/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-envelope-open-text"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">MENSAJERO</span>
                </div>
                </div>
            </div> -->
            
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 3 || perfil_id == 1">
                <div class="info-box"  title="RECAUDO" routerLink="/recaudo/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-money-check-alt"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">MENSAJERO VIRTUAL</span>
                </div>
                </div>
            </div>

            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 3 || perfil_id == 1">
                <div class="info-box"  title="RECAUDO" routerLink="/recaudo/importcsv" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-file-upload"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">ARCHIVO PLANO CSV</span>
                </div>
                </div>
            </div>            
            
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 4 || perfil_id == 1">
                <div class="info-box"  title="COMPENSACIÓN" routerLink="/mensajero/reporte" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-chart-bar"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">REPORTE </span>
                </div>
                </div>
            </div>            
            
            <!-- <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <div class="info-box"  title="COMPENSACIÓN" routerLink="/compensacion/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-file-upload"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">COMPENSACIÓN </span>
                </div>
                </div>
            </div>    -->
            
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <div class="info-box"  title="COMISION PRODUCTOS" routerLink="/mensajero/producto" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-box"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">PRODUCTOS % </span>
                </div>
                </div>
            </div> 
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <div class="info-box"  title="PASA CUPO" routerLink="/comercios/pasacupo" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-cash-register"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">PASA CUPO </span>
                </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <div class="info-box"  title="PASA CUPO" routerLink="/compensaciones/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-receipt"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">COMPENSACIÓN CBC </span>
                </div>
                </div>
            </div> 

            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1 || perfil_id == 3">
                <div class="info-box"  title="CONCILIACIÓN" routerLink="/comercios/conciliacion" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-money-bill"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">CONCILIACIÓN</span>
                </div>
                </div>
            </div>
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <a class="info-box" target="_blank" title="SORTEO" routerLink="/inscripcion" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-money-bill"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">SORTEO</span>
                </div>
            </a>
            </div>
            <div class="col-lg-3 col-xs-6" *ngIf="perfil_id == 1">
                <a class="info-box" target="_blank" title="SORTEO" routerLink="/ancheta" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                    <i class="fa fa-money-bill"></i>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">ANCHETA</span>
                </div>
            </a>
            </div>
        </div>       

    </div>
      
      <app-footer-main></app-footer-main>  
  </div>  
  
