<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>

    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">                        
                        <h3 *ngIf="!editar"><a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/productos/listado" class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a> Añadir nuevo producto</h3>
                        <h3 *ngIf="editar"><a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/productos/listado" class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a> Editar producto</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="">

                            <form [formGroup]="nuevoProductoForm" (ngSubmit)="onSubmit()">
                                <div class="col-md-12">
                                    <div class="card card-default">

                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-8">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="titulo"
                                                                    style="font-size: 14px;">Titulo</label>
                                                                <input [readonly] = "ver" formControlName="titulo" name="titulo" type="text" class="form-control">
                                                            </div>
                                                        </div>  
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="descripcion"
                                                                    style="font-size: 14px;">Descripción</label>
                                                                <textarea [readonly] = "ver" formControlName="descripcion" name="descripcion" class="form-control" rows="5"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="valor"
                                                                    style="font-size: 14px;">Valor</label>
                                                                <input [readonly] = "ver" formControlName="valor" name="valor"  class="form-control" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="valor"
                                                                    style="font-size: 14px;">Stock</label>
                                                                <input [readonly] = "ver" formControlName="stock" name="stock"  class="form-control" type="number">
                                                            </div>
                                                        </div>                                                        
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="estado" style="font-size: 14px;">Estado</label>
                                                                <select formControlName="estado" class="custom-select" name="estado">
                                                                <option value="1">Activo</option>
                                                                <option value="2">Inactivo</option>
                                                                </select>                                    
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            

                                                            <div class="form-group text-center">
                                                                <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files, '1')" style="display:none;"/>
                                                                <h5 class="linkFile" (click)="file.click()" style="border: 2px dashed #ccc;padding-top: 15px;padding-bottom: 15px;margin-top: 10px;cursor: pointer;">Cargar imagenes</h5>
                                                            </div>
                                                            <!-- FIN CARGAR ADJUNTOS-->
                                                            <div *ngIf="cargando" class="alert text-center mt-3 animated fadeIn faster">
                                                                <h4 class="alert-heading">Cargando...</h4>
                                                                <p>
                                                                    <i class="fa fa-sync-alt fa-spin fa-3x"></i>
                                                                </p>
                                                                <p class="mb-0">
                                                                    Espere por favor
                                                                </p>
                                                            </div>

                                                            <div class="form-group">
                                                                <label *ngIf="listadjuntos.length > 0" for="img" style="font-size: 14px;">Imagenes</label>
                                                                <div>
                                                                    <!-- <img *ngIf="itemAdjunto.estado == 2" style="margin-top:1%" src="https://api1.pagoexpress.co/public/img/upload/{{itemAdjunto.archivo}}" height="50" width="50%" class="img-fluid" alt="Responsive image"> -->
                                                                    <div class="row">
                                                                        <div class="col-sm-4" *ngFor="let itemAdjunto of listadjuntos">
                                                                            <img style="margin-top:1%" src="https://api1.pagoexpress.co/public/img/upload/{{itemAdjunto.archivo}}" width="100%" class="img-fluid" alt="Responsive image">
                                                                        </div>
                                                                    </div>
                                                                </div>  
                                                            </div>                                                            
                                                            
                                                            <div class="list-group" *ngFor="let itemAdjunto of listadjuntos let i = index">
                                                                <div class="row">
                                                                <div class="col-md-11">
                                                                    <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a>
                                                                </div>
                                                                <div class="col-md-1 ">
                                                                    <button class="btn btn-sm btn-secondary" type="button" (click)="deleteAdjunto(i,itemAdjunto)"><i class="fa fa-trash-alt"></i></button> 
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-sm-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="btn-group float-right" role="group" aria-label="Basic example">
                                                                <button *ngIf="!editar" class="btn btn-primary float-right " type="submit">Publicar Producto</button>
                                                                <button *ngIf="editar" class="btn btn-warning float-right " type="submit">Editar Producto</button>
                                                                <a routerLink="/productos/listado" class="btn btn-secondary float-right ">Regresar</a>
                                                              </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h6>Categorias del producto</h6>
                                                        </div>
                                                        <div class="card-body">
                                                            <div  class="form-check" *ngFor="let item of categorias; let i=index">
                                                                <input class="form-check-input" type="checkbox" [id]="'ck'+item.id" [value]="item.id" (change)="onSelectCategoria($event,item)" [checked]="item.checked" />
                                                                <label class="form-check-label" [for]="'ck'+item.id">{{item.nombre}}</label>
                                                            </div>                                                                                                                          
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-body">
                                                            <!-- CARGAR ADJUNTOS DESTACADA -->
                                                            <div class="form-group text-center">                                                                
                                                                <input  #img type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(img.files, '2')" style="display:none;"/>
                                                                <img *ngIf="nuevoProductoForm.value.img_destacada" (click)="img.click()" style="margin-top:1%;cursor: pointer;" src="https://api1.pagoexpress.co/public/img/upload/{{nuevoProductoForm.value.img_destacada}}" width="100%" class="img-fluid rounded">
                                                                <span *ngIf="!nuevoProductoForm.value.img_destacada">
                                                                    <h5 class="linkFile" (click)="img.click()" style="border: 2px dashed #ccc;padding-top: 15px;padding-bottom: 15px;margin-top: 10px;cursor: pointer;">Cargar imagen destacada</h5>
                                                                </span>
                                                            </div>                                                            
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <app-footer-main></app-footer-main> 
</div>