<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>

    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Reporte de Prospectos</h1>
                </div>
              </div>
            </div>
          </section>
        <!-- Inicio Calendario -->
        <section class="content">            
            <div class="row">
              <div class="col-8">
          
        <div class="row text-center">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Anterior
                    </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        Actual
                    </div>
                    <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Siguiente
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn | titlecase }}</h3>
            </div>
        </div>
        <br />
        <div [ngSwitch]="view">
            <mwl-calendar-month-view [cellTemplate]="customeCell" *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                [locale]="locale">
            </mwl-calendar-month-view>
        </div>

        <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

        <br /><br /><br />

        <ng-template #modalContent let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Reporte</h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngFor="let item of datosDia">
                    <h4 class="lead">{{item.usuario_name}}: <span class="badge bg-secondary">{{item.total}}</span></h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" (click)="close()">
                    Cerrar
                </button>
            </div>
        </ng-template>
        <!-- Fin Calendario -->
        </div>
        <div class="col-4 mt-5">
            <div *ngIf="datosDia" class="mt-3">
                <h2 *ngIf="totalDia > 0" class="text-center">Total día <b>{{totalDia}}</b></h2>
                <div *ngFor="let item of datosDia" class="card">
                    <div class="card-header"><h3>{{item.usuario_name}}: {{item.countProspectos}}</h3></div>
                    <div class="card-body">
                        <p *ngFor="let element of item.prospectos_asesor; index as i">
                            {{i+1}}. <b>{{element.nombre}}:</b> {{element.departamento}} {{element.ciudad}}
                        </p>
                    </div> 
                </div>
            </div>
            <div *ngIf="datosDia.length < 1" class="alert alert-danger" role="alert">
                Ningún día seleccionado
            </div>
        </div>
        <!-- fin modal -->
        <!-- inicio custom cell -->
        <ng-template
            #customeCell
            let-day="day"
            let-openDay="openDay"
            let-locale="locale"
            let-tooltipPlacement="tooltipPlacement"
            let-highlightDay="highlightDay"
            let-unhighlightDay="unhighlightDay"
            let-eventClicked="eventClicked"
            let-tooltipTemplate="tooltipTemplate"
            let-tooltipAppendToBody="tooltipAppendToBody"
            >
            <div class="cal-cell-top">
                <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
                >{{ day.badgeTotal }}</span
                >
                <span class="cal-day-number"
                >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
                >
            </div>
        </ng-template>
        <!-- fin custom cell -->
    </div>
    </section>
    </div>
    <app-footer-main></app-footer-main>
</div>