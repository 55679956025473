import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listado-empresas',
  templateUrl: './listado-empresas.component.html',
  styleUrls: ['./listado-empresas.component.css']
})
export class ListadoEmpresasComponent implements OnInit {

  cargando: boolean = false;
  usuarios: any = [];
  pagesNumber: any;
  ItemVer: any = [];
  offset = 1;

  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.listadoUsuarios();
  }

  changePage(page) {
    this.usuarios.current = page;
    this.listadoUsuarios(page);
  }

  Editar(item) {
    let navigationExtras: NavigationExtras = {
      state: {
        itemUsuario: item
      }
    }
    this.router.navigate(['/usuarios/editar'], navigationExtras);
  }



  VerUsuario(item) {
    this.ItemVer = item;
    // console.log(item);
  }

  cerrarmodal() {
    this.ItemVer = '';
  }



  Eliminar(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar el ususario ${item.nombre}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Aprobar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          id: item.id,
          eliminar: true,
          estado: 0,
        }
        console.log(params);
        this.servicios.postUsuario(params)
          .subscribe((data) => {
            console.log(data);
            if (data["logger"] == true) {
              this.listadoUsuarios();
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });

            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
            }
          });
      }
    });
  }

  listadoUsuarios(page = null) {
    this.cargando = true;
    if (!page) {
      this.servicios.getUsuarios(1).subscribe((data) => {
        this.usuarios = data['usuarios'];
        this.cargando = false;
        // console.log(data['usuarios']);
        this.ForpagesNumber()
      });
    } else {
      var url = this.servicios.getUsuarios(page).subscribe((data) => {
        this.usuarios = data['usuarios'];
        this.cargando = false;
        // console.log(data['usuarios']);
        this.ForpagesNumber()

      });
    }
  }


  ForpagesNumber() {
    let from = this.usuarios.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.usuarios.total_page) {
      to = this.usuarios.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

}