<!-- SEARCH FORM -->
<!-- <form class="form-inline ml-3">
    <div class="input-group input-group-sm">
      <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form> -->
<ul id="saldos" class="d-flex flex-wrap justify-content-center">
  <li><button type="button" class="btn" (click)="listadoStatus()"><i class="fas fa-2x fa-sync" style="color: red;"></i></button></li>
  <li class="saldo saldo_recarga d-flex flex-column flex-md-row align-items-center">
    <i class="fas fa-money"></i>
    <div class="saldo_info text-center">
      <p><strong>Saldo</strong></p>
      <p>$ 
        <span *ngIf="perfil_id ==1" >{{status.count_comercio_admin | decimal}}</span>
        <span *ngIf="perfil_id ==3" >{{status.saldo_recaudo | decimal}}</span>
        <span *ngIf="perfil_id ==4">{{status.saldo | decimal}}</span> 
      </p>
    </div>        
  </li>
  <li *ngIf="perfil_id !=3" class="saldo saldo_recarga d-flex flex-column flex-md-row align-items-center">
    <i class="fas fa-money"></i>
    <div class="saldo_info text-center" >
      <p><strong>Ganancias</strong></p>
      <p>$ {{status.ganancia | decimal}}</p>
    </div>
  </li>
</ul>
