
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Pasa Cupo</h1>
              </div>
              <div class="col-sm-12 col-md-3 mt-3">             
                <!-- <button type="button" routerLink="/comercios/nuevo" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button> -->
                <button type="button" (click)="listadoComercios()" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
              </div>
              <div class="col-sm-12 col-md-9 mt-3" *ngIf="perfil_id == 1">
                <input [(ngModel)]="buscar" name="buscar" type="text" (change)="filtro()"
                  placeholder="Buscar por Nombre, Razón social o Número de documento"
                  class="form-control float-right text-uppercase">
              </div>
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th (click) = "sort('id')">ID <i class="fas fa-sort"></i></th>
                        <th (click) = "sort('razon_social')">COMERCIO / RAZÓN SOCIAL <i class="fas fa-sort"></i></th>
                        <th (click) = "sort('saldo')">SALDO <i class="fas fa-sort"></i></th>
                        <th (click) = "sort('ganancia')">GANANCIA <i class="fas fa-sort"></i></th>
                        <th (click) = "sort('zona')">ZONA <i class="fas fa-sort"></i></th>
                        <!-- <th></th>
                        <th>ACCIONES</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of comercios.items | orderBy: key: reverse">
                        <td>{{item.id}}</td> <!--(click)="verPasacupo(item)" style="cursor: pointer;" -->
                        <td>{{item.razon_social}} / {{item.nombre}}</td>
                        <td style="color:red"><b>${{item.saldo | decimal}}</b></td>
                        <td style="color: green;"><b>${{item.ganancia | decimal}}</b></td>
                        <td>{{item.zona}}</td>
                        <!-- <td></td>
                         <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="verPasacupo(item)" type="button" class="dropdown-item"><i class="fas fa-search"></i> Asignar Pasa Cupo</button>
                            </div>
                          </div>                         
                        </td> -->
                      </tr>
  
                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="comercios.current > 1">
                      <button type="button" class="page-link" (click)="changePage(comercios.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': comercios.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="comercios.current < comercios.total_page">
                      <button type="button" class="page-link" (click)="changePage(comercios.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{comercios.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>   
        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  