import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-operaciones',
  templateUrl: './operaciones.component.html',
  styleUrls: ['./operaciones.component.css']
})
export class OperacionesComponent implements OnInit {

  cargando: boolean =false;
  statusMensajeroHoy: any;
  countPuntos: any;
  id: any;
  perfil_id: any;

  constructor(private servicios: ServicesService,
    public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoStatus();
  }

  listadoStatus(){
    this.cargando = true;
      this.servicios.getStatus(this.id+'-'+this.perfil_id).subscribe( (data) => {
        this.statusMensajeroHoy = data['mensajeroHoy'];
        this.countPuntos = data['countPuntos'];
        
        // this.cargando = false;
        // console.log(data['comercios']);
      });    
  }

}
