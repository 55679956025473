
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

    <div class="content-wrapper">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Recaudo</h1>
              </div>
            </div>
          </div>
      </div>

        <div class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Crear Recaudo</h3>
                  </div>
                  <form [formGroup]="form" (ngSubmit)="EnviarRecaudo()">
                    <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-6">
                              <label>Fecha</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.fecha_recaudo.errors }"
                                formControlName="fecha_recaudo" 
                                class="form-control" 
                                type="date" 
                                value="2011-08-19" 
                                id="fecha_recaudo">
                                <div *ngIf="submitted && f.fecha_recaudo.errors" class="invalid-feedback">
                                  <div *ngIf="f.fecha_recaudo.errors.required">Campo obligatorio</div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                              <label>Hora</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.hora_recaudo.errors }"
                                formControlName="hora_recaudo" 
                                class="form-control" 
                                type="time" 
                                value="13:45:00" 
                                id="hora_recaudo">
                                <div *ngIf="submitted && f.hora_recaudo.errors" class="invalid-feedback">
                                  <div *ngIf="f.hora_recaudo.errors.required">Campo obligatorio</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                              <div style="margin-top:0%" class="form-group">
                                  <label>Nombre <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.nombre_recaudo.errors }"
                                  formControlName="nombre_recaudo" 
                                  type="text" 
                                  class="form-control" 
                                  name="nombre_recaudo" 
                                  required>
                                  <div *ngIf="submitted && f.nombre_recaudo.errors" class="invalid-feedback">
                                    <div *ngIf="f.nombre_recaudo.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
                            </div> 

                            <div class="col-md-6">
                              <div class="form-group">
                                  <label>Celular <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.celular_recaudo.errors }"
                                  name="celular_recaudo" 
                                  type="number" 
                                  class="form-control" 
                                  formControlName="celular_recaudo">
                                  <div *ngIf="submitted && f.celular_recaudo.errors" class="invalid-feedback">
                                    <div *ngIf="f.celular_recaudo.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                  <label>Correo <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.correo_recaudo.errors }"
                                  name="correo_recaudo" 
                                  type="email" 
                                  class="form-control" 
                                  formControlName="correo_recaudo">
                                  <div *ngIf="submitted && f.correo_recaudo.errors" class="invalid-feedback">
                                    <div *ngIf="f.correo_recaudo.required">El Email es obligatorio</div>
                                  </div>
                              </div>
                            </div> 

                            <div class="col-md-6">
                              <div class="form-group">
                                  <label>Recaudo <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.recaudo.errors }" 
                                  type="text" 
                                  name="recaudo" 
                                  class="form-control" 
                                  formControlName="recaudo">
                                  <div *ngIf="submitted && f.recaudo.errors" class="invalid-feedback">
                                    <div *ngIf="f.recaudo.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                  <label>Referencia <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.referencia_recaudo.errors }" 
                                  type="text" 
                                  name="referencia_recaudo" 
                                  class="form-control" 
                                  formControlName="referencia_recaudo">
                                  <div *ngIf="submitted && f.referencia_recaudo.errors" class="invalid-feedback">
                                    <div *ngIf="f.referencia_recaudo.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                  <label>valor <span style="color: red;">*</span></label>
                                  <input [ngClass]="{ 'is-invalid': submitted && f.valor_recaudo.errors }" 
                                  type="text" 
                                  name="valor_recaudo" 
                                  class="form-control" 
                                  formControlName="valor_recaudo">
                                  <div *ngIf="submitted && f.valor_recaudo.errors" class="invalid-feedback">
                                    <div *ngIf="f.valor_recaudo.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <button type="submit" class="btn btn-primary float-right">Guardar</button>
                    </div>
                  </form>
                </div>
  
              </div>
            </div>
          </div>
      </div>
    </div> 
    <app-footer-main></app-footer-main>  
</div>

