import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operaciones-ecommerce',
  templateUrl: './operaciones-ecommerce.component.html',
  styleUrls: ['./operaciones-ecommerce.component.css']
})
export class OperacionesEcommerceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
