import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-productos-publiexpress',
  templateUrl: './productos-publiexpress.component.html',
  styleUrls: ['./productos-publiexpress.component.css']
})
export class ProductosPubliexpressComponent implements OnInit {


  comercio_id:any;
  perfil_id:any;
  cargando:boolean = false;
  editar:boolean = false;
  productos: any = [];
  itemImp: any = [];
  pagesNumber: any;
  offset= 3;

  constructor(private servicios: ServicesService, private router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.perfil_id);
    this.listadoProductos();
  }


  listadoProductos(page = null){
    this.productos = [];
    this.ForpagesNumber();
    if(!page){
      this.servicios.getPubliexpressProducto(this.perfil_id+''+page).subscribe( (data) => {
        // console.log(data);
        this.productos = data['obj'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
        this.servicios.getPubliexpressProducto(this.perfil_id+'-'+page).subscribe( (data) => {
        this.productos = data['obj'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }    
  }

  ForpagesNumber() {
    let from = this.productos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.productos.total_page){
      to = this.productos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  onVer(item){
    let navigationExtras: NavigationExtras = {
      state: {
        editar: true,
        item: item
      }
    }
    this.router.navigate(['/publiexpress/ver'],navigationExtras);
  }


}
