<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content">
           <div class="row">
             <div class="col-md-12">
              <div class="card contentCenter">
                <div class="card-body">
                  <div>
                    <span id="logo-plataforma" class="logo-lg text-center">
                      <!-- <h6>Este ticket no es valido como comprobante de transacción bancaria</h6> -->
                      <img  src="assets/img/logo-mensajero.png" style="display:block;margin:auto;width: 200px;">
                    </span>
                    <hr>
                    <div><span style="font-weight: bold;">Recibo Nº: </span><span> {{ItemImprimirOrden.id}}</span></div>
                    <div><span style="font-weight: bold;">Fecha: </span><span> {{ItemImprimirOrden.fecha}}</span></div>
                    <div><span style="font-weight: bold;">Hora: </span><span> {{ItemImprimirOrden.hora}}</span></div>
                    <div><span style="font-weight: bold;">Nombre: </span><span> {{ItemImprimirOrden.nombre}}</span></div>
                    <div><span style="font-weight: bold;">Celular: </span><span> {{ItemImprimirOrden.celular}}</span></div>
                    <!-- <div><span style="font-weight: bold;">Correo: </span><span> {{ItemImprimirOrden.email}}</span></div> -->
                    <div *ngIf="ItemImprimirOrden.banco"><span style="font-weight: bold;">Banco: </span><span> {{ItemImprimirOrden.banco}}</span></div>
                    <div *ngIf="ItemImprimirOrden.tipo"><span style="font-weight: bold;">Tipo: </span><span> {{ItemImprimirOrden.tipo}}</span></div>
                    <div *ngIf="ItemImprimirOrden.numero_cuenta"><span style="font-weight: bold;">Numero Cuenta: </span><span> {{ItemImprimirOrden.numero_cuenta}}</span></div>
                    <div *ngIf="ItemImprimirOrden.numero_convenio"><span style="font-weight: bold;">Convenio: </span><span> {{ItemImprimirOrden.numero_convenio}}</span></div>
                    <div *ngIf="ItemImprimirOrden.recaudo_empresa"><span style="font-weight: bold;">Recaudo: </span><span> {{ItemImprimirOrden.recaudo_empresa}}</span></div>
                    <div *ngIf="ItemImprimirOrden.recaudo_empresa_nombre"><span style="font-weight: bold;">Empresa Recaudo: </span><span> {{ItemImprimirOrden.recaudo_empresa_nombre}}</span></div>
                    <div *ngIf="ItemImprimirOrden.recaudo_referencia"><span style="font-weight: bold;">Referencia: </span><span> {{ItemImprimirOrden.recaudo_referencia}}</span></div>
                    <div *ngIf="ItemImprimirOrden.punto"><span style="font-weight: bold;">Punto: </span><span> {{ItemImprimirOrden.punto}}</span></div>
                    
                    <div><span style="font-weight: bold;">Valor: </span><span>$ {{ItemImprimirOrden.valor | decimal}}</span></div>
                    <!-- <p style="font-weight: bold;text-align: center;margin-top: 5%;">En un tiempo estimado de 5 a 20 minutos llegará su confirmación.</p> -->
                    <!-- <p style="font-weight: bold;text-align: center;">Mensajero Virtual  - 2020</p> -->
                  </div>
                </div>
              </div>
            </div>
           </div>

            <div class="row d-print-none" style="margin-bottom: 5%">
                <div class="col-md-12 mt-4" style="display: flex;justify-content: center;align-items: center;">
                  <button (click)="print()" style="margin-left: -1%;" type="button" class="btn btn-primary btn-sm btn-lg">
                    <span style="font-size: 18px;">Imprimir</span>
                  </button>
                  <button (click)="NuevaOperacion()" style="margin-left: 1%;" type="button" class="btn btn-primary btn-sm btn-lg">
                    <span style="font-size: 18px;">Nueva Operación</span>
                  </button>
                </div>
            </div>
        </div>
      </div>
      
      <app-footer-main></app-footer-main>  
  </div> 