import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-navbar-search-form',
  templateUrl: './navbar-search-form.component.html',
  styleUrls: ['./navbar-search-form.component.css']
})
export class NavbarSearchFormComponent implements OnInit {

  id: any;
  perfil_id: any;
  status: any = [];

  constructor(private servicios: ServicesService) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.perfil_id);
    

    this.listadoStatus();
  }

  listadoStatus(){
    // this.cargando = true;
      this.servicios.getStatusSaldosGanancias(this.id).subscribe( (data) => {
        this.status = data['status'];
        // console.log(this.status);
              
      });    
  }

}
