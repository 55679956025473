
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1>Ventas</h1>
          </div>
          <div class="col-sm-12 mt-3">
            <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/ecommerce/operaciones" class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a>
            <!-- <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/productos/form" class="btn btn-success mr-1">Vender</a> -->
            <!-- <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
            <button type="button" (click)="listadoProductos()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button> -->
          </div>
        </div>
      </div>
    </section>

     

  </div>
    
    <app-footer-main></app-footer-main>  
</div>  

