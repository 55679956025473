import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-productos-publiexpress',
  templateUrl: './ver-productos-publiexpress.component.html',
  styleUrls: ['./ver-productos-publiexpress.component.css']
})
export class VerProductosPubliexpressComponent implements OnInit {
  public nuevoProductoForm: FormGroup;

  comercio_id: any;
  perfil_id: any;
  ver: boolean = false;
  editar: boolean = false;
  id: any;

  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;
  cargando: boolean = false;


  constructor(private servicios: ServicesService, private router: Router, private route: ActivatedRoute) {
    this.nuevoProductoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      ancho: new FormControl(null, Validators.nullValidator),
      largo: new FormControl(null, Validators.nullValidator),
      min_m2: new FormControl(null, Validators.nullValidator),
      max_m2: new FormControl(null, Validators.nullValidator),
      pmt2: new FormControl(null, Validators.nullValidator),
      unidad: new FormControl(null, Validators.nullValidator),
      valor: new FormControl(null, Validators.nullValidator),
      img: new FormControl(null, Validators.nullValidator),
      estado: new FormControl(null, Validators.nullValidator),
    });

    if (this.router.getCurrentNavigation().extras.state) {

      if (this.router.getCurrentNavigation().extras.state.editar) {
        this.editar = true;
        let itemProducto = this.router.getCurrentNavigation().extras.state.item;
        this.id = itemProducto.id;
        this.nuevoProductoForm.get('nombre').setValue(itemProducto.nombre);
        this.nuevoProductoForm.get('ancho').setValue(itemProducto.ancho);
        this.nuevoProductoForm.get('largo').setValue(itemProducto.largo);
        this.nuevoProductoForm.get('min_m2').setValue(itemProducto.min_m2);
        this.nuevoProductoForm.get('max_m2').setValue(itemProducto.max_m2);
        this.nuevoProductoForm.get('pmt2').setValue(itemProducto.pmt2);
        this.nuevoProductoForm.get('unidad').setValue(itemProducto.unidad);
        this.nuevoProductoForm.get('valor').setValue(itemProducto.valor);
        this.nuevoProductoForm.get('estado').setValue(itemProducto.estado);
        this.nuevoProductoForm.get('img').setValue(itemProducto.img);
      }
    }

  }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
  }


  async onSubmit() {
    // console.log(this.nuevoProductoForm.value);
    if (this.nuevoProductoForm.valid) {
      var params = {};
      if (!this.editar) {
        params = {
          nuevo: true,
          nombre: this.nuevoProductoForm.value.nombre,
          ancho: this.nuevoProductoForm.value.ancho,
          largo: this.nuevoProductoForm.value.largo,
          min_m2: this.nuevoProductoForm.value.min_m2,
          max_m2: this.nuevoProductoForm.value.max_m2,
          pmt2: this.nuevoProductoForm.value.pmt2,
          unidad: this.nuevoProductoForm.value.unidad,
          valor: this.nuevoProductoForm.value.valor,
          estado: this.nuevoProductoForm.value.estado,
          img: this.nuevoProductoForm.value.img,
        }
      } else {
        params = {
          editar: true,
          id: this.id,
          nombre: this.nuevoProductoForm.value.nombre,
          ancho: this.nuevoProductoForm.value.ancho,
          largo: this.nuevoProductoForm.value.largo,
          min_m2: this.nuevoProductoForm.value.min_m2,
          max_m2: this.nuevoProductoForm.value.max_m2,
          pmt2: this.nuevoProductoForm.value.pmt2,
          unidad: this.nuevoProductoForm.value.unidad,
          valor: this.nuevoProductoForm.value.valor,
          estado: this.nuevoProductoForm.value.estado,
          img: this.nuevoProductoForm.value.img,
        }
      }

      console.log(params);
      this.servicios.setPubliexpressProducto(params)
        .subscribe((data) => {
          console.log(data);
          if (data["logger"] == true) {
            this.router.navigate(['publiexpress/productos'])
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'El formulario no es válido!',
        showConfirmButton: false,
        timer: 1500,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      });
    }
  }



  preview(files, estado) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // this.field = field;
    this.fileName = files[0].name
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.fileURL = reader.result;
      // console.log("esta es la imagen"+this.fileURL);
      this.GuardarImagen(estado);
    }

  }

  GuardarImagen(estado) {
    if (!this.fileURL) {
      console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL,
        estado: estado
      };
      this.cargando = true;
      this.servicios.enviarImgOrden(params)
        .subscribe((data) => {
          if (data['logger'] == true) {
            let params = {
              nombre: this.fileName,
              archivo: data['filename'],
              ruta: data['ruta'],
              estado: estado
            };
            this.fileURL = data['filename'];
            if (estado == 2) {
              //img destacada
              this.nuevoProductoForm.get('img').setValue(data['filename']);
            }
            if (estado == 1) {
              //img galeria
              this.listadjuntos.push(params);
              console.log(this.listadjuntos);
            }
            this.cargando = false;
          }
        });
    }
  }

}