import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-listado-afiliaciones',
  templateUrl: './listado-afiliaciones.component.html',
  styleUrls: ['./listado-afiliaciones.component.css']
})
export class ListadoAfiliacionesComponent implements OnInit {
  cargando: boolean =false;
  id: any;
  perfil_id:any;
  afiliaciones: any = [];
  compensacionesHistorial: any = [];
  compensacionesReporte: any = [];
  pagesNumber: any;
  offset= 3;
  ordenItem: any = {};
  imgURL: any;
  buscar: any;
  page: any;
  ReporteExport: any = {};
  fecha:any;  
  nota:any;
  
  imagePath:any;
  fileName:any;
  fileURL:any;
  listadjuntos:any = [];
  
  constructor(private servicios: ServicesService,
    public router: Router) { }

    ngOnInit(): void {
      this.id = localStorage.getItem('id');
      this.perfil_id = localStorage.getItem('perfil_id');
      // console.log(this.id);
      // console.log(this.perfil_id);
      

      
      
  
      this.listadoAfiliciones();
    }
    changePage(page) {
      this.afiliaciones.current = page;
      this.listadoAfiliciones(page);        
    }

    listadoAfiliciones(page = null){
      this.cargando = true;
      if(!page){
        this.servicios.getAfiliaciones(this.id+'-'+this.perfil_id+'-'+1).subscribe( (data) => {
          this.afiliaciones = data['afiliaciones'];
          this.fecha = this.afiliaciones.fecha;
          
          this.cargando = false;
          // console.log(data);
          this.ForpagesNumber()
        });
      }else{
          this.servicios.getAfiliaciones(this.id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
          this.afiliaciones = data['afiliaciones'];
          this.fecha = this.afiliaciones.fecha;
  
          this.cargando = false;
          // console.log(data);
          this.ForpagesNumber()
        });
      }
      this.cargando = false;     
    }
    
    ForpagesNumber() {
      let from = this.afiliaciones.current - this.offset;
      if(from < 1){
        from = 1;
      }
  
      let to = from + (this.offset * 2); 
      if(to >= this.afiliaciones.total_page){
        to = this.afiliaciones.total_page;
      }
  
      let pagesArray = [];
      while(from <= to){
        pagesArray.push(from);
        from++;
      }
      this.pagesNumber = pagesArray;
      // return pagesArray;
    } 

    VerOrden(item){
      this.ordenItem = item;
      this.nota = item.nota;
      console.log(item);
    }

    cerrarmodal(info){
      this.imgURL = '';
      this.ordenItem = '';
      this.nota = '';
      // document.getElementById(info).style.display = "none";
      if(info == 'modalVer'){
        $('#modalVer').modal('hide');
      }
    } 

    estadoAfilicion(estado,AfiliacionId){
      let params = {
        editarEstado: true,
        estado: estado,
        id: AfiliacionId,
        operador_comercio_id: this.id,
        nota: this.nota,
        adjuntos: this.listadjuntos
      }      
      this.servicios.setAfiliacion(params).subscribe( (data) => {
        // console.log(data);
        if (data["logger"] == true){
          // document.getElementById('estadoCorresponsal').style.display="none";
  
          // this.router.navigate(['../compensaciones/nuevo'])
          this.cerrarmodal('modalVer');
          this.listadoAfiliciones();
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }
      }); 
         
    }

  preview(files) {
    // this.cargando = true;
    // console.log('entrando');
    // console.log(files);
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // if (mimeType.match(/application\/pdf/) || mimeType.match(/image\/png/) || mimeType.match(/image\/jpeg/) ||  mimeType.match(/image\/jpg/)  == null) {
    //   this.message = "No hay Archivo.";
    //   return;
    // }
    var reader = new FileReader();
    this.imagePath = files;
    this.fileName = files[0].name
    // console.log(this.fileName);
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      this.saveAdjunto();
    }
  }

  saveAdjunto(){
    if (!this.fileURL){
        // console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL
      };
      this.cargando = true;
      this.servicios.setFile(params)
      .subscribe( (data) => {
        // console.log(data);
        
        if(data['logger'] == true){
          let params = {
            nombre: this.fileName,
            archivo: data['filename'],
            folder: data['folder'],
            tipo: data['tipo'],
            ruta: data['ruta'],
          };
          this.listadjuntos.push(params);
          console.log(this.listadjuntos)
          this.cargando = false;
        } else {
          // this.alerta(data['msg'], 'error');
          this.cargando = false;
        }
      });
    }
  }

  deleteAdjunto(i: number,itemAdjunto){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea borrar el archivo `+itemAdjunto.nombre,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo!',
    }).then((result) => {
      if (result.value) {
        this.listadjuntos.splice(i,1);       
      }
    });       
}

}
