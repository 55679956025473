<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>

  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h3><a routerLink="/publiexpress/operaciones" class="btn btn-default mr-1"><i
                  class="fa fa-angle-left"></i></a> Cotizador</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <!-- SELECT PRODUCTO -->
            <div class="card">
              <div class="card-body">
                <h5>Seleccione producto</h5>
                <ul class="list-group mt-3" id="productoListado">
                  <button type="button" *ngFor="let item of obj.items; let i = index"
                    class="list-group-item list-group-item-action" (click)="selectProducto(item)">{{item.nombre |
                    titlecase}}</button>
                </ul>
                <div class="row" *ngIf="productoItem.id">
                  <div class="col-md-2">
                    <img class="img-fluid img-thumbnail" src="{{productoItem.imgUrl}}">
                  </div>
                  <div class="col-md-9">
                    <h4>{{productoItem.nombre | titlecase}} {{productoItem.ancho | titlecase}}x{{productoItem.largo | titlecase}}</h4>
          
                    <div class="row">
                      <div class="col-md-2">
                        <label>Unidad</label>
                        <h5>{{productoItem.unidad | titlecase}}</h5>                        
                      </div>
                      <div class="col-md-2" *ngIf="!productoItem.pmt2">
                        <div class="form-group">
                          <label>Cantidad:</label>
                          <div class="input-group">
                            <input [(ngModel)]="cantidad" (keyup)="calvalor(productoItem)" name="cantidad" type="number" class="form-control float-right">
                          </div>
                        </div>                        
                      </div>

                      <div class="col-md-2" *ngIf="productoItem.pmt2">
                        <div class="form-group">
                          <label>Ancho:</label>
                          <div class="input-group">
                            <input [(ngModel)]="ancho" (keyup)="calvalor(productoItem)" name="ancho" type="number" class="form-control float-right">
                          </div>
                        </div>                        
                      </div>                      
                      <div class="col-md-2" *ngIf="productoItem.pmt2">
                        <div class="form-group">
                          <label>Largo:</label>
                          <div class="input-group">
                            <input [(ngModel)]="largo" (keyup)="calvalor(productoItem)" name="largo" type="number"  class="form-control float-right">
                          </div>
                        </div>                        
                      </div>  

                      <div class="col-md-2" *ngIf="productoItem.pmt2">
                        <div class="form-group">
                          <label>MTS 2:</label>
                          <div class="input-group">
                            {{ancho * largo}} m2
                          </div>
                        </div>                        
                      </div>

                      <div class="col-md-2">
                        <label>Valor</label>
                        <h5  *ngIf="productoItem.valor">${{productoItem.valor | decimal}}</h5>
                        <h5  *ngIf="productoItem.pmt2">${{productoItem.pmt2 | decimal}}</h5>    
                      </div>

                      <div class="col-md-2">
                        <label>Valor Total</label>
                        <h5><span *ngIf="!estado">${{ valorTotal | decimal}}</span> <span style="color: red;">{{estado}}</span></h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button type="button" (click)="deleteProducto()" class="btn btn-lg btn-secondary"><i class="fa fa-trash"></i></button>
                    <button [disabled]="!valorTotal" type="button" (click)="addProducto(productoItem)" class="btn  btn-lg btn-primary mt-1"><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- ORDEN -->
            <div class="card" *ngIf="productoOrdenItemsValorTotal">
              <div class="card-body">
                <h5>Productos cotizados</h5>
                <table class="table table-hover mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Producto</th>
                      <th scope="col" class="text-center">Cantidad</th>
                      <th scope="col"  class="text-right">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of productoOrdenItems; let i = index">
                      <th scope="row">{{i+1}}</th>
                      <td>{{item.nombre | titlecase}}</td>
                      <td class="text-center"><span *ngIf="item.cantidad">{{item.cantidad}} {{item.unidad | titlecase}}</span> <span *ngIf="item.calpmt">{{item.calpmt}} Mt2</span></td>
                      <td class="text-right text-bold">${{item.valor | decimal}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right mt-2"><h4>${{productoOrdenItemsValorTotal | decimal}}</h4></div>
              </div>
            </div>
            <!-- CLIENTE  -->
            <div class="card" *ngIf="productoOrdenItemsValorTotal">
              <div class="card-body">
                <h5>Datos del cliente</h5>
                <div class="row mt-3">
                  <div class="col-md-4">
                    <div class="form-floating">
                      <label for="floatingInputValue">Nombre <span class="text-red">*</span></label>
                      <input [(ngModel)]="nombre" type="text" class="form-control">
                    </div> 
                  </div>
                  <div class="col-md-4">
                    <div class="form-floating">
                      <label for="floatingInputValue">Contacto <span class="text-red">*</span></label>
                      <input [(ngModel)]="contacto" type="number" class="form-control">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-floating">
                      <label for="floatingInputValue">Email <span class="text-red">*</span></label>
                      <input [(ngModel)]="email" type="email" class="form-control">
                    </div>                    
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mt-3">
                      <textarea [(ngModel)]="nota" class="form-control" placeholder="Nota (opcional)" rows="3"></textarea>
                    </div>                    
                  </div>
                  <div class="col-md-12" *ngIf="nombre && contacto">
                    <button [disabled]="!productoOrdenItemsValorTotal" type="button" (click)="newOrden()" class="btn btn-block  btn-lg btn-primary mt-1"><i class="fa fa-plus"></i> Crear orden ${{productoOrdenItemsValorTotal | decimal}}</button>
                  </div>
                </div>
                <small>luego del registro uno de nuestros asesores de publiexpress tomará la orden y se pondrá en contacto con el cliente</small>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-main></app-footer-main>
</div>