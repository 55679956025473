import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import-csv',
  templateUrl: './import-csv.component.html',
  styleUrls: ['./import-csv.component.css']
})
export class ImportCsvComponent implements OnInit {
  file:any;
  mes: any;
  cargando: any;
  cargandoImportar:any;
  id: any;
  idMes:any;
  comercio_id:any;
  perfil_id: any;
  recaudo_clientes: any = [];
  recaudo_clientes_procesado: any;
  pagesNumber: any;
  offset= 1;

  csvRecords: any[] = [];
  countImportar:any;
  btnImport=false;
  header: boolean = false;

  constructor(public servicios: ServicesService,public router: Router,private ngxCsvParser: NgxCsvParser) { }
  @ViewChild('fileImportInput') fileImportInput: any;

  ngOnInit(): void {
    this.perfil_id = localStorage.getItem('perfil_id');
    this.id = localStorage.getItem('id');
    this.comercio_id = localStorage.getItem('comercio_id');
    // console.log(this.id+' '+this.comercio_id+' '+this.perfil_id);
  }

  selectMes(idMes,mesName){
    this.csvRecords= [];
    this.file= null;
    this.mes = mesName;
    this.idMes = idMes;
    let page = null;
    this.cargando = true;
    if(!page){
      let page = 0;
      this.servicios.getRecaudoClientes(this.id+'-'+idMes+'-'+page).subscribe( (data) => {
        this.recaudo_clientes = data['recaudo_clientes'];
        this.recaudo_clientes_procesado = data['procesado'];
        this.cargando = false;
        // console.log(data['recaudo_clientes']);
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getRecaudo(this.id+'-'+idMes+'-'+1).subscribe( (data) => {
        this.recaudo_clientes = data['recaudo_clientes'];
        this.recaudo_clientes_procesado = data['procesado'];
        this.cargando = false;
        // console.log(data['recaudo']);
        this.ForpagesNumber()

      });
    }  
  }

  ForpagesNumber() {
    let from = this.recaudo_clientes.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.recaudo_clientes.total_page){
      to = this.recaudo_clientes.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
  } 
  


  fileChangeListener($event: any): void {
    const files = $event.srcElement.files;
    this.header = (this.header as unknown as string) === 'false' || this.header === true;

    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        // console.log('Result', result);
        this.csvRecords = result;
        this.countImportar = this.csvRecords.length -1;
        if(this.countImportar <=0){
          this.countImportar = 0;
        }
        // console.log(this.csvRecords);        
        this.btnImport=true;
      }, (error: NgxCSVParserError) => {
        // console.log('Error', error);
      });

  }  


  importar(){
    this.cargandoImportar = true;
    let params = {
      import:true,
      mes: this.idMes,
      comercio_id:this.comercio_id,
      file: this.csvRecords,
    };
    // console.log(params);    
    this.servicios.postImportRecaudo(params).subscribe(data => {
        // console.log(data);
        this.selectMes(this.idMes, this.mes);
        this.cargandoImportar = false;
        this.countImportar = 0;
        if(data["logger"] == true){
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }        

    });    
  }

}
