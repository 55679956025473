import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ancheta',
  templateUrl: './ancheta.component.html',
  styleUrls: ['./ancheta.component.css']
})
export class AnchetaComponent implements OnInit {

  submitted: boolean = false;
  id:any;
  perfil_id:any;
  cargando: boolean =false;
  field:any;

  prospectos:any = [];
  pagesNumber: any;
  itemVer: any = [];
  page: any;
  offset= 3;

  reporteExport:any;
  
  public message: string;
  listServicios: any = [];
  listadjuntos: any = [];
  public imagePath;
  fileURL: any;
  fileName: any;
  
  form = new FormGroup({
    nombre_representante: new FormControl('', Validators.required),
    celular_representante: new FormControl('', Validators.required),
    ciudad: new FormControl('', Validators.required),
    departamento: new FormControl('', Validators.required),
    direccion: new FormControl('', Validators.required),
    nombre_atiende: new FormControl('', Validators.nullValidator),
    celular_atiende: new FormControl('', Validators.nullValidator),
    negocio_complementario: new FormControl('', Validators.nullValidator),
    promedio_venta1: new FormControl('', Validators.nullValidator),
    promedio_venta2: new FormControl('', Validators.nullValidator),
    promedio_venta_venezuela: new FormControl('', Validators.nullValidator),
    promedio_venta_soat: new FormControl('', Validators.nullValidator),
    corresponsal_activo: new FormControl('', Validators.nullValidator),
    pago_express_fintech: new FormControl('', Validators.nullValidator),
    img1: new FormControl('', Validators.required),
    img2: new FormControl('', Validators.required),
    img3: new FormControl('', Validators.required),
    img4: new FormControl('', Validators.nullValidator),
    fotos: new FormControl('', Validators.nullValidator),
  })

  constructor(private servicios: ServicesService) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoProspectos();
  }
  get f() { return this.form.controls; }

  listadoProspectos(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getAncheta(this.id+"-"+this.perfil_id).subscribe( (data) => {
        this.prospectos = data['prospectos'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getAncheta(this.id+"-"+this.perfil_id+"-"+page).subscribe( (data) => {
        this.prospectos = data['prospectos'];
        this.cargando = false;
        this.ForpagesNumber()

      });
    }     
  }
  
  onSubmit(){
    this.submitted = true;
    if(this.listadjuntos.length > 2){
    this.form.get('img1').setValue(this.listadjuntos[0]['archivo']);
    this.form.get('img2').setValue(this.listadjuntos[1]['archivo']);
    this.form.get('img3').setValue(this.listadjuntos[2]['archivo']);
    // this.form.get('img4').setValue(this.listadjuntos[3]['archivo']);

    console.log(this.form);
    if(this.form.valid){
      let params = {
        nuevo: true,
        prospecto: this.form.value
      }
      this.servicios.postAncheta(params)
      .subscribe( (data) => {
        if (data["logger"] == true){
          // this.router.navigate(['inscripcion']);
          // this.form.get('ccorut').setValue(null);
          // this.form.get('cc').setValue(null);
          // this.form.get('img1').setValue(null);
          // this.form.get('img2').setValue(null);
          // this.form.get('img3').setValue(null);
          this.form.reset();
          this.listadjuntos = [];
          this.submitted = false;
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
        } else {
          this.submitted = false;
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }
      });
    }else{
      this.submitted = false;
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ha ocurrido un error al enviar la solicitud. Por favor ingrese todos los campos e intentelo nuevamente',
        showConfirmButton: false,
        timer: 3000,
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      });
    }
  }else{
    this.submitted = false;
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Por favor adjunte las 3 fotos',
      showConfirmButton: false,
      timer: 3000,
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      }
    });
  }  
  }

  ForpagesNumber() {
    let from = this.prospectos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.prospectos.total_page){
      to = this.prospectos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  changePage(page) {
    // console.log(page);
    this.page = page; //para el filtro
    this.prospectos.current = page;

    // this.filtro();
    this.listadoProspectos(page);       
  }
  
  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    // this.field = field;
    this.fileName = files[0].name
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.fileURL = reader.result; 
      // console.log("esta es la imagen"+this.fileURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen(){
    if(!this.fileURL) {
      console.log('error');
      return;
    } else {
      let params = {
        archivo: this.fileURL
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          let params = {
            nombre: this.fileName,
            archivo: data['filename'],
            ruta: data['ruta'],
          };
          this.fileURL = data['filename'];
          this.listadjuntos.push(params);
          // if (this.field == 'ccorut') this.form.get('ccorut').setValue(this.fileURL);
          // if (this.field == 'cc') this.form.get('cc').setValue(this.fileURL);
          // if (this.field == 'img1') this.form.get('img1').setValue(this.fileURL);
          // if (this.field == 'img2') this.form.get('img2').setValue(this.fileURL);
          // if (this.field == 'img3') this.form.get('img3').setValue(this.fileURL);
          // console.log("img:::"+this.fileURL);
          // console.log("field:::"+this.field);
          Swal.fire({
            icon: 'success',
            title: 'El archivo se ha cargado exitosamente!',
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }
      });
    }
  }

  deleteAdjunto(i: number,itemAdjunto){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea borrar el archivo `+itemAdjunto,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Eliminalo!',
    }).then((result) => {
      if (result.value) {
        this.listadjuntos.splice(i,1);       
      }
    });       
}

ver(item){
  this.itemVer = item;
}

cerrarmodal(){
  this.itemVer = '';
}


exportExcel(){
  let params = {
    export: true,
    expsorteo: true
  }
  // console.log(params);
  this.servicios.postAncheta(params)
  .subscribe( (data) => {
    this.reporteExport = data['prospectos'];
    console.log(this.reporteExport);
    
    
    const fileName = 'reporte_prospectos.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);


    // ANCHO COLUMNAS
    ws['!cols'] = [ {width:2},{width:2},{width:30}, {width:20},{width:20},{width:50},{width:20},{width:30},{width:20},{width:40},{width:20},{width:20},{width:20},{width:20},{width:20},{width:40},{width:40},{width:40},{width:40},{width:30},{width:30},{width:20}];
    // ELIMINA COLUMNA1
    ws['!cols'][0] = { hidden: true };
    ws['!cols'][1] = { hidden: true };
    ws['!cols'][6] = { hidden: true };
    ws['!cols'][12] = { hidden: true };
    ws['!cols'][13] = { hidden: true };
    ws['!cols'][14] = { hidden: true };
    ws['!cols'][15] = { hidden: true };
    ws['!cols'][16] = { hidden: true };
    ws['!cols'][17] = { hidden: true };
    // ws['G2'] = { Target:"#E2" };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // CAMBIA CABECERA
    // XLSX.utils.sheet_add_aoa(ws, [["log","Id","NOMBRE","DEPARTAMENTO","CIUDAD","DIRECCION","ZONA","CORREO","CELULAR","TIPO NEGOCIO","ANTIGUEDAD","FLUJO CLIENTES","CALIFICACIÓN","IMAGEN ","IMAGEN 2","IMAGEN 3"," ","FECHA","ASESOR"]]);
    // XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  
  
    XLSX.utils.book_append_sheet(wb, ws, 'reporte');
    XLSX.writeFile(wb, fileName);

  }); 
}

onEliminar(item) {
  Swal.fire({
    title: '¿Está seguro?',
    text: `Desea borar la inscripcion de ${item.nombre_representante}`,
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Sí, Eliminar!',
  }).then((result) => {
    if (result.value) {
      let params = {
        id: item.id,
        editarEstado: true,
        estado: 0,
      }
      // console.log(params);
      this.servicios.postAncheta(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.listadoProspectos();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });

          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
        });
    }
  });
}

}
