<div class="wrapper">
   <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12 mb-2">
                <h1>Modulo Afiliaciones</h1>
              </div>
              <div class="col-md-12">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                  <button type="button" *ngIf="cargando" class="btn btn-secondary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>                              
                    <button type="button" (click)="listadoAfiliciones()"  *ngIf="!cargando" class="btn btn-secondary"><i class="fa fa-sync"></i> </button>                              
                    <span *ngIf="perfil_id !=7">
                      <button type="button" routerLink="/afiliaciones/nueva" class="btn btn-primary ml-1"><i class="fa fa-plus"></i> Nueva Solicitud</button>                                
                    </span>

                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul class="navbar-nav mr-auto">
                      <!-- <li class="nav-item">
                        <span class="navbar-text ml-2" style="font-weight: 400;">Total Comercios</span> {{compensaciones.size}}
                      </li>
                      <li class="nav-item">
                        <span class="navbar-text ml-2" style="font-weight: 400;">Bloqueados </span> {{compensaciones.countBloqueado}}
                      </li> -->
                      
                      <!-- <li class="nav-item">
                        <span class="navbar-text ml-2" style="font-weight: 400;">Compensación Semanal</span> {{compensaciones.countSemana}}                        
                      </li> -->

                      <!-- <li class="nav-item dropdown ml-2">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Exportar
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <button type="button" (click)="exportar('PTM')" class="dropdown-item"><i class="fa fa-file-export"></i> PTM</button>                    
                          <button type="button" (click)="exportar('DISTRICOL')" class="dropdown-item"><i class="fa fa-file-export"></i> DISTRICOL</button>
                        </div>
                      </li> -->

                    </ul>
                    <form class="col-md-4  form-inline my-2 my-lg-0">
                      <!-- <input style="width:100%" [(ngModel)]="buscar" name="buscar" type="text" (change)="filtro()" placeholder="Buscar por código comercio" class="form-control float-right text-uppercase"> -->
                    </form>
                  </div>
                </nav>
              </div>
              
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Servicio</th>
                        <th>Cliente</th>
                        <th>Documento</th>
                        <th>Contacto</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of afiliaciones.items">
                        <td (click)="VerOrden(item)" data-toggle="modal" data-target="#modalVer" style="cursor: pointer;">{{item.servicio_nombre}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.cedula}}</td>
                        <td>{{item.celular}}</td>
                        <td><h5><span class="badge bg-success" *ngIf="item.estado ==3">Aprobado</span><span class="badge bg-danger" *ngIf="item.estado ==4">Rechazado</span><span class="badge bg-warning" *ngIf="item.estado ==1 || item.estado ==2">En proceso</span></h5></td>
                        <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="VerOrden(item)" data-toggle="modal" data-target="#modalVer"  type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver</button>
                              <!-- <button (click)="EditarComercio(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button> -->
                              <!-- <div class="dropdown-divider"></div> -->
                              <!-- <button (click)="EliminarComercio(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button> -->
                            </div>
                          </div>                         
                        </td>
                      </tr>

                    <!--Modal Ver  -->
                    <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                      <div role="document" [ngClass]="!ordenItem.recaudo_id_comercio ? 'modal-dialog modal-lg' : 'modal-dialog modal-md'">
                        <div class="modal-content">
                          <div [ngClass]="{'bg-warning': ordenItem.estado == 1, 'bg-primary':ordenItem.estado == 2, 'bg-success':ordenItem.estado == 3, 'bg-danger':ordenItem.estado == 4 }" class="modal-header ">
                            <h5 class="modal-title">
                              Solicitud {{ordenItem.id}}
                              <span class="bg-warning" style="padding: 5px;" *ngIf="ordenItem.estado == 1"><b>- Pendiente</b></span> 
                              <span class="bg-primary" style="padding: 5px;" *ngIf="ordenItem.estado == 2"><b>- Procesando</b></span> 
                              <span class="bg-success" style="padding: 5px;" *ngIf="ordenItem.estado == 3"><b>- Procesado</b></span> 
                              <span class="bg-danger" style="padding: 5px;" *ngIf="ordenItem.estado == 4"><b>- Cancelada</b></span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>                            
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-12"><h4>Información Básica Personal</h4></div>                              

                              <div class="col-md-12">Servicio: {{ordenItem.servicio_nombre}}</div>
                              <div class="col-md-6">Nombre: {{ordenItem.nombre}}</div>
                              <div class="col-md-6">Cedula: {{ordenItem.cedula}}</div>
                              <div class="col-md-6">Dirección: {{ordenItem.direccion}} {{ordenItem.ciudad}}</div>
                              <div class="col-md-6">Contacto: {{ordenItem.celular}}</div>
                              <div class="col-md-6">Email: {{ordenItem.correo}}</div>
                            </div>

                            <div class="row mt-5">
                              <div class="col-md-12"><h4>Información Adicional y Adjuntos</h4></div>                              
                            <!-- SERVICIO 1 ARL-->
                            <div class="col-6" id="servicio1" *ngIf="ordenItem.servicio_id ==1">

                              <div class="form-group">
                                <label>Cargo o Función</label>
                                <p>{{ordenItem.cargo_funcion}}</p>
                              </div> 


                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.certificado_medico_laboral"  id="op2" value="option2" disabled>
                                <label class="form-check-label" for="op2">
                                  Certificado Médico Ocupacional <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.contrato_laboral" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  Contrato laborar (opcional) <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.cedula_150" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div> 
                            </div> 

                            <!-- SERVICIO 2 EPS -->
                            <div class="col-6" id="servicio2" *ngIf="ordenItem.servicio_id ==2">

                              <div class="form-group">
                                <label>Beneficiario</label>
                                <input [(ngModel)]="ordenItem.beneficiario"  type="text" class="form-control" placeholder="">
                              </div>   

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.cedula_150" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div> 

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hm7_registro" id="op1" value="option1" disabled>
                                <label class="form-check-label" for="op1">
                                  Hijos menores 7 años registro civil <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hm7_18_registro" id="op2" value="option2" disabled>
                                <label class="form-check-label" for="op2">
                                  Hijos mayores a 7 años menores 18 tarjeta y registro <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hm18_25_cedula" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  De 18 a 25 Cedula <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.cedula_150" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div>                               

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.casado_registro_cedula" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  Casados Registro Civil Matriminio y Copia cedula cónyuge <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.union_libre_declaracion_jurada" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  Unión libre declaración juramentada <b>Adjuntar</b>.
                                </label>
                              </div>
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.padres_registro_civil_como_hijo" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  Padres Registro Civil como hijo <b>Adjuntar</b>.
                                </label>
                              </div> 
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hermanos_discapacitados" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  Hermanos discapacitados declaración <b>Adjuntar</b>.
                                </label>
                              </div>                               

                            </div>    
                            

                            <!-- SERVICIO 3 PENSION-->
                            <div class="col-6" id="servicio2" *ngIf="ordenItem.servicio_id ==3">
                              <div class="form-group">
                                <label>Tipo Afiliación</label>
                                <select [(ngModel)]="ordenItem.primera_vez_o_traslado" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="PRIMERA VEZ">PRIMERA VEZ</option>
                                  <option value="TRANSLADO">TRANSLADO</option>
                                </select>
                              </div> 

                              <div class="form-group">
                                <label>Regimen</label>
                                <select [(ngModel)]="ordenItem.regimen" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="PRIMERA VEZ">CONTRIBUTIVO</option>
                                  <option value="TRANSLADO">SUBSIDIADO</option>
                                </select>
                              </div>                               

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.formulario_fondo" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Formulario según Fondo <b>Adjuntar</b>.
                                </label>
                              </div>
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.cedula_150" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div>                              
                             
                            </div> 
                            
                            <!-- SERVICIO 4 PILA -->
                            <div class="col-6" id="servicio2" *ngIf="ordenItem.servicio_id ==4">

                              <div class="form-group">
                                <label>Mes a Cancelar</label>
                                <input [(ngModel)]="ordenItem.mes_a_cancelar"  type="month" class="form-control" placeholder="">
                              </div>   

                              <div class="form-group">
                                <label>Novedad</label>
                                <select [(ngModel)]="ordenItem.novedades_ingreso_retiro" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="Ingreso">Ingreso</option>
                                  <option value="Retiro">Retiro</option>
                                </select>
                              </div>                               

                              <div class="form-group">
                                <label>Nivel Riesgo</label>
                                <select [(ngModel)]="ordenItem.nivel_riesgo" class="form-control">
                                  <option value="">SELECCIONE</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div> 

                              <div class="form-group">
                                <label>Base de Cotización</label>
                                <input [(ngModel)]="ordenItem.base_cotizacion"  type="text" class="form-control" placeholder="">
                              </div>                               
                              

                              
                              <div class="form-check">
                                <input class="form-check-input" [(ngModel)]="ordenItem.cedula_150"  type="checkbox" name="exampleRadios" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div>                              
                             
                            </div>  
                            
                            
                            <!-- SERVICIO 5 CAJA COMPENSACION-->
                            <div class="col-6" id="servicio1" *ngIf="ordenItem.servicio_id ==5">

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.cedula_150" id="op4" value="option4" disabled>
                                <label class="form-check-label" for="op4">
                                  Copia Cedula al 150% <b>Adjuntar</b>.
                                </label>
                              </div> 

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hm7_registro" id="op1" value="option1" disabled>
                                <label class="form-check-label" for="op1">
                                  Hijos menores 7 años registro civil <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hm7_18_registro" id="op2" value="option2" disabled>
                                <label class="form-check-label" for="op2">
                                  Hijos mayores a 7 años menores 18 tarjeta y registro <b>Adjuntar</b>.
                                </label>
                              </div>

                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.hm18_25_cedula" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  De 18 a 25 Cedula <b>Adjuntar</b>.
                                </label>
                              </div>  
                              
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="ordenItem.casado_registro_cedula" id="op3" value="option3" disabled>
                                <label class="form-check-label" for="op3">
                                  Casados Registro Civil Matriminio y Copia cedula cónyuge <b>Adjuntar</b>.
                                </label>
                              </div>                                

                            </div>  
                            
                            <div class="col-6">
                              <div><h4>Adjuntos</h4></div>                                 
                              <div  *ngIf="ordenItem.servicio_id">
                                <!-- ADJUNTOS -->                                
                                <div class="list-group" *ngFor="let itemAdjunto of ordenItem.adjuntos let i = index">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a>
                                    </div>

                                  </div>
                                </div>                                   
                                                        
                              </div>
                            
                              <br>                              
                            </div> 

                            <div class="col-6 mt-4" *ngIf="perfil_id == 1 || perfil_id ==7">
                              <div>
                                <div><b>{{ordenItem.punto | titlecase}}</b></div>
                                <div>Servicio <b>${{ordenItem.servicio_costo | decimal}}</b></div>
                                <div>Comisión <b>${{ordenItem.servicio_comision | decimal}}</b></div>                                
                              </div>                              
                            </div>
                            <form class="col-10 mt-3">
                              <div *ngIf="perfil_id == 7">
                                <div class="form-group" *ngIf="perfil_id == 7 && (ordenItem.estado==2 || ordenItem.estado==1)">
                                <div>
                                  <label for="img" class="form-label">Documentos:</label>
                                  <div *ngIf="cargando" class="spinner-border" role="status"></div>
                                </div>
                                <input type="file" class="form-control" #file accept='application/pdf, image/png, image/jpeg, image/jpg' (change)="preview(file.files)">
                              </div>
                                <div class="list-group" *ngFor="let itemAdjunto of listadjuntos let i = index">
                                  <div class="row">
                                    <div class="col-md-11">
                                      <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a>
                                    </div>
                                    <div  *ngIf="perfil_id == 7" class="col-md-1 ">
                                      <button class="btn btn-sm btn-secondary" type="button" (click)="deleteAdjunto(i,itemAdjunto)"><i class="fa fa-trash-alt"></i></button> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="">
                                <label for="nota" class="form-label">Nota</label>
                                <textarea [(ngModel)]="nota" class="form-control" name="nota" rows="3" [disabled]="!((perfil_id == 1 || perfil_id ==7) && (ordenItem.estado==2 || ordenItem.estado==1))"></textarea>
                              </div>
                            </form>
                            <div class="col-md-12 mt-5" *ngIf="(perfil_id == 1 || perfil_id ==7) && (ordenItem.estado==2 || ordenItem.estado==1)">
                              <div class="btn-group" role="group">
                                <button type="button" class="btn btn-success" (click)="estadoAfilicion(3,ordenItem.id)">Aprobado</button>
                                <button type="button" class="btn btn-warning" (click)="estadoAfilicion(2,ordenItem.id)">En proceso</button>
                                <button type="button" class="btn btn-danger" (click)="estadoAfilicion(4,ordenItem.id)">Rechazado</button>
                              </div>
                            </div>


                            </div>                            
                           
                          </div>
                          <div class="modal-footer">
                            <button #CerrarModal (click)="cerrarmodal('modalVer')" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- fin modal -->
  
                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix" > 
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="afiliaciones.current > 1">
                      <button type="button" class="page-link" (click)="changePage(afiliaciones.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': afiliaciones.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="afiliaciones.current < afiliaciones.total_page">
                      <button type="button" class="page-link" (click)="changePage(afiliaciones.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{afiliaciones.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>    


        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
</div>  