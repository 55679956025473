<div class="my-5">
    <div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="perfil_id != 1">
        <div class="col-md-12">
            <div class="card card-default">
                <div class="card-header" style="background: rgb(238, 237, 237);">
                    <h2>
                        Sorteo de franquicia</h2>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre"
                                    style="font-size: 14px;">Nombre</label>
                                <input formControlName="nombre" name="nombre" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="ciudad"
                                    style="font-size: 14px;">Ciudad</label>
                                <input formControlName="ciudad" name="ciudad" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="departamento"
                                    style="font-size: 14px;">Departamento</label>
                                <input formControlName="departamento" name="departamento" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="direccion"
                                    style="font-size: 14px;">Dirección</label>
                                <input formControlName="direccion" name="direccion" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="celular"
                                    style="font-size: 14px;">Celular</label>
                                <input formControlName="celular" name="celular" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="email"
                                    style="font-size: 14px;">Correo electrónico</label>
                                <input formControlName="email" name="email" type="email" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!-- <div class="form-group">
                                <label style="font-size: 14px;">Tipo de negocio</label>
                                <div class="input-group">
                                <select formControlName="tipo_negocio" name="tipo_negocio" class="form-control float-right">
                                    <option value=''><b>Seleccione</b></option>
                                    <option value="Oficina">Oficina</option>
                                    <option value="Drogueria">Drogueria</option>
                                    <option value="Internet">Internet</option>
                                    <option value="Peluqueria">Peluqueria</option>
                                    <option value="Papeleria">Papeleria</option>
                                    <option value="Tienda">Tienda</option>
                                    <option value="Otros">Otros</option>
                                </select>
                                </div>
                            </div>                                 -->
                            
                            <div class="form-group">
                                <label for="tipo_negocio"
                                    style="font-size: 14px;">Tipo de negocio</label>
                                <input formControlName="tipo_negocio" name="tipo_negocio" type="text" class="form-control">
                            </div>
                        </div>  
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="antiguedad"
                                    style="font-size: 14px;">Antiguedad</label>
                                <input formControlName="antiguedad" name="antiguedad" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="flujo_clientes"
                                    style="font-size: 14px;">Flujo de clientes</label>
                                <input formControlName="flujo_clientes" name="flujo_clientes" type="text" class="form-control">
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label style="font-size: 14px;">Camara y Comercio o RUT <b>Adjuntar</b></label>
                                <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files,'ccorut')"/>                          
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label style="font-size: 14px;">Cédula de identificación <b>Adjuntar</b></label>
                                <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files,'cc')"/>                          
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label style="font-size: 14px;">Imágen interna del negocio <b>Adjuntar</b></label>
                                <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files,'img1')"/>                          
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label style="font-size: 14px;">Imágen frontal del negocio <b>Adjuntar</b></label>
                                <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files,'img2')"/>                          
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label style="font-size: 14px;">Imágen lado izquierdo / derecho del negocio <b>Adjuntar</b></label>
                                <input #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files,'img3')"/>                          
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="ccorut">
                                <label class="form-check-label">
                                  Camara de comercio o RUT <b>Adjuntar</b>.
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="cc">
                                <label class="form-check-label">
                                  Cedula de ciudadania <b>Adjuntar</b>.
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="img1">
                                <label class="form-check-label">
                                  Imágen interna del negocio <b>Adjuntar</b>.
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="img2">
                                <label class="form-check-label">
                                  imágen frontal del negocio <b>Adjuntar</b>.
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="img3">
                                <label class="form-check-label">
                                  Imágen lado derecho / izquierdo del negocio <b>Adjuntar</b>.
                                </label>
                            </div>
                            <input class="form-control" #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files)"/>
                        </div>
                        <div class="col-md-6">
                            <div class="list-group" *ngFor="let itemAdjunto of listadjuntos let i = index">
                                <div class="row">
                                  <div class="col-md-11">
                                    <!-- <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a> -->
                                    {{ itemAdjunto.nombre}}
                                  </div>
                                  <div class="col-md-1 ">
                                    <button class="btn btn-sm btn-secondary" type="button" (click)="deleteAdjunto(i,itemAdjunto)"><i class="fa fa-trash-alt"></i></button> 
                                  </div>
                                </div>
                              </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-check text-center my-2">
                                <input class="form-check-input" type="checkbox" formControlName="uso_datos">
                                <label class="form-check-label">Al realizar la inscripción acepta el uso y tratamiento de los datos aportados.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex justify-content-center">
                            <button [disabled]="!f.uso_datos.value" class="btn btn-lg btn-success" type="submit">Inscribirse</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    
    <div class="col-12" *ngIf="perfil_id == 1">
        <div class="card">
            <div class="card-header">
                <h2>Prospectos Inscritos</h2>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoProspectos()"  *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                <button *ngIf="prospectos.items" (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>
              </div>
          <div class="card-body table-responsive p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo de Negocio</th>
                  <th>Contacto</th>
                  <th>Ciudad</th>
                  <th>Flujo de clientes</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of prospectos.items">
                  <td>{{item.nombre | titlecase}}</td>
                  <td>{{item.tipo_negocio}}</td>
                  <td>{{item.celular}}</td>
                  <td>{{item.ciudad}}</td>
                  <td>{{item.flujo_clientes}}</td>
                  <td>{{item.fecha}}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button (click)="ver(item)" data-toggle="modal" data-target="#modalVer" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver Prospecto</button>
                        <!-- <button (click)="Editar(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button> -->
                        <!-- <div class="dropdown-divider"></div> -->
                        <!-- <button (click)="Eliminar(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button> -->
                      </div>
                    </div>                         
                  </td>
                </tr>

              <!--Modal Ver  -->
              <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                <div role="document" class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header bg-info">
                      <h5 class="modal-title">
                        Detalle Prospecto
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>                            
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-5 text-bold">Nombre:</div>
                            <div class="col-md-7">{{itemVer.nombre | titlecase}}</div>

                            <div class="col-md-5 text-bold">Departamento:</div>
                            <div class="col-md-7">{{itemVer.departamento | titlecase}}</div>

                            <div class="col-md-5 text-bold">Ciudad:</div>
                            <div class="col-md-7">{{itemVer.ciudad | titlecase}}</div>

                            <div class="col-md-5 text-bold">Dirección:</div>
                            <div class="col-md-7">{{itemVer.direccion | titlecase}}</div>

                            <div class="col-md-5 text-bold">Correo:</div>
                            <div class="col-md-7">{{itemVer.correo}}</div>

                            <div class="col-md-5 text-bold">Celular:</div>
                            <div class="col-md-7">{{itemVer.celular}}</div>

                            <div class="col-md-5 text-bold">Tipo Negocio:</div>
                            <div class="col-md-7">{{itemVer.tipo_negocio | titlecase}}</div>

                            <div class="col-md-5 text-bold">Antiguedad:</div>
                            <div class="col-md-7">{{itemVer.antiguedad}}</div>

                            <div class="col-md-5 text-bold">Flujo Clientes:</div>
                            <div class="col-md-7">{{itemVer.flujo_clientes | titlecase}}</div>

                            <div class="col-md-5 text-bold">Fecha:</div>
                            <div class="col-md-7">{{itemVer.fecha}}</div>

                          </div>                                 
                        </div>
                        <div class="col-6">
                            <div><h4>Adjuntos</h4></div>                                 
                            <div>
                              <!-- ADJUNTOS -->                                
                              <div class="list-group" *ngFor="let itemAdjunto of itemVer.adjuntos let i = index">
                                <div class="row">
                                  <div class="col-md-12">
                                    <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a>
                                  </div>

                                </div>
                              </div>                                   
                                                      
                            </div>
                          
                            <br>                              
                          </div> 

                      </div>
                    
                    </div>
                    <div class="modal-footer">
                      <button #CerrarModal (click)="cerrarmodal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>

              </tbody>
            </table>
          </div>


          <!-- paginador -->
          <div class="card-footer clearfix">
            <ul class="pagination pagination-sm m-0 float-right">
              <li class="page-item" *ngIf="prospectos.current > 1">
                <button type="button" class="page-link" (click)="changePage(prospectos.current - 1)">
                  <span>Atras</span>
                </button>
              </li>                  

              <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': prospectos.current == page}">
                <button type="button" class="page-link" (click)="changePage(page)">
                  {{ page }}
                </button>
              </li>                  

              <li class="page-item" *ngIf="prospectos.current < prospectos.total_page">
                <button type="button" class="page-link" (click)="changePage(prospectos.next)">
                  <span>Siguiente</span>
                </button>
              </li>                  
            </ul>
            <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
              <p>Total registros encontrados: <b>{{prospectos.size}}</b></p>
            </div>
          </div>              
          <!-- Fin paginador -->               
        
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>
    </div>
</div>