import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operaciones-publiexpress',
  templateUrl: './operaciones-publiexpress.component.html',
  styleUrls: ['./operaciones-publiexpress.component.css']
})
export class OperacionesPubliexpressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
