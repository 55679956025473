import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {

  @ViewChild('CerrarModal') modalClose;

  fecha_desde: any;
  fecha_hasta: any;
  punto: any;
  id: any;
  perfil_id: any;
  Reporte: any = {};
  ReporteExport: any = {};
  
  puntos: any = [];
  valorTotal:any;

  ordenReporte: any = {};

  pagesNumber: any;
  offset= 1;

  constructor(public servicios: ServicesService,
              public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.listadoPuntos();
    if(this.perfil_id ==4){
      this.punto = this.id;
    }
  }

  ListadoReportes(page = null){
    // console.log(this.punto);
    console.log(page);
    
    if(page ==null){
      page = 1;
    }
    let params = {
      id: this.id,
      perfil_id: this.perfil_id,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
      punto: this.punto,
      pagina: page
    }
    console.log(params);
    this.servicios.getReporte(params)
    .subscribe( (data) => {
      this.Reporte = data['mensajero'];
      this.valorTotal = data['valor_total'];
      console.log(data);
      this.ForpagesNumber()

    });
  }

  VerReporte(item){
    this.ordenReporte = item;
  }

  ForpagesNumber() {
    let from = this.Reporte.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.Reporte.total_page){
      to = this.Reporte.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  changePage(page) {
    // console.log(page);
    this.Reporte.current = page;
    this.ListadoReportes(page);       
  }

  listadoPuntos(){
    // 3CAPAGO
    this.servicios.getPuntos('3CAPAGO').subscribe( (data) => {
      this.puntos = data['comercios'];
      // console.log(this.puntos);
    });    
  }

  exportExcel(){
    let params = {
      export: true,
      id: this.id,
      perfil_id: this.perfil_id,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
      punto: this.punto,
      page: 1
    }
    console.log(params);
    this.servicios.getReporteExport(params)
    .subscribe( (data) => {
      this.ReporteExport = data['mensajero'];
      this.valorTotal = data['valor_total'];
      console.log(data);

  
      // let fileName = "myFilePlus.xlsx";
      // let myWorkSheet = XLSX.utils.json_to_sheet(this.ReporteExport);
      // XLSX.utils.sheet_add_aoa(myWorkSheet, [["Your Mesage Goes Here"]], { origin: 0 });
      // // let merges = myWorkSheet['!merges'] = [{ s: 'A1', e: 'D1' }];
      // let myWorkBook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "myWorkSheet");
      // XLSX.writeFile(myWorkBook, fileName);

      
      
      const fileName = 'reporte.xlsx';  
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.ReporteExport, 
        // {
        //   header: ['id','fecha','hora', 'nombre', 'email', 'valor','estado']
        // }              
      )
        // ws['A1'].v = 'dentro';

        // ws['A2'].v = 'ID'
        // ws['B2'].v = 'Fecha'
        // ws['C2'].v = 'HORA'
        // ws['D2'].v = 'Nombre'
        // ws['E2'].v = 'Email'
        // ws['F2'].v = 'Valor'
        // ws['G2'].v = 'Estado'

        // /* merge cells A1:B1 */
        // var merge = { s: {r:0, c:0}, e: {r:0, c:1} };
        // //var merge = XLSX.utils.decode_range("A1:B1"); // this is equivalent

        // /* generate worksheet */
        // var t = XLSX.utils.aoa_to_sheet(ws);

        // /* add merges */
        // if(!ws['!merges']) ws['!merges'] = [];
        // ws['!merges'].push(merge);



      XLSX.utils.sheet_add_aoa(ws, [["Your Mesage Goes Here"]], { origin: 0 });
  
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(this.ReporteExport, [["Your Mesage Goes Here"]], { origin: 0 });
      let merges = wb['!merges'] = [{ s: 'A1', e: 'G1' }];

    
      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);

    });
    
    
  }

  

}
