import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-multiproducto-comercio',
  templateUrl: './multiproducto-comercio.component.html',
  styleUrls: ['./multiproducto-comercio.component.css']
})
export class MultiproductoComercioComponent implements OnInit {
  
  verComercio: any = {};
  id: any;
  razon_social: any;   
  nombre: any; 
  listProductos: any = [];

  constructor(public router: Router,
              public servicios: ServicesService) {
                if(this.router.getCurrentNavigation().extras.state){
                  this.verComercio = this.router.getCurrentNavigation().extras.state.itemComercio;
                  this.id = this.verComercio['id'];  
                  this.nombre = this.verComercio['nombre'];   
                  this.razon_social = this.verComercio['razon_social'];   
                }

               }

  ngOnInit(): void {
    if(!this.id){
      this.router.navigate(['../comercios/listado']);
    } 
    this.getComercioProductos(this.id);
    // console.log(this.id);
  }

  getComercioProductos(id){
    this.servicios.getComercioProductos(id).subscribe( (data) => {
      this.listProductos = data['productos'];
      console.log(data['productos']);
    });
  }
  
  setComercioProductos(){
    // console.log(this.listProductos);

     let params = {
          nuevo: true,
          id: this.id,
          listProductos: this.listProductos
        }
        console.log(params);
        this.servicios.postComercioProductos(params)
        .subscribe( (data) => {
          console.log(data);

          if (data["logger"] == true){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
            this.router.navigate(['../comercios/listado'])
          } else {

          }
        });

  }

}
