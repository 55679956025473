
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>Ordenes PubliExpress</h1>
            </div>
            <div class="col-sm-12 mt-3">
              <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/publiexpress/operaciones" class="btn btn-default mr-1"><i class="fa fa-angle-left"></i></a>
              <a *ngIf="perfil_id == 1 || perfil_id == 4" routerLink="/publiexpress/cotizador" class="btn btn-success mr-1">Cotizar</a>
              <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
              <button type="button" (click)="listadoProductos()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive p-0">
                <table class="table table-hover" id="">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th></th>
                      <th>NOMBRE</th>
                      <th class="text-center">CONTACTO</th>
                      <th class="text-center">EMAIL</th>
                      <th class="text-center">ASESOR</th>
                      <th class="text-center">ESTADO</th>
                      <th class="text-center">VALOR</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of productos.items; let i = index">
                      <td>{{i+1}}</td>
                      <td (click)="onVer(item)" style="cursor: pointer;"><img *ngIf="item.imgUrl" style="margin-top:1%" src="{{item.imgUrl}}" height="50px" width="" class=""></td>
                      <td (click)="onVer(item)" style="cursor: pointer;">{{item.nombre | titlecase}}</td>
                      <td class="text-center">{{item.contacto | titlecase}}</td>
                      <td class="text-center">{{item.email | titlecase}}</td>
                      <td class="text-center">{{item.usuario_nombre | titlecase}}</td>
                      <td class="text-center">
                        <span *ngIf="item.estado == 1">Cotización</span>
                        <span *ngIf="item.estado == 2">Pte Infomarción</span>
                        <span *ngIf="item.estado == 3">Aprobado</span>
                        <span *ngIf="item.estado == 4">En proceso</span>
                        <span *ngIf="item.estado == 5">Finalizado</span>
                      </td>
                      <td class="text-center">${{item.valor_total | decimal}}</td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button (click)="onVer(item)" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver detalle</button>
                            <button *ngIf="perfil_id == 1 && item.estado == 1" (click)="onEditarEstado(item, 0, 'Eliminación')" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button>
                          </div>
                        </div>                         
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>


              <!-- paginador -->
              <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="productos.current > 1">
                    <button type="button" class="page-link" (click)="changePage(productos.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>                  

                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': productos.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>                  

                  <li class="page-item" *ngIf="productos.current < productos.total_page">
                    <button type="button" class="page-link" (click)="changePage(productos.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>                  
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{productos.size}}</b></p>
                </div>
              </div>              
              <!-- Fin paginador -->               
            
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>   
      </section>
    </div>
    <app-footer-main></app-footer-main>  
</div>  

<!-- Modal ver -->
<div class="modal fade" id="verModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ver Orden {{ordenItem.id}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6"><label>Nombre</label> {{ordenItem.nombre | titlecase}}</div>
              <div class="col-md-6"><label>Comercio </label> {{ordenItem.comercio}}</div>
              <div class="col-md-6"><label>Contacto</label> {{ordenItem.contacto}}</div>
              <div class="col-md-6"><label>Fecha </label> {{ordenItem.publiexpress_ordenes_at}}</div>
              <div class="col-md-6"><label>Email </label> {{ordenItem.email}}</div>
              <div class="col-md-6"><label>Asesor </label> {{ordenItem.usuario_nombre}}</div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5>Productos cotizados</h5>
            <table class="table table-hover mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Producto</th>
                  <th scope="col" class="text-center">Cantidad</th>
                  <th scope="col"  class="text-right">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ordenItem.productos; let i = index">
                  <th scope="row">{{i+1}}</th>
                  <td>
                    {{item.nombre | titlecase}}
                    <div><h6 *ngIf="item.cantidad == 0">Medida {{item.ancho}} x {{item.largo}}</h6></div>
                  </td>
                  <td class="text-center"><span *ngIf="item.cantidad >=1">{{item.cantidad}} {{item.unidad | titlecase}}</span> <span *ngIf="item.cantidad ==0">1 Und</span></td>
                  <td class="text-right text-bold">${{item.valor | decimal}}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-right mt-2"><h4>${{ordenItem.valor_total | decimal}}</h4></div>
          </div>
        </div>

        <div class="btn-group btn-block" role="group">
          <button [ngClass]="{'active': ordenItem.estado == 1}" type="button" (click)="estadoUpdate(1,ordenItem.id)" class="btn btn-secondary">Cotización</button>
          <button [ngClass]="{'active': ordenItem.estado == 2}" type="button" (click)="estadoUpdate(2,ordenItem.id)" class="btn btn-secondary">Pte Infomarción</button>
          <button [ngClass]="{'active': ordenItem.estado == 3}" type="button" (click)="estadoUpdate(3,ordenItem.id)" class="btn btn-secondary">Aprobado</button>
          <button [ngClass]="{'active': ordenItem.estado == 4}" type="button" (click)="estadoUpdate(4,ordenItem.id)" class="btn btn-secondary">En proceso</button>
          <button [ngClass]="{'active': ordenItem.estado == 5}" type="button" (click)="estadoUpdate(5,ordenItem.id)" class="btn btn-secondary">Finalizado</button>
        </div>


      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>