<div class="my-5">
    <div class="container">
    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="perfil_id != 1"> -->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="col-md-12">
            <div class="card card-default">
                <div class="card-header" style="background: rgb(238, 237, 237);">
                    <h2>Inscripción Remodelación de Fachada</h2>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_representante"
                                    style="font-size: 14px;">Nombre completo</label>
                                <input formControlName="nombre_representante" name="nombre_representante" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="celular_representante"
                                    style="font-size: 14px;">Celular</label>
                                <input formControlName="celular_representante" name="celular_representante" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="direccion"
                                    style="font-size: 14px;">Dirección</label>
                                <input formControlName="direccion" name="direccion" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="departamento"
                                    style="font-size: 14px;">Departamento</label>
                                <input formControlName="departamento" name="departamento" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="ciudad"
                                    style="font-size: 14px;">Ciudad</label>
                                <input formControlName="ciudad" name="ciudad" type="text" class="form-control">
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_atiende"
                                    style="font-size: 14px;">¿Nombre de quién atiende?</label>
                                <input formControlName="nombre_atiende" name="nombre_atiende" type="text" class="form-control">
                            </div>
                        </div> -->
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label for="celular_atiende"
                                    style="font-size: 14px;">¿Celular de quién atiende?</label>
                                <input formControlName="celular_atiende" name="celular_atiende" type="text" class="form-control">
                            </div>
                        </div> -->
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label for="negocio_complementario"
                                    style="font-size: 14px;">¿Tiene Negocio Complementario?(Tienda, Miscelanea, etc...)</label>
                                <input formControlName="negocio_complementario" name="negocio_complementario" type="text" class="form-control">
                            </div>
                        </div> -->
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label for="promedio_venta1"
                                    style="font-size: 14px;">Promedio mensual de venta pago express 1</label>
                                <input formControlName="promedio_venta1" name="promedio_venta1" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="promedio_venta2"
                                    style="font-size: 14px;">Promedio mensual de venta pago express 2</label>
                                <input formControlName="promedio_venta2" name="promedio_venta2" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="promedio_venta_venezuela"
                                    style="font-size: 14px;">Promedio de venta mensual en giros a venezuela</label>
                                <input formControlName="promedio_venta_venezuela" name="promedio_venta_venezuela" type="text" class="form-control">
                            </div>
                        </div> -->
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label for="promedio_venta_soat"
                                    style="font-size: 14px;">Promedio de SOAT vendidos al mes</label>
                                <input formControlName="promedio_venta_soat" name="promedio_venta_soat" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="corresponsal_activo"
                                    style="font-size: 14px;">¿Qué corresponsal tiene activo con nosotros?</label>
                                <input formControlName="corresponsal_activo" name="corresponsal_activo" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="pago_express_fintech"
                                    style="font-size: 14px;">¿Conóce los servicios de pago express fintech?</label>
                                <input formControlName="pago_express_fintech" name="pago_express_fintech" type="text" class="form-control">
                            </div>
                        </div> -->
                        <div class="col-md-12">
                            <!-- <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="fotos">
                                <label class="form-check-label">
                                  
                                </label>
                            </div> -->
                            <div>3 fotos (internas y externas(fachada)) <b>Adjuntar</b>.</div>
                            <input class="form-control" #file type="file" accept='application/pdf,image/png, image/jpeg, image/jpg' (change)="preview(file.files)"/>
                        </div>
                        <div class="col-md-12">
                          <ul class="list-group mt-4">
                            <li *ngFor="let itemAdjunto of listadjuntos let i = index" class="list-group-item"><img src="https://api1.pagoexpress.co/public/img/upload/{{itemAdjunto.archivo}}" width="64px">  Imagen adjunta {{i+1}}  <button class="btn btn-sm btn-secondary" type="button" (click)="deleteAdjunto(i,itemAdjunto)"><i class="fa fa-trash-alt"></i></button></li>
                          </ul>

                            <!-- <div class="list-group" *ngFor="let itemAdjunto of listadjuntos let i = index">
                                <div class="row">
                                  <div class="col-md-11">
                                    <a [href]="itemAdjunto.ruta" target="_blank" class="list-group-item list-group-item-action">{{ itemAdjunto.nombre}}</a>
                                    {{ itemAdjunto.nombre}}
                                  </div>
                                  <div class="col-md-1 ">
                                    <button class="btn btn-sm btn-secondary" type="button" (click)="deleteAdjunto(i,itemAdjunto)"><i class="fa fa-trash-alt"></i></button> 
                                  </div>
                                </div>
                              </div> -->
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="form-check text-center my-2">
                                <input class="form-check-input" type="checkbox" formControlName="uso_datos">
                                <label class="form-check-label">Al realizar la inscripción acepta el uso y tratamiento de los datos aportados.
                                </label>
                            </div>
                        </div> -->
                        <div class="col-md-12 mt-5 d-flex justify-content-center">
                            <button [disabled]="submitted" class="btn btn-lg btn-success" type="submit">Inscribirse</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    
    <div class="col-12" *ngIf="perfil_id == 1">
        <div class="card">
            <div class="card-header">
                <h2>Inscritos</h2>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoProspectos()"  *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                <button *ngIf="prospectos.items" (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>
              </div>
          <div class="card-body table-responsive p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Celular</th>
                  <th>Dirección</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of prospectos.items">
                  <td>{{item.nombre_representante | titlecase}}</td>
                  <td>{{item.celular_representante}}</td>
                  <td>{{item.direccion}} {{item.ciudad}} {{item.departamento}}</td>
                  <td>{{item.ancheta_at}}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button (click)="ver(item)" data-toggle="modal" data-target="#modalVer" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver Prospecto</button>
                        <!-- <button (click)="Editar(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button> -->
                        <!-- <div class="dropdown-divider"></div> -->
                        <button (click)="onEliminar(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button>
                      </div>
                    </div>                         
                  </td>
                </tr>

              <!--Modal Ver  -->
              <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                <div role="document" class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header bg-info">
                      <h5 class="modal-title">
                        Detalle
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>                            
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-7">
                          <div class="row">
                            <div class="col-md-6 text-bold">Nombre Representante:</div>
                            <div class="col-md-6">{{itemVer.nombre_representante | titlecase}}</div>
                            
                            <div class="col-md-6 text-bold">Celular Representante:</div>
                            <div class="col-md-6">{{itemVer.celular_representante}}</div>

                            <div class="col-md-6 text-bold">Departamento:</div>
                            <div class="col-md-6">{{itemVer.departamento | titlecase}}</div>

                            <div class="col-md-6 text-bold">Ciudad:</div>
                            <div class="col-md-6">{{itemVer.ciudad | titlecase}}</div>

                            <div class="col-md-6 text-bold">Dirección:</div>
                            <div class="col-md-6">{{itemVer.direccion | titlecase}}</div>

                            <div class="col-md-6 text-bold">Nombre Atiende:</div>
                            <div class="col-md-6">{{itemVer.nombre_atiende | titlecase}}</div>
                            
                            <div class="col-md-6 text-bold">Celular Atiende:</div>
                            <div class="col-md-6">{{itemVer.celular_atiende}}</div>

                            <div class="col-md-6 text-bold">Negocio complementario:</div>
                            <div class="col-md-6">{{itemVer.negocio_complementario}}</div>

                            <div class="col-md-6 text-bold">Promedio mensual de ventas en pago express 1:</div>
                            <div class="col-md-6">{{itemVer.promedio_venta1}}</div>

                            <div class="col-md-6 text-bold">Promedio mensual de ventas en pago express 2:</div>
                            <div class="col-md-6">{{itemVer.promedio_venta2}}</div>
                            
                            <div class="col-md-6 text-bold">Promedio mensual en giros a venezuela:</div>
                            <div class="col-md-6">{{itemVer.promedio_venta_venezuela}}</div>
                            
                            <div class="col-md-6 text-bold">Promedio de SOAT vendidos al mes:</div>
                            <div class="col-md-6">{{itemVer.promedio_venta_soat}}</div>
                            
                            <div class="col-md-6 text-bold">Corresponsal activo:</div>
                            <div class="col-md-6">{{itemVer.corresponsal_activo}}</div>
                            
                            <div class="col-md-6 text-bold">¿Conóce los servicios de pago express fintech?:</div>
                            <div class="col-md-6">{{itemVer.pago_express_fintech}}</div>
                                                    
                            <div class="col-md-6 text-bold">Fecha:</div>
                            <div class="col-md-6">{{itemVer.fecha}}</div>

                          </div>                                 
                        </div>
                        
                        <div class="col-md-5">
                            <div class="row">
                              <div class="col-md-12">

                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                  <div class="carousel-inner">
                                    <div class="carousel-item active" *ngIf="itemVer.img1">
                                      <img [src]="itemVer.img1" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item" *ngIf="itemVer.img2">
                                      <img [src]="itemVer.img2" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item" *ngIf="itemVer.img3">
                                      <img [src]="itemVer.img3" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item" *ngIf="itemVer.img4">
                                      <img [src]="itemVer.img3" class="d-block w-100" alt="...">
                                    </div>
                                  </div>
                                  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </div>

                              </div>
                            </div>                                 
                          </div>

                      </div>
                    
                    </div>
                    <div class="modal-footer">
                      <button #CerrarModal (click)="cerrarmodal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>

              </tbody>
            </table>
          </div>


          <!-- paginador -->
          <div class="card-footer clearfix">
            <ul class="pagination pagination-sm m-0 float-right">
              <li class="page-item" *ngIf="prospectos.current > 1">
                <button type="button" class="page-link" (click)="changePage(prospectos.current - 1)">
                  <span>Atras</span>
                </button>
              </li>                  

              <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': prospectos.current == page}">
                <button type="button" class="page-link" (click)="changePage(page)">
                  {{ page }}
                </button>
              </li>                  

              <li class="page-item" *ngIf="prospectos.current < prospectos.total_page">
                <button type="button" class="page-link" (click)="changePage(prospectos.next)">
                  <span>Siguiente</span>
                </button>
              </li>                  
            </ul>
            <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
              <p>Total registros encontrados: <b>{{prospectos.size}}</b></p>
            </div>
          </div>              
          <!-- Fin paginador -->               
        
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>
    </div>
</div>