import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-pasacupo-comercio',
  templateUrl: './ver-pasacupo-comercio.component.html',
  styleUrls: ['./ver-pasacupo-comercio.component.css']
})
export class VerPasacupoComercioComponent implements OnInit {
  
  verComercio: any = {};
  id: any;
  nombre: any;
  razon_social:any;
  saldo:any;
  ganancia:any;
  historial:any = {};

  valor: any;
  valorConfirmacion: any;
  nota: any;
  checkNumberOnly:any;
  checkString:any;
  dotCount:number=0
  disabledButton = false;

  constructor(public router: Router,
    public servicios: ServicesService,
    private formBuilder: FormBuilder) { 
    if(this.router.getCurrentNavigation().extras.state){
      this.verComercio = this.router.getCurrentNavigation().extras.state.itemComercio;
      this.id = this.verComercio['id']; 
      this.nombre = this.verComercio['nombre'];                     
      this.razon_social = this.verComercio['razon_social'];                     
      this.saldo = this.verComercio['saldo'];                     
      this.ganancia = this.verComercio['ganancia'];                     
      this.historial = this.verComercio['historial']; 
                          
    }    
  }

  ngOnInit(): void {
    if(!this.id){
      this.router.navigate(['../comercios/pasacupo'])
    }    
  }

  setPasacupo(){
    this.disabledButton = true;
    // console.log(this.valor);
    
    let params = {
      nuevo: true,
      id: this.id,
      valor: this.valor,
      nota:this.nota
    }
    // console.log(params);
    this.servicios.postComerciosPasacupo(params)
    .subscribe( (data) => {
      console.log(data);

      if (data["logger"] == true){
        this.disabledButton = false;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
        this.router.navigate(['../comercios/pasacupo'])
      } else {
        this.disabledButton = false;
      }
    });

  }

  checarSiSonIguales(){
    if(this.valor ==this.valorConfirmacion){
      console.log('son iguales');
    }
    
  }
  cancelar(){
    this.router.navigate(['../comercios/pasacupo'])
  }

  numberOnly() {
    // console.log('dentro');
    // console.log(event.target.value);
    // console.log(this.valor);
    
    let num = this.valor; 
    // if(!isNaN(num)){
    // num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    // num = num.split('').reverse().join('').replace(/^[\.]/,'');
    // return this.valor = num;
    // }else{
    //   return null;
    // }       
  }

}
