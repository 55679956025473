import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevo-recaudo',
  templateUrl: './nuevo-recaudo.component.html',
  styleUrls: ['./nuevo-recaudo.component.css']
})
export class NuevoRecaudoComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  verRecaudo: any;

  constructor(public router: Router,
              private formBuilder: FormBuilder) { 
              }

  ngOnInit(): void {
    this.form = this.formBuilder.group({      
      fecha_recaudo: ['', [Validators.required]],
      hora_recaudo: ['', [Validators.required]],
      nombre_recaudo: ['', [Validators.required]],
      celular_recaudo: ['', [Validators.required]],
      correo_recaudo: ['', [Validators.required]],
      recaudo: ['', [Validators.required]],
      referencia_recaudo: ['', [Validators.required]],
      valor_recaudo: ['', [Validators.required]],
    }); 
  }

  get f() { return this.form.controls; } 

  EnviarRecaudo(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
  }

}
