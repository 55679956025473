import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  getCurrentUser() {
    let perfil_id = localStorage.getItem('perfil_id');
    let id = localStorage.getItem('id');
    let comercio_id = localStorage.getItem('comercio_id');
    let loggedIn = localStorage.getItem('logger');
    let name = localStorage.getItem('name');
    if (!isNullOrUndefined(perfil_id)) {
      let user = perfil_id;
      return user;
    } else {
      return null;
    }
  }

}
