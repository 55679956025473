<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Reporte Conciliación</h1>
              </div>
              <div class="col-sm-12 col-md-4 mt-3">
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="getListConvenios()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
              </div>
            </div>
          </div>
        </section>
        <div class="row align-items-center ml-3">
            <div class="col-2" *ngFor="let item of listConvenios; let i = index">
                <div class="mt-2" style="cursor: pointer;" (click)="selectConvenio(item)">
                    <img [src]="item.imgUrl" class="card-img-top rounded-circle" alt="...">
                </div>
            </div>
        </div>
        <div class="m-3" *ngIf="convenioSelected">
          <h2>{{infoConvenio.razon_social}}</h2>
          <div class="alert alert-warning h4">Total transacciones mes actual: <b>{{infoConvenio.recaudo_total_items_mes}}</b> - Total transacciones: <b>{{infoConvenio.recaudo_total_items}}</b> <button (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button></div>
          
          <div class="row">
          <div (click)="onRecaudos()" class="card text-white bg-danger mb-3 ml-1" style="max-width: 18rem;cursor: pointer;">
            <div class="card-header"><h4>Recaudo</h4></div>
            <div class="card-body">
              <h5 class="card-title">Total: ${{infoConvenio.recaudo_total | decimal}}</h5><br/>
              <h5 class="card-title">Total Mes: ${{(infoConvenio.recaudo_total_mes > 0 ? infoConvenio.recaudo_total_mes : 0) | decimal}}</h5>
            </div>
          </div>
          <div (click)="onConciliaciones()" class="card text-white bg-success mb-3 ml-1" style="max-width: 18rem;cursor: pointer;">
            <div class="card-header"><h4>Conciliación</h4></div>
            <div class="card-body">
              <h5 class="card-title">Total: ${{infoConvenio.conciliacion_total | decimal}}</h5><br/>
              <h5 class="card-title">Total Mes: ${{(infoConvenio.conciliacion_total_mes > 0 ? infoConvenio.conciliacion_total_mes : 0) | decimal}}</h5>
            </div>
          </div>
          <div class="card text-white bg-info mb-3 ml-1" style="max-width: 18rem;">
            <div class="card-header"><h4>Balance</h4></div>
            <div class="card-body">
              <h5 class="card-title">Total: ${{infoConvenio.recaudo_total - infoConvenio.conciliacion_total | decimal}}</h5><br/>
              <h5 class="card-title">Total Mes: ${{((infoConvenio.recaudo_total_mes - infoConvenio.conciliacion_total_mes) > 0 ? (infoConvenio.recaudo_total_mes - infoConvenio.conciliacion_total_mes) : 0) | decimal}}</h5>
            </div>
          </div>
        </div>
        </div>
        <div *ngIf="isSelectedRecaudos">
        <button (click)="exportExcelRecaudos()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Fecha</th>
                        <th>Referencia</th>
                        <th># Cuenta</th>
                        <th>Nombre</th>
                        <th>Punto</th>
                        <th>Valor</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of mensajero.items; let i = index">
                        <td><span (click)="VerOrdenMensajero(item)" data-toggle="modal" data-target="#modalVerMensajero" style="cursor: pointer;">{{item.id}}</span></td>
                        <td>{{item.fecha}} {{item.hora}}</td>
                        <td>{{item.recaudo_referencia | uppercase}}</td>
                        <td><b>{{item.numero_cuenta}}</b></td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.recaudo_comercio_razon_social}}</td>
                        <td>${{item.valor | decimal}}</td>
                        <td><span class="bg-warning" style="padding: 5px;" *ngIf="item.estado == 1"><b>Pendiente</b></span> 
                            <span class="bg-primary" style="padding: 5px;" *ngIf="item.estado == 2"><b>Procesando</b></span> 
                            <span class="bg-success" style="padding: 5px;" *ngIf="item.estado == 3"><b>Procesado</b></span> 
                            <span class="bg-danger" style="padding: 5px;" *ngIf="item.estado == 4"><b>Cancelada</b></span></td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <!-- paginador -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="mensajero.current > 1">
                      <button type="button" class="page-link" (click)="changePageM(mensajero.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumberM" [ngClass]="{'active': mensajero.current == page}">
                      <button type="button" class="page-link" (click)="changePageM(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="mensajero.current < mensajero.total_page">
                      <button type="button" class="page-link" (click)="changePageM(mensajero.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{mensajero.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>   
        </section>
        </div>

        <div *ngIf="isSelectedConciliaciones">
          <button (click)="exportExcelConciliaciones()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>
          <section class="content">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body table-responsive p-0">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>COMERCIO</th>
                          <th>RECAUDADOR</th>
                          <th>VALOR</th>
                          <th>FECHA</th>
                          <th>ESTADO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of conciliaciones.items">
                          <td>{{item.id}}</td>
                          <td>PagoExpress</td>
                          <td>{{item.recaudo_razon_social | titlecase}}</td>
                          <td>${{item.valor | decimal}}</td>
                          <td>{{item.conciliacion_at}}</td>
                          <td><h4><span class="badge bg-success" *ngIf="item.estado ==3">Recibido</span><span class="badge bg-danger" *ngIf="item.estado ==0">Rechazado</span><span class="badge bg-warning" *ngIf="item.estado ==1">Pendiente</span><span class="badge bg-info" *ngIf="item.estado ==2">Procesado</span></h4></td>
                          
                        </tr>
    
                      </tbody>
                    </table>
                  </div>
    
    
                  <!-- paginador -->
                  <div class="card-footer clearfix">
                    <ul class="pagination pagination-sm m-0 float-right">
                      <li class="page-item" *ngIf="conciliaciones.current > 1">
                        <button type="button" class="page-link" (click)="changePageC(conciliaciones.current - 1)">
                          <span>Atras</span>
                        </button>
                      </li>                  
    
                      <li class="page-item" *ngFor="let page of pagesNumberC" [ngClass]="{'active': conciliaciones.current == page}">
                        <button type="button" class="page-link" (click)="changePageC(page)">
                          {{ page }}
                        </button>
                      </li>                  
    
                      <li class="page-item" *ngIf="conciliaciones.current < conciliaciones.total_page">
                        <button type="button" class="page-link" (click)="changePageC(conciliaciones.next)">
                          <span>Siguiente</span>
                        </button>
                      </li>                  
                    </ul>
                    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                      <p>Total registros encontrados: <b>{{conciliaciones.size}}</b></p>
                    </div>
                  </div>              
                  <!-- Fin paginador -->               
                
                  <!-- /.card-body -->
                </div>
                <!-- /.card -->
              </div>
            </div>   
          </section> 
        </div>
       
    </div> 
    <app-footer-main></app-footer-main>  
</div>  