<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12 mb-2">
                <h1>Modulo Compensaciones</h1>
              </div>
              <div class="col-md-12">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                  <button type="button" *ngIf="cargando" class="btn btn-secondary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>                              
                  <button type="button" (click)="listadoCompensaciones()"  *ngIf="!cargando" class="btn btn-secondary"><i class="fa fa-sync"></i> </button>                              

                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul class="navbar-nav mr-auto">
                      <li class="nav-item">
                        <span class="navbar-text ml-2" style="font-weight: 400;">Total Comercios</span> {{compensaciones.size}}
                      </li>
                      <li class="nav-item">
                        <span class="navbar-text ml-2" style="font-weight: 400;">Bloqueados </span> {{compensaciones.countBloqueado}}
                      </li>
                      
                      <!-- <li class="nav-item">
                        <span class="navbar-text ml-2" style="font-weight: 400;">Compensación Semanal</span> {{compensaciones.countSemana}}                        
                      </li> -->
                      <li class="nav-item dropdown ml-2">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Exportar
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <button type="button" (click)="exportar('PTM')" class="dropdown-item"><i class="fa fa-file-export"></i> PTM</button>                    
                          <button type="button" (click)="exportar('DISTRICOL')" class="dropdown-item"><i class="fa fa-file-export"></i> DISTRICOL</button>
                        </div>
                      </li>

                    </ul>
                    <form class="col-md-4  form-inline my-2 my-lg-0">
                      <input style="width:100%" [(ngModel)]="buscar" name="buscar" type="text" (change)="filtro()" placeholder="Buscar por código comercio" class="form-control float-right text-uppercase">
                    </form>
                  </div>
                </nav>

              </div>
              
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <!-- <th>ID</th> -->
                        <th>Codigo Punto</th>
                        <th style="border-right: 2px solid #dee2e6;">Comercio</th>
                        <th>Ultima Fecha</th>
                        <!-- <th>Información</th> -->
                        <th>Cupo</th>
                        <!-- <th>Cupo Prepago</th> -->
                        <th>Estado</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of compensaciones.items">
                        <td><span (click)="VerOrdenCompensacion(item)" data-toggle="modal" data-target="#modalVerCompensacion" style="cursor: pointer;">
                          <!-- <div *ngIf="item.estado_corresponsal ==-1" class="pill-danger"></div> 
                          <div *ngIf="item.estado_corresponsal >=1" class="pill-success"></div>  -->
                          {{item.codigo_punto}}</span>
                        </td>
                        <td style="border-right: 2px solid #dee2e6;">{{item.razon_social | uppercase}}</td>
                        <td>{{item.compensacion_fecha}}</td>
                        <!-- <td>{{item.compensacion_detalle}}</td> -->
                        <td>$ {{item.compensacion_cupo | decimal}}</td>
                        <!-- <td>$ {{item.compensacion_cupo_prepago | decimal}}</td> -->
                        <td>
                          <span class="bg-warning" style="padding: 5px;" *ngIf="item.compensacion_estado == 1"><b>Pendiente</b></span> 
                          <span class="bg-primary" style="padding: 5px;" *ngIf="item.compensacion_estado == 2"><b>Procesando</b></span> 
                          <span class="bg-success" style="padding: 5px;" *ngIf="item.compensacion_estado == 3 && fecha >= item.compensacion_fecha"><b>Procesado</b></span> 
                          <span class="bg-danger" style="padding: 5px;" *ngIf="item.compensacion_estado == 4"><b>Cancelada</b></span>
                        </td>


                        <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="VerOrdenCompensacion(item)" data-toggle="modal" data-target="#modalVerCompensacion" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Ver Compensación</button>
                            </div>
                          </div>                         
                        </td>
                      </tr>


                    <!--Modal Ver Compensacion -->
                      <div id="modalVerCompensacion" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                        <div role="document" [ngClass]="!ordenItem.recaudo_id_comercio ? 'modal-dialog modal-lg' : 'modal-dialog modal-md'">
                          <div class="modal-content">
                            <div [ngClass]="{'bg-warning': ordenItem.compensacion_estado == 1, 'bg-primary':ordenItem.compensacion_estado == 2, 'bg-success':ordenItem.compensacion_estado == 3, 'bg-danger':ordenItem.compensacion_estado == 4 }" class="modal-header ">
                              <h5 class="modal-title">
                                Compensación {{ordenItem.compensacion_id}}
                                <span class="bg-warning" style="padding: 5px;" *ngIf="ordenItem.compensacion_estado == 1"><b>- Pendiente</b></span> 
                                <span class="bg-primary" style="padding: 5px;" *ngIf="ordenItem.compensacion_estado == 2"><b>- Procesando</b></span> 
                                <span class="bg-success" style="padding: 5px;" *ngIf="ordenItem.compensacion_estado == 3"><b>- Procesado</b></span> 
                                <span class="bg-danger" style="padding: 5px;" *ngIf="ordenItem.compensacion_estado == 4"><b>- Cancelada</b></span>
                              </h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>                            
                            </div>
                            <div class="modal-body">
                              <div class="col-md-12">

                                <div [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-12' : 'col-md-12'">
                                  <div class="row">
                                    <div class="mt-2" [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-7' : 'col-md-12'">
                                      <img style="display:block;margin:auto;" *ngIf="ordenItem.banco == 'Bancolombia'" width="250" src="assets/img/logo-bancolombia.png">
                                      <img style="display:block;margin:auto;" *ngIf="ordenItem.banco == 'Colpatria'" width="250" src="assets/img/logo-colpatria.png">
                                      <img style="display:block;margin:auto;" *ngIf="ordenItem.banco == 'Davivienda'" width="250" src="assets/img/logo-davivienda.png">

                                      <div style="margin-top:-1%;font-size: 2em;" class="form-group">
                                        <div *ngIf="ordenItem.recaudo_empresa">{{ordenItem.recaudo_empresa | titlecase}} - {{ordenItem.recaudo_empresa_nit}}</div>
                                        <div class="text-muted">{{ordenItem.recaudo_referencia}}</div>

                                        <span> {{ordenItem.tipo | titlecase}}</span>
                                      </div>  
                                      <div style="margin-top:-5%;font-size: 1.3em;" class="form-group" *ngIf="ordenItem.numero_cuenta">
                                        <label>Numero de Cuenta:</label> <span> {{ordenItem.numero_cuenta}}</span>
                                      </div> 
                                      
                                      <!-- <div class="form-group">
                                        <div class="badge badge-danger" *ngIf="ordenItem.estado_corresponsal ==-1" style="font-size: 1.3em;margin-bottom: 10px;">CORRESPONSAL BLOQUEADA</div>
                                      </div> -->
                                    
                                      <div style="margin-top:-5%;font-size: 1.4em;" class="form-group">
                                        <label>Cupo Bancolombia:</label> <span> ${{ordenItem.cupo_bancolombia | decimal}}</span>
                                      </div>
                                      <div style="margin-top:-5%;font-size: 1.4em;" class="form-group">
                                        <label>Cupo Prepagado:</label> <span> ${{ordenItem.cupo_prepagado | decimal}}</span>
                                      </div>
                                      <div style="margin-top:-5%;font-size: 1.4em;" class="form-group">
                                        <label>Cupo Total:</label> <span> ${{ (+ordenItem.cupo_bancolombia) + (+ordenItem.cupo_prepagado) | decimal}}</span>
                                      </div>

                                      <div style="margin-top:-5%;font-size: 1.4em;" class="form-group">
                                        <label>Cupo  Reportado:</label> <span> ${{ordenItem.compensacion_cupo | decimal}}</span>
                                      </div>                                      

                                      <!-- <div style="margin-top:-5%;font-size: 1.4em;" class="form-group">
                                        <label>Cupo Prepago:</label> <span> ${{ordenItem.compensacion_cupo_prepago | decimal}}</span>
                                      </div> -->
                                      

                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="row">
                                            <div class="col-md-6">
                                              <input readonly [(ngModel)]="ordenItem.compensacion_fecha" class="form-control" name="fecha" type="date" 
                                                    value="2011-08-19" style="outline: none;background-color: rgb(229, 233, 231);
                                                    border: 0;text-align: center;">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="mt-4" [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-12' : 'col-md-12'"> 
                                            <div style="margin-top:-1%" class="form-group">
                                              <label>Razón Social:</label> <span> {{ordenItem.razon_social}}</span>
                                            </div>    
                                            <div style="margin-top:-3%" class="form-group">
                                              <label>Codigo Punto:</label> <span> {{ordenItem.codigo_punto}} </span><span *ngIf="ordenItem.imgUrl"><a [href]="'https://api.whatsapp.com/send?phone=57'+ordenItem.celular+'&text=Operación Exitosa Mensajero Virtual '+ordenItem.imgUrl" target="_blank">link</a></span>
                                            </div>  
                                            <div style="margin-top:-3%" class="form-group">
                                              <label>Email:</label> <span> {{ordenItem.usuario_email}}</span>
                                            </div>  
                                            <div style="margin-top:-3%" class="form-group">
                                              <label>Contacto:</label> <span> {{ordenItem.celular}}</span>
                                            </div>                                               
                                            <div style="margin-top:-3%" class="form-group">
                                              <label>Nota:</label> <span> {{ordenItem.compensacion_detalle}}</span>
                                            </div>                                               
                                        </div>
                                      </div>
                                    </div>
                                    <div [ngClass]="!ordenItem.recaudo_id_comercio ? 'col-md-5' : 'col-md-1'">
                                      <div class="col-md-12" *ngIf="!ordenItem.recaudo_id_comercio">
                                        <div class="row">
                                          <div class="col-md-12" *ngIf="perfil_id <= 3">
                                            <div   class="form-group" *ngIf="ordenItem.estado == 1 || ordenItem.estado == 3 || ordenItem.estado == 4">
                                              <label>Estado</label>
                                              <select [(ngModel)]="ordenItem.estado" name="estado" class="form-control form-control-sm">
                                                <option value="1">Pendiente</option>
                                                <!-- <option value="2">Procesando</option> -->
                                                <option value="3">Procesado</option>
                                                <option value="4">Rechazada</option>
                                              </select>                                 
                                            </div>
                                          </div>
                                          <div class="col-md-12">
                                            <div class="form-group text-center mt-2">
                                      
                                              <span style="color:red;" *ngIf="message">{{message}}</span>
                                              <input #file type="file" accept='image/*' (change)="preview(file.files, ordenItem.id)" style="display:none;"/>
                                              <div class="d-flex justify-content-center mb-3" *ngIf="cargandoImg">
                                                <div class="spinner-border" role="status" style="width: 1.5rem; height: 1.5rem;">
                                                  <span class="sr-only">Cargando...</span>
                                                </div>
                                              </div>

                                              <a [href]="ordenItem.compensacion_imgUrl" target="_blank"><img *ngIf="!imgURL && ordenItem.compensacion_img" style="margin-top:1%" [src]="ordenItem.compensacion_imgUrl" height="200" width="100%" class="img-fluid" alt="Responsive image"></a>
                                              <img *ngIf="!ordenItem.compensacion_img && !imgURL" style="margin-top:1%" src="assets/img/default-image.png" height="200" width="100%" class="img-fluid" alt="Responsive image">
                                            </div>

                                            <button *ngIf="ordenItem.compensacion_estado != 3"  (click)="AprobadoBloqueado(3,ordenItem)" type="button" class="btn btn-block btn-success" data-dismiss="modal">APROBADO</button>
                                            <button *ngIf="ordenItem.compensacion_estado != 4" (click)="AprobadoBloqueado(4,ordenItem)" type="button" class="btn btn-block btn-danger" data-dismiss="modal">BLOQUEADO</button>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> 
                              <div class="col-md-12 pt-3" style="border-top: 1px solid #e9ecf0;">
                                <div class="text-center"><h5>Historial Compensaciones <button type="button" class="btn btn-sm btn-light" (click)="HistorialCompensacion(ordenItem)"><i class="fa fa-sync"></i></button></h5></div>

                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Fecha</th>
                                      <th scope="col">Cupo</th>
                                      <th scope="col">IMG</th>
                                      <th scope="col">Estado</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of compensacionesHistorial">
                                      <th scope="row">{{item.compensacion_fecha}}</th>
                                      <td>{{item.compensacion_cupo | decimal}}</td>
                                      <!-- <td>{{item.compensacion_cupo_prepago | decimal}}</td> -->
                                      <td><a *ngIf="item.compensacion_img" [href]="item.compensacion_imgUrl" target="_blank"><i class="fa fa-image"></i></a>
                                      </td>
                                      <td>
                                        <span class="bg-warning" style="padding: 5px;" *ngIf="item.compensacion_estado == 1"><b>Pendiente</b></span> 
                                        <span class="bg-primary" style="padding: 5px;" *ngIf="item.compensacion_estado == 2"><b>Procesando</b></span> 
                                        <span class="bg-success" style="padding: 5px;" *ngIf="item.compensacion_estado == 3"><b>Procesado</b></span> 
                                        <span class="bg-danger" style="padding: 5px;" *ngIf="item.compensacion_estado == 4"><b>Cancelada</b></span>              
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                              </div>                           
                            </div>
                            <div class="modal-footer">
                              <button #CerrarModal (click)="cerrarmodal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                          </div>
                        </div>
                      </div>                      
  
                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix" > 
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="compensaciones.current > 1">
                      <button type="button" class="page-link" (click)="changePage(compensaciones.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': compensaciones.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="compensaciones.current < compensaciones.total_page">
                      <button type="button" class="page-link" (click)="changePage(compensaciones.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{compensaciones.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>    


        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  