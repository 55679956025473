import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;

@Component({
  selector: 'app-recaudo-listado',
  templateUrl: './recaudo-listado.component.html',
  styleUrls: ['./recaudo-listado.component.css']
})
export class RecaudoListadoComponent implements OnInit {

  @ViewChild('CerrarModal') modalClose;

  public imagePath;
  imgURL: any;
  imgurl: any;
  public message: string;
  img: any;

  findConvenio = false;
  cargando = false;
  cargandoImg = false;
  loaderImgComprobante: boolean = false;
  mensajero: any = [];
  pagesNumber: any;
  offset= 3;
  id: any;
  idItem: any;
  perfil_id;
  buscar: any;
  page: any;

  id_orden: any;
  ordenItem: any = {};

  listConvenios: any = [];
  
  recaudo_clientes_item_id:any;
  codigo_cuenta:any;
  referencia:any;
  nombre:any;
  fecha_ven:any;
  valor:any;

  convenio_comercio_id:any;
  razon_social:any;
  tipo_documento:any;
  numero_documento:any;

  val1=false;
  val2=false;
  val3=false;
  val4=false;

  reporteExport:any;
  reporteMes:any;
  fecha_desde:any;
  fecha_hasta: any;


  constructor(public servicios: ServicesService, public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.perfil_id);
    this.listadoMensajero();
    this.getListConvenios();
  }

  preview(files, id) {
    this.idItem = id;
    this.loaderImgComprobante = true;

    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "No hay fotos.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      // console.log("esta es la imagen"+this.imgURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen(){
    if(this.imgURL) {
      let params = {
        archivo: this.imgURL
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgOrden(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          this.img = data['filename'];
          this.imgurl = data['fileUrl'];
          // this.cargandoImg = false;
          // this.loaderImgComprobante = false;
          this.SetEditarImg();
        }
      });
    } else {
      Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Porfavor seleccione una imagen",
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
    }
  }

  SetEditarImg(){
    let params = {
      editarImg: true,
      id: this.idItem,
      mensajero: {
        operador_comercio_id: this.id,
        img: this.img
      }
    }
    // console.log(params);
    this.servicios.postEditarEstado(params)
    .subscribe( (data) => {
      if (data["logger"] == true){
        this.loaderImgComprobante= false;
        this.img= '';
      } else {
      }
    });  
  }

  VerOrdenMensajero(item){
    this.ordenItem = item;
    // console.log(item);
  }

  EnviarEstadoOrden(id,estado,nota){
      let params = {
        editarEstado: true,
        id: id,
        mensajero: {
          estado: estado,
          operador_comercio_id: this.id,
          nota: nota,
          // img: this.img
        }
      }
      // console.log(params);
      this.servicios.postEditarEstado(params)
      .subscribe( (data) => {
        if (data["logger"] == true){
          this.listadoMensajero();
          this.imgURL= '';
          this.ordenItem = '';
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
        }
      });
  }

  EditarMensajero(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajero: item
      }
    }
    this.router.navigate(['/mensajero/editar'],navigationExtras);
  }

  changePage(page) {
    this.page = page;
    // console.log(page);
    this.mensajero.current = page;
    this.listadoMensajero(page); 
    // this.filtro();      
  }

  listadoMensajero(page = null){
    this.cargando = true;

    if(!page){
      this.servicios.getMensajero(this.id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        this.mensajero = data['mensajero'];
        this.cargando = false;
        // console.log(data['mensajero']);
        this.ForpagesNumber()
      });
    }else{
      this.servicios.getMensajero(this.id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.mensajero = data['mensajero'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }     
  }

  ForpagesNumber() {
    let from = this.mensajero.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.mensajero.total_page){
      to = this.mensajero.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  cerrarmodal(){
    this.imgURL = '';
    this.ordenItem = '';
  } 

  Imprimir(item){
    this.EnviarItemImprimir(item);
  }

  EnviarItemImprimir(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemMensajeroImprimirOrden: item
      }
    }
    this.router.navigate(['../operaciones/recaudos/imprimir'],navigationExtras); 
  }
 

  setSolicitudRecaudo(){
    this.cargando = true;
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea Enviar la solicitud recaudo #'+this.codigo_cuenta+' por un valor de $'+this.valor+'?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, enviar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          recaudo_id_comercio: this.convenio_comercio_id,
          solicitud_comercio_id: this.id,
          nuevo: true,
          mensajero: {
            numero_cuenta: this.codigo_cuenta,
            nombre: this.nombre,
            recaudo_referencia: this.referencia,
            valor: this.valor,
            producto_id: 3,
            estado: 3,
            recaudo_clientes_item_id: this.recaudo_clientes_item_id,
          },
        }
    
        // console.log(params);
    
        this.servicios.setMensajero(params)
        .subscribe( (data) => {
          this.cargando = false;
    
            if (data["logger"] == true){
              $('#nuevoRecaudo').modal('hide')
              this.setEditRecaudoClientes();
              this.listadoMensajero();
              this.EnviarItemImprimir(data['item']);
    
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
    
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });          
            }
        });
        this.cargando = false;
        
      }else{
        console.log('dentro false');        
        this.cargando = false;
      }
    });

  }

  setEditRecaudoClientes(){
    let params = {
      id: this.recaudo_clientes_item_id,
      estado_solicitud: true,
      estado: 2,
    }
    // console.log(params);
    this.servicios.postRecaudoClientes(params)
    .subscribe( (data) => {
        if (data["logger"] == true){} else {}
    });
  }

  selectConvenio(item){
    this.img = item.imgUrl;
    this.convenio_comercio_id = item.id;
    this.razon_social = item.razon_social
    this.tipo_documento= item.tipo_documento;
    this.numero_documento= item.numero_documento;
    
    document.getElementById('convenios').style.display = "none";  
    document.getElementById('newRecaudo').style.display = "block";
    // console.log(item);
  }

  backConvenios(){
    document.getElementById('convenios').style.display = "block";  
    document.getElementById('newRecaudo').style.display = "none";
    this.codigo_cuenta= "";
    this.referencia= "";
    this.nombre = null;
    this.fecha_ven= null;
    this.valor= null;
    this.convenio_comercio_id= null;
  }

  getListConvenios(){
    this.cargando = true;
    // id sera el perfil_id
    let params = {
      statusperfilid: true,
      id: 3
    };
    this.servicios.postComercio(params).subscribe( (data) => {
      this.listConvenios = data['comercio_convenios'];
      this.cargando = false;
      // console.log('dentro');      
      // console.log(data['comercio_convenios']);
    });
  }

  findRecaudo(comercio_id){
    this.cargando = true;
    let params = {
      filtro: true,
      comercio_id: comercio_id,
      codigo_cuenta: this.codigo_cuenta,
    }
    // console.log(params);
    this.servicios.postRecaudoClientes(params)
    .subscribe( (data) => {
      // console.log(data);  
      this.cargando = false;    
      if (data["logger"] == true){
        // this.findConvenio = true;
        this.recaudo_clientes_item_id = data['recaudo_clientes'].recaudo_clientes_item.id;
        this.codigo_cuenta = data['recaudo_clientes'].recaudo_clientes_item.codigo_cuenta;
        this.nombre = data['recaudo_clientes'].recaudo_clientes_item.nombre;
        this.referencia = data['recaudo_clientes'].recaudo_clientes_item.referencia;
        this.fecha_ven  = data['recaudo_clientes'].recaudo_clientes_item.fecha_ven;
        this.valor = data['recaudo_clientes'].recaudo_clientes_item.valor;
        this.val1=false;
        this.val2=false;
        this.val3=false;
        this.val4=false;

        if(data['recaudo_clientes'].recaudo_clientes_item){
          this.findConvenio = true;
          // console.log('recaudo_clientes true');          
        }else{
          this.findConvenio = false;
          // console.log('recaudo_clientes false');
        }
      } else {
        this.findConvenio = false;
      }
    });    
  }

  filtro(){
    // console.log('page '+this.page);
    // console.log('buscar '+this.buscar);
      let params = {
        buscar: true,
        busqueda: this.buscar,
        page: this.page
      }
      // console.log(params)
      this.servicios.setMensajero(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.mensajero = data['mensajero'];
            // console.log(this.mensajero)
            this.ForpagesNumber();
          } else {
            this.mensajero.size = 0;
            this.mensajero.items = [];
          }      
      });    
  }  


  exportExcel(){
    this.cargando = true;
    let params = {
      exportPunto: true,
      id:this.id,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
      mes:this.reporteMes,
    }
    console.log(params);
    this.servicios.postRecaudo(params)
    .subscribe( (data) => {
      this.reporteExport = data['recaudo'];
      let fecha = data['fecha']
      // console.log(this.reporteExport);
      
      const fileName = this.fecha_desde+'-'+this.fecha_hasta+'-reporte_recaudos_punto.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

      // ANCHO COLUMNAS
      ws['!cols'] = [ {width:2},{width:8},{width:15}, {width:10},{width:20},{width:20},{width:40},{width:50},{width:20},{width:20}];
      // ELIMINA COLUMNA1
      ws['!cols'][0] = { hidden: true };
      // ws['!cols'][1] = { hidden: true };
      // ws['!cols'][13] = { hidden: true };
      // ws['!cols'][14] = { hidden: true };
      // ws['!cols'][18] = { hidden: true };
      // // ws['G2'] = { Target:"#E2" };
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // CAMBIA CABECERA
      XLSX.utils.sheet_add_aoa(ws, [["log","ID","FECHA","HORA","CUENTA","REFERENCIA","NOMBRE","PUNTO","RECAUDO","VALOR","ESTADO"]]);
      XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  

      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);

      this.cargando = false;
    });
    
  }    

  onReversar(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea Reversar la transacción con número de cuenta '+item.numero_cuenta+' por un valor de '+item.valor+'?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, Reversar!',
    }).then((result) => {
      if (result.value) {
        let params = {
          reversar : true,
          id: item.id
        }     
        this.servicios.setMensajero(params).subscribe( data => {
          if (data["logger"] == true){
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }
          this.listadoMensajero();
        } );
      }
    });
  }

  cerrarModal(){
    // console.log(info);
    $('#nuevoRecaudo').modal('hide')

    // document.getElementById('nuevoRecaudo').style.display = "none";
  }
    
}