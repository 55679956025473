<!-- Sidebar -->
<div class="sidebar">

  <ul class="nav nav-pills nav-sidebar flex-column">
    <li class="nav-item" style="padding-top: 3%;">
      <div class="nav-link">
        <i class="fas fa-user" style="font-size: 25px;color: white;"></i>        
        <p style="margin-left: 10px;color: white;">{{nombre}}</p>
      </div>
    </li>

    <li class="nav-header" style="color: white;padding-top: 1%;">
      <p class="text-left info_label"><b>Código: </b> 00{{comercio_id}}</p>
      <p class="text-left info_label" style="margin-top:-7%"><b>Perfil: </b> <span *ngIf="perfil_id==1">Administrador</span> <span *ngIf="perfil_id ==2">Vendedor</span> <span *ngIf="perfil_id ==3">Empresario</span> <span *ngIf="perfil_id ==4">Punto de Venta</span></p>
      <p class="text-left info_label" style="margin-top:-7%;margin-bottom: 0px;"><b>Comercio:</b> {{nombre}}</p>
    </li>
  </ul>


  
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        
        <!-- <app-sidebarmenu-menu-item class="nav-item has-treeview" *ngFor="let itemMenu of menu" [menuItem]="itemMenu"></app-sidebarmenu-menu-item> -->

        <!-- Operaciones -->      
        <li class="nav-item has-treeview">
          <a *ngIf="perfil_id == 4 || perfil_id == 3 || perfil_id == 1" class="nav-link" routerLink="/operaciones" style="cursor: pointer">
            <i class="nav-icon fas fa-cubes"></i>
              <p>
                Operaciones
              </p>
          </a>
        </li> 

        <!-- Comercios -->      
        <li class="nav-item has-treeview">
          <a *ngIf="perfil_id == 1" class="nav-link" routerLink="/comercios/listado" style="cursor: pointer">
            <i class="nav-icon fas fa-table"></i>
              <p>
                Comercios
              </p>
          </a>
        </li> 


        <!-- Comercios -->      
        <li class="nav-item has-treeview">
          <a *ngIf="perfil_id == 1" class="nav-link" routerLink="/ecommerce/operaciones" style="cursor: pointer">
            <i class="nav-icon fas fa-store"></i>
              <p>
                Ecommerce
              </p>
          </a>
        </li>         

        <!-- Comercios -->      
        <li class="nav-item has-treeview">
          <a *ngIf="perfil_id == 1" class="nav-link" routerLink="/publiexpress/operaciones" style="cursor: pointer">
            <i class="nav-icon fas fa-bullhorn"></i>
              <p>
                Publicidad
              </p>
          </a>
        </li>         

        <!-- Mensajero -->
        <!-- <li *ngIf="perfil_id == 4 || perfil_id == 1" class="nav-item has-treeview" routerLink="/mensajero/listado">
          <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-envelope-open-text"></i>
            <p>
              Mensajero
            </p>
          </a>
        </li> -->

        <!-- <li *ngIf="perfil_id == 3 || perfil_id == 1" class="nav-item has-treeview" routerLink="/recaudo/listado">
          <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-money-check-alt"></i>
            <p>
              Recaudo 
            </p>
          </a>
        </li> -->

        <li *ngIf="perfil_id == 4 || perfil_id == 1 || perfil_id == 2 || perfil_id == 3" class="nav-item has-treeview" routerLink="/reportes">
          <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-chart-bar"></i>
            <p>
              Reportes
            </p>
          </a>
        </li> 

          <!-- compensacion  -->
          <!-- <li *ngIf="perfil_id == 1 " class="nav-item has-treeview" routerLink="/compensaciones/listado">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-receipt"></i>
            <p>
              Compensación CBC
            </p>
            </a>
          </li>  -->

          <!-- compensacion comercio -->
          <li *ngIf="perfil_id == 4 " class="nav-item has-treeview" routerLink="/compensaciones/nuevo">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-receipt"></i>
            <p>
              Compensación CBC
            </p>
            </a>
          </li>

          <!-- mensajeria -->
          <li *ngIf="perfil_id == 1 || perfil_id == 4" class="nav-item has-treeview" routerLink="/mensajeria/listado">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-receipt"></i>
            <p>
              Mensajeria
            </p>
            </a>
          </li>

          <!-- prospectos admin -->
          <li *ngIf="perfil_id == 1 " class="nav-item has-treeview" routerLink="/prospectos/listado">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-user-friends"></i>
            <p>
              Prospectos
            </p>
            </a>
          </li>     

          <!-- empresa admin -->
          <li *ngIf="perfil_id == 1 " class="nav-item has-treeview" routerLink="/empresas/listado-empresas">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-building"></i>
            <p>
              Empresas
            </p>
            </a>
          </li> 

          <!-- usuarios admin -->
          <li *ngIf="perfil_id == 1 " class="nav-item has-treeview" routerLink="/usuarios/listado">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-users"></i>
            <p>
              Usuarios
            </p>
            </a>
          </li>        
          
          
          <!-- usuarios admin -->
          <li *ngIf="perfil_id == 1 || perfil_id == 4 || perfil_id == 7 " class="nav-item has-treeview" routerLink="/afiliaciones/listado">
            <a class="nav-link" style="cursor: pointer">
            <i class="nav-icon fas fa-user-shield"></i>
            <p>
              Afiliaciones
            </p>
            </a>
          </li> 
          
        <!-- Cambiar contraseña -->      
        <li class="nav-item has-treeview">
          <a *ngIf="perfil_id == 7 || perfil_id == 4 || perfil_id == 3 || perfil_id == 1" class="nav-link" routerLink="/comercios/password" style="cursor: pointer">
            <i class="nav-icon fas fa-lock"></i>
              <p>
                Contraseña
              </p>
          </a>
        </li>           
        

        <!-- Login -->
        <!-- <li>
          <a class="nav-link" routerLink="./account/login">
            <p>Login<i class="right fas fa-angle-left"></i></p>
          </a>
        </li> -->

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->

