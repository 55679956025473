
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Prospectos 
                  <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                  <button type="button" (click)="listadoProspectos()"  *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                </h1>
              </div>
            </div>
          </div>
        </section>
  

        <section class="content">            
          <div class="row">
            <div class="col-12">
                <div class="row">
                    <!-- <div class="col-md-3">
                        <div class="form-group">
                            <label>Departamento:</label>
                            <div class="input-group">
                              <select [(ngModel)]="departamento" name="asesor" class="form-control float-right">
                                <option value=''><b>TODOS</b></option>
                                <option [value]="item.id" *ngFor="let item of asesores">{{item.nombre | uppercase}}</option>
                              </select>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Municipio:</label>
                            <div class="input-group">
                              <select [(ngModel)]="municipio" name="asesor" class="form-control float-right">
                                <option value=''><b>TODOS</b></option>
                                <option [value]="item.id" *ngFor="let item of asesores">{{item.nombre | uppercase}}</option>
                              </select>
                            </div>
                        </div>                                
                    </div> -->
                      <div class="col-md-3">
                        <div class="form-group">
                            <label>Fecha Desde:</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </div>
                              <input [(ngModel)]="fecha_desde" name="fecha_desde" type="date" class="form-control float-right" (change)="filtro()">
                            </div>
                          </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Fecha Hasta:</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </div>
                              <input [(ngModel)]="fecha_hasta"name="fecha_hasta" type="date" class="form-control float-right" (change)="filtro()">
                            </div>
                          </div>                                
                    </div>                    

                    <div class="col-md-2">
                      <div class="form-group">
                          <label>Zona:</label>
                          <div class="input-group">
                            <input [(ngModel)]="zona" name="zona" type="number" class="form-control float-right" (change)="filtro()">
                          </div>
                      </div>                                
                    </div>                    

                    <div class="col-md-3">
                      <div class="form-group">
                          <label>Asesores:</label>
                          <div class="input-group">
                            <select [(ngModel)]="asesor" name="asesor" class="form-control float-right" (change)="filtro()">
                              <option value=''><b>TODOS</b></option>
                              <option [value]="item.id" *ngFor="let item of asesores">{{item.nombre | uppercase}}</option>
                            </select>
                          </div>
                        </div>                                
                    </div>                            
                    <div class="col-md-1" style="margin-top:31px;">
                      <button *ngIf="prospectos.items" (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>

                      <!-- <button (click)="ListadoReportes()" type="button" class="btn btn-primary"><i class="fa fa-search"></i> </button> -->
                      <!-- <button *ngIf="Reporte.items" (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button> -->
                    </div>
                </div> 
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Tipo de Negocio</th>
                        <th>Contacto</th>
                        <th>Zona</th>
                        <th>Asesor</th>
                        <th>Fecha</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of prospectos.items">
                        <td><span (click)="ver(item)" data-toggle="modal" data-target="#modalVer" style="cursor: pointer;">{{item.id}}</span></td>
                        <td>{{item.nombre | titlecase}}</td>
                        <td>{{item.tipo_negocio}}</td>
                        <td>{{item.celular}}</td>
                        <td>{{item.zona}}</td>
                        <td>{{item.usuario_nombre | titlecase}}</td>
                        <td>{{item.prospecto_at}}</td>
                        <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="ver(item)" data-toggle="modal" data-target="#modalVer" type="button" class="dropdown-item"><i class="fas fa-search"></i> Ver Prospecto</button>
                              <!-- <button (click)="Editar(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button> -->
                              <!-- <div class="dropdown-divider"></div> -->
                              <!-- <button (click)="Eliminar(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button> -->
                            </div>
                          </div>                         
                        </td>
                      </tr>
  
                    <!--Modal Ver  -->
                    <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                      <div role="document" class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div [ngClass]="{'bg-warning': itemVer.estado == 2, 'bg-primary':itemVer.estado == 1, 'bg-success':itemVer.estado == 3, 'bg-danger':itemVer.estado == 4 }"  class="modal-header ">
                            <h5 class="modal-title">
                              Detalle Prospecto
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>                            
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-5 text-bold">Nombre:</div>
                                  <div class="col-md-7">{{itemVer.nombre | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Departamento:</div>
                                  <div class="col-md-7">{{itemVer.departamento | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Ciudad:</div>
                                  <div class="col-md-7">{{itemVer.ciudad | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Dirección:</div>
                                  <div class="col-md-7">{{itemVer.direccion | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Zona:</div>
                                  <div class="col-md-7">{{itemVer.zona | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Correo:</div>
                                  <div class="col-md-7">{{itemVer.correo}}</div>

                                  <div class="col-md-5 text-bold">Celular:</div>
                                  <div class="col-md-7">{{itemVer.celular}}</div>

                                  <div class="col-md-5 text-bold">Tipo Negocio:</div>
                                  <div class="col-md-7">{{itemVer.tipo_negocio | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Antiguedad:</div>
                                  <div class="col-md-7">{{itemVer.antiguedad}}</div>

                                  <div class="col-md-5 text-bold">Flujo Clientes:</div>
                                  <div class="col-md-7">{{itemVer.flujo_clientes | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Calificación:</div>
                                  <div class="col-md-7">{{itemVer.calificacion}}</div>

                                  <div class="col-md-5 text-bold">Asesor:</div>
                                  <div class="col-md-7">{{itemVer.usuario_nombre | titlecase}}</div>

                                  <div class="col-md-5 text-bold">Fecha:</div>
                                  <div class="col-md-7">{{itemVer.prospecto_at}}</div>

                                  <button class="btn btn-block btn-danger mt-4" (click)="editarEstado(-1,itemVer.id)">Eliminar</button>
                                  
                                </div>                                 
                              </div>
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-12">

                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                      <div class="carousel-inner">
                                        <div class="carousel-item active" *ngIf="itemVer.img1">
                                          <img [src]="itemVer.img1" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="carousel-item" *ngIf="itemVer.img2">
                                          <img [src]="itemVer.img2" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="carousel-item" *ngIf="itemVer.img3">
                                          <img [src]="itemVer.img3" class="d-block w-100" alt="...">
                                        </div>
                                      </div>
                                      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                      </a>
                                      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                      </a>
                                    </div>

                                  </div>
                                </div>                                 
                              </div>

                            </div>
                          
                          </div>
                          <div class="modal-footer">
                            <button #CerrarModal (click)="cerrarmodal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="prospectos.current > 1">
                      <button type="button" class="page-link" (click)="changePage(prospectos.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': prospectos.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="prospectos.current < prospectos.total_page">
                      <button type="button" class="page-link" (click)="changePage(prospectos.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{prospectos.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>   
        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  