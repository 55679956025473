import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-listado-compensacion',
  templateUrl: './listado-compensacion.component.html',
  styleUrls: ['./listado-compensacion.component.css']
})
export class ListadoCompensacionComponent implements OnInit {


  cargando: boolean =false;
  compensaciones: any = [];
  compensacionesHistorial: any = [];
  compensacionesReporte: any = [];
  pagesNumber: any;
  offset= 3;
  ordenItem: any = {};
  imgURL: any;
  buscar: any;
  page: any;
  ReporteExport: any = {};
  fecha:any;


  
  constructor(private servicios: ServicesService,
    public router: Router,
    private _elementRef : ElementRef) { }

  ngOnInit(): void {
    this.listadoCompensaciones();
  }
  changePage(page) {
    this.compensaciones.current = page;
    this.listadoCompensaciones(page);        
  }

  

  convertirimg64(){
    var regularImages = this._elementRef.nativeElement.querySelectorAll("img");
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    [].forEach.call(regularImages, function (imgElement) {
      // preparing canvas for drawing
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;

      ctx.drawImage(imgElement, 0, 0);
      // by default toDataURL() produces png image, but you can also export to jpeg
      // checkout function's documentation for more details
      var dataURL = canvas.toDataURL();
      imgElement.setAttribute('src', dataURL);
    })
    canvas.remove();    
  }

  exportar(tipo){
    let params = {
      exportar: true,
      buscar: tipo,
    }
    console.log(params);
    
    this.servicios.postCompensacion(params)
    .subscribe( (data) => {
      this.ReporteExport = data['compensaciones'];
      console.log(data);
      
      const fileName = 'reporte_compensacion_'+tipo+'.xlsx';  
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.ReporteExport)
      
      // ANCHO COLUMNAS
      ws['!cols'] = [ {width:2},{width:2},{width:20}, {width:30},{width:30},{width:50},{width:20},{width:30},{width:10},{width:100}];
      // ELIMINA COLUMNA1
      ws['!cols'][0] = { hidden: true };
      ws['!cols'][1] = { hidden: true };
      ws['G2'] = { Target:"#E2" };
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      // CAMBIA CABECERA
      XLSX.utils.sheet_add_aoa(ws, [["log","Id","CODIGO PUNTO", "NOMBRE", "APELLIDO", "RAZON SOCIAL", "CUPO","CORRESPONSAL ESTADO","ESTADO", "IMG COMPENSACIÓN"],]);
      XLSX.utils.sheet_add_json(ws, this.ReporteExport, { origin: 'A2', skipHeader: true });  
    
      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);

     
    }); 

    
  }

  listadoCompensaciones(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getCompensaciones(1).subscribe( (data) => {
        this.compensaciones = data['compensaciones'];
        this.fecha = this.compensaciones.fecha;
        
        this.cargando = false;
        // console.log(data);
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getCompensaciones(page).subscribe( (data) => {
        this.compensaciones = data['compensaciones'];
        this.fecha = this.compensaciones.fecha;

        this.cargando = false;
        // console.log(data['compensaciones']);
        this.ForpagesNumber()

      });
    }     
  }

  filtro(){
    // console.log('page '+this.page);
    // console.log('buscar '+this.buscar);
      let params = {
        filtro: true,
        buscar: this.buscar,
        page: this.page
      }
      this.servicios.postCompensacion(params)
      .subscribe( (data) => {
          if (data["logger"] == true){
            this.compensaciones = data['compensaciones'];
            this.ForpagesNumber();
          } else {
          }      
      });    
  }    

  ForpagesNumber() {
    let from = this.compensaciones.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.compensaciones.total_page){
      to = this.compensaciones.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  } 
  
  VerOrdenCompensacion(item){
    this.compensacionesHistorial = [];
    this.ordenItem = item;
  }

  AprobadoBloqueado(estado,ordenItem){
    console.log('page current '+this.compensaciones.current);
     let params = {
          id: ordenItem.compensacion_id,
          editarEstado: true,
          estado: estado,
        }
        // this.ordenItem = ordenItem;

        // console.log(params);
        this.servicios.postCompensacion(params)
        .subscribe( (data) => {
          // console.log(data);
          if (data["logger"] == true){
            this.listadoCompensaciones(this.compensaciones.current);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });

          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
          }

        });    
  }

  HistorialCompensacion(ordenItem){
    this.servicios.getCompensacionesHistorial(ordenItem.id).subscribe( (data) => {
      this.compensacionesHistorial = data['compensaciones'];
      // this.cargando = false;
      console.log(data['compensaciones']);
    });      
  }

  cerrarmodal(){
    this.imgURL = '';
    this.ordenItem = '';
    this.compensacionesHistorial = [];
  } 

}
