
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header d-print-none">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Mensajeria</h1>
              </div>
              <div class="col-sm-12 mt-3">
                <a routerLink="/mensajeria/listado" class="btn btn-success mr-1">Regresar</a>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="onDescargar()" *ngIf="!cargando" class="btn btn-primary"><i class="fas fa-download"></i> Descargar</button>
                <button (click)="onPrint()" type="button" class="btn btn-secondary ml-1">
                    <i class="fas fa-print"></i> Imprimir
                </button>
              </div>
            </div>
          </div>
        </section>
  
        <section class="content">
            
        <div class="" id="print">
            <div class="card">
              <div class="card-header bg-dark text-white">
                <h4>Pago Express Mensajeria</h4>
              </div>
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                        <div class="row d-flex justify-content-between">
                          <h3>Origen: {{itemImp.ciudad_origen}} - Destino: {{itemImp.ciudad_destino}}</h3>
                          <ngx-barcode [bc-value]="itemImp.id" [bc-display-value]="true" [bc-height]="40"></ngx-barcode>
                        </div>
                    </div> 
                    <div class="col-4">
                      <h5>Destinatario</h5>
                      <p>{{itemImp.destino_tipo_identificacion | titlecase}}: {{itemImp.destino_identificacion}}</p>
                      <p>Nombre: {{itemImp.destino_nombre_razon_social}} {{itemImp.destino_primer_apellido}} {{itemImp.destino_segundo_apellido}}</p>
                      <p>Dirección: {{itemImp.destino_direccion}}</p>
                      <p>Celular: {{itemImp.destino_celular}}</p>
                      <p>Email: {{itemImp.destino_email}}</p>
                      <p>Ciudad: {{itemImp.departamento_destino}}</p>
                      <p>Ciudad: {{itemImp.ciudad_destino}}</p>
                    </div>
                    <div class="col-4">
                      <h5>Remitente</h5>
                      <p>{{itemImp.remite_tipo_identificacion | titlecase}}: {{itemImp.remite_identificacion}}</p>
                      <p>Nombre: {{itemImp.remite_nombre_razon_social}} {{itemImp.remite_primer_apellido}} {{itemImp.remite_segundo_apellido}}</p>
                      <p>Dirección: {{itemImp.remite_direccion}}</p>
                      <p>Celular: {{itemImp.remite_celular}}</p>
                      <p>Email: {{itemImp.remite_email}}</p>
                      <p>Ciudad: {{itemImp.departamento_origen}}</p>
                      <p>Ciudad: {{itemImp.ciudad_origen}}</p>
                    </div>
                    <div class="col-4">
                      <h5>Despacho</h5>
                      <p>Peso: {{itemImp.peso}} Kg</p>
                      <p>Dice Contener: {{itemImp.dice_contener}}</p>
                      <p>Observaciones: {{itemImp.nota}}</p>
                      <h4>Forma de pago: {{itemImp.forma_pago | titlecase}}</h4>
                      <!-- <h5>Liquidación</h5>
                      <p>Valor flete: $ {{itemImp.valor_flete | decimal}}</p>
                      <p>Valor servicio: $ {{itemImp.valor_servicio | decimal}}</p>
                      <p>Valor total: $ {{itemImp.valor_total | decimal}}</p>
                      <p class="bg-dark text-light">Forma de pago: {{itemImp.forma_pago}}</p> -->
                    </div>
                    <div class="col-12">
                        <h3 class="float-right">Valor Total: ${{itemImp.valor_total | decimal}}</h3>
                    </div>  
                </div>
              </div>
            </div>
          </div>   
            <div class="card">
              <div class="card-header bg-dark text-white">
                <h4>Pago Express Mensajeria</h4>
              </div>
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                        <div class="row d-flex justify-content-between">
                          <h3>Origen: {{itemImp.ciudad_origen}} - Destino: {{itemImp.ciudad_destino}}</h3>
                          <ngx-barcode [bc-value]="itemImp.id" [bc-display-value]="true" [bc-height]="40"></ngx-barcode>
                        </div>
                    </div> 
                    <div class="col-4">
                      <h5>Destinatario</h5>
                      <p>{{itemImp.destino_tipo_identificacion | titlecase}}: {{itemImp.destino_identificacion}}</p>
                      <p>Nombre: {{itemImp.destino_nombre_razon_social}} {{itemImp.destino_primer_apellido}} {{itemImp.destino_segundo_apellido}}</p>
                      <p>Dirección: {{itemImp.destino_direccion}}</p>
                      <p>Celular: {{itemImp.destino_celular}}</p>
                      <p>Email: {{itemImp.destino_email}}</p>
                      <p>Ciudad: {{itemImp.departamento_destino}}</p>
                      <p>Ciudad: {{itemImp.ciudad_destino}}</p>
                    </div>
                    <div class="col-4">
                      <h5>Remitente</h5>
                      <p>{{itemImp.remite_tipo_identificacion | titlecase}}: {{itemImp.remite_identificacion}}</p>
                      <p>Nombre: {{itemImp.remite_nombre_razon_social}} {{itemImp.remite_primer_apellido}} {{itemImp.remite_segundo_apellido}}</p>
                      <p>Dirección: {{itemImp.remite_direccion}}</p>
                      <p>Celular: {{itemImp.remite_celular}}</p>
                      <p>Email: {{itemImp.remite_email}}</p>
                      <p>Ciudad: {{itemImp.departamento_origen}}</p>
                      <p>Ciudad: {{itemImp.ciudad_origen}}</p>
                    </div>
                    <div class="col-4">
                      <h5>Despacho</h5>
                      <p>Peso: {{itemImp.peso}} Kg</p>
                      <p>Dice Contener: {{itemImp.dice_contener}}</p>
                      <p>Observaciones: {{itemImp.nota}}</p>
                      <h4>Forma de pago: {{itemImp.forma_pago | titlecase}}</h4>
                      <!-- <h5>Liquidación</h5>
                      <p>Valor flete: $ {{itemImp.valor_flete | decimal}}</p>
                      <p>Valor servicio: $ {{itemImp.valor_servicio | decimal}}</p>
                      <p>Valor total: $ {{itemImp.valor_total | decimal}}</p>
                      <p class="bg-dark text-light">Forma de pago: {{itemImp.forma_pago}}</p> -->
                    </div>
                    <div class="col-12">
                        <h3 class="float-right">Valor Total: ${{itemImp.valor_total | decimal}}</h3>
                    </div>  
                </div>
              </div>
            </div>
          </div>   
            <div class="card">
              <div class="card-header bg-dark text-white">
                <h4>Pago Express Mensajeria</h4>
              </div>
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                        <div class="row d-flex justify-content-between">
                          <h3>Origen: {{itemImp.ciudad_origen}} - Destino: {{itemImp.ciudad_destino}}</h3>
                          <ngx-barcode [bc-value]="itemImp.id" [bc-display-value]="true" [bc-height]="40"></ngx-barcode>
                        </div>
                    </div> 
                    <div class="col-4">
                      <h5>Destinatario</h5>
                      <p>{{itemImp.destino_tipo_identificacion | titlecase}}: {{itemImp.destino_identificacion}}</p>
                      <p>Nombre: {{itemImp.destino_nombre_razon_social}} {{itemImp.destino_primer_apellido}} {{itemImp.destino_segundo_apellido}}</p>
                      <p>Dirección: {{itemImp.destino_direccion}}</p>
                      <p>Celular: {{itemImp.destino_celular}}</p>
                      <p>Email: {{itemImp.destino_email}}</p>
                      <p>Ciudad: {{itemImp.departamento_destino}}</p>
                      <p>Ciudad: {{itemImp.ciudad_destino}}</p>
                    </div>
                    <div class="col-4">
                      <h5>Remitente</h5>
                      <p>{{itemImp.remite_tipo_identificacion | titlecase}}: {{itemImp.remite_identificacion}}</p>
                      <p>Nombre: {{itemImp.remite_nombre_razon_social}} {{itemImp.remite_primer_apellido}} {{itemImp.remite_segundo_apellido}}</p>
                      <p>Dirección: {{itemImp.remite_direccion}}</p>
                      <p>Celular: {{itemImp.remite_celular}}</p>
                      <p>Email: {{itemImp.remite_email}}</p>
                      <p>Ciudad: {{itemImp.departamento_origen}}</p>
                      <p>Ciudad: {{itemImp.ciudad_origen}}</p>
                    </div>
                    <div class="col-4">
                      <h5>Despacho</h5>
                      <p>Peso: {{itemImp.peso}} Kg</p>
                      <p>Dice Contener: {{itemImp.dice_contener}}</p>
                      <p>Observaciones: {{itemImp.nota}}</p>
                      <h4>Forma de pago: {{itemImp.forma_pago | titlecase}}</h4>
                      <!-- <h5>Liquidación</h5>
                      <p>Valor flete: $ {{itemImp.valor_flete | decimal}}</p>
                      <p>Valor servicio: $ {{itemImp.valor_servicio | decimal}}</p>
                      <p>Valor total: $ {{itemImp.valor_total | decimal}}</p>
                      <p class="bg-dark text-light">Forma de pago: {{itemImp.forma_pago}}</p> -->
                    </div>
                    <div class="col-12">
                        <h3 class="float-right">Valor Total: ${{itemImp.valor_total | decimal}}</h3>
                    </div>  
                </div>
              </div>
            </div>
          </div>   
        </div> 
        </section>
        
      <app-footer-main></app-footer-main>  
  </div>  