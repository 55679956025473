import { Component, OnInit } from '@angular/core';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';

@Component({
  selector: 'app-print-mensajeria',
  templateUrl: './print-mensajeria.component.html',
  styleUrls: ['./print-mensajeria.component.css']
})
export class PrintMensajeriaComponent implements OnInit {

  itemImp:any;
  cargando:any = false;
  constructor(private router: Router,
              private servicios: ServicesService) {
    if(this.router.getCurrentNavigation().extras.state){
      this.itemImp = this.router.getCurrentNavigation().extras.state.itemMensajeria;
    }else{
      this.router.navigate(['/mensajeria/listado']);
    }
  }

  ngOnInit(): void {
  this.servicios.getDepartamentoMunicipios(this.itemImp.departamento_origen+"-"+this.itemImp.ciudad_origen).subscribe(
    data => {
      this.itemImp.departamento_origen = data['departamento'][0].nombre;
      this.itemImp.ciudad_origen = data['municipio'][0].municipio;
    }
  )
  this.servicios.getDepartamentoMunicipios(this.itemImp.departamento_destino+"-"+this.itemImp.ciudad_destino).subscribe(
    data => {
      this.itemImp.departamento_destino = data['departamento'][0].nombre;
      this.itemImp.ciudad_destino = data['municipio'][0].municipio;
    }
  )
  }

  onDescargar(item){
    this.cargando = true;
    // Extraemos el
    const DATA = document.getElementById('print');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 2;
      const bufferY = 2;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      this.cargando = false;
    });
  }

  onPrint(){
    window.print();
  }
}
