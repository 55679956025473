<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Cambiar Contraseña</h1>
              </div>
              <div class="col-sm-12 mt-3">
              </div>
            </div>
          </div>
        </section>
        
        <section class="content">
            <div class="card">
                <div class="row mt-4 p-4">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Nueva Contraseña</label>
                            <div class="input-group">
                                <input [(ngModel)]="pass"
                                minlength="5"
                                name="pass" 
                                placeholder=""
                                [type]="fieldTextType ? 'text' : 'password'"
                                class="form-control">
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                      (click)="toggleFieldTextTypePass()"></i>
                                  </span>
                                </div>    
                            </div>                    
                        </div>                
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Repetir Contraseña</label>
                            <div class="input-group">
                                <input [(ngModel)]="repass"
                                name="repass" 
                                minlength="5"
                                placeholder=""
                                [type]="fieldTextType ? 'text' : 'password'"
                                class="form-control">
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                                      (click)="toggleFieldTextTypePass()"></i>
                                  </span>
                                </div>    
                            </div>
                        </div>                
                        <div class="form-group">
                        </div>                
                    </div>   
                    <div class="col-12 text-center mt-4">
                        <button *ngIf="pass === repass" type="button" class="btn btn-lg btn-primary" [disabled]="!pass || !repass" (click)="changePass()">Actualizar</button>
                    </div>         
                </div> 
            </div>

        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  