
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content">
           <div class="row">
            <div class="col-md-12" style="margin-top: 2%;">
                <div class="card card-primary">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Fecha Desde:</label>
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">
                                          <i class="far fa-calendar-alt"></i>
                                        </span>
                                      </div>
                                      <input [(ngModel)]="fecha_desde" name="fecha_desde" type="date" class="form-control float-right">
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Fecha Hasta:</label>
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">
                                          <i class="far fa-calendar-alt"></i>
                                        </span>
                                      </div>
                                      <input [(ngModel)]="fecha_hasta"name="fecha_hasta" type="date" class="form-control float-right">
                                    </div>
                                  </div>                                
                            </div>

                            <div class="col-md-3" *ngIf="perfil_id <= 2">
                              <div class="form-group">
                                  <label>Punto:</label>
                                  <div class="input-group">
                                    <select [(ngModel)]="punto" name="punto" class="form-control float-right">
                                      <option value=''><b>TODOS</b></option>
                                      <option [value]="item.id" *ngFor="let item of puntos">{{item.razon_social | uppercase}}</option>
                                    </select>
                                  </div>
                                </div>                                
                            </div>                            
                            <div class="col-md-3" style="margin-top:31px;">
                              <button (click)="ListadoReportes()" type="button" class="btn btn-primary"><i class="fa fa-search"></i> </button>
                              <button *ngIf="Reporte.items" (click)="exportExcel()" type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button>
                            </div>
                        </div>
                      <!-- Date range -->
                      

                      <section class="content">
                        <div class="row">
                          <div class="col-12" *ngIf="Reporte.items">
                            <div class="card card-primary" style="padding-top: 5px;padding-bottom: 5px;">
                              <div class="card-body" style="padding-top: 0%;padding-bottom: 0%;">
                                <h4><span class="badge bg-warning">Pendiente {{Reporte.estado_count_pendiente}}</span> <span class="badge bg-info">En ruta {{Reporte.estado_count_en_ruta}}</span> <span class="badge bg-success">Despachado {{Reporte.estado_count_despachado}}</span>  <span class="badge bg-danger">Cancelado {{Reporte.estado_count_cancelado}}</span> 
                                  <!-- <button (click)="exportExcel()" type="button" class="btn btn-sm btn-success"><i class="fa fa-file-excel"></i> </button> -->
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="card">
                              <div class="card-body table-responsive p-0">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>NÚMERO GUIA</th>
                                          <th>COMERCIO</th>
                                          <th>VALOR</th>
                                          <th>FECHA</th>
                                          <th>ESTADO</th>
                                          <th>ACCIONES</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let item of Reporte.items">
                                          <td>{{item.id}}</td>
                                          <td>{{item.numero_guia}}</td>
                                          <td>{{item.razon_social | titlecase}}</td>
                                          <td>${{item.valor_total | decimal}}</td>
                                          <td>{{item.mensajeria_at}}</td>
                                          <td><h4><span class="badge bg-success" *ngIf="item.estado ==3">Despachado</span><span class="badge bg-danger" *ngIf="item.estado ==0">Rechazado</span><span class="badge bg-warning" *ngIf="item.estado ==1">Pendiente</span><span class="badge bg-info" *ngIf="item.estado ==2">En ruta</span></h4></td>
                                          <td>                         
                                          </td>
                                        </tr>
                                     
                                  </tbody>
                                </table>

                                <!-- valor total -->
                                <div style="text-align: right;margin-right: 15%;" *ngIf="valorTotal"><h1>$ {{valorTotal  | decimal}}</h1></div>
                              </div>
                
                              <div class="card-footer clearfix">
                                <ul class="pagination pagination-sm m-0 float-right">
                                  <li class="page-item" *ngIf="Reporte.current > 1">
                                    <button type="button" class="page-link" (click)="changePage(Reporte.current - 1)">
                                      <span>Atras</span>
                                    </button>
                                  </li>                  
                
                                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': Reporte.current == page}">
                                    <button type="button" class="page-link" (click)="changePage(page)">
                                      {{ page }}
                                    </button>
                                  </li>                  
                
                                  <li class="page-item" *ngIf="Reporte.current < Reporte.total_page">
                                    <button type="button" class="page-link" (click)="changePage(Reporte.next)">
                                      <span>Siguiente</span>
                                    </button>
                                  </li>                  
                                </ul>
                                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                                  <p>Total registros encontrados: <b>{{Reporte.size}}</b></p>
                                </div>
                              </div>              
                            </div>
                         
                          </div>
                        </div>   
                      </section>  

                    </div>
                    <!-- /.card-body -->
                  </div> 
            </div>
           </div>
        </div>
        <!-- fin content -->
      </div>
      
      <app-footer-main></app-footer-main>  
  </div>  
  
