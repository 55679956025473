<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>
  
    <div class="content-wrapper">
      <!-- Modales -->
      <!--Modal Solicitud Pasar Ganancia -->
      <<div id="modalSolicitudPasarGanancia" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title">
                Nueva Solicitud 
                <div *ngIf="spinner" class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </h5>
              <button type="button" class="close" (click)="cerrarModal('modalSolicitudPasarGanancia')" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <!-- <p>Rellena los siguientes campos <small>(los campos marcados con * son obligatorios)</small></p> -->
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="valor" class="form-label">Valor*</label>
                          <input [(ngModel)]="valor" class="form-control" name="valor" currencyMask
                            [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button (click)="guardar()" type="button" class="btn btn-primary">Enviar</button>
              <button #CerrarModal (click)="cerrarModal('modalSolicitudPasarGanancia')" type="button" class="btn btn-danger"
                data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin modal solicitud saldo -->
  
      <!--Modal Ver  -->
      <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true" data-backdrop="true">
        <div role="document" class="modal-dialog modal-sm">
          <div class="modal-content">
            <div
              [ngClass]="{'bg-warning': itemVer.estado == 1, 'bg-success':itemVer.estado == 2, 'bg-danger':itemVer.estado == 3 }"
              class="modal-header ">
              <h5 class="modal-title">
                Detalle Solicitud
                <div *ngIf="spinner" class="spinner-border mt-2" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5 text-bold h5">ID:</div>
                    <div class="col-md-7 h5">{{itemVer.id}}</div>
  
                    <div class="col-md-5 text-bold h5">Comercio:</div>
                    <div class="col-md-7 h5">{{itemVer.razon_social | titlecase}}</div>
  
                    <div class="col-md-5 text-bold h5">Valor:</div>
                    <div class="col-md-7 h5">${{itemVer.valor | decimal}}</div>
                    
                    <div class="col-md-5 text-bold h5">Fecha:</div>
                    <div class="col-md-7 h5">{{itemVer.fecha}}</div>
  
                    <div class="col-md-5 text-bold h5">Estado:</div>
                    <div class="col-md-7">
                      <h4><span class="badge bg-danger" *ngIf="itemVer.estado == 3">Rechazado</span><span
                          class="badge bg-warning" *ngIf="itemVer.estado == 1">Pendiente</span><span
                          class="badge bg-success" *ngIf="itemVer.estado == 2">Aprobado</span></h4>
                    </div>
  
                    <button [disabled] = "spinner" *ngIf="itemVer.estado == 1" class="btn btn-block btn-success mt-4" (click)="procesar(itemVer,2)">Aprobar</button>
                    <button [disabled] = "spinner" *ngIf="itemVer.estado == 1" class="btn btn-block btn-danger mt-1" (click)="procesar(itemVer,3)">Rechazar</button>
                  </div>
                </div>
  
              </div>
  
            </div>
            <div class="modal-footer">
              <button #CerrarModal (click)="cerrarModal('modalVer')" type="button" class="btn btn-secondary"
                data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin modal ver -->
      <!-- Fin modales -->
  
  
      <!-- INICIO LISTADO -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>Modulo Solicitud Pasar Ganancia a Saldo</h1>
            </div>
            <div class="col-sm-12 col-md-6 mt-3">
  
              <button type="button" *ngIf="cargando" class="btn btn-primary"><span
                  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
              <button type="button" (click)="listadoSolicitudesPasarGanancia()" *ngIf="!cargando" class="btn btn-primary"><i
                  class="fa fa-sync"></i> </button>
              <!-- Boton Modal Solicitud -->
              <button *ngIf="perfil_id == 4" type="button" class="btn btn-success m-2" data-toggle="modal"
                data-target="#modalSolicitudPasarGanancia"><i class="fa fa-plus"></i> Solicitar pasar gananacia a saldo</button>
  
            </div>
            <!-- <div class="col-sm-12 col-md-6 mt-3" *ngIf="perfil_id == 1">
              <input [(ngModel)]="buscar" name="buscar" type="text" (change)="filtro()"
                placeholder="Buscar por comercio"
                class="form-control float-right text-uppercase">
            </div> -->
          </div>
        </div>
      </section>
  
      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Comercio</th>
                      <th>Valor</th>
                      <th>Fecha</th>
                      <th>Estado</th>
                      <th *ngIf="perfil_id == 1">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of pasar_ganancia.items">
                      <td>{{item.id}}</td>
                      <td>{{item.razon_social | titlecase}}</td>
                      <td>${{item.valor | decimal}}</td>
                      <td>{{item.fecha}}</td>
                      <td>
                        <h4><span class="badge bg-danger" *ngIf="item.estado == 3">Rechazado</span><span
                            class="badge bg-warning" *ngIf="item.estado == 1">Pendiente</span><span
                            class="badge bg-success" *ngIf="item.estado == 2">Aprobado</span></h4>
                      </td>
                      <td *ngIf="perfil_id == 1">
                        <div class="dropdown">
                          <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button (click)="ver(item)" data-toggle="modal" data-target="#modalVer" type="button"
                              class="dropdown-item"><i class="fas fa-search"></i> Ver Detalles</button>
  
                            <!-- <button (click)="EditarComercio(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button>
                            <button (click)="MultiproductoComercio(item)" type="button" class="dropdown-item"><i class="fas fa-shopping-basket"></i> Multiproductos</button>
                            <div class="dropdown-divider"></div>
                            <button (click)="EliminarComercio(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button> -->
                          </div>
                        </div>
                      </td>
                    </tr>
  
                  </tbody>
                </table>
              </div>
  
  
              <!-- paginador -->
              <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="pasar_ganancia.current > 1">
                    <button type="button" class="page-link" (click)="changePage(pasar_ganancia.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>
  
                  <li class="page-item" *ngFor="let page of pagesNumber"
                    [ngClass]="{'active': pasar_ganancia.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>
  
                  <li class="page-item" *ngIf="pasar_ganancia.current < pasar_ganancia.total_page">
                    <button type="button" class="page-link" (click)="changePage(pasar_ganancia.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{pasar_ganancia.size}}</b></p>
                </div>
              </div>
              <!-- Fin paginador -->
  
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </section>
      <!-- FIN LISTADO -->
  
    </div>
    <app-footer-main></app-footer-main>
  </div>