
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        
      <!-- MODAL NUEVO RECAUDO -->
      <div class="modal fade" id="nuevaConciliacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header" style="border-bottom:0px;">
              <!-- <h5 class="modal-title" id="exampleModalLabel">Nuevo Recaudo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
              <div class="mb-2" style="border-radius: 1%/30%;
              background-color: #00c4ea;
              color: #fff;
              width: 100%;
              line-height: 2.3;
              padding-top: 5px;
              padding-bottom: 5px;">
                <h3 class="text-center m-0" style="font-weight: 600;">Conciliacion Convenios</h3>
              </div>                
            </div>
            <div class="modal-body pt-0">
              <div id="convenios">
                <nav class="navbar navbar-dark bg-dark p-0 text-center" style="border-radius: 5px;">
                  <div class="" style="text-align: center;width: 100%;">CONVENIOS</div>
                </nav>
                <div class="row align-items-center">
                  <div class="col-2" *ngFor="let item of listConvenios; let i = index">
                    <div id="divConvenio" class="mt-2" style="cursor: pointer;" (click)="selectConvenio(item)">
                      <img [src]="item.imgUrl" class="card-img-top rounded-circle" alt="...">
                    </div>
                  </div>
                </div>

              </div>
              <div id="newConciliacion" style="display: none;">

                <div class="row">
                  <div class="col-3 text-center">
                    <img [src]="img" class="card-img-top" style="width: 150px;">
                    <div class="pt-0">
                      <h3 class="card-text mb-0"><b>{{razon_social}}</b></h3>
                      <p class="card-text mt-0">{{tipo_documento}} {{numero_documento}}</p>
                    </div>                      
                  </div>
                  <div class="col-9">
                    <div class="row">
                      <button class="btn btn-block btn-default mb-3" (click)="backConvenios()"><i class="fa fa-chevron-left"></i></button>

                      <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Valor</label>
                                <input [(ngModel)]="valor" name="valor" class="form-control" style="font-size: 2em;" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" min="900">
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Observación</label>
                                <textarea class="form-control" rows="3" [(ngModel)]="nota"></textarea>
                            </div>                            
                        </div>
                        <div class="col-md-12">
                        <div class="form-group">
                          <div>
                            <label for="img" class="form-label">Comprobante PNG/JPEG/JPG*</label>
                            <div *ngIf="cargando" class="spinner-border" role="status"></div>
                          </div>
                            <input type="file" class="form-control" #file accept='image/png, image/jpeg, image/jpg' (change)="preview(file.files)">
                          </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <button class="btn btn-primary btn-block" [disabled]="!img || !valor || !nota || disabledButton" (click)="saveConciliacion()">
                              <span *ngIf="!disabledButton">Enviar</span>
                              <span *ngIf="disabledButton">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Procesando...
                              </span>
                            </button>
                        </div>                        
                    </div>
                    </div>
                  </div>
                  <div class="col-6">

                    <!-- <div class="row">
                      <div class="col-2">
                        <img [src]="img" class="card-img-top">
                      </div>
                      <div class="col-10">
                        <div class="pt-0">
                          <h3 class="card-text mb-0"><b>{{razon_social}}</b></h3>
                          <p class="card-text mt-0">{{tipo_documento}} {{numero_documento}}</p>
                        </div>                          
                      </div>
                    </div> -->
  
                  </div> 
                </div>                                                      
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModal('nuevaConciliacion')">Cerrar</button>
              <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
          </div>
        </div>
      </div>  
      <!-- FIN MODAL NUEVO RECAUDO -->
      <!--Modal Ver  -->
      <div id="modalVer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div role="document" class="modal-dialog modal-lg">
        <div class="modal-content">
          <div
            [ngClass]="{'bg-info': itemVer.estado == 2,'bg-warning': itemVer.estado == 1, 'bg-danger':itemVer.estado == 0, 'bg-success':itemVer.estado == 3}"
            class="modal-header ">
            <h5 class="modal-title">
              Detalle Conciliación
              <div *ngIf="spinner" class="spinner-border mt-2" role="status">
                <span class="visually-hidden"></span>
              </div>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-5 text-bold h5">ID:</div>
                  <div class="col-md-7 h5">{{itemVer.id}}</div>

                  <div class="col-md-5 text-bold h5">Comercio:</div>
                  <div class="col-md-7 h5">PagoExpress</div>
                  
                  <div class="col-md-5 text-bold h5">Recaudador:</div>
                  <div class="col-md-7 h5">{{itemVer.recaudo_razon_social | titlecase}}</div>

                  <div class="col-md-5 text-bold h5">Valor:</div>
                  <div class="col-md-7 h5">${{itemVer.valor | decimal}}</div>

                  <div class="col-md-5 text-bold h5">Fecha:</div>
                  <div class="col-md-7 h5">{{itemVer.conciliacion_at}}</div>

                  <div class="col-md-5 text-bold h5">Descripción:</div>
                  <div class="col-md-7 h5">{{itemVer.nota | titlecase}}</div>

                  <div class="col-md-5 text-bold h5">Estado:</div>
                  <div class="col-md-7">
                    <h4><span class="badge bg-success" *ngIf="itemVer.estado ==3">Recibido</span><span class="badge bg-danger" *ngIf="itemVer.estado ==0">Rechazado</span><span class="badge bg-warning" *ngIf="itemVer.estado ==1">Pendiente</span><span class="badge bg-info" *ngIf="itemVer.estado ==2">Procesado</span></h4>
                  </div>

                  <button [disabled] = "spinner" *ngIf="itemVer.estado == 1 && perfil_id == 1" class="btn btn-block btn-info mt-4" (click)="procesar(itemVer, 2)">Procesar</button>
                  <button [disabled] = "spinner" *ngIf="itemVer.estado == 1 && perfil_id == 1" class="btn btn-block btn-danger mt-1" (click)="procesar(itemVer, 0)">Rechazar</button>
                  <button [disabled] = "spinner" *ngIf="itemVer.estado == 2 && perfil_id == 3" class="btn btn-block btn-success mt-4" (click)="procesar(itemVer, 3)">Aprobar</button>
                  <button [disabled] = "spinner" *ngIf="itemVer.estado == 2 && perfil_id == 3" class="btn btn-block btn-danger mt-1" (click)="procesar(itemVer, -1)">Rechazar</button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <img *ngIf="itemVer.img" [src]="itemVer.img" class="d-block w-100" alt="Imagen no encontrada">
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button #CerrarModal (click)="cerrarModal('modalVer')" type="button" class="btn btn-secondary"
              data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
      </div>
      <!-- Fin modal ver -->

    <!-- Modal Filtro Reporte -->
    <div class="modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exportModalLabel">Exportar Reporte</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
            
              <div class="col-md-5">
                <div class="form-group">
                    <label>Fecha Desde:</label>
                    <div class="input-group">
                      <input [(ngModel)]="fecha_desde" name="fecha_desde" type="date" class="form-control float-right">
                    </div>
                  </div>
              </div>
              <div class="col-md-5">
                  <div class="form-group">
                      <label>Fecha Hasta:</label>
                      <div class="input-group">
                        <input [(ngModel)]="fecha_hasta"name="fecha_hasta" type="date" class="form-control float-right">
                      </div>
                    </div>                                
              </div>
              <div class="col-md-2" style="margin-top:31px;">
                <button [disabled]="!fecha_hasta || !fecha_desde" (click)="exportExcel()" type="button" class="btn btn-primary"><i class="fa fa-search"></i> </button>
              </div>         
            </div>
          </div>

        </div>
      </div>
    </div>



        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Conciliación</h1>
              </div>
              <div class="col-sm-12 mt-3">
                <button *ngIf="perfil_id == 1" type="button" data-toggle="modal" data-target="#nuevaConciliacion" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoConciliaciones()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                <button class="btn btn-success" type="button" id="button-addon2"  data-toggle="modal" data-target="#exportModal"><i class="fa fa-file-excel"></i></button>

              </div>
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>COMERCIO</th>
                        <th>RECAUDADOR</th>
                        <th>VALOR</th>
                        <th>FECHA</th>
                        <th>ESTADO</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of conciliaciones.items">
                        <td>{{item.id}}</td>
                        <td>{{item.comercio_razon_social}} - PagoExpress</td>
                        <td>{{item.recaudo_razon_social | titlecase}}</td>
                        <td>${{item.valor | decimal}}</td>
                        <td>{{item.conciliacion_at}}</td>
                        <td><h4><span class="badge bg-success" *ngIf="item.estado ==3">Recibido</span><span class="badge bg-danger" *ngIf="item.estado ==0">Rechazado</span><span class="badge bg-warning" *ngIf="item.estado ==1">Pendiente</span><span class="badge bg-info" *ngIf="item.estado ==2">Procesado</span></h4></td>
                        <td>
                          <div class="dropdown">
                            <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button (click)="ver(item)" type="button" class="dropdown-item" data-toggle="modal" data-target="#modalVer"><i class="fas fa-search"></i> Ver detalle</button>
                            </div>
                          </div>                         
                        </td>
                      </tr>
  
                    </tbody>
                  </table>
                </div>
  
  
                <!-- paginador -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" *ngIf="conciliaciones.current > 1">
                      <button type="button" class="page-link" (click)="changePage(conciliaciones.current - 1)">
                        <span>Atras</span>
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': conciliaciones.current == page}">
                      <button type="button" class="page-link" (click)="changePage(page)">
                        {{ page }}
                      </button>
                    </li>                  
  
                    <li class="page-item" *ngIf="conciliaciones.current < conciliaciones.total_page">
                      <button type="button" class="page-link" (click)="changePage(conciliaciones.next)">
                        <span>Siguiente</span>
                      </button>
                    </li>                  
                  </ul>
                  <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                    <p>Total registros encontrados: <b>{{conciliaciones.size}}</b></p>
                  </div>
                </div>              
                <!-- Fin paginador -->               
              
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
          </div>   
        </section>   
      </div> 

      <app-footer-main></app-footer-main>  
  </div>  