
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Compensaciones Nuevo</h1>
              </div>
              <!--
              <div class="col-sm-12 mt-3">             
                <div style="font-size: 1.2em;" id="estadoCorresponsal" *ngIf="!comercio.estado_corresponsal">Su correponsal esta bloqueado? <button (click)="estadoCorresponsal(-1)" class="btn btn-primary">SI</button> <button (click)="estadoCorresponsal(1)" class="btn btn-success ml-1">NO</button></div>

                <button type="button" routerLink="/comercios/nuevo" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
                <button type="button" (click)="listadoComercios()" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
              </div>
              -->
            </div>
          </div>
        </section>
  
        <section class="content">
          <div class="card">
            <div class="card-body">
              <!-- formulario compensacion -->
              <div *ngIf="!compensacionCliente">
                <form [formGroup]="form" (ngSubmit)="EnviarCompensacion()">
                <div class="row">
                  <div class="col-6">
                      <div class="row">
                        <div class="col-md-12">
                          <label><h2>Cupo Asignado <span style="color: red;">${{cupoAsignado | decimal}}</span></h2></label>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Cupo Bancolombia <span style="color: red;">*</span> </label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.cupo.errors }" 
                            name="cupo" 
                            class="form-control" 
                            formControlName="cupo"
                            currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"
                            min="900"
                            style="font-size: 2em;">
                            <div *ngIf="submitted && f.cupo.errors" class="invalid-feedback">
                              <div *ngIf="f.cupo.errors.required">Campo obligatorio</div>
                            </div>
                          </div>
                        </div> 

                        <!-- <div class="col-md-12">
                          <div class="form-group">
                            <label>Cupo Prepago </label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.cupo_prepago.errors }" 
                            name="cupo_prepago" 
                            class="form-control" 
                            formControlName="cupo_prepago"
                            currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"
                            min="900"
                            style="font-size: 2em;">
                            <div *ngIf="submitted && f.cupo_prepago.errors" class="invalid-feedback">
                              <div *ngIf="f.cupo_prepago.errors.required">Campo obligatorio</div>
                            </div>
                          </div>
                        </div>                        -->
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Detalle </label>
                            <textarea [ngClass]="{ 'is-invalid': submitted && f.detalle.errors }" 
                            name="detalle" 
                            class="form-control" 
                            formControlName="detalle"                          
                            style="font-size: 1.2em; height: 200px;">
                            </textarea>
                            <div *ngIf="submitted && f.detalle.errors" class="invalid-feedback">
                              <div *ngIf="f.detalle.errors.required">Campo obligatorio</div>
                            </div>
                          </div>
                        </div>  
                      </div>
                  </div>
                  <div class="col-6 text-center">
                    <img *ngIf="imgURL" style="margin-top:1%" [src]="imgURL" height="200" width="100%" class="img-fluid" alt="Responsive image"> 
      
                    <input #file type="file" accept='image/*' (change)="preview(file.files)" style="display:none;"/>
                      
                    
                    <div *ngIf="!imgurl">
                      <i class="fas fa-receipt" style="font-size: 5em;margin-top: 10%;margin-bottom: 2%;"></i>
                      <h5 class="linkFile mt-2" (click)="file.click()"><span id="subirImg" style="cursor: pointer;">Subir Imagen Compensación</span></h5>
                    </div>
                        
                    <div *ngIf="loaderImgComprobante" class="text-center">
                      <div class="spinner-border" role="status"></div>
                    </div>              
      
      
                  </div>
                  <div class="col-12">
                    <!-- <button type="submit" *ngIf="form.value.cupo && imgurl"  class="btn btn-primary btn-block" [disabled]="disabledButton"> -->
                    <button type="submit" *ngIf="form.value.cupo && imgurl"  class="btn btn-primary btn-block" [disabled]="disabledButton">
                      <span *ngIf="!disabledButton">Enviar Solicitud Compensación</span>
                      <span *ngIf="disabledButton">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Procesando...
                      </span>
                    </button>                  
                  </div>
                </div>
                <!-- fin row -->
                </form>
              </div>
              <div *ngIf="compensacionCliente">
                <h3 class="text-center m-5" style="color: #f00;">Compensación semanal cerrada.</h3>
              </div>
              <!-- fin formulario compensacion -->

              <div class="card">
                <div class="card-body table-responsive p-0 pt-3">
                  <div class="col-md-12">
                    <div class="text-center"><h3>Historial Compensaciones </h3></div>
    
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Cupo</th>
                          <!-- <th scope="col">Cupo Prepago</th> -->
                          <th scope="col">Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of compensacionesHistorial">
                          <th scope="row">{{item.compensacion_fecha}}</th>
                          <td>$ {{item.compensacion_cupo | decimal}}</td>
                          <!-- <td>$ {{item.compensacion_cupo_prepago | decimal}}</td> -->
                          <td>
                            <span class="bg-warning" style="padding: 5px;" *ngIf="item.compensacion_estado == 1"><b>Pendiente</b></span> 
                            <span class="bg-primary" style="padding: 5px;" *ngIf="item.compensacion_estado == 2"><b>Procesando</b></span> 
                            <span class="bg-success" style="padding: 5px;" *ngIf="item.compensacion_estado == 3"><b>Procesado</b></span> 
                            <span class="bg-danger" style="padding: 5px;" *ngIf="item.compensacion_estado == 4"><b>Cancelada</b></span>              
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>



            </div>
          </div>

        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  