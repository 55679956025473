import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar-right-links',
  templateUrl: './navbar-right-links.component.html',
  styleUrls: ['./navbar-right-links.component.css']
})
export class NavbarRightLinksComponent implements OnInit {

  comercio_id: any;
  loggedIn: any;
  perfil_id: any;
  name: any;
  id: any;

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.comercio_id = localStorage.getItem('comercio_id');
    this.loggedIn = localStorage.getItem('logger');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.name = localStorage.getItem('name');
  }

  CerrarSesion(){
    localStorage.removeItem('perfil_id');      
    localStorage.removeItem('nombre');      
    localStorage.removeItem('loggedIn'); 
    localStorage.removeItem('comercio_id');   
    localStorage.clear();
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Sesión Cerrada',
      showConfirmButton: false,
      timer: 1500,
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      }
    });
    //location.reload();
    this.router.navigate(['./account/login']);
  }

}
