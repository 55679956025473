import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from 'src/app/ApiServices/services.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: any;
  password: any;

  perfil_id: any;

  constructor(public router: Router,
              public servicios: ServicesService) { }

  ngOnInit(): void {
  }

  Ingresar(){
    let params = {
      login: this.usuario,
      password: this.password,
    };
    this.servicios.Login(params)
    .subscribe( (data) => {
      if (data["logger"] == true){
        // console.log(data);
        localStorage.setItem('loggedIn','true'); 
        localStorage.setItem('id',data['comercio_id']);
        localStorage.setItem('perfil_id',data['perfil_id']);
        localStorage.setItem('nombre',data['nombre']);
        localStorage.setItem('comercio_id',data['comercio_id']);
        this.perfil_id = localStorage.getItem('perfil_id');
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
          if(this.perfil_id == 1) {
            this.router.navigate(['operaciones']);
          } else if (this.perfil_id == 2){
            this.router.navigate(['operaciones']);
          } else if (this.perfil_id == 3){
            this.router.navigate(['recaudo/listado']);
          } else if (this.perfil_id == 4){
            this.router.navigate(['operaciones']);
          } else if (this.perfil_id == 7){
            this.router.navigate(['afiliaciones/listado']);
          }  
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
      }
    });
  }

}
