import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-listado-recaudo',
  templateUrl: './listado-recaudo.component.html',
  styleUrls: ['./listado-recaudo.component.css']
})
export class ListadoRecaudoComponent implements OnInit {

  cargando: any;
  id: any;
  perfil_id: any;
  recaudo: any = [];
  pagesNumber: any;
  offset= 4;
  page: any;
  reporteExport:any;
  reporteMes:any;
  verItem:any = [];
  fecha_desde:any;
  fecha_hasta: any;


  constructor(public servicios: ServicesService,
              public router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    // console.log(this.id);
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.perfil_id);
    this.listadoRecaudo();
  }

  exportExcel(){
    this.cargando = true;
    let params = {
      export: true,
      id:this.id,
      fecha_desde: this.fecha_desde,
      fecha_hasta: this.fecha_hasta,
      mes:this.reporteMes,
    }
    // console.log(params);
    this.servicios.postRecaudo(params)
    .subscribe( (data) => {
      this.reporteExport = data['recaudo'];
      let fecha = data['fecha']
      // console.log(this.reporteExport);
      
      const fileName = this.fecha_desde+'-'+this.fecha_hasta+'-reporte_recaudos.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

      // ANCHO COLUMNAS
      ws['!cols'] = [ {width:2},{width:8},{width:15}, {width:10},{width:20},{width:20},{width:40},{width:50},{width:20},{width:20}];
      // ELIMINA COLUMNA1
      ws['!cols'][0] = { hidden: true };
      // ws['!cols'][1] = { hidden: true };
      // ws['!cols'][13] = { hidden: true };
      // ws['!cols'][14] = { hidden: true };
      // ws['!cols'][18] = { hidden: true };
      // // ws['G2'] = { Target:"#E2" };
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // CAMBIA CABECERA
      XLSX.utils.sheet_add_aoa(ws, [["log","ID","FECHA","HORA","CUENTA","REFERENCIA","NOMBRE","PUNTO","VALOR","ESTADO"]]);
      XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });  

      XLSX.utils.book_append_sheet(wb, ws, 'reporte');
      XLSX.writeFile(wb, fileName);

      this.cargando = false;
    });
    
    
  }  

  listadoRecaudo(page = null){
    this.cargando = true;
    if(!page){
      this.servicios.getRecaudo(this.id+'-'+this.perfil_id+''+page).subscribe( (data) => {
        this.recaudo = data['recaudo'];
        this.cargando = false;
        // console.log(data['recaudo']);
        this.ForpagesNumber()
      });
    }else{
      var url = this.servicios.getRecaudo(this.id+'-'+this.perfil_id+'-'+page).subscribe( (data) => {
        this.recaudo = data['recaudo'];
        this.cargando = false;
        // console.log(data['recaudo']);
        this.ForpagesNumber()

      });
    }     
  }

  EditarRecaudo(item){
    let navigationExtras: NavigationExtras = {
      state: {
        itemRecaudo: item
      }
    }
    this.router.navigate(['/recaudo/editar'],navigationExtras);
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.recaudo.current = page;
    this.listadoRecaudo(page);       
  }

  ForpagesNumber() {
    let from = this.recaudo.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.recaudo.total_page){
      to = this.recaudo.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  cerrarModal(info){
    // console.log(info);
    document.getElementById(info).style.display = "none";
  }

  verDetalle(item){
    // console.log(item);
    this.verItem = item;
  }
}
