
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Modulo Productos</h1>
              </div>
              <div class="col-sm-12 mt-3">  
                <button type="button" data-toggle="modal" data-target="#modalCrearProducto" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
              </div>
            </div>
          </div>
        </section>

        <div id="modalCrearProducto" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
            <div role="document" class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header ">
                  <h2 class="modal-title">Nuevo Producto</h2>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>                            
                </div>
                <div class="modal-body">
                    <form [formGroup]="form" (ngSubmit)="enviarNuevoProducto()">
                        <div class="row"> 
                            <div class="col-md-12">
                                <div style="margin-top:0%" class="form-group">
                                    <label>Nombre Producto <span style="color: red;">*</span></label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                                    formControlName="nombre" 
                                    type="text" 
                                    class="form-control" 
                                    name="nombre" 
                                    required>
                                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                      <div *ngIf="f.nombre.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label>Categoria Producto <span style="color: red;">*</span></label>
                                  <select [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                                  formControlName="producto_categoria_id" 
                                  type="text" 
                                  class="form-control" 
                                  name="producto_categoria_id" 
                                  required>
                                  <option *ngFor="let item of productos" [value]="item.id">{{item.nombre  | titlecase}}</option>
                                </select>                                  
                                </div>
                              </div> 
                              <div class="col-md-12">
                                <div style="margin-top:0%" class="form-group">
                                    <label>Porcentaje % </label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.porcentaje.errors }"
                                    formControlName="porcentaje" 
                                    type="text" 
                                    class="form-control" 
                                    name="porcentaje" 
                                    required>
                                    <!-- <div *ngIf="submitted && f.porcentaje.errors" class="invalid-feedback">
                                      <div *ngIf="f.porcentaje.errors.required">Campo obligatorio</div>
                                    </div> -->
                                </div>
                              </div> 
                              
                              <div class="col-md-12">
                                <div style="margin-top:0%" class="form-group">
                                    <label>Tarifa Fija $</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.tarifa_fija.errors }"
                                    formControlName="tarifa_fija" 
                                    type="text" 
                                    class="form-control" 
                                    name="tarifa_fija" 
                                    required>
                                    <div *ngIf="submitted && f.tarifa_fija.errors" class="invalid-feedback">
                                      <div *ngIf="f.tarifa_fija.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                              </div>                               
                        </div>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                        <button #CerrarModal type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </form>
                </div>
                <div class="modal-footer">
                  
                </div>
              </div>
            </div>
          </div>        
  
        <section class="content">
        <div class="row">
            <div class="col-12" *ngFor="let item of productos">
              <div class="row">
                <div class="col-12 "><h3>{{item.nombre | titlecase}}</h3></div>
                <div class="col-lg-3 col-md-4 col-xs-6" *ngFor="let itemProduct of item.productos">
                  <div class="card">
                    <div class="card-body" style="padding-bottom: 0px;">
                        <h3>{{itemProduct.nombre | titlecase}}</h3>
                        <div>Porcentaje <b>%{{itemProduct.porcentaje}}</b></div>
                        <div>Tarifa Fija <b>${{itemProduct.tarifa_fija}}</b></div>
                    </div>  
                    <div class="card-footer text-muted text-right">
                        <span class="card-link"><i class="fa fa-pen"></i></span>
                        <span class="card-link"><i class="fa fa-trash"></i></span>
                    </div>                  

                </div>

                </div>
              </div>

            </div>
        </div>

 
        </section>   
      </div> 
      <app-footer-main></app-footer-main>  
  </div>  