
<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-12">
                  <h1>Mensajero Nueva Solicitud</h1>
                </div>
              </div>
            </div>
        </div>      

      <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-default">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row" id="selectOperacion">
                                        <div class="col-12" style="text-align: center;">


                                            <div style="text-align: center;"><h3>Seleccione tipo de operación</h3></div>
                                            <!-- <ngx-barcode bc-value="415770727449967980200687642905390000000292009620210621" [bc-display-value]="true"></ngx-barcode> -->
                                            <br>

                                            <!-- <button id="btnBanco" type="button" class="btn btn-lg btn-danger" style="margin-right: 10px;" (click)="opcionBanco()">Bancos</button>
                                            <button id="btnRecaudo" type="button" class="btn btn-lg btn-danger" (click)="opcionRecaudo()">Recaudos</button> -->
                                              <button *ngFor="let item of listProductosComercioCategoria; let i = index" type="button" class="btn btn-lg btn-danger" style="margin-right: 10px;" (click)="opcionProducto(item.nombre,item.productos)">{{item.nombre | titlecase}}</button>
                                        </div>
                                    </div>                                    
                                </div>



                                <!-- Banco -->
                                <form [formGroup]="form" (ngSubmit)="EnviarMensajeroBanco()">
                                  <div class="card-body" id="banco" style="display: none">
                                    <h2>Banco</h2>
                                    <hr>
                                      <div class="row">
                                        <div class="col-md-5">
                                          <div style="margin-top:0%" class="form-group">
                                              <label>Nombre <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"
                                              formControlName="nombre" 
                                              type="text" 
                                              class="form-control" 
                                              name="nombre" 
                                              required>
                                              <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                                <div *ngIf="f.nombre.errors.required">Campo obligatorio</div>
                                              </div>
                                          </div>
                                        </div> 

                                        <div class="col-md-3">
                                          <div class="form-group">
                                              <label>Celular <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && f.celular.errors }" 
                                              type="number" 
                                              name="celular" 
                                              class="form-control" 
                                              formControlName="celular">
                                              <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                                                <div *ngIf="f.celular.errors.required">Campo obligatorio</div>
                                              </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4">
                                          <div class="form-group">
                                              <label style="font-size: 14px;">Correo <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                              name="email" 
                                              type="email" 
                                              class="form-control" 
                                              formControlName="email">
                                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">El Email es obligatorio</div>
                                              </div>
                                          </div>
                                        </div>                                         



                                        <div class="mt-4 form-group col-md-12">
                                          <label>Seleccione Banco <span style="color: red;">*</span>{{form.value.banco | titlecase }}</label>
                                          <div>
                                            <button type="button" class="mr-1 btn btn-outline-secondary " *ngFor="let item of listProductos ; let i = index" [ngClass]="{'btn-success': item.nombre === form.value.banco, 'btn-outline-secondary':item.nombre !== form.value.banco}"  (click)="selectTipoBancoProductoId(item)">{{item.nombre | titlecase }}</button>
                                          </div>
                                        </div> 

                                        <div class="form-group col-md-4">
                                          <label>Tipo <span style="color: red;">*</span></label>
                                          <select [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }"
                                          formControlName="tipo" 
                                          class="custom-select" 
                                          name="tipo">
                                            <option value="ahorros">Ahorros</option>
                                            <option value="corriente">Corriente</option>
                                          </select> 
                                          <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback">
                                            <div *ngIf="f.tipo.errors.required">Campo obligatorio</div>
                                          </div>
                                        </div> 

                                        <div class="form-group col-md-4">
                                          <label>Convenio {{ this.form.get('convenio').value }}</label>
                                          <select [ngClass]="{ 'is-invalid': submitted && f.convenio.errors }" class="custom-select" formControlName="convenio" name="convenio">
                                            <option value="NO">NO</option>
                                            <option value="SI">SI</option>
                                          </select> 
                                        </div>
                                        
                                        <div class="col-md-6" *ngIf="this.form.get('convenio').value == 'SI'">
                                          <div class="form-group">
                                              <label>Numero de Convenio <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && f.numero_convenio.errors }" 
                                              type="text" 
                                              name="numero_convenio" 
                                              class="form-control" 
                                              formControlName="numero_convenio">
                                              <div *ngIf="submitted && f.numero_convenio.errors" class="invalid-feedback">
                                                <div *ngIf="f.numero_convenio.errors.required">Campo obligatorio</div>
                                              </div>
                                          </div>
                                        </div>                                        

                                        <div class="col-md-6">
                                          <div class="form-group">
                                              <label>Numero de Cuenta <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && f.numero_cuenta.errors }" 
                                              type="text" 
                                              name="numero_cuenta" 
                                              class="form-control" 
                                              formControlName="numero_cuenta">
                                              <div *ngIf="submitted && f.numero_cuenta.errors" class="invalid-feedback">
                                                <div *ngIf="f.numero_cuenta.errors.required">Campo obligatorio</div>
                                              </div>
                                          </div>
                                        </div>

                                        <div class="col-md-6">
                                          <div class="form-group">
                                              <label>Valor <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && f.valor.errors }" 
                                              name="valor" 
                                              class="form-control" 
                                              formControlName="valor"
                                              currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"
                                              min="900"
                                              style="font-size: 2em;">
                                              <div *ngIf="submitted && f.valor.errors" class="invalid-feedback">
                                                <div *ngIf="f.valor.errors.required">Campo obligatorio</div>
                                              </div>
                                          </div>
                                        </div>


                                      </div>

                                      <button type="submit" *ngIf="form.value.banco"  class="btn btn-primary float-right" [disabled]="disabledButton">
                                        <span *ngIf="!disabledButton">Enviar Solicitud</span>
                                        <span *ngIf="disabledButton">
                                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                          Procesando...
                                        </span>
                                      </button>

                                      <div><b><span style="color:red;">Nota:</span> Por favor verificar los campos antes de enviar la solicitud.</b></div>
                                  </div>
                                </form>

                                <!-- Fin Banco -->
                 
                                <!-- Buscador recaudo -->
                                <div id="modalVerRecaudo" class="modal" tabindex="-1" role="dialog">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title">Empresas</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <form class="form-inline d-flex justify-content-center md-form form-sm mt-0">
                                          <input [(ngModel)]="racaudoEmpresa" name="racaudoEmpresa" class="form-control form-control-sm ml-3 w-75" type="text" placeholder="Buscar empresa"
                                            aria-label="Search">
                                            <button (click)="buscarEmpresa()" type="button" class="btn btn-secondary btn-sm ml-2">Buscar</button>
                                        </form>

                                        <table class="table mt-2 table-hover" *ngIf="empresaListado">
                                          <thead>
                                            <tr>
                                              <th scope="col">Razon Social</th>
                                              <th scope="col">Documento</th>
                                            </tr>
                                          </thead>
                                          <tbody *ngFor="let item of empresaListado; let i = index">
                                            <tr (click)="razonSocial(item.razon_social,item.numero_documento,item.id)">
                                              <td style="cursor: pointer;">{{item.razon_social}}</td>
                                              <td style="cursor: pointer;">{{item.numero_documento}}</td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <div *ngIf="!empresaListado" style="text-align: center;margin-top: 10px;"><h6>No hay registros.</h6></div>


                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- Recaudo -->
                                <form [formGroup]="formulario" (ngSubmit)="EnviarMensajeroRecaudo()">
                                  <div class="card-body" id="recaudo" style="display: none;">
                                    <h2>Recaudo</h2>
                                    <hr>
                                    <div class="row">


                                      <div class="mt-4 form-group col-md-12">
                                        <label>Seleccione Tipo Recaudo <span style="color: red;">*</span></label>
                                        <div>
                                          <button type="button" class="mr-1 btn btn-outline-secondary " *ngFor="let item of listProductos ; let i = index" [ngClass]="{'btn-success': item.producto_id === formulario.value.producto_id, 'btn-outline-secondary':item.producto_id !== formulario.value.producto_id}"  (click)="selectTipoRecaudoProductoId(item)">{{item.nombre | titlecase }}</button>
                                        </div>
                                      </div> 

                                      <div  *ngIf="productoId ==3" class="col-md-12">
                                        <div class="form-group">
                                            <label>
                                              Empresa Recaudo Registrada<span style="color: red;">*</span>
                                              <button style="margin-top: 3px;" data-toggle="modal" data-target="#modalVerRecaudo" type="button" class="btn btn-secondary btn-sm ml-2"><i class="fas fa-search"></i></button>
                                            </label> 
                                            <div class="row">
                                              <div class="col-md-12">
                                                <ul class="list-group list-group-flush mb-1">
                                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <h2>{{razonsocial}} {{numeroDocumento}}</h2>
                                                  </li>
                                                </ul>

                                              </div>
                                            </div>
                                            
                                        </div>
                                      </div>                                     
                                      
                                      <div *ngIf="productoId ==4" class="col-md-12">
                                        <div style="margin-top:0%" class="form-group">
                                            <label>Empresa Nombre <span style="color: red;">*</span></label>
                                            <input [ngClass]="{ 'is-invalid': submitted && ff.recaudo_empresa_nombre.errors }"
                                            formControlName="recaudo_empresa_nombre" 
                                            type="text" 
                                            class="form-control" 
                                            name="recaudo_empresa_nombre" 
                                            required>
                                            <div *ngIf="submitted && ff.recaudo_empresa_nombre.errors" class="invalid-feedback">
                                              <div *ngIf="ff.recaudo_empresa_nombre.errors.required">Campo obligatorio</div>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="col-md-6">
                                      <div class="form-group">
                                          <label>Referencia <span style="color: red;">*</span></label>
                                          <input [ngClass]="{ 'is-invalid': submitted && ff.recaudo_referencia.errors }" 
                                          type="text" 
                                          name="recaudo_referencia" 
                                          class="form-control" 
                                          formControlName="recaudo_referencia">
                                          <div *ngIf="submitted && ff.recaudo_referencia.errors" class="invalid-feedback">
                                            <div *ngIf="ff.recaudo_referencia.errors.required">Campo obligatorio</div>
                                          </div>
                                      </div>
                                    </div>                                    

                                        
                                      <div class="col-md-5">
                                          <div style="margin-top:0%" class="form-group">
                                              <label>Nombre <span style="color: red;">*</span></label>
                                              <input [ngClass]="{ 'is-invalid': submitted && ff.nombre.errors }"
                                              formControlName="nombre" 
                                              type="text" 
                                              class="form-control" 
                                              name="nombre" 
                                              required>
                                              <div *ngIf="submitted && ff.nombre.errors" class="invalid-feedback">
                                                <div *ngIf="ff.nombre.errors.required">Campo obligatorio</div>
                                              </div>
                                          </div>
                                      </div> 

                                      <div class="col-md-3">
                                        <div class="form-group">
                                            <label style="font-size: 14px;">Celular <span style="color: red;">*</span></label>
                                            <input [ngClass]="{ 'is-invalid': submitted && ff.celular.errors }"
                                            name="celular" 
                                            type="number" 
                                            class="form-control" 
                                            formControlName="celular">
                                            <div *ngIf="submitted && ff.celular.errors" class="invalid-feedback">
                                              <div *ngIf="ff.celular.errors.required">Campo obligatorio</div>
                                            </div>
                                        </div>
                                      </div>

                                      <div class="col-md-4">
                                        <div class="form-group">
                                            <label style="font-size: 14px;">Correo <span style="color: red;">*</span></label>
                                            <input [ngClass]="{ 'is-invalid': submitted && ff.email.errors }"
                                            name="email" 
                                            type="email" 
                                            class="form-control" 
                                            formControlName="email">
                                            <div *ngIf="submitted && ff.email.errors" class="invalid-feedback">
                                              <div *ngIf="ff.email.errors.required">El Email es obligatorio</div>
                                            </div>
                                        </div>
                                      </div> 


                                      <div class="col-md-6">
                                        <div class="form-group">
                                            <label>valor <span style="color: red;">*</span> </label>
                                            <input [ngClass]="{ 'is-invalid': submitted && ff.valor.errors }" 
                                            name="valor" 
                                            class="form-control" 
                                            formControlName="valor"
                                            currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"
                                            min="900"
                                            style="font-size: 2em;">
                                            <div *ngIf="submitted && ff.valor.errors" class="invalid-feedback">
                                              <div *ngIf="ff.valor.errors.required">Campo obligatorio</div>
                                            </div>
                                        </div>
                                      </div>

                                        
                                    </div>
                                    <button type="submit" *ngIf="formulario.value.producto_id && formulario.value.recaudo_empresa_nombre"  class="btn btn-primary float-right" [disabled]="disabledButton">
                                      <span *ngIf="!disabledButton">Enviar Solicitud</span>
                                      <span *ngIf="disabledButton">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Procesando...
                                      </span>
                                    </button>
                                    <!-- <button type="submit" *ngIf="formulario.value.producto_id && formulario.value.recaudo_empresa_nombre" class="btn btn-primary float-right">Enviar Solicitud</button> -->
                                    <div><b><span style="color:red;">Nota:</span> No se recaudan facturas vencidas.</b></div>

                                </div>
                                </form>
                                <!-- Fin Recaudo -->

                                <!-- Resumen -->
                                <div class="col-md-12" id="resumen" style="display: none;">
                                    <h2>Resume</h2><hr>
                                </div>
                                <!-- Fin Resumen -->

                            </div>
                        </div>
                    </div>                    
                </div>          
            </div>
        </div>
      </div>

      </div>
      
      <app-footer-main></app-footer-main>  
  </div>  
  
  
  
  