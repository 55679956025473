import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/ApiServices/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nueva-compensacion',
  templateUrl: './nueva-compensacion.component.html',
  styleUrls: ['./nueva-compensacion.component.css']
})
export class NuevaCompensacionComponent implements OnInit {

  comercio_id: any;
  comercio: any = [];

  form: FormGroup;
  submitted = false;
  compensacion: any = {};
  fieldTextType: boolean;
  cargandoImg = false;
  loaderImgComprobante: boolean = false;
  idItem: any;

  public imagePath;
  imgURL: any;
  imgurl: any;
  public message: string;
  img: any;
  id: any;
  disabledButton = false;
  compensacionesHistorial: any = [];
  ultimaCompensacion: any;
  compensacionCliente: any;
  cupoAsignado: any;


  constructor(public router: Router,
              public servicios: ServicesService,
              private formBuilder: FormBuilder) {
              }
  ngOnInit() {
    this.comercio_id = localStorage.getItem('comercio_id');
    this.form = this.formBuilder.group({      
      comercio_id: ['', [Validators.required]],
      banco: ['', [Validators.required]],
      cupo: ['', [Validators.required]],
      // cupo_prepago: ['', [Validators.required]],
      detalle: ['', [Validators.required]],
      img: ['', [Validators.nullValidator]],
      fecha: ['', [Validators.required]],
    });
    this.getComercio();
    this.HistorialCompensacion(this.comercio_id);
  }

  

  get f() { return this.form.controls; }

  getComercio(){
    let params ={
      get: true,
      id: this.comercio_id
    }    
    this.servicios.postComercio(params).subscribe( (data) => {
      this.comercio = data['comercio'];     
    })
  }

  EnviarCompensacion(){
    this.submitted = true;
    this.disabledButton = true;

    // if(this.form.invalid){
    //   this.disabledButton = false
    //   return;
    // }

    this.form.controls.comercio_id.setValue(this.comercio_id);
    this.form.controls.banco.setValue('bancolombia');
    this.form.controls.img.setValue(this.img);    
      // console.log(this.form.value);
    
    this.compensacion = this.form.value;
     let params = {
          nuevo: true,
          compensacion: this.compensacion
        }
        // console.log(params);
        this.servicios.postCompensacion(params)
        .subscribe( (data) => {
          // console.log(data);
          if (data["logger"] == true){
            this.HistorialCompensacion(this.comercio_id);
            this.router.navigate(['../compensaciones/nuevo'])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
              this.disabledButton = false;
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: data['msg'],
              showConfirmButton: false,
              timer: 1500,
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            });
            this.disabledButton = false;
          }
        });
  }

  preview(files) {
    this.idItem = '';
    this.loaderImgComprobante = true;

    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "No hay fotos.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      // console.log("esta es la imagen"+this.imgURL);
      this.GuardarImagen();
    }

  }

  GuardarImagen(){
    if(this.imgURL) {
      let params = {
        archivo: this.imgURL
      };
      // this.cargandoImg = true;
      this.servicios.enviarImgComprobante(params)
      .subscribe( (data) => {
        if(data['logger'] == true){
          this.img = data['filename'];
          this.imgurl = data['fileUrl'];
          // this.cargandoImg = false;
          this.loaderImgComprobante = false;
          // this.SetEditarImg();
          // console.log(this.loaderImgComprobante);
          
        }
      });
    } else {
      Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Porfavor seleccione una imagen",
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
    }
  }


  HistorialCompensacion(comercio_id){
    this.servicios.getCompensacionesHistorial(comercio_id).subscribe( (data) => {
      this.compensacionesHistorial = data['compensaciones'];
      this.ultimaCompensacion = data['ultimaCompensacion'];
      this.compensacionCliente = ""; // habilita compensaciones dobles temporal
      // this.compensacionCliente = data['compensacionCliente'];
      this.cupoAsignado = data['cupoAsignado'];
      // console.log(data['compensaciones']);
    });      
  }

  estadoCorresponsal(estado){
    let params = {
      estadoCorresponsal: true,
      estado: estado,
      id: this.comercio_id
    }
    this.servicios.postCompensacion(params)
    .subscribe( (data) => {
      console.log(data);
      if (data["logger"] == true){

        document.getElementById('estadoCorresponsal').style.display="none";

        this.router.navigate(['../compensaciones/nuevo'])
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: data['msg'],
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });
          this.disabledButton = false;
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });
        this.disabledButton = false;
      }
    });    
  }

}
