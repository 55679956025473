
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12">
              <h1>Modulo Comercio</h1>
            </div>
            <div class="col-sm-12 col-md-4 mt-3">             
              <button type="button" routerLink="/comercios/nuevo" class="btn btn-success"><i class="fa fa-plus"></i> Nuevo</button>
              <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
              <button type="button" (click)="listadoComercios()"  *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
            </div>
            <div class="col-sm-12 col-md-8 mt-3">
              <input [(ngModel)]="buscar" name="buscar" type="text" (change)="filtro()" placeholder="Buscar por contacto, razón social, documento o Zona" class="form-control float-right text-uppercase">
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Perfil</th>
                      <th>Documento</th>
                      <th>Nombre</th>
                      <th>Razon Social</th>
                      <th>Zona</th>
                      <th>Contacto</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of comercios.items">
                      <td>{{item.id}}</td>
                      <td (click)="EditarComercio(item)" style="cursor: pointer;"><span *ngIf="item.perfil_id==1">Administrador</span> <span *ngIf="item.perfil_id ==2">Vendedor</span> <span *ngIf="item.perfil_id ==3">Empresario</span> <span *ngIf="item.perfil_id ==4">Punto</span></td>
                      <td>{{item.numero_documento}}</td>
                      <td>{{item.nombre}}</td>
                      <td>{{item.razon_social}}</td>
                      <td>{{item.zona}}</td>
                      <td>{{item.celular}}</td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button (click)="EditarComercio(item)" type="button" class="dropdown-item"><i class="fas fa-pen"></i> Editar</button>
                            <button (click)="MultiproductoComercio(item)" type="button" class="dropdown-item"><i class="fas fa-shopping-basket"></i> Multiproductos</button>
                            <div class="dropdown-divider"></div>
                            <button (click)="EliminarComercio(item)" type="button" class="dropdown-item" style="color: red;"><i class="fas fa-trash"></i> Borrar</button>
                          </div>
                        </div>                         
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>


              <!-- paginador -->
              <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" *ngIf="comercios.current > 1">
                    <button type="button" class="page-link" (click)="changePage(comercios.current - 1)">
                      <span>Atras</span>
                    </button>
                  </li>                  

                  <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': comercios.current == page}">
                    <button type="button" class="page-link" (click)="changePage(page)">
                      {{ page }}
                    </button>
                  </li>                  

                  <li class="page-item" *ngIf="comercios.current < comercios.total_page">
                    <button type="button" class="page-link" (click)="changePage(comercios.next)">
                      <span>Siguiente</span>
                    </button>
                  </li>                  
                </ul>
                <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                  <p>Total registros encontrados: <b>{{comercios.size}}</b></p>
                </div>
              </div>              
              <!-- Fin paginador -->               
            
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>   
      </section>   
    </div> 
    <app-footer-main></app-footer-main>  
</div>  