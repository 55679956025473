<div class="wrapper">
    <app-navbar-main></app-navbar-main>
    <app-sidebar-main></app-sidebar-main>  
  
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Reporte Conciliación Puntos</h1>
              </div>
              <div class="col-sm-12 col-md-4 mt-3">
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button type="button" (click)="listadoPuntos()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
              </div>
            </div>
          </div>
        </section>
            <!-- <button type="button" class="btn btn-success" style="margin-left: 1%;"><i class="fa fa-file-excel"></i> </button> -->
            <section class="content">
            <div class="row">
                <div class="col-7">
                <div class="card">
                    <div class="card-header">
                        <h2>Listado de Puntos</h2>
                        <h5 class="float-right">Saldo total: ${{saldo_total | decimal}}</h5>
                    </div>
                    <div class="card-body table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>COMERCIO</th>
                            <th>Municipio</th>
                            <th>SALDO</th>
                            <th>GANANCIA</th>
                            <th>CONCILIACIONES</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of puntos.items">
                            <td>{{item.id}}</td>
                            <td>{{item.razon_social | titlecase}}</td>
                            <td>{{item.municipio }}</td>
                            <td>${{(item.saldo ? item.saldo : 0) | decimal}}</td>
                            <td>${{(item.ganancia ? item.ganancia : 0) | decimal}}</td>
                            <td><button class="btn btn-info" (click)="onSelectPunto(item)">Ver</button></td>
                        </tr>

                        </tbody>
                    </table>
                    </div>


                    <!-- paginador -->
                    <div class="card-footer clearfix">
                    <ul class="pagination pagination-sm m-0 float-right">
                        <li class="page-item" *ngIf="puntos.current > 1">
                        <button type="button" class="page-link" (click)="changePage(puntos.current - 1)">
                            <span>Atras</span>
                        </button>
                        </li>                  

                        <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': puntos.current == page}">
                        <button type="button" class="page-link" (click)="changePage(page)">
                            {{ page }}
                        </button>
                        </li>                  

                        <li class="page-item" *ngIf="puntos.current < puntos.total_page">
                        <button type="button" class="page-link" (click)="changePage(puntos.next)">
                            <span>Siguiente</span>
                        </button>
                        </li>                  
                    </ul>
                    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                        <p>Total registros encontrados: <b>{{puntos.size}}</b></p>
                    </div>
                    </div>              
                    <!-- Fin paginador -->               
                
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                </div>
                
            <div class="col-5" *ngIf="isPuntoSelected">
                <div class="card">
                    <div class="card-header">
                        <h2>Listado de Conciliaciones del punto: {{puntoSelected.razon_social | titlecase}}</h2>
                        <h5 class="float-right">Total: ${{(conciliacion_total ? conciliacion_total : 0) | decimal}}</h5>
                    </div>
                  <div class="card-body table-responsive p-0">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>RECAUDADOR</th>
                          <th>VALOR</th>
                          <th>FECHA</th>
                          <th>ESTADO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of conciliaciones.items">
                          <td>{{item.id}}</td>
                          <td>{{item.recaudo_razon_social | titlecase}}</td>
                          <td>${{item.valor | decimal}}</td>
                          <td>{{item.conciliacion_at}}</td>
                          <td><h4><span class="badge bg-success" *ngIf="item.estado ==3">Recibido</span><span class="badge bg-danger" *ngIf="item.estado ==0">Rechazado</span><span class="badge bg-warning" *ngIf="item.estado ==1">Pendiente</span><span class="badge bg-info" *ngIf="item.estado ==2">Procesado</span></h4></td>
                          
                        </tr>
    
                      </tbody>
                    </table>
                  </div>
    
    
                  <!-- paginador -->
                  <div class="card-footer clearfix">
                    <ul class="pagination pagination-sm m-0 float-right">
                      <li class="page-item" *ngIf="conciliaciones.current > 1">
                        <button type="button" class="page-link" (click)="changePage2(conciliaciones.current - 1)">
                          <span>Atras</span>
                        </button>
                      </li>                  
    
                      <li class="page-item" *ngFor="let page of pagesNumber2" [ngClass]="{'active': conciliaciones.current == page}">
                        <button type="button" class="page-link" (click)="changePage2(page)">
                          {{ page }}
                        </button>
                      </li>                  
    
                      <li class="page-item" *ngIf="conciliaciones.current < conciliaciones.total_page">
                        <button type="button" class="page-link" (click)="changePage2(conciliaciones.next)">
                          <span>Siguiente</span>
                        </button>
                      </li>                  
                    </ul>
                    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
                      <p>Total registros encontrados: <b>{{conciliaciones.size}}</b></p>
                    </div>
                  </div>              
                  <!-- Fin paginador -->               
                
                  <!-- /.card-body -->
                </div>
                <!-- /.card -->
              </div>
            </div>   
            </section> 

            
          
    </div> 
    <app-footer-main></app-footer-main>  
</div>          