import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  cabecera;

  constructor(private http: HttpClient) {
    this.cabecera = new Headers();
    this.cabecera.append('Access-Control-Allow-Origin', '*');
    this.cabecera.append("Cache-Control", "no-cache");
    this.cabecera.append("Access-Control-Allow-Methods", "OPTIONS, GET, POST, PUT");
    this.cabecera.append("Access-Control-Allow-Headers", "gid, filename, Origin, X - Requested - With, Content - Type, Accept, Authorization");

    // localStorage.setItem('dev','MODO DESARROLLO'); 
    localStorage.setItem('dev', '');

  }

  public static get baseURL(): string { return "https://api1.pagoexpress.co/" };
  // public static get baseURL():string{ return"https://apideveloper.pagoexpress.co/"};
  // public static get baseURL():string{ return"http://localhost:8888/apiMensajeroVirtual/"};
  // public static get baseURL():string{ return"http://localhost/apirest-pagoexpress/"};


  Login(params) {
    return this.http.post(ServicesService.baseURL + 'login', params);
  }

  // USUARIOS

  getUsuarios(params) {
    return this.http.get(ServicesService.baseURL + 'usuario/' + params);
  }

  postUsuario(params) {
    return this.http.post(ServicesService.baseURL + 'usuario/', params);
  }


  // PROSPECTOS

  getProspectosAdmin(params) {
    return this.http.get(ServicesService.baseURL + 'prospectos/' + params);
  }

  postProspectosAdmin(params) {
    return this.http.post(ServicesService.baseURL + 'prospectos/', params);
  }


  // COMERCIO

  getComercios(params) {
    return this.http.get(ServicesService.baseURL + 'comercio/' + params);
  }

  postComercio(params) {
    return this.http.post(ServicesService.baseURL + 'comercio/', params);
  }

  getComercioCategoriaProductos(params) {
    return this.http.get(ServicesService.baseURL + 'comercio_categoria_producto/' + params);
  }

  getComercioProductos(params) {
    return this.http.get(ServicesService.baseURL + 'comercio_producto/' + params);
  }

  postComercioProductos(params) {
    return this.http.post(ServicesService.baseURL + 'comercio_producto/', params);
  }

  getComerciosPasacupo(params) {
    return this.http.get(ServicesService.baseURL + 'comercio_pasacupo/' + params);
  }

  postComerciosPasacupo(params) {
    return this.http.post(ServicesService.baseURL + 'comercio_pasacupo/', params);
  }

  //conciliación
  getComerciosConciliacion(params) {
    return this.http.get(ServicesService.baseURL + 'comercio_conciliacion/' + params);
  }

  postComerciosConciliacion(params) {
    return this.http.post(ServicesService.baseURL + 'comercio_conciliacion/', params);
  }


  // Mensajero
  getMensajero(params) {
    return this.http.get(ServicesService.baseURL + 'mensajero/' + params);
  }

  setMensajero(params) {
    return this.http.post(ServicesService.baseURL + 'mensajero/', params);
  }

  // Recaudo
  getRecaudo(params) {
    return this.http.get(ServicesService.baseURL + 'recaudo/' + params);
  }

  postRecaudo(params) {
    return this.http.post(ServicesService.baseURL + 'recaudo/', params);
  }

  // Importar Recaudo archivo plano
  postImportRecaudo(params) {
    return this.http.post(ServicesService.baseURL + 'import_recaudo/', params);
  }

  postBanco(params) {
    return this.http.post(ServicesService.baseURL + 'mensajero/', params);
  }

  getMensajeroEmpresa(racaudo) {
    return this.http.get(ServicesService.baseURL + 'buscar_empresa/' + racaudo);
  }

  // Recaudo Clientes
  getRecaudoClientes(params) {
    return this.http.get(ServicesService.baseURL + 'recaudo_clientes/' + params);
  }

  postRecaudoClientes(params) {
    return this.http.post(ServicesService.baseURL + 'recaudo_clientes/', params);
  }

  // Editar estado de la solicitud bancaria
  postEditarEstado(params) {
    return this.http.post(ServicesService.baseURL + 'mensajero/', params);
  }

  // Guardar Imagenes
  enviarImgOrden(params) {
    return this.http.post(ServicesService.baseURL + 'upload_img/', params, { headers: this.cabecera });
  }

  enviarImgComprobante(params) {
    return this.http.post(ServicesService.baseURL + 'upload_img_comprobante/', params, { headers: this.cabecera });
  }


  setFile(params) {
    return this.http.post(ServicesService.baseURL + 'upload_file/', params);
  }

  // Reporte Listado
  getReporte(params) {
    return this.http.post(ServicesService.baseURL + 'mensajero_reporte', params, { headers: this.cabecera });
  }

  getReporteExport(params) {
    return this.http.post(ServicesService.baseURL + 'mensajero_reporte_export', params, { headers: this.cabecera });
  }

  // Afiliaciones
  getAfiliaciones(params) {
    return this.http.get(ServicesService.baseURL + 'afiliaciones/' + params);
  }
  setAfiliacion(params) {
    return this.http.post(ServicesService.baseURL + 'afiliaciones/', params);
  }

  getAfiliacionesServicios() {
    return this.http.get(ServicesService.baseURL + 'afiliaciones_servicios/');
  }

  // COMPENSACIONES 

  getCompensaciones(params) {
    return this.http.get(ServicesService.baseURL + 'compensacion/' + params);
  }

  postCompensacion(params) {
    return this.http.post(ServicesService.baseURL + 'compensacion/', params);
  }

  getCompensacionesHistorial(params) {
    return this.http.get(ServicesService.baseURL + 'compensacion_historial/' + params);
  }

  // status

  getStatus(params) {
    return this.http.get(ServicesService.baseURL + 'status/' + params);
  }

  getStatusSaldosGanancias(params) {
    return this.http.get(ServicesService.baseURL + 'status_sg/' + params);
  }
  getPuntos(params) {
    return this.http.get(ServicesService.baseURL + 'punto/' + params);
  }


  getProductos(params) {
    return this.http.get(ServicesService.baseURL + 'producto/' + params);
  }
  setProducto(params) {
    return this.http.post(ServicesService.baseURL + 'producto/', params);
  }

  // listado de usuario
  getAsesores(params) {
    return this.http.post(ServicesService.baseURL + 'usuario/', params);
  }

  // listado de usuario
  setSolicitudSaldo(params) {
    return this.http.post(ServicesService.baseURL + 'solicitud_saldo/', params);
  }

  // listado de solicitudes de saldo
  getSolicitudesSaldo(params) {
    return this.http.get(ServicesService.baseURL + 'solicitud_saldo/' + params);
  }

  // mensajeria
  getMensajeria(params) {
    return this.http.get(ServicesService.baseURL + 'mensajeria/' + params);
  }

  setMensajeria(params) {
    return this.http.post(ServicesService.baseURL + 'mensajeria/', params);
  }

  //Departamentos
  getDepartamentos() {
    return this.http.get(ServicesService.baseURL + 'departamento/');
  }
  getDepartamentoMunicipios(params) {
    return this.http.get(ServicesService.baseURL + 'departamento/' + params);
  }

  // pasar_ganancia
  getPasarGanancia(params) {
    return this.http.get(ServicesService.baseURL + 'pasar_ganancia/' + params);
  }

  postPasarGanancia(params) {
    return this.http.post(ServicesService.baseURL + 'pasar_ganancia/', params);
  }

  // ancheta
  getAncheta(params) {
    return this.http.get(ServicesService.baseURL + 'ancheta/' + params);
  }

  postAncheta(params) {
    return this.http.post(ServicesService.baseURL + 'ancheta/', params);
  }


  // ECOMMERCE
  getEcommerceProducto(params) {
    return this.http.get(ServicesService.baseURL + 'ecommerce_producto/' + params);
  }

  setEcommerceProducto(params) {
    return this.http.post(ServicesService.baseURL + 'ecommerce_producto/', params);
  }

  // ECOMMERCE CATEGORIA
  getEcommerceCategoria() {
    return this.http.get(ServicesService.baseURL + 'ecommerce_categoria/');
  }

  // ECOMMERCE PRODUCTO CATEGORIA
  getEcommerceProductoCategoria() {
    return this.http.get(ServicesService.baseURL + 'ecommerce_producto_categoria/');
  }


  // PUBLIEXPRESS PRODUCTOS
  getPubliexpressProducto(params) {
    return this.http.get(ServicesService.baseURL + 'publiexpress_producto/' + params);
  }

  setPubliexpressProducto(params) {
    return this.http.post(ServicesService.baseURL + 'publiexpress_producto/', params);
  }
  // PUBLIEXPRESS ORDENTES
  getPubliexpressOrdenes(params) {
    return this.http.get(ServicesService.baseURL + 'publiexpress_ordenes/' + params);
  }

  setPubliexpressOrdenes(params) {
    return this.http.post(ServicesService.baseURL + 'publiexpress_ordenes/', params);
  }


}
