import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css']
})

export class SidebarMenuComponent implements OnInit {

  comercio_id: any;
  loggedIn: any;
  perfil_id: any;
  nombre: any;

  constructor() { 
  }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('comercio_id');
    this.loggedIn = localStorage.getItem('logger');
    this.perfil_id = localStorage.getItem('perfil_id');
    this.nombre = localStorage.getItem('nombre');
  }

}
