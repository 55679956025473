
<div class="wrapper">
  <app-navbar-main></app-navbar-main>
  <app-sidebar-main></app-sidebar-main>  

  <div class="content-wrapper">
      <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1>Ecommerce</h1>
              </div>
            </div>
          </div>
      </div>
<!-- 
      <div class="content row">
          <div class="col-lg-3 col-xs-6">
              <div class="info-box"  title="hoy">
              <span class="info-box-icon bg-primary">
              </span>
              <div class="info-box-content">
                  <span class="info-box-text">SOLICITUDES HOY</span>
              </div>
              </div>
          </div> 

          <div class="col-lg-3 col-xs-6">
              <div class="info-box"  title="hoy">
              <span class="info-box-icon bg-primary">
              </span>
              <div class="info-box-content">
                  <span class="info-box-text">PUNTOS</span>
              </div>
              </div>
          </div>                                     
      </div> -->

      <div class="content row">          
          <div class="col-lg-6 col-xs-6">
              <div class="info-box"  title="PRODUCTOS" routerLink="/productos/listado" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                  <i class="fa fa-boxes"></i>
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">MIS PRODUCTOS</span>
                </div>
              </div>
          </div>

          <div class="col-lg-6 col-xs-6">
            <div class="info-box"  title="VENTAS" routerLink="/ecommerce/ventas" style="cursor: pointer">
              <span class="info-box-icon bg-warning">
                <i class="fa fa-tags"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text">VENTAS</span>
              </div>
            </div>            
          </div> 
          
          <div class="col-lg-6 col-xs-6">
            <div class="info-box"  title="COMPRAS" routerLink="/ecommerce/compras" style="cursor: pointer">
              <span class="info-box-icon bg-warning">
                <i class="fa fa-shopping-bag"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text">MIS COMPRAS</span>
              </div>
            </div>            
          </div>  
          
          <div class="col-lg-6 col-xs-6">
            <a routerLink="/catalogo" target="_blank" style="color: #000;">
              <div class="info-box"  title="CATALOGO" style="cursor: pointer">
                <span class="info-box-icon bg-warning">
                  <i class="fa fa-gifts"></i>
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">CATALOGO</span>
                </div>
              </div> 
            </a>           
          </div>            
        
        

      </div>       

  </div>
    
    <app-footer-main></app-footer-main>  
</div>  

