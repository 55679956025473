import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/ApiServices/services.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-ordenes-publiexpress',
  templateUrl: './ordenes-publiexpress.component.html',
  styleUrls: ['./ordenes-publiexpress.component.css']
})
export class OrdenesPubliexpressComponent implements OnInit {


  comercio_id:any;
  perfil_id:any;
  cargando:boolean = false;
  editar:boolean = false;
  productos: any = [];
  ordenItem: any = [];
  itemImp: any = [];
  pagesNumber: any;
  offset= 3;

  constructor(private servicios: ServicesService, private router: Router) { }

  ngOnInit(): void {
    this.comercio_id = localStorage.getItem('id');
    this.perfil_id = localStorage.getItem('perfil_id');
    // console.log(this.perfil_id);
    this.listadoProductos();
  }


  listadoProductos(page = null){
    this.productos = [];
    this.ForpagesNumber();
    if(!page){
      this.servicios.getPubliexpressOrdenes(this.perfil_id+'-'+this.comercio_id+''+page).subscribe( (data) => {
        console.log(data);
        this.productos = data['obj'];
        this.cargando = false;
        this.ForpagesNumber()
      });
    }else{
        this.servicios.getPubliexpressOrdenes(this.perfil_id+'-'+this.comercio_id+'-'+page).subscribe( (data) => {
        this.productos = data['obj'];
        console.log('dentro else');
        console.log(this.productos);        
        this.cargando = false;
        this.ForpagesNumber()
      });
    }    
  }

  ForpagesNumber() {
    let from = this.productos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.productos.total_page){
      to = this.productos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  onVer(item){
    this.ordenItem = item;
    $('#verModal').modal('show');
  }


  estadoUpdate(estado, id){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea actualizar el estado la orden? `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
    }).then((result) => {
      if (result.value) {
        console.log('dentro');
        let params = {
          changeEstado: true,
          estado: estado,
          id: id
        }
        // console.log(this.Banco);        
        this.servicios.setPubliexpressOrdenes(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: data['msg'],
                  showConfirmButton: false,
                  timer: 1500,
                  showClass: {
                    popup: 'animated fadeInDown faster'
                  },
                  hideClass: {
                    popup: 'animated fadeOutUp faster'
                  }
                });
                // $('#verModal').modal('hide');
                this.listadoProductos();

                // this.router.navigate(['../publiexpress/operaciones'])

            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: data['msg'],
                showConfirmButton: false,
                timer: 1500,
                showClass: {
                  popup: 'animated fadeInDown faster'
                },
                hideClass: {
                  popup: 'animated fadeOutUp faster'
                }
              });
            }
            
        });        
        
      }
    });     
  }


}
