import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editar-recaudo',
  templateUrl: './editar-recaudo.component.html',
  styleUrls: ['./editar-recaudo.component.css']
})
export class EditarRecaudoComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  editarRecaudo: any = {};

  id: any;
  fecha: any;
  hora: any;
  nombre: any;
  celular: any;
  email: any;
  recaudo_referencia: any;
  valor: any;

  constructor(public router: Router,
              private formBuilder: FormBuilder) { 
                if(this.router.getCurrentNavigation().extras.state){
                  this.editarRecaudo = this.router.getCurrentNavigation().extras.state.itemRecaudo;
                  console.log(this.editarRecaudo);                  
                  this.id = this.editarRecaudo['id']; 
                  this.fecha = this.editarRecaudo['fecha']; 
                  this.hora = this.editarRecaudo['hora'];
                  this.nombre = this.editarRecaudo['nombre'];  
                  this.celular = this.editarRecaudo['celular'];  
                  this.email = this.editarRecaudo['email'];  
                  this.recaudo_referencia = this.editarRecaudo['recaudo_referencia'];  
                  this.valor = this.editarRecaudo['valor'];                        
                }
              }

  ngOnInit(): void {
    if(!this.id){
      this.router.navigate(['../recaudo/listado'])
    }
    this.form = this.formBuilder.group({      
      fecha: ['', [Validators.required]],
      hora: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      celular: ['', [Validators.required]],
      email: ['', [Validators.required]],
      recaudo: ['', [Validators.required]],
      recaudo_referencia: ['', [Validators.required]],
      valor: ['', [Validators.required]],
    }); 
  }

  get f() { return this.form.controls; } 

  EnviarRecaudo(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
  }
}
